import React from 'react'
import HomePage from '../home'
import MyPage from '../../components/MyPage'
import Button from '@material-ui/core/Button'

import TextAreaWithLabel from '../../components/TextAreaWithLabel'

import { actions as screensTextActions } from '../../redux/modules/screensText'

const personalConsultationQuestions = [
  'I expect and worry about pain in certain situations',
  'I will stop any activity as soon as I sense pain coming on',
  'I try to avoid activities that cause pain.',
  'As soon as pain comes on, I take medication to reduce it.',
  'When I hurt I think about the pain constantly.',
  'When pain comes on strong I think I might become paralyzed or more disabled.',
  'I find it hard to concentrate when I hurt'
]

class ScreensTextEdit extends MyPage {
  constructor (props) {
    super(props)
    this.state = {
      personalConsultationData: '',
      personalTraits: '',
      lifeExperience: '',
      newData: [],
      isDataChanged: false,
      isLoading: true,
      isDataSaveError: false,
      statusMessage: 'All data saved',
      error: false
    }
  }

  handlePersonalConsultationChange = e => {
    const { name, id, value } = e.target
    const changedGroupField = this.state.personalConsultationData

    if (id === '') {
      changedGroupField[name] = value
    } else if (id.indexOf('byQuestions') + 1) {
      const idIndex = id.split('.')
      changedGroupField[name][idIndex[0]][idIndex[1]] = value
    } else {
      changedGroupField[name][id] = value
    }

    this.setState({
      personalConsultationData: changedGroupField,
      isDataChanged: true
    })
  }

  handlePersonalTraitsChange = e => {
    const { name, id, value } = e.target
    const changedGroupField = this.state.personalTraits

    if (id === '') {
      changedGroupField[name] = value
    } else {
      changedGroupField[name][id] = value
    }

    this.setState({
      personalTraits: changedGroupField,
      isDataChanged: true
    })
  }

  handleLifeExperienceChange = e => {
    const { name, id, value } = e.target
    const changedGroupField = this.state.lifeExperience

    if (id === '') {
      changedGroupField[name] = value
    } else {
      changedGroupField[name][id] = value
    }

    this.setState({
      lifeExperience: changedGroupField,
      isDataChanged: true
    })
  }

  saveChanges = async () => {
    console.log('test')
    console.log(this.state.isDataChanged)
    if (!this.state.isDataChanged) {
      return
    }

    const savingData = await screensTextActions.saveData({
      personalConsultation: this.state.personalConsultationData,
      additionalConsultation: {
        personalTraits: this.state.personalTraits,
        lifeExperience: this.state.lifeExperience
      }
    })

    console.log('Saving Data: ', savingData)

    if (savingData.success) {
      this.setState({
        isDataChanged: false,
        statusMessage: 'All data saved'
      })
    } else {
      this.setState({
        statusMessage: savingData.error || `Error: Can't save data`,
        error: true
      })
    }
  }

  async componentDidMount () {
    const data = await screensTextActions.getData()

    this.setState({
      personalConsultationData: data.personalConsultation,
      personalTraits: data.additionalConsultation.personalTraits,
      lifeExperience: data.additionalConsultation.lifeExperience,
      isLoading: false
    })
  }

  render () {
    return (
      <HomePage router={this.props.router} addClassName="h-100">
        {!this.state.isLoading && (
          <div className="devices-page-wrapper">
            <h3>Edit screens text</h3>
            <div className="create-device-row">
              <h3>Personals Consultation</h3>

              <h4>Introducing</h4>
              <TextAreaWithLabel
                label="Introducing to questions:"
                defaultValue={
                  this.state.personalConsultationData.introducingToQuestions
                }
                onChange={this.handlePersonalConsultationChange}
                name="introducingToQuestions"
                id=""
              />

              <TextAreaWithLabel
                label="Introducing text:"
                defaultValue={this.state.personalConsultationData.intro}
                onChange={this.handlePersonalConsultationChange}
                name="intro"
                id=""
              />

              <TextAreaWithLabel
                label="Score screen:"
                defaultValue={this.state.personalConsultationData.scoreScreenLearnMore}
                onChange={this.handlePersonalConsultationChange}
                name="scoreScreenLearnMore"
                id=""
              />

              <TextAreaWithLabel
                label="The Pain We Treat:"
                defaultValue={this.state.personalConsultationData.thePainWeTreat}
                onChange={this.handlePersonalConsultationChange}
                name="thePainWeTreat"
                id=""
              />

              <h4>Analysis</h4>
              <TextAreaWithLabel
                label="Analysis (less then 7 score):"
                defaultValue={
                  this.state.personalConsultationData.analysis.less_7
                }
                onChange={this.handlePersonalConsultationChange}
                name="analysis"
                id="less_7"
              />

              <TextAreaWithLabel
                label="Analysis (between 8 and 25 scores):"
                defaultValue={
                  this.state.personalConsultationData.analysis.between_8_and_25
                }
                onChange={this.handlePersonalConsultationChange}
                name="analysis"
                id="between_8_and_25"
              />

              <TextAreaWithLabel
                label="Analysis (more then 25 scores):"
                defaultValue={
                  this.state.personalConsultationData.analysis.more_25
                }
                onChange={this.handlePersonalConsultationChange}
                name="analysis"
                id="more_25"
              />

              <h4>Analysis details</h4>
              {personalConsultationQuestions.map((question, index) => {
                return (
                  <TextAreaWithLabel
                    label={`Q: ${question}`}
                    defaultValue={
                      this.state.personalConsultationData.analysisDetails
                        .byQuestions[index]
                    }
                    onChange={this.handlePersonalConsultationChange}
                    key={index}
                    name="analysisDetails"
                    id={`byQuestions.${index}`}
                  />
                )
              })}

              <h4>View sources</h4>
              <TextAreaWithLabel
                label="Sources"
                defaultValue={this.state.personalConsultationData.viewSources}
                onChange={this.handlePersonalConsultationChange}
                name="viewSources"
                id=""
              />

              <h3>Additional Consultation - Persanal Traits</h3>
              <h4>Introducing</h4>
              <TextAreaWithLabel
                label="Introducing Text"
                defaultValue={this.state.personalTraits.intro}
                onChange={this.handlePersonalTraitsChange}
                name="intro"
                id=""
              />

              <h4>Analysis</h4>
              <TextAreaWithLabel
                label="Analysis"
                defaultValue={this.state.personalTraits.analysis}
                onChange={this.handlePersonalTraitsChange}
                name="analysis"
                id=""
              />

              <h4>Analysis details</h4>
              <TextAreaWithLabel
                label="Analysis in case when selected personal traits count 0 or 1"
                defaultValue={this.state.personalTraits.analysisDetails[0]}
                onChange={this.handlePersonalTraitsChange}
                name="analysisDetails"
                id="0"
              />

              <TextAreaWithLabel
                label="Analysis in case when selected personal traits count more then 1"
                defaultValue={this.state.personalTraits.analysisDetails[1]}
                onChange={this.handlePersonalTraitsChange}
                name="analysisDetails"
                id="1"
              />

              <h4>View sources</h4>
              <TextAreaWithLabel
                label="Sources"
                defaultValue={this.state.personalTraits.viewSources}
                onChange={this.handlePersonalTraitsChange}
                name="viewSources"
                id=""
              />

              <h3>Additional Consultation - Life experience</h3>
              <h4>Introducing</h4>
              <TextAreaWithLabel
                label="Introducing Text"
                defaultValue={this.state.lifeExperience.intro}
                onChange={this.handleLifeExperienceChange}
                name="intro"
                id=""
              />

              <h4>Analysis</h4>
              <TextAreaWithLabel
                label='Analysis when "yes" answers 1 or more'
                defaultValue={this.state.lifeExperience.analysis[0]}
                onChange={this.handleLifeExperienceChange}
                name="analysis"
                id="0"
              />

              <TextAreaWithLabel
                label='Analysis when no "yes" answers'
                defaultValue={this.state.lifeExperience.analysis[1]}
                onChange={this.handleLifeExperienceChange}
                name="analysis"
                id="1"
              />

              <h4>Analysis details</h4>
              <TextAreaWithLabel
                label="Analysis details"
                defaultValue={this.state.lifeExperience.analysisDetails}
                onChange={this.handleLifeExperienceChange}
                name="analysisDetails"
                id=""
              />

              <h4>View sources</h4>
              <TextAreaWithLabel
                label="Sources"
                defaultValue={this.state.lifeExperience.viewSources}
                onChange={this.handleLifeExperienceChange}
                name="viewSources"
                id=""
              />
            </div>

            <div className="create-device-row" style={{ flexDirection: 'row' }}>
              <Button
                variant="contained"
                color={this.state.dataChanged ? 'secondary' : 'primary'}
                onClick={this.saveChanges}
                style={{ width: '200px' }}
                disabled={!this.state.isDataChanged}
              >
                Save changes
              </Button>
              {(!this.state.isDataChanged || this.state.error) && (
                <p style={
                {
                  color: this.state.error ? 'red' : '#18ab18',
                  fontWeight: 'bold',
                  width: '200px',
                  textAlign: 'center',
                  marginTop: '0.4em',
                  marginBottom: '0'
                }}>{this.state.statusMessage}</p>
              )}
            </div>
          </div>
        )}
      </HomePage>
    )
  }
}

export default ScreensTextEdit
