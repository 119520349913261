import React from 'react';
import { Modal } from 'reactstrap';
// import { Link } from 'react-router';

import Paper from '@material-ui/core/Paper';
// import './Style.css';
// import Images from '../../themes';

import MyPage from '../../components/MyPage';
import MyTable from '../../components/MyTable';
import MyTableHeaderSort from '../../components/MyTableHeaderSort';
import { urlImg, urlAudio } from "../../redux/fetch/urlHelper";

import imageLib from '../../themes';

// import { actions as sessionActions } from '../../redux/modules/sessions';
import { actions as authorActions } from '../../redux/modules/authors';



// class CollectionSessions extends React.Component {
class CollectionSessions extends MyPage {
    constructor(props) {
        super(props);
        const obj = this.props.object;
        this.state = {
            modal: false,
            isCreating: ! this.props.object ? true : false,
            object: obj,
            // selected: obj.sessions,
            sessions: obj.sessions,
            authorSessions: [],
        };
        // this.toggle = this.toggle.bind(this);
    }

    // getObject = () => {
    //     if (this.props.object) {
    //         return this.props.object;
    //     } else {
    //         return {
    //             title: "",
    //             correctAnswer: 1,
    //             text: "",
    //         };
    //     }
    // }

    getTexts = () => {
        return {
            tableTitle: "Sessions",
        }
    }

    toggle = async () => {
        const list = await authorActions.getSessions(this.props.authorId);
        
        if (!this.state.modal) {
            const obj = this.props.object;
            console.log("LOAD", obj);
            this.setState({
                object: obj,
                sessions: obj.sessions,
                // selectedSessions: [],
                authorSessions: list,
                selected: obj.sessions,
            });
        }
        this.setState({ modal: !this.state.modal });
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    // handleSelect = (stateId, value) => {
    //   this.setState({ [stateId]: value });
    // }

    handleCheck = (e, selected) => {
        e.preventDefault();

        if (selected !== undefined) {
            const newSessions = [];
            selected.forEach((newSession, index) => {
                const oldSession = this.state.object.sessions.find(oldSession => oldSession._id === newSession._id);
                if (oldSession) {
                    oldSession.index = index;
                    newSessions.push(oldSession);
                } else {
                    newSession.index = index;
                    newSessions.push(newSession);
                }
            });
            this.state.object.sessions = newSessions;

        } else {
            this.state.object.sessions = [];
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        this.toggle();
        this.props.onExit(this.state.object);
    }



    save = async (e) => {
        e.preventDefault();
        if (window.confirm("Are you sure you want to save this question?")) {
    
            this.state.object.title = this.state.title;
            this.state.object.correctAnswer = this.state.correctAnswer;
            this.state.object.text = this.state.text;
          
            this.toggle();

            this.props.onExit(this.state.object);
        }
    }

    
    makeHead = () => {
        return (
            <div className={"table-head"}>

                {/* <div style={{ width: '10%', paddingLeft: "15px" }}>
                    <MyTableHeaderSort label="#" />
                </div> */}

                <div style={{ width: '10%', paddingLeft: "15px" }}>
                    <MyTableHeaderSort label="Image" />
                </div>

                <div style={{ width: '30%', paddingLeft: "15px" }}>
                    <MyTableHeaderSort
                        label="Title"
                        active={this.state.orderBy === "title"}
                        direction={this.state.order}
                        onClick={this.createSortHandler("title")}
                    />
                </div>

            
                {/* <div style={{ width: '20%', paddingLeft: "15px" }}>
                    <MyTableHeaderSort
                        label="Type"
                        active={this.state.orderBy === "type"}
                        direction={this.state.order}
                        onClick={this.createSortHandler("type")}
                    />
                </div> */}
                
                <div style={{ width: '15%', paddingLeft: "15px" }}>
                    <MyTableHeaderSort label="Audio File" />
                </div>

                <div style={{ width: '60%', paddingLeft: "15px" }}>
                    <MyTableHeaderSort label="Description" />
                </div>

            
                {/* <div style={{ width: '10%', paddingLeft: "15px" }}>
                    <MyTableHeaderSort
                        label="Free"
                        active={this.state.orderBy === "isFree"}
                        direction={this.state.order}
                        onClick={this.createSortHandler("isFree")}
                    />
                </div> */}
            

                {/* <div style={{ width: '20%', paddingLeft: "15px" }}>
                    <MyTableHeaderSort label="Actions" />
                </div> */}

            </div>
        );
    }

    makeRow = (item) => {
        return (
            <div className={"table-row"}>
                {/* <div style={{ width: "10%", paddingLeft: "15px" }}>{item.index}</div> */}

                <div style={{ width: "10%", paddingLeft: "15px" }}>
                    {item.imageFileName !== ""
                        ?
                        <img style={{'width': 50, 'height': 50, 'objectFit': 'contain'}} src={urlImg(item.imageFileName)} alt="" />
                        :
                        <img style={{'width': 50, 'height': 50, 'objectFit': 'contain'}} src={imageLib.noImage} alt="" />
                    }
                </div>

                <div style={{ width: "30%", paddingLeft: "15px" }}>{item.title}</div>
                {/* <div style={{ width: "20%", paddingLeft: "15px" }}>{sessionActions.getTypeStr(item.type)}</div> */}
                <div style={{ width: "15%", paddingLeft: "15px" }}><a href={urlAudio(item.audioFileName)}>Open</a></div>
                <div style={{ width: "60%", paddingLeft: "15px" }}>{item.description}</div>
                {/* <div style={{ width: "10%", paddingLeft: "15px" }}>{item.isFree === true ? <RemoveIcon /> : <ShopIcon />}</div> */}
                
                {/* <div style={{ width: "20%", paddingLeft: "15px" }}>
                    <button type="button" className="btn" onClick={(e) => this.edit(e, item)}>Edit</button>
                </div> */}
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.props.toggleButton}
                <button className="btn green mr-2" type="button" onClick={this.toggle}>
                    {this.state.isCreating ? "+" : "Edit"}
                </button>

                <Modal
                    toggle={this.toggle}
                    isOpen={this.state.modal}
                >
                    {/* <Paper router={this.props.router}> */}
                    <Paper style={{ width: '100%', padding: '2%' }}>

                        <MyTable
                            tableName={this.getTexts().tableTitle}
                            tableToolBar={this.makeToolBar}
                            tableHead={this.makeHead}
                            tableRow={this.makeRow}
                            // isDraggable={true}
                            isCheckable={true}
                            handleCheck={(e, selected) => { this.handleCheck(e, selected) }}
                            selected={this.state.selected}
                            hasIndex={true}
                            order={this.state.order}
                            orderBy={this.state.orderBy}
                            data={this.state.authorSessions}
                            rowsPerPage={10}
                        />

                        <div style={{display: "flex", "justify-content": "flex-end", paddingTop: "10px"}}>
                            <button type="button" className="btn green mr-2" onClick={ this.handleSubmit }>Confirm</button>
                            <button type="button" className="btn mr-2" onClick={ this.toggle }>Cancel</button>
                        </div>
                        
                    </Paper>
                </Modal>
            </div>
        );
    }
}

export default CollectionSessions;