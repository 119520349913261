import React from 'react'
import HomePage from '../home'
import Formsy from 'formsy-react'
import Dropzone from 'react-dropzone'
import MyPage from '../../components/MyPage'
import MyInput from '../../components/MyInput'
import MySelect from '../../components/MySelect'
import ErrorMessage from '../../components/errormessage'
import CloseIcon from '@material-ui/icons/Close'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import imageLib from '../../themes'
import { urlImg } from '../../redux/fetch/urlHelper'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import '../../components/QuillEditor/quill.css'
import quillSettings from '../../components/QuillEditor/quillSettings'
import { actions as challengesActions } from '../../redux/modules/challenges'
import { actions as challengeSubtopicActions } from '../../redux/modules/challengeSubtopics'

class ChallengeEdit extends MyPage {
  constructor (props) {
    super(props)
    const obj = this.getObject()

    this.state = {
      object: obj,
      title: obj.title,
      goalText: obj.goalText,
      whyText: obj.whyText,
      challengeNotificationTitle: obj.challengeNotificationTitle,
      challengeNotificationMessage: obj.challengeNotificationMessage,
      imageFileName: obj.imageFileName,
      imageURL: obj.imageURL,
      type: obj.type || '',
      subtype: obj.subtype || '',
      defaultLength: obj.defaultLength || undefined,
      isVisibleByDefault: obj.isVisibleByDefault || false,
      typeOptions: this.getTypeOptions(),
      subtypeOptions: this.getSubtypeOptions(),
      challengeSubtopics: [],
      challengeSubtopicOptions: [],
      selectedChallengeSubtopics: this.getSelectedChallengeSubtopics(obj),
      errorMessage: '',
      canSubmit: false,
      showValidationErrors: false,
      isLoading: false,
      openDialog: false
    }
  }

  getObject = () => {
    if (this.props.location.state && this.props.location.state.obj) {
      return this.props.location.state.obj
    } else {
      return {
        title: '',
        goalText: '',
        whyText: '',
        challengeNotificationTitle: '',
        challengeNotificationMessage: '',
        imageFileName: '',
        imageURL: '',
        type: '',
        subtype: '',
        defaultLength: undefined,
        challengeSubtopics: []
      }
    }
  }

  getTexts = () => {
    return {
      pageTitle: 'Challenge'
    }
  }

  handleChange = e => {
    e.preventDefault()
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleChangeFor = field => (value) => {
    this.setState({ [field]: value })
  }

  handleCheckboxChange = field => () => this.setState(state => ({ [field]: !state[field] }))

  isRecurrentSession () {
    return this.state.subtype === 'recurring'
  }

  cancel = e => {
    e.preventDefault()
    this.props.router.push('/challenges-list')
  }

  save = async e => {
    e.preventDefault()
    if (window.confirm('Are you sure you want to save this Challenge?')) {
      this.state.object.title = this.state.title
      this.state.object.goalText = this.state.goalText
      this.state.object.whyText = this.state.whyText
      this.state.object.challengeNotificationTitle = this.state.challengeNotificationTitle
      this.state.object.challengeNotificationMessage = this.state.challengeNotificationMessage
      this.state.object.imageFileName = this.state.imageFileName
      this.state.object.imageURL = this.state.imageURL
      this.state.object.isVisibleByDefault = this.state.isVisibleByDefault
      this.state.object.challengeSubtopics = []

      if (this.state.type) {
        this.state.object.type = this.state.type
      }

      if (this.state.subtype) {
        this.state.object.subtype = this.state.subtype
      }

      if (this.state.challengeSubtopics) {
        this.state.object.challengeSubtopics = this.state.selectedChallengeSubtopics.map(option => option.value)
      }

      if (this.isRecurrentSession()) {
        this.state.object.defaultLength = this.state.defaultLength
      }

      const result = await challengesActions.saveChallenge(this.state.object)
      if (result) {
        if (!result.success) {
          this.setState({ errorMessage: result.error })
        } else {
          this.cancel(e)
          this.setState({ errorMessage: '' })
        }
      }
    }
  }

  async componentDidMount () {
    this.loadChallengeSubtopics()
  }

  /**
   * Loads Challege Subtopics and sets to state
   * @param {number} page
   */
  async loadChallengeSubtopics (page = 1) {
    let { challengeSubtopics } = this.state
    const { success, data, error } = await challengeSubtopicActions.getList({
      page,
      perPage: 50,
      order: 'ASC',
      orderBy: 'title'
    })

    if (error) {
      this.setState({ errorMessage: error })
    }

    if (!success || !data) {
      return
    }

    const { items, total } = data

    if (!items || !items.length || !total) {
      return
    }

    challengeSubtopics = challengeSubtopics.concat(items)

    if (challengeSubtopics.length) {
      this.setState({
        challengeSubtopics,
        challengeSubtopicOptions: challengeSubtopics.map(({ _id, title }) => ({ value: _id, label: title }))
      },
        () => {
          const currentLength = this.state.challengeSubtopicOptions.length

          if (currentLength && currentLength < parseInt(total)) {
            this.loadChallengeSubtopics(page + 1)
          }
        })
    }
  }

  /**
   * Sets selected Subtopics options for the Challenge
   */
  getSelectedChallengeSubtopics ({ challengeSubtopics }) {
    if (challengeSubtopics && challengeSubtopics.length) {
      return challengeSubtopics.map(({ _id, title }) => ({ value: _id, label: title }))
    }

    return []
  }

  getTypeOptions () {
    return [
      { value: 'mind', label: 'Mind Challenge' },
      { value: 'body', label: 'Body Challenge' }
    ]
  }

  getSubtypeOptions () {
    return [
      { value: 'one-time', label: 'One-Time Challenge' },
      { value: 'recurring', label: 'Daily Recurring Challenge' }
    ]
  }

  render () {
    const style = { color: 'red' }
    let iimm = this.state.imageFileName === '' ? imageLib.noImage : urlImg(this.state.imageFileName)

    return (
      <HomePage router={this.props.router}>
        <div className="devices-page-wrapper">
          <ErrorMessage text={this.state.errorMessage} />
          <h3>{this.getTexts().pageTitle}</h3>
          {!this.state.isLoading && (
            <Formsy
              className="create-device-form"
              onValidSubmit={this.submit}
              onInvalidSubmit={() =>
                this.setState({ showValidationErrors: true })
              }
              onValid={() => this.setState({ canSubmit: true })}
              onInvalid={() => this.setState({ canSubmit: false })}
            >
              <div className="create-device-row">
                <label>
                  Title <span style={style}>*</span>
                </label>
                <MyInput
                  id="title"
                  name="title"
                  classNameDiv="create-branch-row"
                  className="create-device-input"
                  autocomplete="off"
                  value={this.state.title}
                  onChange={this.handleChange}
                  required
                  showError={this.state.showValidationErrors}
                />
              </div>
              <div className="create-device-row">
                <label>
                  Goal <span style={style}>*</span>
                </label>
                <ReactQuill
                  value={this.state.goalText}
                  onChange={this.handleChangeFor('goalText')}
                  theme={quillSettings.theme}
                  modules={quillSettings.modules}
                  />
              </div>
              <div className="create-device-row">
                <label>
                  Why <span style={style}>*</span>
                </label>
                <ReactQuill
                  value={this.state.whyText}
                  onChange={this.handleChangeFor('whyText')}
                  theme={quillSettings.theme}
                  modules={quillSettings.modules}
                  />
              </div>
              <div className="create-device-row">
                  <label>Type</label>
                  <MySelect
                    stateField="type"
                    className="create-branch-select"
                    value={this.state.type}
                    options={this.state.typeOptions}
                    onChange={this.handleSelect}
                  />
                </div>
              <div className="create-device-row">
                <label>Subtype</label>
                <MySelect
                  stateField="subtype"
                  className="create-branch-select"
                  value={this.state.subtype}
                  options={this.state.subtypeOptions}
                  onChange={this.handleSelect}
                />
              </div>

              {this.isRecurrentSession() && (
              <div className="create-device-row">
                <label>Default Length</label>
                <MyInput
                  id="defaultLength"
                  name="defaultLength"
                  classNameDiv="create-branch-row"
                  className="create-device-input"
                  type="number"
                  value={this.state.defaultLength}
                  onChange={this.handleChange}
                  showError={this.state.showValidationErrors}
                />
              </div>
              )}

              <div className="create-device-row">
                <label>
                  Challenge Subtopics
                </label>
                <MySelect
                  className="create-branch-select"
                  stateField="selectedChallengeSubtopics"
                  value={this.state.selectedChallengeSubtopics}
                  options={this.state.challengeSubtopicOptions}
                  onChange={this.handleSelect}
                  isMulti={true}
                  isSearchable={true}
                />
              </div>
              <div className='create-device-row'>
                <label>Notification Title</label>
                <MyInput
                  id="challengeNotificationTitle"
                  name="challengeNotificationTitle"
                  classNameDiv='create-branch-row'
                  className='create-device-input'
                  autocomplete="off"
                  value={this.state.challengeNotificationTitle}
                  onChange={this.handleChange}
                />
              </div>
              <div className='create-device-row'>
                <label>Notification Message</label>
                <textarea
                  id="challengeNotificationMessage"
                  name="challengeNotificationMessage"
                  className='create-device-input'
                  rows={5}
                  value={this.state.challengeNotificationMessage}
                  onChange={this.handleChange}
                />
              </div>
              <div className="create-device-row">
                <label>Visibility</label>
                <FormControlLabel
                  className="checkbox-control"
                  control={
                    <Checkbox
                      id="isVisibleByDefault"
                      name="isVisibleByDefault"
                      checked={this.state.isVisibleByDefault}
                      onChange={this.handleCheckboxChange('isVisibleByDefault')}
                      value="isVisibleByDefault"
                      color="primary"
                    />
                  }
                  label={
                    <span className="create-checkbox-label">
                      Make visible for all users by default
                    </span>
                  }
                />
              </div>

              <div className='create-device-row'>
                <label>Image <span style={style}>*</span></label>
                <div className='div-row'>
                  <Dropzone
                    className="upload-border"
                    onDrop={files => { this.handleDropImage(files, 'imageFileName', 'imageFileNameError') }}
                    accept="image/jpeg,image/jpg,image/tiff,image/gif,image/png"
                    multiple={false}
                    type={'file'}>
                    <span className="w-100" style={{ textAlign: 'center', marginBottom: '5px' }}>
                      Drag and drop file or
                      </span>
                    <button className="btn blue" type="button">BROWSE</button>
                  </Dropzone>
                  <div style={{ textAlign: 'right', position: 'relative' }}>
                    {this.state.imageFileName !== '' &&
                      <button className="btn delete-btn" type="button"
                        onClick={() => this.deleteUploadFile('imageFileName', 'imageFileNameError')}>
                        <CloseIcon />
                      </button>
                    }
                    <img style={{
                      width: 180,
                      height: 180,
                      objectFit: 'contain',
                      border: '1px solid #e6e9ec',
                      marginLeft: '20px'
                    }} src={iimm} alt="" />
                  </div>
                </div>
                <ErrorMessage text={this.state.imageFileNameError} />
                <MyInput
                  id="imageFileName"
                  name="imageFileName"
                  classNameDiv='create-branch-row'
                  className='create-device-input'
                  value={this.state.imageFileName}
                  hidden
                  disabled
                  required
                  showError={this.state.showValidationErrors}
                />
              </div>

              <button
                className="btn green mr-2"
                type="submit"
                onClick={this.state.canSubmit ? this.save : undefined}
              >
                Save
              </button>
              <button
                className="btn blue mr-2"
                type="button"
                onClick={this.cancel}
              >
                Cancel
              </button>
            </Formsy>
          )}
        </div>
      </HomePage>
    )
  }
}

export default ChallengeEdit
