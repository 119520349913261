import React from 'react';
import { browserHistory } from 'react-router';
import './Style.css';
import HomePage from '../home';
import MyPage from '../../components/MyPage';
import MyTable from '../../components/MyTable';
import MyTableHeaderSort from '../../components/MyTableHeaderSort';
import ErrorMessage from '../../components/errormessage';

import { actions as sessionActions} from '../../redux/modules/sessions';
import { urlImg, urlAudio } from "../../redux/fetch/urlHelper";

import imageLib from '../../themes';

class DiagnosisEditSessions extends MyPage {

  constructor(props) {
    super(props);

    let obj;
    if (this.props.location.state) {
      obj = this.props.location.state.obj;
    } else {
      browserHistory.push('/diagnosis-list');
    }

    this.state = {
      diagnosis: obj,
      savedDiagnosis: obj,
      //   sessions: [],
      //   oldSessions: [],
      objectSet: obj ? true : false,
      canSubmit: false,
      errorMessage: "",
    };
  }


  getSelectedSessions() {
    return this.state.diagnosisSessions.map((element) => {
      return element._id;
    });
  }


  handleCheck = async (e, selected) => {
    e.preventDefault();
    if (selected !== undefined) {
      const result = await sessionActions.getSessionSelection(selected);
      if (result.success) {
        const newSessions = [];
        this.state.diagnosis.sessions.forEach((session) => {
          if (result.data.some(element => element._id === session._id)) {
            newSessions.push(session);
          }
        })
        result.data.forEach((element) => {
          if (! newSessions.some(session => session._id === element._id)) {
            newSessions.push(element);
          }
        })
        this.state.diagnosis.sessions = newSessions;
      }
    } else {
      this.state.diagnosis.sessions = [];
    }
  }

  handleChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  pageTitle() {
    return "Diagnosis Sessions";
  }

  handleConfirm = (e) => {
    e.preventDefault();
    const obj = this.state.diagnosis;
    this.props.router.push({
      pathname: '/diagnosis-edit',
      state: { obj }
    });
  }

  handleCancel = (e) => {
    e.preventDefault();
    const obj = this.state.savedDiagnosis;
    this.props.router.push({
      pathname: '/diagnosis-edit',
      state: { obj }
    });
  }



  async componentDidMount() {
    const list = await sessionActions.getDiagnosisTypeSessionsList();
    this.setState({ sessions: list, oldSessions: list });
  }


  makeToolBar = () => {
    return (
      <div className='device-tools' style={{display: "flex", "justify-content": "flex-end"}}>
        <div>
          <button className={"btn green mr-2"} onClick={this.handleConfirm}>Confirm</button>
          <button className={"btn blue mr-2"} onClick={this.handleCancel}>Cancel</button>
        </div>
      </div>
    );
  }

  makeHead = () => {
    return (
      <div className={"table-head"}>

        <div style={{ width: '10%', paddingLeft: "15px" }}>
          <MyTableHeaderSort label="Image" />
        </div>

        <div style={{ width: '30%', paddingLeft: "15px" }}>
          <MyTableHeaderSort
            label="Title"
            active={this.state.orderBy === "title"}
            direction={this.state.order}
            onClick={this.createSortHandler("title")}
          />
        </div>


        <div style={{ width: '20%', paddingLeft: "15px" }}>
          <MyTableHeaderSort
            label="Type"
            active={this.state.orderBy === "type"}
            direction={this.state.order}
            onClick={this.createSortHandler("type")}
          />
        </div>

        <div style={{ width: '15%', paddingLeft: "15px" }}>
          <MyTableHeaderSort label="Audio File" />
        </div>

        <div style={{ width: '60%', paddingLeft: "15px" }}>
          <MyTableHeaderSort label="Description" />
        </div>


        <div style={{ width: '10%', paddingLeft: "15px" }}>
          <MyTableHeaderSort
            label="Free"
            active={this.state.orderBy === "isFree"}
            direction={this.state.order}
            onClick={this.createSortHandler("isFree")}
          />
        </div>

        <div style={{ width: '10%', paddingLeft: "15px" }}>
          <MyTableHeaderSort
            label="Favorite"
            active={this.state.orderBy === "isFavorite"}
            direction={this.state.order}
            onClick={this.createSortHandler("isFavorite")}
          />
        </div>

        <div style={{ width: '20%', paddingLeft: "15px" }}>
          <MyTableHeaderSort label="Actions" />
        </div>

      </div>
    );
  }

  makeRow = (item) => {
    return (
      <div className={"table-row"}>

        <div style={{ width: "10%", paddingLeft: "15px" }}>
          {item.imageFileName !== ""
            ?
            <img style={{'width': 50, 'height': 50, 'objectFit': 'contain'}} src={urlImg(item.imageFileName)} alt="" />
            :
            <img style={{'width': 50, 'height': 50, 'objectFit': 'contain'}} src={imageLib.noImage} alt="" />
          }
        </div>

        <div style={{ width: "30%", paddingLeft: "15px" }}>{item.title}</div>
        <div style={{ width: "20%", paddingLeft: "15px" }}>{sessionActions.getTypeStr(item.type)}</div>
        <div style={{ width: "15%", paddingLeft: "15px" }}><a href={urlAudio(item.audioFileName)}>Open</a></div>
        <div style={{ width: "60%", paddingLeft: "15px" }}>{item.description}</div>
        <div style={{ width: "10%", paddingLeft: "15px" }}>{item.isFree === true ? "Yes" : "No"}</div>
        <div style={{ width: "10%", paddingLeft: "15px" }}>{item.isFavorite === true ? "Yes" : "No"}</div>

        <div style={{ width: "20%", paddingLeft: "15px" }}>
          <button type="button" className="btn" onClick={(e) => this.edit(e, item)}>Edit</button>
        </div>
      </div>
    );
  }

  render() {
    if (! this.state.objectSet) {
      return "";
    }
    return (
      <HomePage router={this.props.router}>
        <div className="devices-page-wrapper">
          <h3>{this.pageTitle()}</h3>
          <h3>{this.state.diagnosis.title}</h3>

          <div className='create-device-row'>
            <MyTable
              // tableName="Sessions"
              tableToolBar={this.makeToolBar}
              tableHead={this.makeHead}
              tableRow={this.makeRow}
              // isDraggable={true}
              isCheckable={true}
              // hasIndex={true}
              order={this.state.order}
              orderBy={this.state.orderBy}
              data={this.state.sessions}
              selected={this.getSelectedSessions()}
              handleCheck={(e, selected) => { this.handleCheck(e, selected) }}
              // onDragEnd={() => {console.log("END DRAG")}}
            />

          </div>
          <ErrorMessage text={this.state.errorMessage}/>
        </div>
      </HomePage>
    );
  }
}

export default DiagnosisEditSessions;
