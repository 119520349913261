import SessionList from '../../components/SessionList';
import { actions as sessionActions } from '../../redux/modules/sessions';
import config from '../../config'

class MeTimeSessionList extends SessionList {

  constructor(props) {
    super(props);
    this.state.pageType = "meTime";
  }

  getTexts = () => {
    return {
      tableTitle: config.customTitles && config.customTitles['meTimeSessions']
        ? config.customTitles['meTimeSessions'] : 'MeTime Sessions',
      searchTitle: "Search Session",
    };
  }

  getList = async () => {
    const list = await sessionActions.getMeTimeSessionsList();
    return list;
  }

  getEditRoute = () => {
    return "/session-metime-edit";
  }

}

export default MeTimeSessionList;
