import { store } from '../store'
import { fetchFromUrlPOSTAsync, fetchFromUrlGETAsync, fetchFromUrlDELETEAsync } from '../fetch/'
import md5 from 'md5'
import moment from 'moment'

const SET_TOKEN = 'SET_TOKEN'

export function reducer (state, action) {
  if (!state) {
    state = {
      accessToken: ''
    }
  }

  switch (action.type) {
    case SET_TOKEN:
      return { ...state, accessToken: action.payload }
    default:
      return state
  }
}

export const actions = {
  getUserList: async ({ page, perPage, order, orderBy, searchField, searchKeyword }, extended = false) => {
    const urlParams = new URLSearchParams('')
    urlParams.append('page', page)
    urlParams.append('perPage', perPage)
    urlParams.append('order', order)
    urlParams.append('orderBy', orderBy)
    if (searchField && searchKeyword) {
      urlParams.append('searchField', searchField)
      urlParams.append('searchKeyword', searchKeyword)
    }

    let result = false
    const urlParamsStr = urlParams.toString()
    if (extended) {
      result = await fetchFromUrlGETAsync('/user/extended-list?' + urlParamsStr)
    } else {
      result = await fetchFromUrlGETAsync('/user?' + urlParamsStr)
    }

    if (result && result.success) {
      return result
    }

    return []
  },

  getById: async userId => {
    const result = await fetchFromUrlGETAsync('/user/edit/' + userId)
    if (result && result.success && result.data) {
      return result.data
    }

    return []
  },

  saveUser: async userData => {
    let urlParams = [{ key: 'saveData', value: userData }]
    const result = await fetchFromUrlPOSTAsync('/user/save_one', urlParams)
    return result.success
  },

  saveToken: token => {
    store.dispatch({ type: SET_TOKEN, payload: token })
  },

  clearToken: () => {
    store.dispatch({ type: SET_TOKEN, payload: '' })
  },

  getSessions: async userId => {
    let urlParams = [{ key: 'userId', value: userId }]
    const result = await fetchFromUrlPOSTAsync(
      '/session/get_by_user_web',
      urlParams
    )
    return result.success ? result.data : []
  },

  getDiagnosis: async userId => {
    let urlParams = [{ key: 'userId', value: userId }]
    const result = await fetchFromUrlPOSTAsync(
      '/user/get_user_diagnosis_web',
      urlParams
    )
    return result.success ? result.data : []
  },

  getPainStatistics: async (userId, fromDate, toDate) => {
    try {
      const result = await fetchFromUrlGETAsync(
        `/pain-statistics/?userId=${userId}&fromDate=${moment(fromDate).format('YYYY-MM-DD') + 'T00:00:00.000Z'}&toDate=${moment(toDate).format('YYYY-MM-DD') + 'T00:00:00.000Z'}
      `)

      return result.success ? result.data : []
    }
    catch (e) {
      console.error(e.message)
      return []
    }
  },

  getSubscriptions: async userId => {
    let urlParams = [{ key: 'userId', value: userId }]
    const result = await fetchFromUrlPOSTAsync(
      '/user/get_subscriptions',
      urlParams
    )
    return result.success ? result.data : []
  },

  setSubscriptions: async payload => {
    let urlParams = [{ key: 'payload', value: payload }]
    const result = await fetchFromUrlPOSTAsync(
      '/user/set_subscriptions',
      urlParams
    )
    return result.success ? result.data : []
  },

  deleteSubscription: async payload => {
    let urlParams = [{ key: 'payload', value: payload }]
    const result = await fetchFromUrlPOSTAsync(
      '/user/delete_subscriptions',
      urlParams
    )
    return result.success ? result.data : []
  },

  setNewPassword: async payload => {
    payload.password = md5(payload.password)

    let urlParams = [{ key: 'payload', value: payload }]
    const result = await fetchFromUrlPOSTAsync(
      '/user/reset_password',
      urlParams
    )
    return result.success ? result.data : []
  },

  getAllUsersStatistics: async () => {
    const result = await fetchFromUrlPOSTAsync(
      '/user/get_all_user_statistics',
      []
    )
    return result
  },

  getFirstQuizResults: async userId => {
    let urlParams = [{ key: 'userId', value: userId }]
    const result = await fetchFromUrlPOSTAsync(
      '/user_first_quiz/get_user_results_web',
      urlParams
    )
    return result
  },

  getUserInfo: async userId => {
    // TODO - maybe refactor all user info
    const result = await fetchFromUrlGETAsync(
      `/user/${userId}/info`
    )
    return result.success ? result.data : {}
  },

  deleteById: async userId => {
    try {
      const result = await fetchFromUrlDELETEAsync(`/user/delete/${userId}`)
      if (result.status === 200) {
        return { success: true }
      }
      if (result.status === 422 && result.validationErrors) {
        return { success: false, validationErrors: result.validationErrors }
      }
      return false
    } catch (e) {
      return false
    }
  }
}
