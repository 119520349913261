import { fetchFromUrlGETAsync } from '../fetch'

export const actions = {
  getProducts: async () => {
    try {
      const result = await fetchFromUrlGETAsync('/products', [])
      return result.success ? result.data : null
    } catch (e) {
      console.log(e.message)
    }
  }
}
