import React from 'react';
import HomePage from '../home';
import Formsy from 'formsy-react';
import MyPage from '../../components/MyPage';
import MyInput from '../../components/MyInput';
import MySelect from '../../components/MySelect';
import ErrorMessage from '../../components/errormessage';

import { actions as diagnosisActions} from '../../redux/modules/diagnosis';

class DiagnosisEdit extends MyPage {

  constructor(props) {
    super(props);
    let isRequest = false;
    if (props.location.state && props.location.state.isRequest) {
      isRequest = props.location.state.isRequest ? props.location.state.isRequest : false;
    }
    const obj = this.getObject();
    this.state = {
      object: obj,
      title: obj.title,
      oldTitle: obj.title,
      description: obj.description ? obj.description : "",
      canSubmit: false,
      showValidationErrors: false,
      isRequest: isRequest,
      isLoading: true,
      errorMessage: "",
    };
  }

  getObject = () => {
    if (this.props.location.state  && this.props.location.state.obj) {
      return this.props.location.state.obj;
    } else {
      return {
        title: "",
        description: "",
      };
    }
  }

  getTexts = () => {
    return {
      pageTitle: "Diagnosis",
    }
  }

  cancel = (e) => {
    e.preventDefault();
    this.props.router.push(this.state.isRequest ? '/diagnosis_request' : '/diagnosis-list');
  }

  save = async (e) => {
    e.preventDefault();

    if (window.confirm("Are you sure you want to save this diagnosis?")) {
      this.state.object.title = this.state.title;
      if (this.state.oldTitle === "") {
        this.state.oldTitle = this.state.title;
      }

      this.state.object.description = this.state.description;
      this.state.object.relations = this.state.selectedRelations.map(option => option.value);

      const result = await diagnosisActions.saveDiagnosis(this.state.object, this.state.isRequest ? this.state.oldTitle : this.state.title);
      if (result.success) {
        this.setState({ errorMessage: "" });
        this.cancel(e);
      } else {
        this.setState({ errorMessage: result.message });
      }
    }
  }

  async componentDidMount() {
    const diagList = await diagnosisActions.getDiagnosisList();
    let relationOptions = diagList.map((element) => {
      return { value: element._id, label: element.title };
    });
    if (this.state.object._id) {
      relationOptions = relationOptions.filter(option => option.value !== this.state.object._id);
    }
    let selectedRelations;
    if (this.state.object.relations) {
      selectedRelations = this.state.object.relations.map((diagnosisId) => {
        return relationOptions.find(option => option.value === diagnosisId);
      });
    } else {
      selectedRelations = [];
    }
    this.setState({
      relationOptions,
      selectedRelations,
      isLoading: false
    });

  }

  render() {
    const style = {color:'red'}
    return (
      <HomePage router={this.props.router}>
        {! this.state.isLoading &&

          <div className="devices-page-wrapper">
            <h3>{this.getTexts().pageTitle}</h3>
            <Formsy className='create-device-form'
              onValidSubmit={this.submit}
              onInvalidSubmit={() => this.setState({showValidationErrors: true})}
              onValid={() => this.setState({canSubmit: true})}
              onInvalid={() => this.setState({canSubmit: false})}
            >
              <div className='create-device-row'>
                <label>Title <span style={style}>*</span></label>
                <MyInput
                  id="title"
                  name="title"
                  classNameDiv='create-branch-row'
                  className='create-device-input'
                  autocomplete="off"
                  value={this.state.title}
                  onChange={this.handleChange}
                  required
                  showError={this.state.showValidationErrors}
                />
              </div>

              <div className='create-device-row'>
                <label>Description</label>
                <textarea
                  id="description"
                  name="description"
                  className='create-device-input'
                  rows={5}
                  value={this.state.description}
                  onChange={this.handleChange}
                />
              </div>

              <div className='create-device-row'>
                <label>Related</label>

                <MySelect
                  stateField="selectedRelations"
                  className="create-branch-select"
                  value={this.state.selectedRelations}
                  options={this.state.relationOptions}
                  onChange={this.handleSelect}
                  isMulti={true}
                  isSearchable={true}
                />

              </div>

              <ErrorMessage text={this.state.errorMessage}/>
              <div className="w-100 d-flex justify-content-end">
                <button className='btn green mr-2' type="submit" onClick={this.state.canSubmit ? this.save : undefined}>Save</button>
                <button className='btn blue mr-2' type="button" onClick={this.cancel}>Cancel</button>
              </div>
            </Formsy>
          </div>
        }
      </HomePage>
    );
  }
}

export default DiagnosisEdit;
