import SessionList from '../../components/SessionList'
import { actions as sessionActions } from '../../redux/modules/sessions'
import { actions as taskActions } from '../../redux/modules/feelGoodTasks'

class SessionSoundList extends SessionList {
  constructor (props) {
    super(props)
    this.state.pageType = 'main'
  }

  getTexts = () => {
    return {
      tableTitle: 'Sessions',
      searchTitle: 'Search Session'
    }
  }

  getList = async () => {
    const list = await sessionActions.getSessionsList()
    return list
  }

  getTaskList = async () => {
    const taskList = await taskActions.getTasksList()
    return taskList
  }

  getEditRoute = () => {
    return '/session-sound-edit'
  }
}

export default SessionSoundList
