import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'

import './index.css'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import AuthRouter from './Auth'

import { store, persistor } from './redux/store'

import config from './config'

if (config.title) {
  document.getElementById('htmlPageTitle').textContent = config.title + ' Admin Panel'
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AuthRouter />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
