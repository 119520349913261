import { fetchFromUrlPOSTAsync } from '../fetch/'

export const actions = {
  getTasksList: async () => {
    const result = await fetchFromUrlPOSTAsync('/feel_good_task/get_list', [])
    return result.success ? result.data : []
  },

  saveTask: async saveData => {
    let urlParams = [{ key: 'saveData', value: saveData }]
    const result = await fetchFromUrlPOSTAsync(
      '/feel_good_task/save_one',
      urlParams
    )
    return result
  }
}
