import React from 'react'
import { Router, Route, IndexRoute } from 'react-router'
import { Switch } from 'react-router-dom'

import config from './config.js'
import App from './App'

// Routes
import Dashboard from './pages/dashboard'
import Login from './pages/login'
import Faq from './pages/faq'
import FaqEdit from './pages/faq_edit'
import Error from './pages/error'
import AdminList from './pages/admin_list'
import AdminEdit from './pages/admin_edit'
import UserList from './pages/user_list'
import UserEdit from './pages/user_edit'
import QuizList from './pages/quiz_list'
import QuizEdit from './pages/quiz_edit'
import DailyInspiration from './pages/daily_inspiration'
import DailyInspirationEdit from './pages/daily_inspiration_edit'
import ForgotPassword from './pages/forgot_password'
import ForgotPasswordChange from './pages/forgot_password_change'
import AuthorList from './pages/author_list'
import AuthorEdit from './pages/author_edit'
import SessionTranscriptionEdit from './pages/session_transcription_edit'
import SessionAboutEdit from './pages/session_about_edit'
import TopicList from './pages/topic_list'
import TopicEdit from './pages/topic_edit'
import SubtopicsList from './pages/subtopics_list'
import SubtopicEdit from './pages/subtopic_edit'
import CollectionList from './pages/collection_list'
import CollectionEdit from './pages/collection_edit'
import PermissionList from './pages/permission_list'
import PermissionEdit from './pages/permission_edit'
import SessionSoundEdit from './pages/session_sound_edit'
import SessionSoundList from './pages/session_sound_list'
import SessionMetimeList from './pages/session_metime_list'
import SessionMetimeEdit from './pages/session_metime_edit'
import MindSessionsList from './pages/MindSessionsList'
import BodySessionsList from './pages/BodySessionsList'
import DiagnosisList from './pages/diagnosis_list'
import DiagnosisEdit from './pages/diagnosis_edit'
import DiagnosisRequest from './pages/diagnosis_request'
import DiagnosisEditSessions from './pages/diagnosis_edit_sessions'
import SessionPainList from './pages/session_pain_list'
import SessionPainEdit from './pages/session_pain_edit'
import TaskList from './pages/task_list'
import TaskEdit from './pages/task_edit'
import ScreensTextEdit from './pages/screensTextEdit'
import FeedbackList from './pages/feedbackList'
import PollsList from './pages/RegistrationPollsList'
import PollEdit from './pages/RegistrationPollsEdit'
import MilestonesList from './pages/MilestonesList'
import MilestoneEdit from './pages/MilestoneEdit'
import ChallengesList from './pages/ChallengesList'
import ChallengeEdit from './pages/ChallengeEdit'
import ChallengeSubtopicsList from './pages/ChallengeSubtopicsList'
import ChallengeSubtopicEdit from './pages/ChallengeSubtopicEdit'
import FactorsList from './pages/FactorsList'
import FactorEdit from './pages/FactorEdit'
import FactorSubtopicsList from './pages/FactorSubtopicsList'
import FactorSubtopicEdit from './pages/FactorSubtopicEdit'
import SuccessStoriesList from './pages/SuccessStoriesList'
import SuccessStoryEdit from './pages/SuccessStoryEdit'
import ReferralCodesList from './pages/ReferralCodesList'
import ReferralCodeEdit from './pages/ReferralCodeEdit'
import SOSExperiencesList from './pages/SOSExperiencesList'
import SOSExperienceEdit from './pages/SOSExperienceEdit'
import SOSExperienceSubtopicsList from './pages/SOSExperienceSubtopicsList'
import SOSExperienceSubtopicEdit from './pages/SOSExperienceSubtopicEdit'

const diagnosis = () => (
    <Switch>
      <Route path="/diagnosis-list" component={DiagnosisList}/>
      <Route path="/diagnosis-edit" component={DiagnosisEdit}/>
      <Route path="/diagnosis-request" component={DiagnosisRequest}/>
      <Route path="/diagnosis-edit-sessions" component={DiagnosisEditSessions}/>
    </Switch>
)
const sessionPlain = () => (
    <Switch>
          <Route path="/session-pain-list" component={SessionPainList}/>
          <Route path="/session-pain-edit" component={SessionPainEdit}/>
    </Switch>
)
const feelGoodTask = () => (
    <Switch>
          <Route path="/task-list" component={TaskList}/>
          <Route path="/task-edit" component={TaskEdit}/>
    </Switch>
)
const screensText = () => (
    <Route path="/screens-text-edit" component={ScreensTextEdit}/>
)
const userFeedback = () => (
    <Route path="/user-feedback" component={FeedbackList}/>
)

export default (
  <Router>
    <Route path="/" component={App}>
      <IndexRoute component={Dashboard} />
    </Route>
    <Route path="/dashboard" component={Dashboard} />
    <Route path="/login" component={Login} />
    <Route path="/faq" component={Faq} />
    <Route path="/faq-edit" component={FaqEdit} />
    <Route path="/error" component={Error} />
    <Route path="/admin-list" component={AdminList} />
    <Route path="/admin-edit" component={AdminEdit} />
    <Route path="/user-list" component={UserList} />
    <Route path="/user-edit" component={UserEdit} />
    <Route path="/quiz-list" component={QuizList} />
    <Route path="/quiz-edit" component={QuizEdit} />
    <Route path="/daily-inspiration" component={DailyInspiration} />
    <Route path="/daily-inspiration-edit" component={DailyInspirationEdit} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/forgot-password-change" component={ForgotPasswordChange} />
    <Route path="/author-list" component={AuthorList} />
    <Route path="/author-edit" component={AuthorEdit} />
    <Route path="/session-transcription-edit"
            component={SessionTranscriptionEdit} />
    <Route path="/session-about-edit" component={SessionAboutEdit} />
    <Route path="/polls-list" component={PollsList} />
    <Route path="/poll-edit" component={PollEdit} />
    <Route path="/journey-edit" component={MilestonesList} />
    <Route path="/milestone-edit" component={MilestoneEdit} />
    <Route path="/topic-list" component={TopicList} />
    <Route path="/topic-edit" component={TopicEdit} />
    <Route path="/subtopic-list" component={SubtopicsList} />
    <Route path="/subtopic-edit" component={SubtopicEdit} />
    <Route path="/collection-list" component={CollectionList} />
    <Route path="/collection-edit" component={CollectionEdit} />
    <Route path="/permission-list" component={PermissionList} />
    <Route path="/permission-edit" component={PermissionEdit} />
    <Route path="/session-sound-edit" component={SessionSoundEdit} />
    <Route path="/session-sound-list" component={SessionSoundList} />
    <Route path="/session-metime-list" component={SessionMetimeList} />
    <Route path="/session-metime-edit" component={SessionMetimeEdit} />
    <Route path="/session-body-edit" component={SessionMetimeEdit} />
    <Route path="/mind-sessions-list" component={MindSessionsList} />
    <Route path="/body-sessions-list" component={BodySessionsList} />
    <Route path="/challenges-list" component={ChallengesList} />
    <Route path="/challenge-edit" component={ChallengeEdit} />
    <Route path="/challenge-subtopics-list" component={ChallengeSubtopicsList} />
    <Route path="/challenge-subtopic-edit" component={ChallengeSubtopicEdit} />
    <Route path="/factors-list" component={FactorsList} />
    <Route path="/factor-edit" component={FactorEdit} />
    <Route path="/factor-subtopics-list" component={FactorSubtopicsList} />
    <Route path="/factor-subtopic-edit" component={FactorSubtopicEdit} />
    <Route path="/success-stories-list" component={SuccessStoriesList} />
    <Route path="/success-stories-edit" component={SuccessStoryEdit} />
    <Route path="/referral-codes-list" component={ReferralCodesList} />
    <Route path="/referral-code-edit" component={ReferralCodeEdit} />
    <Route path="/sos-experiences-list" component={SOSExperiencesList} />
    <Route path="/sos-experience-edit" component={SOSExperienceEdit} />
    <Route path="/sos-experience-subtopics-list" component={SOSExperienceSubtopicsList} />
    <Route path="/sos-experience-subtopic-edit" component={SOSExperienceSubtopicEdit} />

    { config.feature.diagnosis ? diagnosis() : null }
    { config.feature.sessionPlain ? sessionPlain() : null }
    { config.feature.feelGoodTask ? feelGoodTask() : null }
    { config.feature.screensText ? screensText() : null }
    { config.feature.userFeedback ? userFeedback() : null }

  </Router>
)
