import React from 'react'
import PropTypes from 'prop-types'
import HomePage from '../home'
import MyPage from '../../components/MyPage'
import UserTable from '../../components/UserTable'
import MyTableHeaderSort from '../../components/MyTableHeaderSort'
import { SearchIcon } from '../../Icons/svg'
import { Loader } from '../../components/loader/index'
import config from '../../config'
import DeleteIcon from '@material-ui/icons/Delete'
import DeleteDialog from '../../components/DeleteDialog'
import ValidationDialog from '../../components/ValidationDialog'
import formatDate from '../../utils/formatDate'
import { actions as userActions } from '../../redux/modules/users'
import formateUserDiagnosis from './helpers/formateUserDiagnosis'
import { throttle, debounce } from 'lodash'

class UserList extends MyPage {
  constructor (props) {
    super(props)
    let state = {
      list: [],
      isLoading: true,
      isLoadingExt: true
    }
    const query = this.props.location.query || {}
    this.state = this.addQueryParamsToState(query, state)
    this.addHistoryListener()
    // updateHistory with debouncing
    this.debouncedUpdateHistory = debounce(this.updateHistory, 500)
  }

  /**
   * Sets pagination, sort and search params
   */
  addQueryParamsToState = (query, state) => {
    state.page = query.page ? parseInt(query.page) : 1
    state.perPage = query.perPage ? parseInt(query.perPage) : 100
    state.order = query.order === 'ASC' ? 'ASC' : 'DESC'
    state.orderBy = query.orderBy ? query.orderBy : 'createdDate'
    if (query.searchField && query.searchField) {
      state.searchField = query.searchField
      state.searchKeyword = query.searchKeyword
    }

    return state
  }

  /**
   * Updates list when browser history changes
   */
  addHistoryListener = () => {
    const historyListener = (location, action) => {
      this.setState(state => {
        return this.addQueryParamsToState(location.query, state)
      },
      async () => {
        this.getList()
        this.getExtendedList()
      })
    }
    const throttledHistoryListener = throttle(historyListener, 100)
    this.props.router.listen(throttledHistoryListener)
  }

  /**
   * Sets history
   */
  updateHistory = () => {
    const { page, perPage, order, orderBy, searchField, searchKeyword } = this.state
    const search = this.props.location.search || ''
    const searchParams = new URLSearchParams(search)
    searchParams.set('page', page)
    searchParams.set('perPage', perPage)
    searchParams.set('order', order)
    searchParams.set('orderBy', orderBy)
    if (searchField && searchKeyword) {
      searchParams.set('searchField', searchField)
      searchParams.set('searchKeyword', searchKeyword)
    } else {
      searchParams.delete('searchField')
      searchParams.delete('searchKeyword')
    }
    this.props.router.push(this.props.location.pathname + '?' + searchParams.toString())
  }

  /**
   * Changes history when page changed,
   * then data is fetched
   */
  handleChangePage = async page => {
    this.setState(state => {
      return {
        ...state,
        page
      }
    }, async () => {
      this.updateHistory()
    })
  }

  /**
   * Changes history when page changed,
   * and data is fetched
   */
  handleChangeRowsPerPage = async perPage => {
    this.setState(state => {
      return {
        ...state,
        perPage,
        page: 1
      }
    }, async () => {
      this.updateHistory()
    })
  }

  /**
   * Changes history when searching
   * and data is fetched
   */
  handleСhangeSearch = e => {
    e.preventDefault()
    let searchField = 'name,email'
    let searchKeyword = e.target.value
    searchKeyword = searchKeyword.replace(/[-+*.?|^$/()[\]{}\\]/g, '\\$&')

    this.setState(state => {
      return {
        ...state,
        searchField,
        searchKeyword,
        page: 1
      }
    }, async () => {
      this.debouncedUpdateHistory()
    })
  }

  /**
   * Changes history when sorting
   * and data is fetched
   */
  handleChangeSort = (orderBy, order) => {
    this.setState(state => {
      return {
        ...state,
        orderBy,
        order: order === 'DESC' ? 'ASC' : 'DESC',
        page: 1
      }
    }, async () => {
      this.updateHistory()
    })
  }

  /**
   * Fetches user data
   */
  getList = async () => {
    try {
      const { page, perPage, order, orderBy, searchField, searchKeyword } = this.state
      const result = await userActions.getUserList({
        page,
        perPage,
        order,
        orderBy,
        searchField,
        searchKeyword
      })

      this.setState(state => {
        return {
          ...state,
          totalCount: result.totalCount || 0,
          list: result.data || [],
          isLoading: false
        }
      })
    } catch (e) {
      console.error(e.message)
    }
  }

  /**
   * Fetches extended user data
   */
  getExtendedList = async () => {
    this.setState(state => {
      return {
        ...state,
        isLoadingExt: true
      }
    })

    try {
      const { page, perPage, order, orderBy, searchField, searchKeyword } = this.state
      const result = await userActions.getUserList({
        page,
        perPage,
        order,
        orderBy,
        searchField,
        searchKeyword
      }, true)

      this.setState(state => {
        return {
          ...state,
          list: result.data || [],
          isLoadingExt: false
        }
      })
    } catch (e) {
      console.error(e.message)
    }
  }

  /**
   * Listens delete btn
   */
  handleItemDelete = (e, item) => {
    if (!item || !item._id) {
      return
    }
    const deleteDialogParams = {
      open: true,
      title: `Are you sure you want to delete "${item.name || ''}" ?`,
      handleConfirm: async () => {
        try {
          deleteDialogParams.handleClose()
          this.toggleLoader()
          const delRes = await userActions.deleteById(item._id)
          if (delRes && delRes.validationErrors) {
            this.handleDeleteValidation(item, delRes.validationErrors)
          }
          if (delRes && delRes.success) {
            this.getList()
            this.getExtendedList()
          }
          this.toggleLoader()
        } catch (e) {
          console.log(e.message)
        }
      },
      handleClose: () => {
        this.setState(state => {
          return {
            ...state,
            deleteDialogParams: false
          }
        })
      },
      handleCancel: () => {
        deleteDialogParams.handleClose()
      }
    }

    this.setState(state => {
      return {
        ...state,
        deleteDialogParams
      }
    })
  }

  /**
   * Shows Validation notice if needed
   */
  handleDeleteValidation = (item, validationErrors) => {
    if (!item || !validationErrors || !validationErrors.length) {
      return
    }
    let info = validationErrors[0].error

    const validationDiaologParams = {
      open: true,
      title: `User "${item.name || ''}" can't be deleted`,
      info: info,
      handleClose: () => {
        this.setState(state => {
          return {
            ...state,
            validationDiaologParams: false
          }
        })
      }
    }

    this.setState(state => {
      return {
        ...state,
        validationDiaologParams
      }
    })
  }

  toggleLoader = () => {
    this.setState(state => {
      return {
        ...state,
        isLoading: !state.isLoading
      }
    })
  }

  getTexts = () => {
    return {
      tableTitle: 'Users',
      searchTitle: 'Search User'
    }
  }

  edit = (e, obj) => {
    e.preventDefault()
    this.props.router.push({
      pathname: '/user-edit',
      state: { obj: obj }
    })
  }

  async componentDidMount () {
    this.getList()
    this.getExtendedList()
  }

  makeToolBar = () => {
    return (
      <div className="device-tools">
        <div className="search-icon">
          <SearchIcon />
        </div>
        <input
            className="search-input"
            onChange={this.handleСhangeSearch}
            placeholder={this.getTexts().searchTitle}
            tabIndex="-1"
            value={this.state.searchKeyword || ''}
          />
      </div>
    )
  }

  makeHead = () => {
    const { order, orderBy } = this.state
    const orderLowerCase = order.toLowerCase()
    return (
      <div className={'table-head'}>
        <div className={'col col--15'}>
          <MyTableHeaderSort
            label="Name"
            active={orderBy === 'name'}
            direction={orderLowerCase}
            onClick={() => this.handleChangeSort('name', order)}
          />
        </div>

        <div className={'col col--20'}>
          <MyTableHeaderSort
            label="Email"
            active={orderBy === 'email'}
            direction={orderLowerCase}
            onClick={() => this.handleChangeSort('email', order)}
          />
        </div>

        <div className={'col col--10'}>
          <MyTableHeaderSort
            label="Subscription"
            active={orderBy === 'subscriptionUntil'}
            direction={orderLowerCase}
            onClick={() => this.handleChangeSort('subscriptionUntil', order)}
          />
        </div>

        <div className={'col col--5'}>
          <MyTableHeaderSort
            label="Creation Date"
            active={orderBy === 'createdDate'}
            direction={orderLowerCase}
            onClick={() => this.handleChangeSort('createdDate', order)}
          />
        </div>

        { config.feature.diagnosis
          ? <div className={'col col--25 center'}>
          <MyTableHeaderSort label="Diagnoses" />
        </div>
        : null }

        <div className={'col col--5 center'}>
          <MyTableHeaderSort label="Session completed" />
        </div>

        <div className={'col col--5 center'}>
          <MyTableHeaderSort label="Actions" />
        </div>
      </div>
    )
  }

  makeRow = item => {
    return (
      <div className={'table-row'}>
        <div className={'col col--15'}>{item.name}</div>
        <div className={'col col--20'}>{item.email}</div>
        <div className={'col col--10'}>
          {item.subscriptionUntil > new Date()
            ? formatDate(item.subscriptionUntil)
            : 'None'}
        </div>
        <div className={'col col--5'}>
          {formatDate(item.createdDate)}
        </div>
        { config.feature.diagnosis
          ? <div className={'col col--25 center'}>
          {this.state.isLoadingExt ? 'Loading...' : formateUserDiagnosis(item)}
        </div>
        : null }
        <div className={'col col--5 center'}>
            {this.state.isLoadingExt ? 'Loading...' : item.userSessions}
        </div>
        <div className={'col col--5 center'}>
          <button
            type="button"
            className="btn"
            onClick={e => this.edit(e, item)}
          >
            Edit
          </button>
          <button type="button" className="btn" onClick={e => this.handleItemDelete(e, item)}>
            <DeleteIcon />
          </button>
        </div>
      </div>
    )
  }

  render () {
    const {
      deleteDialogParams,
      validationDiaologParams,
      order,
      orderBy,
      totalCount,
      list,
      page,
      perPage
    } = this.state
    return (
      <HomePage router={this.props.router}>
        <div className="device-table-container">
          {this.state.isLoading ? <Loader /> : (
            <UserTable
              tableName={this.getTexts().tableTitle}
              tableToolBar={this.makeToolBar}
              tableHead={this.makeHead}
              tableRow={this.makeRow}
              hasIndex={true}
              order={order}
              orderBy={orderBy}
              data={list}
              hasPagination={true}
              page={(page - 1)}
              rowsPerPage={perPage}
              totalCount={totalCount}
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          )}
        </div>
        {deleteDialogParams && <DeleteDialog {...deleteDialogParams} />}
        {validationDiaologParams && <ValidationDialog {...validationDiaologParams} />}
      </HomePage>
    )
  }
}

UserList.propTypes = {
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
}

export default UserList
