import { fetchFromUrlPOSTAsync } from '../fetch/'

export const actions = {

  getDiagnosisList: async () => {
    const result = await fetchFromUrlPOSTAsync('/diagnosis/get_all', [])
    return result.success ? result.data : []
  },

  getDiagnosisForCollection: async () => {
    let urlParams = []

    const result = await fetchFromUrlPOSTAsync('/diagnosis/get_all_diagnosis', urlParams)

    if (result.data && result.data.length) {
      result.data.sort((a, b) => {
        if (a.title < b.title) {
          return -1
        }
        if (a.title > b.title) {
          return 1
        }
        return 0
      })
    }

    return result.success ? result.data : []
  },

  saveDiagnosis: async (diagnosisData, oldTitle) => {
      let urlParams = [
        { key: 'saveData', value: diagnosisData },
        { key: 'oldTitle', value: oldTitle },
      ]
      const result = await fetchFromUrlPOSTAsync('/diagnosis/save_one', urlParams)
      return result
  },

}
