import React from 'react'
import PropTypes from 'prop-types'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'

const RegistrationPollsStatistics = ({ regPollsData }) => {
  if (!regPollsData) return null
  return (
    <div>
      <h5 className="h-statistics">Registration Polls Statistics</h5>
      {Object.entries(regPollsData).map(([key, { pollTitle, options }], index) => {
        if (!pollTitle || !options || !Object.keys(options).length) {
          return null
        }
        return (
          <div key={`${key}-poll`}>
            <h5 className="sub-h-statistics">{pollTitle}</h5>
            <div className="d-flex mb16">
              <table style={{ width: '50%' }}>
                <tbody>
                  {Object.entries(options).map(([key, { pollOptionTitle, count }], index) => {
                    if (!pollOptionTitle || !count) {
                      return null
                    }
                    return (
                      <tr key={`${key}-option`}>
                        <td>{pollOptionTitle}</td>
                        <td>{count}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <BarChart
                width={600}
                height={250}
                data={Object.entries(options).map(([key, { pollOptionTitle, count }], index) => ({
                  name: pollOptionTitle,
                  Requests: count
                }))}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                className="dashboard-barchart"
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="Requests" stackId="a" fill="#55aeee" />
              </BarChart>
            </div>
          </div>
        )
      })}
    </div>
  )
}

RegistrationPollsStatistics.propTypes = {
  regPollsData: PropTypes.object.isRequired
}

export default RegistrationPollsStatistics
