import React from 'react'
import HomePage from '../home'
import Formsy from 'formsy-react'
import MyPage from '../../components/MyPage'
import MyInput from '../../components/MyInput'
import MySelect from '../../components/MySelect'
import ErrorMessage from '../../components/errormessage'
import { actions } from '../../redux/modules/referralCodes'
import { actions as productActions } from '../../redux/modules/products'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MyTableHeaderSort from '../../components/MyTableHeaderSort'
import TableWithPagination from '../../components/TableWithPagination'
import Paper from '@material-ui/core/Paper'

class ReferralCodesEdit extends MyPage {
  constructor(props) {
    super(props)
    this.state = {
      errorMessage: '',
      canSubmit: false,
      showValidationErrors: false,
      isLoading: true,
      openDialog: false,
      users: [],
      products: [],
      userTotalCount: 0,
      userPage: 1
    }
  }

  getObject = async () => {
    if (this.props.location.state && this.props.location.state.obj) {
      const object = this.props.location.state.obj

      const { data, error } = await actions.getById(object._id)

      if (error) {
        return this.setState({ errorMessage: error })
      }

      const referralCodeUsers = await actions.getReferralCodeUsersList(
        object._id,
        {
          page: this.state.userPage,
          perPage: 20
        }
      )

      if (referralCodeUsers.error) {
        return this.setState({ errorMessage: referralCodeUsers.error })
      }

      const users = referralCodeUsers.data && referralCodeUsers.data.items ? referralCodeUsers.data.items : []
      const userTotalCount = referralCodeUsers.data && referralCodeUsers.data.totalCount ? referralCodeUsers.data.totalCount : 0

      this.setState({
        object: data,
        users,
        userTotalCount,
        isLoading: false
      })
    } else {
      this.setState({
        object: {
          code: '',
          limit: undefined,
          isUnlimited: false,
          isEnabled: true,
          users: [],
          userTotalCount: 0,
          userPage: 1
        },
        isLoading: false
      })
    }
  }

  getProducts = async () => {
    const products = await productActions.getProducts()
    return this.setState({ products })
  }

  getTexts = () => {
    return {
      pageTitle: 'Referral Code'
    }
  }

  handleChange = e => {
    e.preventDefault()
    const target = e.target
    this.setState(state => {
      return {
        object: {
          ...state.object,
          [target.id]: target.value
        }
      }
    })
  }

  handleSelect = (stateId, value) => {
    this.setState(state => {
      return {
        object: {
          ...state.object,
          [stateId]: value
        }
      }
    })
  }

  handleCheckboxChange = field => () => this.setState(state => {
    return {
      object: {
        ...state.object,
        [field]: !state.object[field]
      }
    }
  })

  cancel = e => {
    e.preventDefault()
    this.props.router.push('/referral-codes-list')
  }

  save = async e => {
    e.preventDefault()
    if (window.confirm('Are you sure you want to save this Referral Code?')) {
      if (this.state.object.monthlyProduct && this.state.object.monthlyProduct.product) {
        this.state.object.monthlyProduct = this.state.object.monthlyProduct.product
      }
      if (this.state.object.annualProduct && this.state.object.annualProduct.product) {
        this.state.object.annualProduct = this.state.object.annualProduct.product
      }
      if (this.state.object.lifetimeProduct && this.state.object.lifetimeProduct.product) {
        this.state.object.lifetimeProduct = this.state.object.lifetimeProduct.product
      }

      const result = await actions.save(this.state.object)
      if (result) {
        if (!result.success) {
          this.setState({ errorMessage: result.error })
        } else {
          this.cancel(e)
          this.setState({ errorMessage: '' })
        }
      }
    }
  }

  componentDidMount () {
    (async () => {
      await this.getProducts()
      this.getObject()
    })()
  }

  makeUserTableHead = () => {
    return (
      <div className={'table-head'}>
        <div style={{ width: '50%', paddingLeft: '15px' }}>
          <MyTableHeaderSort
            label="Name"
            active='Name'
          />
        </div>
        <div style={{ width: '50%', paddingLeft: '15px' }}>E-Mail</div>
      </div>
    )
  }

  makeUserTableRow = item => {
    return (
      <div className={'table-row'}>
        <div style={{ width: '50%', paddingLeft: '15px' }}>
          {item.name}
        </div>
        <div style={{ width: '50%', paddingLeft: '15px' }}>
          {item.email}
        </div>
      </div>
    )
  }

  handleUserTableChangePage = async page => {
    const referralCodeUsers = await actions.getReferralCodeUsersList(
        this.props.location.state.obj._id,
        {
          page,
          perPage: 20
        }
      )

    const users = referralCodeUsers.data && referralCodeUsers.data.items ? referralCodeUsers.data.items : []

    this.setState({ users, userPage: page })
  }

  getProductOptions = (type) => {
    if (!this.state.products[type]) {
      return []
    }

    return this.state.products[type].map(p => ({
      value: p.product,
      label: p.title
    }))
  }

  getDefaultProductOption = (type) => {
    if (this.state.object[`${type}Product`]) {
      return this.state.object[`${type}Product`].product
    }

    if (!this.state.products || !this.state.products[type]) {
      return null
    }

    return this.state.products[type][0].product
  }

  render () {
    const style = { color: 'red' }

    return (
      <HomePage router={this.props.router}>
        <div className="devices-page-wrapper">
          <ErrorMessage text={this.state.errorMessage} />
          <h3>{this.getTexts().pageTitle}</h3>
          {!this.state.isLoading && (
            <Formsy
              className="create-device-form"
              onValidSubmit={this.submit}
              onInvalidSubmit={() =>
                this.setState({ showValidationErrors: true })
              }
              onValid={() => this.setState({ canSubmit: true })}
              onInvalid={() => this.setState({ canSubmit: false })}
            >
              <div className="create-device-row">
                <label>
                  Code <span style={style}>*</span>
                </label>
                <MyInput
                  id="code"
                  name="code"
                  classNameDiv="create-branch-row"
                  className="create-device-input"
                  autocomplete="off"
                  value={this.state.object.code}
                  onChange={this.handleChange}
                  required
                  disabled={this.state.object._id ? true : false}
                  showError={this.state.showValidationErrors}
                />
              </div>
              <div className='create-device-row'>
                 <label>Monthly Product <span style={style}>*</span></label>
                  <MySelect
                    id="monthlyProduct"
                    name="monthlyProduct"
                    stateField="monthlyProduct"
                    className="create-branch-select"
                    value={this.getDefaultProductOption('monthly')}
                    options={this.getProductOptions('monthly')}
                    onChange={this.handleSelect}
                    isMulti={false}
                    isSearchable={false}
                  />
              </div>
              <div className='create-device-row'>
                 <label>Annual Product <span style={style}>*</span></label>
                  <MySelect
                    id="annualProduct"
                    name="annualProduct"
                    stateField="annualProduct"
                    className="create-branch-select"
                    value={this.getDefaultProductOption('annual')}
                    options={this.getProductOptions('annual')}
                    onChange={this.handleSelect}
                    isMulti={false}
                    isSearchable={false}
                  />
              </div>
              <div className='create-device-row'>
                 <label>Lifetime Product <span style={style}>*</span></label>
                  <MySelect
                    id="lifetimeProduct"
                    name="lifetimeProduct"
                    stateField="lifetimeProduct"
                    className="create-branch-select"
                    value={this.getDefaultProductOption('lifetime')}
                    options={this.getProductOptions('lifetime')}
                    onChange={this.handleSelect}
                    isMulti={false}
                    isSearchable={false}
                  />
              </div>
              <div className="create-device-row">
                <label>Unlimited</label>
                <FormControlLabel
                  className="checkbox-control"
                  control={
                    <Checkbox
                      id="isUnlimited"
                      name="isUnlimited"
                      checked={this.state.object.isUnlimited}
                      onChange={this.handleCheckboxChange('isUnlimited')}
                      value="isUnlimited"
                      color="primary"
                    />
                  }
                  label={
                    <span className="create-checkbox-label">
                      Make this code usage unlimited
                    </span>
                  }
                />
              </div>
              {!this.state.object.isUnlimited && (<div className="create-device-row">
                <label>Limit</label>
                <MyInput
                  id="limit"
                  name="limit"
                  classNameDiv="create-branch-row"
                  className="create-device-input"
                  autocomplete="off"
                  type="number"
                  value={this.state.object.limit}
                  onChange={this.handleChange}
                  showError={this.state.showValidationErrors}
                  required
                />
              </div>)}
              {this.state.object._id && (<div className="create-device-row">
                <label>Usage Counter</label>
                <MyInput
                  id="usageCounter"
                  name="usageCounter"
                  classNameDiv="create-branch-row"
                  className="create-device-input"
                  autocomplete="off"
                  type="number"
                  value={this.state.usageCounter || 0}
                  disabled
                />
              </div>)}
              <div className="create-device-row">
                <label>Enabled</label>
                <FormControlLabel
                  className="checkbox-control"
                  control={
                    <Checkbox
                      id="isEnabled"
                      name="isEnabled"
                      checked={this.state.object.isEnabled}
                      onChange={this.handleCheckboxChange('isEnabled')}
                      value="isEnabled"
                      color="primary"
                    />
                  }
                  label={
                    <span className="create-checkbox-label">
                      Make this code active
                    </span>
                  }
                />
              </div>
              <div className="create-device-row">
                {this.state.object._id ? (<div>
                  <label>Users</label>
                  { this.state.users.length ? (
                    <TableWithPagination
                      tableHead={this.makeUserTableHead}
                      tableRow={this.makeUserTableRow}
                      hasPagination={true}
                      page={this.state.userPage - 1}
                      rowsPerPage={20}
                      rowsPerPageOptions={[20]}
                      totalCount={this.state.userTotalCount}
                      data={this.state.users}
                      handleChangePage={this.handleUserTableChangePage}
                    />
                  ) : (
                      <Paper
                        style={{ width: '100%', padding: '2%', fontSize: '14px' }}
                      >
                        This referral code hasn't been used yet.
                      </Paper>
                    )}</div>) : ''}
              </div>
              <button
                className="btn green mr-2"
                type="submit"
                onClick={this.state.canSubmit ? this.save : undefined}
              >
                Save
              </button>
              <button
                className="btn blue mr-2"
                type="button"
                onClick={this.cancel}
              >
                Cancel
              </button>
            </Formsy>
          )}
        </div>
      </HomePage>
    )
  }
}

export default ReferralCodesEdit
