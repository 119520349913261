import React from 'react';
import { Modal } from 'reactstrap';
import Dropzone from 'react-dropzone';
import Formsy from 'formsy-react';

import Paper from '@material-ui/core/Paper';

import MyPage from '../../components/MyPage';
import MyInput from '../MyInput';
import ErrorMessage from '../../components/errormessage';
import CloseIcon from '@material-ui/icons/Close';

import { urlImg } from "../../redux/fetch/urlHelper";
import imageLib from '../../themes';

class TimeEventModal extends MyPage {
    constructor(props) {
        super(props);
        const obj = this.getObject();
        this.state = {
            modal: false,
            isCreating: ! this.props.object ? true : false,
            index: this.props.index,
            object: obj,
            type: obj.type,
            value: obj.value,
            timeFromMin: obj.timeFromMin,
            timeFromSec: obj.timeFromSec,
            timeToMin: obj.timeToMin,
            timeToSec: obj.timeToSec,
            timeError: "",
            // fileError: "", // inutile de declarer
            canSubmit: true,
            showValidationErrors: false,
        };
    }

    getObject = () => {
        if (this.props.object) {
            const obj = {
                type: this.props.object.type,
                value: this.props.object.value,
                timeFromMin: this.props.object.timeFrom.split(":")[0],
                timeFromSec: this.props.object.timeFrom.split(":")[1],
                timeToMin: this.props.object.timeTo.split(":")[0],
                timeToSec: this.props.object.timeTo.split(":")[1],
            };
            return obj;
        } else {
            return {
                type: "text",
                value: "",
                timeFromMin: "00:00".split(":")[0],
                timeFromSec: "00:00".split(":")[1],
                timeToMin: "00:00".split(":")[0],
                timeToSec: "00:00".split(":")[1],
            };
        }
    }

    getTexts = () => {
        return {
            tableTitle: "Sessions",
        }
    }

    toggle = async () => {
        if (!this.state.modal) {
            const obj = this.getObject();
            console.log("LOAD", obj);
            this.setState({
                index: this.props.index,
                object: obj,
                type: obj.type,
                value: obj.value,
                timeFromMin: obj.timeFromMin,
                timeFromSec: obj.timeFromSec,
                timeToMin: obj.timeToMin,
                timeToSec: obj.timeToSec,
                timeError: "",
                canSubmit: true,
                showValidationErrors: false,
            });
        }
        this.setState({ modal: !this.state.modal });
    }

    handleChange = (e) => {
        e.preventDefault();

        let allowChange = true;
        const timeFields = ["timeFromMin", "timeFromSec", "timeToMin", "timeToSec"];
        if (timeFields.includes(e.target.id)) {
            if (e.target.value < 0 || e.target.value > 59) {
                allowChange = false;
            }
        }
        if (allowChange) {
            this.setState({
                [e.target.id]: e.target.value,
            });
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        
        this.state.object.type = this.state.type;
        this.state.object.value = this.state.value;

        const table = ["timeFromMin", "timeFromSec", "timeToMin", "timeToSec"];
        table.forEach((stateId) => {
            if (this.state[stateId] === "") {
                this.state[stateId] = 0;
            }
            let value = parseInt(this.state[stateId], 10);
            this.state[stateId] = value < 10 ? "0" + value.toString() : value.toString();
        });

        this.state.object.timeFrom = this.state.timeFromMin + ":" + this.state.timeFromSec;
        this.state.object.timeTo = this.state.timeToMin + ":" + this.state.timeToSec;


        if (this.state.timeFromMin > this.state.timeToMin) {
            this.setState({ timeError: "Finish time must be superior to start time." });
        } else if (this.state.timeFromMin === this.state.timeToMin && this.state.timeFromSec > this.state.timeToSec) {
            this.setState({ timeError: "Finish time must be superior to start time." });
        } else {
            this.setState({ timeError: "" });
            this.toggle();

            delete this.state.object.timeFromMin;
            delete this.state.object.timeFromSec;
            delete this.state.object.timeToMin;
            delete this.state.object.timeToSec;

            this.props.onExit(this.state.object, this.state.index);
        }
    }

    

    handleRadio = (e, stateId) => {
        if (stateId === "type" && e.target.value !== this.state.type) {
            this.setState({ value: "" });
        }
        this.setState({ [stateId]: e.target.value });
    }

    render() {
        let iimm = this.state.value === '' ? imageLib.noImage : urlImg(this.state.value);
        return (
            <div>
                {this.props.toggleButton}
                <button className="btn green mr-2" type="button" onClick={this.toggle}>
                    {this.state.isCreating ? "+" : "Edit"}
                </button>

                <Modal
                    toggle={this.toggle}
                    isOpen={this.state.modal}
                >
                    <Paper style={{ width: '100%', padding: '2%' }}>

                        <Formsy className='create-device-form'
                            onValidSubmit={this.submit}
                            onInvalidSubmit={() => this.setState({ showValidationErrors: true })}
                            onValid={() => this.setState({ canSubmit: true })}
                            onInvalid={() => this.setState({ canSubmit: false })}
                        >
                            <div className='create-device-row'>
                                <label>Type</label>
                                <span>
                                    <input type="radio" id="type" value={"text"}
                                    onChange={(e) => this.handleRadio(e, "type")} checked={this.state.type === "text"} />
                                    Text
                                </span>
                                <span>
                                    <input type="radio" id="type" value={"image"}
                                    onChange={(e) => this.handleRadio(e, "type")} checked={this.state.type === "image"} />
                                    Image
                                </span>
                            </div>
                            {this.state.type === "text" &&
                                <div className='create-device-row'>
                                    <label>Text</label>
                                    <textarea
                                        id="value"
                                        name="value"
                                        className='create-device-input'
                                        rows={5}
                                        value={this.state.value}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            }
                            {this.state.type === "image" &&
                                <div className='create-device-row'>
                                    <label>Image File</label>
                                    <div className='create-device-row'>
                                        <div className='div-row'>
                                            <Dropzone
                                                className="upload-border"
                                                onDrop={(files) => { this.handleDropImage(files, "value", "fileError") }}
                                                // onChange={(e) => this.onChangeImage(e, 0)}
                                                accept="image/jpeg,image/jpg,image/tiff,image/gif,image/png"
                                                multiple={false}
                                                type={'file'}>
                                                <span className="w-100" style={{textAlign: 'center', marginBottom: '5px'}}>Drag and drop file or </span>
                                                <button className="btn blue" type="button">BROWSE</button>
                                            </Dropzone>
                                            <div style={{ textAlign: 'right', position: 'relative' }}>
                                                {this.state.value !== "" &&
                                                <button className="btn delete-btn" type="button" onClick={() => this.deleteUploadFile("value", "fileError")}><CloseIcon /></button>
                                                }
                                                <img style={{width: 180, height: 180, objectFit: 'contain', border: '1px solid #e6e9ec', marginLeft: '20px'}} src={iimm} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <ErrorMessage text={this.state.fileError} />
                            <MyInput
                                id="value"
                                name="value"
                                classNameDiv='create-branch-row'
                                className='create-device-input'
                                value={this.state.value}
                                hidden
                                disabled
                                required
                                showError={this.state.showValidationErrors}
                            />
                            <div className='create-device-row'>
                                <label>Start</label>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <MyInput
                                        id="timeFromMin"
                                        name="timeFromMin"
                                        type="number"
                                        classNameDiv='create-branch-row'
                                        className='create-device-input'
                                        value={this.state.timeFromMin}
                                        onChange={this.handleChange}
                                    />:<MyInput
                                        id="timeFromSec"
                                        name="timeFromSec"
                                        type="number"
                                        classNameDiv='create-branch-row'
                                        className='create-device-input'
                                        value={this.state.timeFromSec}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div className='create-device-row'>
                                <label>End</label>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <MyInput
                                        id="timeToMin"
                                        name="timeToMin"
                                        type="number"
                                        classNameDiv='create-branch-row'
                                        className='create-device-input'
                                        value={this.state.timeToMin}
                                        onChange={this.handleChange}
                                    />:<MyInput
                                        id="timeToSec"
                                        name="timeToSec"
                                        type="number"
                                        classNameDiv='create-branch-row'
                                        className='create-device-input'
                                        value={this.state.timeToSec}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <ErrorMessage text={this.state.timeError} />
                            <div style={{display: "flex", justifyContent: "flex-end", paddingTop: "10px"}}>
                                <button type="submit" className="btn green mr-2" onClick={this.state.canSubmit ? this.handleSubmit : undefined}>Confirm</button>
                                <button type="button" className="btn mr-2" onClick={ this.toggle }>Cancel</button>
                            </div>
                        </Formsy>
                    </Paper>
                </Modal>
            </div>
        );
    }
}

export default TimeEventModal;