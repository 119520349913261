import React, { Component } from 'react';
// import './Style.css';

class Profile extends Component {

  render() {
    return (
      <div className="devices-page-wrapper">
        <h3>Error</h3>
        <p>It seems that the page you're looking for doesn't exist...</p>
      </div>
    );
  }
}

export default Profile;
