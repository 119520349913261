import config from '../../config'

export const BASE_URL = process.env.REACT_APP_API_BASE_URL
  ? process.env.REACT_APP_API_BASE_URL
  : 'https://api.pathwayspainrelief.com'

export function url (path) {
  return BASE_URL + path
}

function urlForFileName (fileName) {
  if (!urlForFileName) {
    return undefined
  }

  const s3enabled = config.s3
  const legacyModeOn = config.legacy && config.legacy.storedOnServerMediaSupport
  let useS3BasedURL = false

  if (s3enabled) {
    if (legacyModeOn) {
      if (fileName.indexOf('s3_') !== -1) {
        useS3BasedURL = true
      }
    } else {
      useS3BasedURL = true
    }
  }
  if (useS3BasedURL) {
    return process.env.REACT_APP_API_BASE_URL
  ? process.env.REACT_APP_API_BASE_URL
  : 'https://pathways-prod.s3.amazonaws.com/' + fileName
  } else {
    return BASE_URL + '/public/uploads/' + fileName
  }
}

export function urlImg (fileName) {
  return urlForFileName(fileName)
}

export function urlAudio (fileName) {
  return urlForFileName(fileName)
}

export function urlVideo (fileName) {
  return urlForFileName(fileName)
}
