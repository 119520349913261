
const pathways = {
  title: 'Pathways',
  customTitles: {
    meTimeSection: 'Mind & Body',
    meTimeSessions: 'Mind & Body Sessions',
    meTimeSession: 'Mind & Body Session'
  },
  feature: {
    journey: true,
    mindAndBody: true,
    recommendations: true,
    challenges: true,
    factors: true,
    referralCodes: true,
    programSection: true,
    otherSection: true,
    registrationPolls: true,
    diagnosis: true,
    sessionPlain: true,
    feelGoodTask: true,
    screensText: true,
    userFeedback: true,
    quiz: true
  },
  legacy: {
    storedOnServerMediaSupport: true
  },
  s3: true
}

const impulse = {
  title: 'Impulse',
  customTitles: {
    meTimeSection: 'Impulse Program',
    meTimeSessions: 'Impulse Sessions',
    meTimeSession: 'Impulse Session'
  },
  feature: {
    journey: false,
    mindAndBody: false,
    recommendations: false,
    challenges: false,
    factors: true,
    referralCodes: true,
    programSection: false,
    otherSection: false,
    registrationPolls: false,
    diagnosis: false,
    sessionPlain: false,
    feelGoodTask: false,
    screensText: false,
    userFeedback: false,
    quiz: false
  },
  s3: true
}

const somnus = {
  title: 'Somnus',
  customTitles: {
    meTimeSection: 'Somnus Program',
    meTimeSessions: 'Somnus Sessions',
    meTimeSession: 'Somnus Session'
  },
  feature: {
    journey: false,
    mindAndBody: false,
    recommendations: false,
    challenges: false,
    factors: true,
    referralCodes: true,
    programSection: false,
    otherSection: false,
    registrationPolls: false,
    diagnosis: false,
    sessionPlain: false,
    feelGoodTask: false,
    screensText: false,
    userFeedback: false,
    quiz: false
  },
  s3: true
}

const hetal = {
  title: 'Hetal',
  customTitles: {
    meTimeSection: 'Hetal Program',
    meTimeSessions: 'Hetal Sessions',
    meTimeSession: 'Hetal Session'
  },
  feature: {
    journey: true,
    mindAndBody: true,
    recommendations: true,
    challenges: true,
    factors: true,
    referralCodes: true,
    programSection: true,
    otherSection: true,
    registrationPolls: true,
    diagnosis: true,
    sessionPlain: true,
    feelGoodTask: true,
    screensText: true,
    userFeedback: true,
    quiz: true
  },
  s3: true
}

const config = process.env.REACT_APP_NAME === 'pathways' ? pathways
  : process.env.REACT_APP_NAME === 'impulse' ? impulse
  : process.env.REACT_APP_NAME === 'somnus' ? somnus
  : process.env.REACT_APP_NAME === 'hetal' ? hetal : pathways
const MONTHLY_SUBSCRIPTION = 'com.pathways.onemonthsubscription'
const ANNUAL_SUBSCRIPTION = 'com.pathways.oneyearsubscription'
const LIFETIME_SUBSCRIPTION = 'com.pathways.lifetimesubcription'
const ONE_OFF_LIFETIME_SUBSCRIPTION = 'com.pathways.lifetimesubcriptionlimitedoffer'
const ONE_OFF_LIFETIME_SUBSCRIPTION_79_99 = 'com.pathways.lifetimesubcriptionlimitedoffer2'

export default config
export { MONTHLY_SUBSCRIPTION, ANNUAL_SUBSCRIPTION, LIFETIME_SUBSCRIPTION, ONE_OFF_LIFETIME_SUBSCRIPTION, ONE_OFF_LIFETIME_SUBSCRIPTION_79_99 }
