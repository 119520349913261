import React from 'react';
import HomePage from '../home';
import MyPage from '../../components/MyPage';
import MyTable from '../../components/MyTable';
import MyTableHeaderSort from '../../components/MyTableHeaderSort';
import { SearchIcon } from '../../Icons/svg';
import DeleteIcon from '@material-ui/icons/Delete'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button } from '@material-ui/core'

import { actions as quizActions } from '../../redux/modules/quiz';


class QuizList extends MyPage {

  constructor(props) {
    super(props);
    this.state = {
      fullList: [],
      filteredList: [],
      isLoading: true,
      deleteDialogIsOpened: false,
      searchKeyword: '',
      order: 'asc',
      orderBy: '',
    };
  }

  getList = async () => {
    const data = await quizActions.getQuizList();
    return data;
  }

  getTexts = () => {
    return {
      tableTitle: "Quizzes",
      searchTitle: "Search Quiz",
    }
  }

  edit = (e, obj = null) => {
    e.preventDefault();
    this.props.router.push({
      pathname: '/quiz-edit',
      state: { obj }
    });
  }

  handleSearch = (e) => {
    e.preventDefault();
    let searchKeyword = e.target.value;
    this.setState({searchKeyword}, () => {
      const regexp = new RegExp(searchKeyword.replace(/[-+*.?|^$/()[\]{}\\]/g, "\\$&"), "gi");
      let filteredList = this.state.fullList.filter(object => {
        return object.title.match(regexp)
      });
      this.setState({
        filteredList
      });
    });
  }

  showDeleteDialog = () => {
    this.setState({
      deleteDialogIsOpened: true,
    })
  }

  hideDeleteDialog = value => {
    this.setState({
      deleteDialogIsOpened: false
    })
  }

  handleDeleteQuiz = (e, item) => {
    this.setState({
      deleteQuizId: item._id
    })
    this.showDeleteDialog()
  }

  deleteQuiz = async () => {
    const result = await quizActions
      .deleteQuiz(this.state.deleteQuizId)
    if (result.success) {
      const list = await this.getList()
      this.setState({
        deleteQuizId: null,
        deleteDialogIsOpened: false,
        fullList: list,
        filteredList: list
      })
    } else {
      console.error(result)
    }
  }

  async componentDidMount() {
    const list = await this.getList();
    this.setState({
      fullList: list,
      filteredList: list,
      isLoading: false,
    });
  }


  makeToolBar = () => {
    return (
      <div className='device-tools'>
        <div className='search-icon'>
          <SearchIcon/>
          <input className='device-search' onChange={this.handleSearch} placeholder={this.getTexts().searchTitle} tabIndex="-1"/>
        </div>
        <div>
          <button className={"btn green mr-2"} onClick={this.edit}>+</button>
        </div>
      </div>
    );
  }

  makeHead = () => {
    return (
      <div className={"table-head"}>

        <div style={{ width: '70%', paddingLeft: "15px" }}>
          <MyTableHeaderSort
            label="Title"
            active={this.state.orderBy === "title"}
            direction={this.state.order}
            onClick={this.createSortHandler("title")}
          />
        </div>

        <div style={{ width: '20%', paddingLeft: "15px" }}>
          <MyTableHeaderSort label="Actions" />
        </div>

        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Delete" />
        </div>

      </div>
    );
  }

  makeRow = (item) => {
    return (
      <div className={"table-row"}>
        <div style={{ width: "80%", paddingLeft: "15px" }}>{item.title}</div>

        <div style={{ width: "20%", paddingLeft: "15px" }}>
          <button type="button" className="btn" onClick={(e) => this.edit(e, item)}>Edit</button>
        </div>

        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <button
            type="button"
            className="btn"
            onClick={e => this.handleDeleteQuiz(e, item)}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <HomePage router={this.props.router}>
        <div className='device-table-container'>
          {! this.state.isLoading &&
            <MyTable
              tableName={this.getTexts().tableTitle}
              tableToolBar={this.makeToolBar}
              tableHead={this.makeHead}
              tableRow={this.makeRow}
              hasIndex={true}
              order={this.state.order}
              orderBy={this.state.orderBy}
              data={this.state.filteredList}
              hasPagination={false}
            />
          }
        </div>
        <Dialog
          onClose={this.hideDeleteDialog}
          open={this.state.deleteDialogIsOpened}
          aria-labelledby="delete-dialog-title"
        >
          <DialogTitle id="simple-dialog-title">
            Confirm quiz delete
          </DialogTitle>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 24px 24px 24px'
          }}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.hideDeleteDialog}>Cancel</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.deleteQuiz}>Ok</Button>
          </div>
        </Dialog>
      </HomePage>
    );
  }
}

export default QuizList;
