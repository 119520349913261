import { actions as topicActions } from '../../redux/modules/topics'

// Types imports
import TopicList from '../topic_list'

class SubtopicsList extends TopicList {
  setIsSubtopicsList () {
    this.isSubtopicsList = true
  }

  getList = async () => {
    const data = await topicActions.getSubtopicsList()
    return data
  }

  getTexts = () => {
    return {
      tableTitle: 'Subtopics',
      searchTitle: 'Search Subtopic'
    }
  }

  edit = (e, obj) => {
    e.preventDefault()
    this.props.router.push({
      pathname: '/subtopic-edit',
      state: { obj: obj }
    })
  }

  handleReorder = async items => {
    items.forEach((element, index) => {
      element.index = index
    })
    this.setState({ fullList: items, order: 'asc', orderBy: 'index' })
    await topicActions.saveSubtopicsList(this.state.fullList)
  }

   /**
   * Listens delete btn
   */
  handleItemDelete = (e, item) => {
    if (!item || !item._id) return
    const deleteDialogParams = {
      open: true,
      title: `Are you sure you want to delete "${item.title || ''}" ?`,
      handleConfirm: async () => {
        try {
          const delRes = await topicActions.deleteSubtopicById(item._id)
          if (!delRes) return
          if (delRes.success) {
            this.updateList()
          }
        } catch (e) {
          console.log(e)
        }
        deleteDialogParams.handleCancel()
      },
      handleCancel: () => {
        this.setState(state => {
          return {
            ...state,
            deleteDialogParams: false
          }
        })
      }
    }

    this.setState(state => {
      return {
        ...state,
        deleteDialogParams
      }
    })
  }
}

export default SubtopicsList
