import React from 'react'
import PropTypes from 'prop-types'
import HomePage from '../home'
import PageWithPagination from '../../components/PageWithPagination'
import TableWithPagination from '../../components/TableWithPagination'
import MyTableHeaderSort from '../../components/MyTableHeaderSort'
import DeleteIcon from '@material-ui/icons/Delete'
import { actions } from '../../redux/modules/SOSExperienceSubtopics'
import DeleteDialog from '../../components/DeleteDialog'
import ErrorMessage from '../../components/errormessage'
import { SearchIcon } from '../../Icons/svg'

class SOSExperienceSubtopicsList extends PageWithPagination {
  constructor (props) {
    super(props)
    this.state = {
      list: [],
      totalCount: 0,
      isLoading: true,
      errorMessage: '',
      searchField: 'title'
    }
    // Bind listener of browser history change
    this.addHistoryListener(this.getList)
  }

  async componentDidMount () {
     // Initialize params from query string or use default and fetch data
    const { location } = this.props
    const params = Object.assign({}, location.query || {})

    if (!params.orderBy) {
      params.orderBy = 'index'
    }

    if (!params.order) {
      params.order = 'ASC'
    }

    this.setQueryParamsToState(params, this.getList)
  }

  /**
   * Fetches data
   */
  getList = async () => {
    const { page, perPage, order, orderBy, searchField, searchKeyword } = this.state
    const { data, totalCount, errorMessage } = await actions.getList({
      page,
      perPage,
      order,
      orderBy,
      searchField,
      searchKeyword
    })

    this.setState({
      list: data || [],
      totalCount: totalCount || 0,
      isLoading: false
    })

    if (errorMessage) {
      this.setState({ errorMessage })
    }
  }

  getTexts = () => {
    return {
      tableTitle: 'SOS Experience Subtopics',
      searchTitle: 'Search SOS Experience Subtopic'
    }
  }

  edit = (e, obj) => {
    e.preventDefault()
    this.props.router.push({
      pathname: '/sos-experience-subtopic-edit',
      state: { obj: obj }
    })
  }

  /**
   * Saves new indexes after drag reorder
   */
  handleReorder = async items => {
    this.setState({ list: items })
    const { errorMessage } = await actions.updateSort(items)

    if (errorMessage) {
      this.setState({ errorMessage })
    }
  }

  isDraggable = () => {
    if (this.state.searchField && this.state.searchKeyword) {
      return false
    }

    return true
  }

   /**
   * Changes history when searching
   * and data is fetched
   */
  handleСhangeSearch = e => {
    e.preventDefault()
    let searchField = 'title'
    let searchKeyword = e.target.value
    searchKeyword = searchKeyword.replace(/[-+*.?|^$/()[\]{}\\]/g, '\\$&')

    this.setState(state => {
      return {
        ...state,
        searchField,
        searchKeyword,
        page: 1
      }
    }, async () => {
      this.debouncedUpdateHistory()
    })
  }

  /**
  * Listens delete btn
  */
  handleItemDelete = (e, item) => {
    if (!item || !item._id) {
      return
    }

    const deleteDialogParams = {
      open: true,
      title: `Are you sure you want to delete "${item.title || ''}" ?`,
      handleConfirm: async () => {
          deleteDialogParams.handleClose()

          const { errorMessage } = await actions.deleteById(item._id)
          
          if (errorMessage) {
            this.setState({ errorMessage })
          } else {
            await this.getList()
          }
      },
      handleClose: () => {
        this.setState({ deleteDialogParams: false })
      },
      handleCancel: () => {
        deleteDialogParams.handleClose()
      }
    }

    this.setState(state => {
      return {
        ...state,
        deleteDialogParams
      }
    })
  }

  makeToolBar = () => {
    return (
      <div className="device-tools">
        <div className="search-icon">
          <SearchIcon />
        </div>
        <input
            className="search-input"
            onChange={this.handleСhangeSearch}
            placeholder='Search SOS Experience Subtopic'
            tabIndex="-1"
            value={this.state.searchKeyword || ''}
          />
          <div>
            <button className={'btn green mr-2'} onClick={this.edit}>+</button>
          </div>
      </div>
    )
  }

  makeHead = () => {
    return (
      <div className={'table-head'}>
        <div style={{ width: '80%', paddingLeft: '25px' }}>
          <MyTableHeaderSort
            label="Title"
          />
        </div>

        <div style={{ width: '20%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Actions" />
        </div>
      </div>
    )
  }

  makeRow = item => {
    return (
      <div className={'table-row'}>
        <div style={{ width: '80%', paddingLeft: '25px' }}>
          {item.title}
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <button
            type="button"
            className="btn"
            onClick={e => this.edit(e, item)}
          >
            Edit
          </button>
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <button
            type="button"
            className="btn"
            onClick={e => this.handleItemDelete(e, item)}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
    )
  }

  render () {
    const {
      order,
      orderBy,
      totalCount,
      list,
      page,
      perPage,
      deleteDialogParams,
      errorMessage,
      isLoading
    } = this.state
    return (
      <HomePage router={this.props.router} addClassName="h-100">
        <div className="device-table-container">
          <ErrorMessage text={errorMessage} />
          {!isLoading && (
            <TableWithPagination
              tableName={this.getTexts().tableTitle}
              tableToolBar={this.makeToolBar}
              tableHead={this.makeHead}
              tableRow={this.makeRow}
              isDraggable={this.isDraggable()}
              hasIndex={true}
              hasPagination={true}
              page={(page - 1)}
              rowsPerPage={perPage}
              totalCount={totalCount}
              order={order}
              orderBy={orderBy}
              data={list}
              onDragEnd={items => {
                this.handleReorder(items)
              }}
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          )}
        </div>
        {deleteDialogParams && <DeleteDialog {...deleteDialogParams} />}
      </HomePage>
    )
  }
}

SOSExperienceSubtopicsList.propTypes = {
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
}

export default SOSExperienceSubtopicsList
