import { fetchFromUrlPOSTAsync, fetchFromUrlDELETEAsync } from '../fetch/';

export const actions = {

  getCollectionList: async () => {
    const result = await fetchFromUrlPOSTAsync('/collection/get_list_web', [
      { key: 'isBase64FilesDisabled', value: true }
    ])
    return result.success ? result.data : []
  },

  saveCollection: async data => {
    const saveData = data
    delete saveData.selectedDiagnoses

    let urlParams = [
      { key: 'saveData', value: saveData },
    ]
    const result = await fetchFromUrlPOSTAsync('/collection/save_one', urlParams)
    return result
  },

  getDifficultyTypes: () => {
    return [
      'Getting Started',
      'Intermediate',
      'Advanced'
    ]
  },

  deleteById: async collectionId => {
    try {
      const result = await fetchFromUrlDELETEAsync(`/collection/delete/${collectionId}`)
      if (result.status === 200) {
        return { success: true }
      }
      return false
    } catch (e) {
      return false
    }
  }
}
