import React from 'react'
import PropTypes from 'prop-types'
import HomePage from '../home'
import PageWithPagination from '../../components/PageWithPagination'
import TableWithPagination from '../../components/TableWithPagination'
import MyTableHeaderSort from '../../components/MyTableHeaderSort'
import DeleteIcon from '@material-ui/icons/Delete'
import { actions } from '../../redux/modules/factorSubtopics'
import DeleteDialog from '../../components/DeleteDialog'
import ErrorMessage from '../../components/errormessage'

class FactorSubtopicsList extends PageWithPagination {
  constructor (props) {
    super(props)
    this.state = {
      list: [],
      totalCount: 0,
      isLoading: true,
      errorMessage: '',
      searchField: 'title'
    }
    // Bind listener of browser history change
    this.addHistoryListener(this.getList)
  }

  async componentDidMount () {
     // Initialize params from query string or use default and fetch data
    const { location } = this.props
    const params = Object.assign({}, location.query || {})

    if (!params.orderBy) {
      params.orderBy = 'index'
    }

    if (!params.order) {
      params.order = 'ASC'
    }

    this.setQueryParamsToState(params, this.getList)
  }

  /**
   * Fetches data
   */
  getList = async () => {
    const { page, perPage, order, orderBy, searchField, searchKeyword } = this.state
    const { success, data, error } = await actions.getList({
      page,
      perPage,
      order,
      orderBy,
      searchField,
      searchKeyword
    })

    if (error) {
      return this.setState({ errorMessage: error })
    }

    if (success && data) {
      this.setState({
        list: data.items || [],
        totalCount: data.total || 0,
        isLoading: false
      })
    }

    this.setState({
      isLoading: false
    })
  }

  getTexts = () => {
    return {
      tableTitle: 'Factor Subtopics'
    }
  }

  edit = (e, obj) => {
    e.preventDefault()
    this.props.router.push({
      pathname: '/factor-subtopic-edit',
      state: { obj: obj }
    })
  }

  /**
  * Listens delete btn
  */
  handleItemDelete = (e, item) => {
    if (!item || !item._id) {
      return
    }

    const deleteDialogParams = {
      open: true,
      title: `Are you sure you want to delete "${item.title || ''}" ?`,
      handleConfirm: async () => {
        try {
          deleteDialogParams.handleClose()
          const delRes = await actions.deleteById(item._id)
          if (delRes && delRes.success) {
            await this.getList()
          }
        } catch (e) {
          console.error(e.message)
        }
      },
      handleClose: () => {
        this.setState({ deleteDialogParams: false })
      },
      handleCancel: () => {
        deleteDialogParams.handleClose()
      }
    }

    this.setState(state => {
      return {
        ...state,
        deleteDialogParams
      }
    })
  }

  handleReorder = async items => {
    items.forEach((element, index) => {
      element.index = index
    })

    this.setState({ list: items, order: 'asc', orderBy: 'index' })

    const result = await actions.updateSort(items)

    if (!result.success) {
      this.setState({ errorMessage: result.error })
    }
  }

  makeToolBar = () => {
    return (
      <div className='device-tools'>
        <div>
          <button className={'btn green mr-2'} onClick={this.edit}>+</button>
        </div>
      </div>
    )
  }

  makeHead = () => {
    return (
      <div className={'table-head'}>
        <div style={{ width: '80%', paddingLeft: '15px' }}>
          <MyTableHeaderSort
            label="Title"
          />
        </div>

        <div style={{ width: '20%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Actions" />
        </div>
      </div>
    )
  }

  makeRow = item => {
    return (
      <div className={'table-row'}>
        <div style={{ width: '80%', paddingLeft: '15px' }}>
          {item.title}
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <button
            type="button"
            className="btn"
            onClick={e => this.edit(e, item)}
          >
            Edit
          </button>
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <button
            type="button"
            className="btn"
            onClick={e => this.handleItemDelete(e, item)}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
    )
  }

  render () {
    const {
      order,
      orderBy,
      totalCount,
      list,
      page,
      perPage,
      deleteDialogParams,
      errorMessage,
      isLoading
    } = this.state
    return (
      <HomePage router={this.props.router} addClassName="h-100">
        <div className="device-table-container">
          <ErrorMessage text={errorMessage} />
          {!isLoading && (
            <TableWithPagination
              tableName={this.getTexts().tableTitle}
              tableToolBar={this.makeToolBar}
              tableHead={this.makeHead}
              tableRow={this.makeRow}
              isDraggable={true}
              hasIndex={true}
              hasPagination={true}
              page={(page - 1)}
              rowsPerPage={perPage}
              totalCount={totalCount}
              order={order}
              orderBy={orderBy}
              data={list}
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              onDragEnd={items => {
                this.handleReorder(items)
              }}
            />
          )}
        </div>
        {deleteDialogParams && <DeleteDialog {...deleteDialogParams} />}
      </HomePage>
    )
  }
}

FactorSubtopicsList.propTypes = {
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
}

export default FactorSubtopicsList
