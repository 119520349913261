import React from 'react';
import HomePage from '../home';
import Formsy from 'formsy-react';
import MyPage from '../../components/MyPage';
import MyInput from '../../components/MyInput';
import ErrorMessage from '../../components/errormessage';

import { actions as FAQActions } from '../../redux/modules/faq';

class FAQEdit extends MyPage {

  constructor(props) {
    super(props);
    const obj = this.getObject();
    this.state = {
      question: obj,
      title: obj.title,
      content: obj.content,
      errorMessage: "",
      canSubmit: false,
      showValidationErrors: false,
    };
  }

  getObject = () => {
    if (this.props.location.state.obj) {
      return this.props.location.state.obj;
    } else {
      return {
        title: "",
        content: "",
      };
    }
  }

  pageTitle() {
    return "Edit FAQ";
  }

  cancel = (e) => {
    e.preventDefault();
    this.props.router.push('/faq');
  }

  save = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to save this question?")) {
      this.state.question.title = this.state.title;
      this.state.question.content = this.state.content;
      const result = await FAQActions.saveQuestion(this.state.question);
      if (result) {
        this.cancel(e);
      }
    }
  }

  render() {
    const style = {color:'red'}
    return (
      <HomePage router={this.props.router}>
        <div className="devices-page-wrapper">
          <h3>{this.pageTitle()}</h3>
          <Formsy className='create-device-form'
            onValidSubmit={this.submit}
            onInvalidSubmit={() => this.setState({ showValidationErrors: true })}
            onValid={() => this.setState({ canSubmit: true })}
            onInvalid={() => this.setState({ canSubmit: false })}
          >
            <div className='create-device-row'>
              <label>Title <span style={style}>*</span></label>
              <MyInput
                id="title"
                name="title"
                classNameDiv='create-branch-row'
                className='create-device-input'
                autocomplete="off"
                value={this.state.title}
                onChange={this.handleChange}
                required
                showError={this.state.showValidationErrors}
              />
            </div>
            <div className='create-device-row'>
              <label>Content</label>
              <textarea
                id="content"
                name="content"
                className='create-device-input'
                rows={5}
                value={this.state.content}
                onChange={this.handleChange}
              />
            </div>
            <ErrorMessage text={this.state.errorMessage}/>
            <button className='btn green mr-2' type="submit" onClick={this.state.canSubmit ? this.save : undefined}>Save</button>
            <button className='btn blue mr-2' type="button" onClick={this.cancel}>Cancel</button>
          </Formsy>
        </div>
      </HomePage>
    );
  }
}

export default FAQEdit;
