import { fetchFromUrlPOSTAsync } from '../fetch/';

export const actions = {

  getFAQList: async () => {
    const result = await fetchFromUrlPOSTAsync('/faq/get_sorted_list', []);
    return result.success ? result.data : [];
  },

  saveQuestion: async (saveData) => {
    let urlParams = [
      { key: 'saveData', value: saveData },
    ];
    const result = await fetchFromUrlPOSTAsync('/faq/save_one', urlParams);
    return result;
  },

  saveFAQList: async (saveData) => {
    let urlParams = [
      { key: 'saveData', value: saveData },
    ];
    const result = await fetchFromUrlPOSTAsync('/faq/save_all', urlParams);
    return result;
  },
};
