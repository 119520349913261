import React from 'react';
import { Modal } from 'reactstrap';
// import { Link } from 'react-router';

import Paper from '@material-ui/core/Paper';
// import Images from '../../themes';


import Formsy from 'formsy-react';
import MyInput from '../../components/MyInput';
import MySelect from '../../components/MySelect';
import ErrorMessage from '../../components/errormessage';


export default class ModalComponent extends React.Component {
    constructor(props) {
        super(props);
        const obj = this.getObject();
        this.state = {
            modal: false,
            isCreating: ! this.props.object ? true : false,
            object: obj,
            title: obj.title,
            correctAnswer: obj.correctAnswer,
            text: obj.text,
            
            canSubmit: false,
            showValidationErrors: false,
        };
        this.toggle = this.toggle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getObject = () => {
        if (this.props.object) {
            return this.props.object;
        } else {
            return {
                title: "",
                correctAnswer: 1,
                text: "",
            };
        }
    }

    toggle() {
        // if (!this.state.modal && !this.props.object) {
        //     console.log("CREATE");
        // }
        if (!this.state.modal) {
            const obj = this.getObject();
            console.log("LOAD", obj);
            this.setState({
                object: obj,
                title: obj.title,
                correctAnswer: obj.correctAnswer,
                text: obj.text,
            });

        }

        this.setState({
            modal: !this.state.modal
        });
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    handleSelect = (stateId, value) => {
      this.setState({ [stateId]: value });
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    save = async (e) => {
        e.preventDefault();
        if (window.confirm("Are you sure you want to save this question?")) {
    
            this.state.object.title = this.state.title;
            this.state.object.correctAnswer = this.state.correctAnswer;
            this.state.object.text = this.state.text;

            this.toggle();

            this.props.onExit(this.state.object);
        }
      }

    render() {
        const style = {color:'red'};
        return (
            <div>
                {this.props.toggleButton}
                <button className="btn green mr-2" type="button" onClick={this.toggle}>
                    {this.state.isCreating ? "+" : "Edit"}
                </button>

                <Modal
                    toggle={this.toggle}
                    isOpen={this.state.modal}
                >
                    <Paper style={{ width: '100%', padding: '2%' }}>
                        <Formsy className='create-device-form80'
                            onValidSubmit={this.submit}
                            onInvalidSubmit={() => this.setState({ showValidationErrors: true })}
                            onValid={() => this.setState({ canSubmit: true })}
                            onInvalid={() => this.setState({ canSubmit: false })}
                            >
                            <div className='create-device-row'>
                                <label>Title <span style={style}>*</span></label>
                                <MyInput
                                    id="title"
                                    name="title"
                                    classNameDiv='create-branch-row'
                                    className='create-device-input'
                                    autocomplete="off"
                                    value={this.state.title}
                                    onChange={this.handleChange}
                                    required
                                    showError={this.state.showValidationErrors}
                                />
                            </div>

                            <div className='create-device-row'>
                                <label>Correct Answer</label>
                                <MySelect
                                    stateField="correctAnswer"
                                    className="create-branch-select"
                                    value={this.state.correctAnswer}
                                    options={[
                                        { value: 1, label: "True" },
                                        { value: 0, label: "False" },
                                    ]}
                                    onChange={this.handleSelect}
                                />
                            </div>

                            <div className='create-device-row'>
                                <label>Text</label>
                                <textarea
                                    id="text"
                                    name="text"
                                    className='create-device-input'
                                    rows={5}
                                    value={this.state.text}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <ErrorMessage text={this.state.errorMessage}/>
                            <button className='btn green mr-2' type="submit" onClick={this.state.canSubmit ? this.save : undefined}>Save</button>
                            <button className='btn blue mr-2' type="button" onClick={this.toggle}>Cancel</button>
                        </Formsy>
                    </Paper>
                </Modal>
            </div>
        );
    }
}
