import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import './Style.css';
import Images from '../../../themes';

import { actions as adminActions } from '../../../redux/modules/admin';
import { actions as userActions } from '../../../redux/modules/users';

class IpadHeader extends Component {
  logOut = (e) => {
    e.preventDefault();
    adminActions.clearAdmin();
    userActions.clearToken();
    this.props.router.push('/login');
  }

  hidelogoOnClick = (e) => {
    document.querySelectorAll(".hide-image")[0].classList.add("hide")
    document.querySelectorAll(".ipad-logo")[0].classList.add("blockk")
    document.querySelectorAll(".ipad-logo-wrapper")[0].classList.remove("left-width2")
    document.querySelectorAll(".left-navbar")[0].classList.remove("left-width2")
    document.querySelectorAll(".menu-text")[0].classList.remove("hide")
    document.querySelectorAll(".menu-text1")[0].classList.remove("hide")
    document.querySelectorAll(".menu-text2")[0].classList.remove("hide")
    document.querySelectorAll(".menu-text3")[0].classList.remove("hide")
    document.querySelectorAll(".add-property-text")[0].classList.remove("hide")
    document.querySelectorAll(".page-bg")[0].classList.remove("page-click-bg")
    document.querySelectorAll(".items-button")[0].classList.remove("i-button")
    document.querySelectorAll(".dashboard-image1")[0].classList.remove("dashboard-image11")
    document.getElementById("hidelogoOver").removeAttribute('data-type')
  }

  // logoOnClick = (e) => {
  //   document.querySelectorAll(".ipad-logo")[0].classList.add("hide")
  //   document.querySelectorAll(".ipad-logo-wrapper")[0].classList.add("left-width2")
  //   document.querySelectorAll(".hide-image")[0].classList.add("small-bg")
  //   document.querySelectorAll(".ipad-logo")[0].classList.remove("blockk")
  //   document.querySelectorAll(".hide-image")[0].classList.remove("hide")
  //   document.querySelectorAll(".left-navbar")[0].classList.add("left-width2")
  //   document.querySelectorAll(".menu-text")[0].classList.add("hide")
  //   document.querySelectorAll(".menu-text1")[0].classList.add("hide")
  //   document.querySelectorAll(".menu-text2")[0].classList.add("hide")
  //   document.querySelectorAll(".menu-text3")[0].classList.add("hide")
  //   document.querySelectorAll(".items-button")[0].classList.add("i-button")
  //   document.querySelectorAll(".page-bg")[0].classList.add("page-click-bg")
  //   document.querySelectorAll(".add-property-text")[0].classList.add("hide")
  //   document.querySelectorAll(".dashboard-image1")[0].classList.add("dashboard-image11")
  //   var data_type = document.createAttribute("data-type");
  //   data_type.value = "1";
  //   document.getElementById("hidelogoOver").setAttributeNode(data_type);
  // }

  smallogoOnClick = (e) => {
    document.querySelectorAll(".smalllogo")[0].classList.add("smal-hide")
    document.querySelectorAll(".ipad-logo-wrapper")[0].classList.add("left-larg-width")
    document.querySelectorAll(".larg-hide-image")[0].classList.add("larg-bg")
    document.querySelectorAll(".ipad-logo-wrapper")[0].classList.remove("left-smal-width")
    document.querySelectorAll(".smalllogo")[0].classList.remove("smal-block")
    document.querySelectorAll(".larg-hide-image")[0].classList.remove("smal-hide")
    document.querySelectorAll(".left-navbar")[0].classList.add("left-width")
    document.querySelectorAll(".menu-text")[0].classList.add("smal-block")
    document.querySelectorAll(".menu-text1")[0].classList.add("smal-block")
    document.querySelectorAll(".menu-text2")[0].classList.add("smal-block")
    document.querySelectorAll(".menu-text3")[0].classList.add("smal-block")
    document.querySelectorAll(".items-button")[0].classList.add("smal-block")
    document.querySelectorAll(".page-bg")[0].classList.add("page-smallclick-bg")
    document.querySelectorAll(".items-button")[0].classList.add("items-small-button")
    document.querySelectorAll(".add-property-text")[0].classList.add("add-property-small-text")
    document.querySelectorAll(".dashboard-image1")[0].classList.add("dashboard-small-image1")
  }

  smolhidelogoOnClick = (e) => {
    document.querySelectorAll(".larg-hide-image")[0].classList.add("smal-hide")
    document.querySelectorAll(".smalllogo")[0].classList.remove("smal-hide")
    document.querySelectorAll(".ipad-logo-wrapper")[0].classList.add("left-smal-width")
    document.querySelectorAll(".larg-hide-image")[0].classList.add("larg-bg")
    document.querySelectorAll(".menu-text")[0].classList.remove("smal-block")
    document.querySelectorAll(".menu-text1")[0].classList.remove("smal-block")
    document.querySelectorAll(".menu-text2")[0].classList.remove("smal-block")
    document.querySelectorAll(".menu-text3")[0].classList.remove("smal-block")
    document.querySelectorAll(".items-button")[0].classList.remove("smal-block")
    document.querySelectorAll(".left-navbar")[0].classList.remove("left-width")
    document.querySelectorAll(".page-bg")[0].classList.remove("page-smallclick-bg")
    document.querySelectorAll(".items-button")[0].classList.remove("items-small-button")
    document.querySelectorAll(".add-property-text")[0].classList.remove("add-property-small-text")
    document.querySelectorAll(".dashboard-image1")[0].classList.remove("dashboard-small-image1")
  }

  dropOnClick = (e) => {
    document.querySelectorAll(".drop-list")[0].classList.toggle("show")
  }

  render() {
    return (
      <div className="ipad-header w-100 d-flex justify-content-between" >
        <div className="collapsed ipad-logo-wrapper d-flex align-items-center" >

          <div className="logo-wrapper">
            <img src={Images.logo} id="logo" alt="" className="logo-image" />
          </div>

          <a href="#menu1" >
            {/* <img src={Images.logo} id="hidelogoOut" alt="" className="ipad-logo" onClick={this.logoOnClick} /> */}
            <div alt="" id="hidelogoOver" className="hide-image" onClick={this.hidelogoOnClick} />
            <div alt="" className="larg-hide-image" onClick={this.smolhidelogoOnClick} />
            <img src={Images.smalllogo} alt="" className="smalllogo" onClick={this.smallogoOnClick} />
          </a>
        </div>
        <div className="header-personal-wrapper d-flex align-items-center">
          <div className="personal-wrapper d-flex justify-content-center align-items-center" onClick={this.dropOnClick}>
            <img src={Images.personal} alt="" className="personal-image" />
            <p className="personal-name">{this.props.user.firstName}</p>
            <img src={Images.arrowdropdown} alt="" className="arrow-dropdown" />
            <ul className="drop-list" >

              {/* <Link className="ptofil-and-logout drop-menu first-drop-menu" to="/admin-edit"> */}
              <Link
                className="ptofil-and-logout drop-menu first-drop-menu"
                to={{
                  pathname: '/admin-edit',
                  state: {
                    obj: this.props.user,
                    selfEdit: true,
                  }
                }}>
                Profile
              </Link>
              <a className="ptofil-and-logout drop-menu" onClick={this.logOut}>Logout</a>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.adminReducer.admin
  }
}

export default connect(mapStateToProps)(IpadHeader);
