import React, { Component } from 'react'
import './Style.css'
import SideMenu from '../../components/menu'
import Header from '../../components/header'

class HomePage extends Component {
  render () {
    let className = 'd-flex overall-page-wrapper'
    if (this.props.addClassName) {
      className += ' ' + this.props.addClassName
    }

    return (
      <div>
        <Header router={this.props.router} />
        <div className={className}>
          <SideMenu />
          <div className="page-bg">{this.props.children}</div>
        </div>
      </div>
    )
  }
}

export default HomePage
