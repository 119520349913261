import React from 'react'
import HomePage from '../home'
import MyPage from '../../components/MyPage'
import MyTable from '../../components/MyTable'
import MyTableHeaderSort from '../../components/MyTableHeaderSort'
import { SearchIcon } from '../../Icons/svg'
import DeleteIcon from '@material-ui/icons/Delete'
import DeleteDialog from '../../components/DeleteDialog'
import ValidationDialog from '../../components/ValidationDialog'
import { actions as authorActions } from '../../redux/modules/authors'
import { urlImg } from '../../redux/fetch/urlHelper'

import imageLib from '../../themes'

class AuthorList extends MyPage {
  constructor (props) {
    super(props)
    this.state = {
      fullList: [],
      filteredList: [],
      isLoading: true,
      searchKeyword: '',
      order: 'asc',
      orderBy: ''
    }
  }

  getList = async () => {
    const data = await authorActions.getAuthorList()
    return data
  }

  getTexts = () => {
    return {
      tableTitle: 'Authors',
      searchTitle: 'Search Author'
    }
  }

  edit = (e, obj) => {
    e.preventDefault()
    this.props.router.push({
      pathname: '/author-edit',
      state: { obj: obj }
    })
  }

  handleSearch = e => {
    e.preventDefault()
    let searchKeyword = e.target.value
    this.setState({ searchKeyword }, () => {
      const regexp = new RegExp(searchKeyword.replace(/[-+*.?|^$/()[\]{}\\]/g, '\\$&'), 'gi')
      let filteredList = this.state.fullList.filter(object => {
        return object.name.match(regexp)
      })
      this.setState({ filteredList })
    })
  }

  /**
   * Listens author delete btn
   */
  handleItemDelete = (e, item) => {
    if (!item || !item._id) return
    const deleteDialogParams = {
      open: true,
      title: `Are you sure you want to delete "${item.name || ''}" ?`,
      handleConfirm: async () => {
        try {
          const delRes = await authorActions.deleteById(item._id)
          if (!delRes) return
          if (delRes.validationErrors) {
            this.handleDeleteValidation(item, delRes.validationErrors)
          }
          if (delRes.success) {
            this.updateList()
          }
        } catch (e) {
          console.log(e)
        }
        deleteDialogParams.handleCancel()
      },
      handleCancel: () => {
        this.setState(state => {
          return {
            ...state,
            deleteDialogParams: false
          }
        })
      }
    }

    this.setState(state => {
      return {
        ...state,
        deleteDialogParams
      }
    })
  }

  /**
   * Shows Validation notice if needed
   */
  handleDeleteValidation = (item, validationErrors) => {
    if (!item || !validationErrors || !validationErrors.length) {
      return
    }
    let info = validationErrors[0].error

    if (validationErrors[0].code &&
       (validationErrors[0].code === 'collections/hasSessionsAndCollections' ||
        validationErrors[0].code === 'collections/hasCollections' ||
        validationErrors[0].code === 'collections/hasSessions')
    ) {
      info += ',<br> delete them at first or assign another author.'
    }

    const validationDiaologParams = {
      open: true,
      title: `Author "${item.name || ''}" can't be deleted`,
      info: info,
      handleClose: () => {
        this.setState(state => {
          return {
            ...state,
            validationDiaologParams: false
          }
        })
      }
    }

    this.setState(state => {
      return {
        ...state,
        validationDiaologParams
      }
    })
  }

  /**
   * Refresh list after delete
   */
  updateList = async () => {
    const list = await this.getList()
    this.setState(state => {
      return {
        ...state,
        isLoading: false,
        fullList: list,
        filteredList: list
      }
    })
  }

  async componentDidMount () {
    await this.updateList()
  }

  makeToolBar = () => {
    return (
      <div className="device-tools">
        <div className="search-icon">
          <SearchIcon />
          <input
            className="device-search"
            onChange={this.handleSearch}
            placeholder={this.getTexts().searchTitle}
            tabIndex="-1"
          />
        </div>
        <div>
          <button className={'btn green mr-2'} onClick={this.edit}>
            +
          </button>
        </div>
      </div>
    )
  }

  makeHead = () => {
    return (
      <div className={'table-head'}>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Image" />
        </div>

        <div style={{ width: '20%', paddingLeft: '15px' }}>
          <MyTableHeaderSort
            label="Name"
            active={this.state.orderBy === 'name'}
            direction={this.state.order}
            onClick={this.createSortHandler('name')}
          />
        </div>

        <div style={{ width: '50%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Description" />
        </div>

        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Actions" />
        </div>

        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Delete" />
        </div>
      </div>
    )
  }

  makeRow = item => {
    return (
      <div className={'table-row'}>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          {item.imageFileName !== '' ? (
            <img style={{ width: 50, height: 50, objectFit: 'contain' }} src={urlImg(item.imageFileName)} alt="" />
          ) : (
            <img style={{ width: 50, height: 50, objectFit: 'contain' }} src={imageLib.noImage} alt="" />
          )}
        </div>

        <div style={{ width: '20%', paddingLeft: '15px' }}>{item.name}</div>
        <div style={{ width: '50%', paddingLeft: '15px' }}>{this.shortenText(item.description, 200)}</div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <button type="button" className="btn" onClick={e => this.edit(e, item)}>
            Edit
          </button>
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <button type="button" className="btn" onClick={e => this.handleItemDelete(e, item)}>
            <DeleteIcon />
          </button>
        </div>
      </div>
    )
  }

  render() {
    const { deleteDialogParams, validationDiaologParams } = this.state
    return (
      <HomePage router={this.props.router} addClassName="h-100">
        <div className="device-table-container">
          {!this.state.isLoading && (
            <MyTable
              tableName={this.getTexts().tableTitle}
              tableToolBar={this.makeToolBar}
              tableHead={this.makeHead}
              tableRow={this.makeRow}
              // isDraggable={true}
              // isCheckable={true}
              hasIndex={true}
              order={this.state.order}
              orderBy={this.state.orderBy}
              data={this.state.filteredList}
              hasPagination={true}
              rowsPerPage={10}
            />
          )}
        </div>
        {deleteDialogParams && <DeleteDialog {...deleteDialogParams} />}
        {validationDiaologParams && <ValidationDialog {...validationDiaologParams} />}
      </HomePage>
    )
  }
}

export default AuthorList
