import React, { Component } from 'react';
import ErrorMessage from '../../components/errormessage';

import { fetchFromUrlPOSTAsync } from '../../redux/fetch';

import config from '../../config'

class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            success: false,
            error: false,
        };
    }

    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    cancel = (e) => {
        e.preventDefault();
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const fetchUrl = '/user/forgot_password';
        const fetchParams = [
            { key: 'email', value: this.state.email },
        ];
        const result = await fetchFromUrlPOSTAsync(fetchUrl, fetchParams);
        if (result.success === true) {
            this.setState({ success : true });
        } else {
            this.setState({ error : true });
        }
    }

    render() {
        return (
            <div className="d-flex">
                <div className="login-page d-flex" >
                <div className='background-img p-0'>
                <div className='background-color'></div>
                </div>
                    <div className="login-wrapper">
                        <h3 className="login-welcome">{ config.title }</h3>
                        {! this.state.success ?
                            <form className="login-form" onSubmit={this.handleSubmit}>
                                <div className="d-flex flex-column align-items-start">
                                    <input className="login-input" placeholder="Email" id="email" autoFocus value={this.state.username} onChange={this.handleChange} />
                                </div>
                                {this.state.error &&
                                    <ErrorMessage text="There is no user registered with this email." />
                                }
                                <button className="login-button" type="submit" onClick={this.handleClick}>
                                    <p className="login-button-text">CONTINUE</p>
                                </button>
                            </form>
                            :
                            <div className="register-text d-flex row justify-content-center">
                                <p className="create-account">Email sent with success. Please check your inbox. </p>
                            </div>
                        }
                        <div className="register-text d-flex row justify-content-center">
                            <a href="" onClick={this.cancel}>Return to Login page</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Profile;
