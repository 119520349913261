import { store } from '../store';
import { fetchFromUrlPOSTAsync } from '../fetch/';

export const actions = {

  getPolicyById: async (policyId) => {
    let urlParams = [
      { key: 'policyId', value: policyId },
    ];

    const result = await fetchFromUrlPOSTAsync('/admin_policies/get_by_id', urlParams);

    return result.success ? result.data : null;
  },


  getPolicyList: async () => {
    const result = await fetchFromUrlPOSTAsync('/admin_policies/get_all', []);
    return result.success ? result.data : [];
  },

  savePolicy: async (saveData) => {
    let urlParams = [
      { key: 'saveData', value: saveData },
    ];
    const result = await fetchFromUrlPOSTAsync('/admin_policies/save', urlParams);
    return result.success;
  },

  
  getPagesList: () => {
    return [
      { label: "Dashboard",                 field: "dashboard",           specifics: "V"},
      { label: "Admin Page",                field: "adminPage",           specifics: "VCD" },
      { label: "Permissions Page",          field: "poilicyPage",         specifics: "VCD" },
      { label: "Users Page",                field: "userPage",            specifics: "VCD" },
      { label: "Diagnosis List",            field: "diagnosis",           specifics: "VCD" },
      { label: "Diagnosis Requests",        field: "diagnosisRequests",   specifics: "VCD" },
      { label: "Pathways Sessions",         field: "mainSessions",        specifics: "VCD" },
      { label: "Pain Sessions",             field: "painChangeSessions",  specifics: "VCD" },
      { label: "Daily Inspiration",         field: "daily",               specifics: "VCD" },
      { label: "Feel Good Tasks",           field: "feelGoodTasks",       specifics: "VCD" },
      { label: "Quizzes",                   field: "quizzes",             specifics: "VCD" },
      { label: "MeTime Authors",            field: "meTimeAuthors",       specifics: "VCD" },
      { label: "MeTime Sessions",           field: "meTimeSessions",      specifics: "VCD" },
      { label: "MeTime Topics",             field: "meTimeTopics",        specifics: "VCD" },
      { label: "MeTime Collections",        field: "meTimeCollections",   specifics: "VCD" },
      { label: "FAQ",                       field: "faq",                 specifics: "VC" },
    ];
  },

};