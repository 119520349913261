import React from 'react'
import HomePage from '../home'
import Dropzone from 'react-dropzone'
import Formsy from 'formsy-react'
import MyPage from '../../components/MyPage'
import MyTable from '../../components/MyTable'
import MyTableHeaderSort from '../../components/MyTableHeaderSort'
import MySelect from '../../components/MySelect'
import MyInput from '../../components/MyInput'
import ErrorMessage from '../../components/errormessage'
import imageLib from '../..//themes'
import Paper from '@material-ui/core/Paper'
import CloseIcon from '@material-ui/icons/Close'

import CollectionModal from './modal'
import SelectRecommendedDiagnosesModal from '../SelectRecommendedDiagnoses'

import { urlImg } from '../../redux/fetch/urlHelper'

import { actions as collectionActions } from '../../redux/modules/collections'
import { actions as authorActions } from '../../redux/modules/authors'
import { actions as topicActions } from '../../redux/modules/topics'

import config from '../../config'

class CollectionEdit extends MyPage {
  constructor (props) {
    super(props)
    const obj = this.getObject()

    this.showIsMasterClass = config.feature.mindAndBody

    this.state = {
      object: obj,
      authorId: obj.authorId,
      topics: obj.topics,
      diagnoses: obj.diagnoses,
      isMasterClass: this.showIsMasterClass ? obj.isMasterClass : undefined,
      title: obj.title,
      description: obj.description,
      imageFileName: obj.imageFileName,
      imageFileName64: obj.imageFileName64 ? obj.imageFileName64 : '',
      imageURL: obj.imageURL,
      sessions: obj.sessions,
      authorOptions: [],
      topicOptions: [],
      diagnosesOptions: [],
      canSubmit: false,
      showValidationErrors: false,
      isLoading: true,
    }
  }

  getObject = () => {
    if (this.props.location.state && this.props.location.state.obj) {
      return this.props.location.state.obj
    } else {
      return {
        authorId: null,
        topics: [],
        diagnoses: [],
        isMasterClass: this.showIsMasterClass ? false : undefined,
        title: '',
        description: '',
        imageFileName: '',
        imageURL: '',
        sessions: [],
      }
    }
  }

  getTexts = () => {
    return {
      pageTitle: 'Collection',
    }
  }

  handleSelect = (stateId, value) => {
    this.setState({ [stateId]: value })
  }

  handleReorder = async items => {
    items.forEach((element, index) => {
      element.index = index
    })
    this.setState({ fullList: items, order: 'asc', orderBy: 'index' })
  }

  cancel = e => {
    e.preventDefault()
    this.props.router.push('/collection-list')
  }

  save = async e => {
    e.preventDefault()

    if (!this.state.canSubmit ||
      !this.state.authorId ||
      this.state.selectedTopics.length === 0) {
        console.log('save')
      return
    }

    this.state.object.title = this.state.title
    this.state.object.description = this.state.description
    this.state.object.authorId = this.state.authorId
    this.state.object.topics = this.state.selectedTopics.map(option => option.value)
    if (config.feature.recommendations) {
      this.state.object.diagnoses = this.state.selectedDiagnoses.map(diagnosis => {
        return {
          diagnosisId: diagnosis._id,
          relevance: diagnosis.relevance
        }
      })
    }
    this.state.object.isMasterClass = this.state.isMasterClass
    this.state.object.imageFileName = this.state.imageFileName
    this.state.object.imageFileName64 = this.state.imageFileName64
    this.state.object.imageURL = this.state.imageURL

    // delete this.state.object.author;
    // // delete this.state.object.sessions; // NECESSARY
    // delete this.state.object.isFavorite;

    // console.log('COLLEC', this.state.object);

    const result = await collectionActions.saveCollection(this.state.object)

    if (result.success) {
      this.cancel(e)
    }
  }

  submit = () => {
    this.setState({ showValidationErrors: true })
  }

  onCloseSelectDiagnoses = () => {
    this.setState({ selectedDiagnoses: this.state.object.selectedDiagnoses })
  }

  endModal = () => {
    this.setState({ sessions: this.state.object.sessions })
  }

  makeToolBar = () => {
    return (
      <div className='device-tools' style={{ display: 'flex', 'justifyContent': 'flex-end' }}>
        <div>
          {this.state.authorId
            ? <CollectionModal object={this.state.object} authorId={this.state.authorId} onExit={this.endModal} />
            : <button className={ 'btn mr-2' } disabled>Edit</button>
          }
        </div>
      </div>
    )
  }

  makeHead = () => {
    return (
        <div className={ 'table-head' }>

            {/* <div style={{ width: '10%', paddingLeft: "15px" }}>
                <MyTableHeaderSort label="#" />
            </div> */}

            <div style={{ width: '10%', paddingLeft: '15px' }}>
                <MyTableHeaderSort label="Image" />
            </div>

            <div style={{ width: '30%', paddingLeft: '15px' }}>
                <MyTableHeaderSort
                    label="Title"
                    active={this.state.orderBy === 'title'}
                    direction={this.state.order}
                    onClick={this.createSortHandler('title')}
                />
            </div>

            {/* <div style={{ width: '20%', paddingLeft: "15px" }}>
                <MyTableHeaderSort
                    label="Type"
                    active={this.state.orderBy === "type"}
                    direction={this.state.order}
                    onClick={this.createSortHandler("type")}
                />
            </div> */}

            {/* <div style={{ width: '15%', paddingLeft: "15px" }}>
                <MyTableHeaderSort label="Audio File" />
            </div> */}

            <div style={{ width: '60%', paddingLeft: '15px' }}>
                <MyTableHeaderSort label="Description" />
            </div>

            {/* <div style={{ width: '10%', paddingLeft: "15px" }}>
                <MyTableHeaderSort
                    label="Free"
                    active={this.state.orderBy === "isFree"}
                    direction={this.state.order}
                    onClick={this.createSortHandler("isFree")}
                />
            </div> */}

            {/* <div style={{ width: '20%', paddingLeft: "15px" }}>
                <MyTableHeaderSort label="Actions" />
            </div> */}

        </div>
    )
}

makeRow = item => {
    return (
        <div className={ 'table-row' }>
            {/* <div style={{ width: "10%", paddingLeft: "15px" }}>{item.index}</div> */}

            <div style={{ width: '10%', paddingLeft: '15px' }}>
                { item.imageFileName !== ''
                  ? <img style={{ 'width': 50, 'height': 50, 'objectFit': 'contain' }}
                    src={urlImg(item.imageFileName)} alt="" />
                  : <img style={{ 'width': 50, 'height': 50, 'objectFit': 'contain' }} src={imageLib.noImage} alt="" />
                }
            </div>

            <div style={{ width: '30%', paddingLeft: '15px' }}>{item.title}</div>
            {/* <div style={{ width: "20%", paddingLeft: "15px" }}>{sessionActions.getTypeStr(item.type)}</div> */}
            {/* <div style={{ width: "15%", paddingLeft: "15px" }}>
              <a href={urlAudio(item.audioFileName)}>Open</a>
            </div> */}
            <div style={{ width: '60%', paddingLeft: '15px' }}>{item.description}</div>
            {/* <div style={{ width: "10%", paddingLeft: "15px" }}>
              {item.isFree === true ? <RemoveIcon /> : <ShopIcon />}
            </div> */}

            {/* <div style={{ width: "20%", paddingLeft: "15px" }}>
                <button type="button" className="btn" onClick={(e) => this.edit(e, item)}>Edit</button>
            </div> */}
        </div>
    )
}

  async componentDidMount () {
    const authList = await authorActions.getAuthorList()
    const authorOptions = [{ value: null, label: 'None' }].concat(authList.map(element => {
      return { value: element._id, label: element.name }
    }))

    const topicList = await topicActions.getTopicList()
    const topicOptions = topicList.map(element => {
      return { value: element._id, label: element.title }
    })
    const selectedTopics = this.state.object.topics.map(topicId => {
      return topicOptions.find(option => option.value === topicId)
    })


    const selectedDiagnoses = this.state.object.diagnoses || []
    this.state.object.selectedDiagnoses = selectedDiagnoses.map(diagnosis => {
      return {
        _id: diagnosis.diagnosisId,
        relevance: diagnosis.relevance
      }
    })

    this.setState({
      authorOptions,
      topicOptions,
      selectedTopics,
      selectedDiagnoses,
      isLoading: false
    })
  }

  render () {
    const style = { color: 'red' }
    let iimm = this.state.imageFileName === '' ? imageLib.noImage : urlImg(this.state.imageFileName)
    return (
      <HomePage router={this.props.router}>

        { !this.state.isLoading &&

          <div className="devices-page-wrapper">
            <h2>{this.getTexts().pageTitle}</h2>
            <Formsy className="page-form"
              onValidSubmit={ this.submit }
              onInvalidSubmit={e => {
                this.setState({ showValidationErrors: true })
              }}
              onValid={() => this.setState({ canSubmit: true })}
              onInvalid={() => this.setState({ canSubmit: false })}
            >
              <div className='create-device-row'>
                <label>Title <span style={style}>*</span></label>
                <MyInput
                  id="title"
                  name="title"
                  classNameDiv='create-branch-row'
                  className='create-device-input'
                  autocomplete="off"
                  value={this.state.title}
                  onChange={this.handleChange}
                  required
                  showError={this.state.showValidationErrors}
                />
              </div>
              <div className='create-device-row'>
                <label>Author <span style={style}>*</span></label>
                <MySelect
                  stateField="authorId"
                  className="create-branch-select"
                  value={this.state.authorId}
                  options={this.state.authorOptions}
                  onChange={this.handleSelect}
                  disabled={this.state.object._id !== undefined}
                />
                {this.state.showValidationErrors && !this.state.authorId &&
                  <ErrorMessage text="This field is required" />
                }
              </div>
              <div className='create-device-row'>
                <label>Topics <span style={style}>*</span></label>
                <MySelect
                  stateField="selectedTopics"
                  className="create-branch-select"
                  value={this.state.selectedTopics}
                  options={this.state.topicOptions}
                  onChange={this.handleSelect}
                  isMulti={true}
                  isSearchable={true}
                />
                {this.state.showValidationErrors && this.state.selectedTopics.length === 0 &&
                  <ErrorMessage text="This field is required" />
                }
              </div>

              { config.feature.recommendations
                ? <div className='create-device-row'>
                  <label>Recommended Diagnoses</label>

                  <SelectRecommendedDiagnosesModal
                    object={this.state.object}
                    authorId={this.state.authorId}
                    onExit={this.onCloseSelectDiagnoses} />

                </div>
                : null }


              { this.showIsMasterClass && (
                <div className="create-device-row">
                  <label>Is Masterclass</label>
                  <MySelect
                    stateField="isMasterClass"
                    className="create-branch-select"
                    value={this.state.isMasterClass}
                    options={[
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' }
                    ]}
                    onChange={this.handleSelect}
                  />
                </div>
              ) }

              <div className='create-device-row'>
                <label>Description</label>
                <textarea
                  id="description"
                  name="description"
                  className='create-device-input'
                  rows={5}
                  value={this.state.description}
                  onChange={this.handleChange}
                />
              </div>
              <div className='create-device-row'>
                <label>Image <span style={style}>*</span></label>
                <div className='div-row'>
                  <Dropzone
                    className="upload-border"
                    onDrop={files => { this.handleDropImage(files, 'imageFileName', 'imageFileNameError') }}
                    // onChange={(e) => this.onChangeImage(e, 0)}
                    accept="image/jpeg,image/jpg,image/tiff,image/gif,image/png"
                    multiple={false}
                    type={'file'}>
                      <span className="w-100" style={{ textAlign: 'center', marginBottom: '5px' }}>
                        Drag and drop file or
                      </span>
                      <button className="btn blue" type="button">BROWSE</button>
                  </Dropzone>
                  <div style={{ textAlign: 'right', position: 'relative' }}>
                    {this.state.imageFileName !== '' &&
                      <button className="btn delete-btn" type="button"
                        onClick={() => this.deleteUploadFile('imageFileName', 'imageFileNameError')}>
                          <CloseIcon />
                      </button>
                    }
                    <img style={{ width: 180,
                      height: 180,
                      objectFit: 'contain',
                      border: '1px solid #e6e9ec',
                      marginLeft: '20px' }} src={iimm} alt="" />
                  </div>
                </div>
                <ErrorMessage text={this.state.imageFileNameError} />
                <MyInput
                  id="imageFileName"
                  name="imageFileName"
                  classNameDiv='create-branch-row'
                  className='create-device-input'
                  value={this.state.imageFileName}
                  hidden
                  disabled
                  required
                  showError={this.state.showValidationErrors}
                />
              </div>
              <div className='create-device-row'>
                <label>Sessions</label>
                {this.makeToolBar()}
                {this.state.sessions.length > 0
                  ? <MyTable
                    tableHead={this.makeHead}
                    tableRow={this.makeRow}
                    isDraggable={true}
                    order={this.state.order}
                    orderBy={this.state.orderBy}
                    data={this.state.sessions}
                    hasPagination={false}
                    onDragEnd={items => { this.handleReorder(items) }}
                  />
                  : <Paper style={{
                    width: '100%',
                    padding: '2%',
                    fontSize: '14px',
                  }}>
                    This collection has no sessions.
                  </Paper>
                }
              </div>
              <button className='btn green mr-2' type="submit" onClick={ this.save }>Save</button>
              <button className='btn blue mr-2' onClick={ this.cancel }>Cancel</button>
            </Formsy>
          </div>
        }
      </HomePage>
    )
  }
}

export default CollectionEdit
