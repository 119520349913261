import axios from 'axios'
import { url } from '../redux/fetch/urlHelper'
import { store } from '../redux/store'

class UploadHelper {
  getAuthData () {
    return 'Web:' + store.getState().usersReducer.accessToken
  }

  instance () {
    const authData = this.getAuthData()
    return axios.create({
      baseURL: url(''),
      timeout: 10000,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        Authorization: authData
      }
    })
  }

  /**
   * Deletes file from aws s3 by url
   * @param {string} fileUrl
   */
  async deleteFileByUrl (fileUrl) {
    try {
      const authData = this.getAuthData()
      const { data: { success, error } } = await axios({
        method: 'post',
        url: url('/upload/delete'),
        data: {
          url: fileUrl
        },
        timeout: 10000,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: authData
        }
      })
      if (success) {
        return true
      } else if (error) {
        console.error(error)
        return false
      } else {
        console.error('Delete file response is invalid')
        return false
      }
    } catch (e) {
      console.error(e.message)
      return false
    }
  }
}

export default new UploadHelper()
