import { fetchFromUrlPOSTAsync, fetchFromUrlGETAsync, fetchFromUrlPATCHAsync, fetchFromUrlDELETEAsync } from '../fetch'

const routeBase = 'api/v2/sos-experiences/subtopics'

export const actions = {
  getList: async ({ page, perPage, order, orderBy, searchField, searchKeyword }) => {
    try {
      const urlParams = new URLSearchParams('')
      urlParams.append('page', page)
      urlParams.append('perPage', perPage)
      urlParams.append('order', order)
      urlParams.append('orderBy', orderBy)
      if (searchField && searchKeyword) {
        urlParams.append('searchField', searchField)
        urlParams.append('searchKeyword', searchKeyword)
      }

      const result = await fetchFromUrlGETAsync(`/${routeBase}/?${urlParams}`)

      if (!result.data && result.code && result.message) {
        return { errorMessage: result.message }
      }

      return result
    } catch (e) {
      console.error(e.message)
      return { errorMessage: 'Fetch error occurred.' }
    }
  },

  getById: async id => {
    try {
      const result = await fetchFromUrlGETAsync(`/${routeBase}/${id}`)

      if (!result._id && result.code && result.message) {
        return { errorMessage: result.message }
      }

      return result
    } catch (e) {
      console.error(e.message)
      return { errorMessage: 'Fetch error occurred.' }
    }
  },

  save: async saveData => {
    try {
      let result = {}
      const { _id } = saveData
      if (_id) {
        result = await fetchFromUrlPATCHAsync(`/${routeBase}/${_id}`, [], saveData)
      } else {
        result = await fetchFromUrlPOSTAsync(`/${routeBase}`, [], saveData)
      }

      if (!result._id && result.code && result.message) {
        return { errorMessage: result.message }
      }

      return result
    } catch (e) {
      console.error(e.message)
      return { errorMessage: 'Saving error occurred.' }
    }
  },

  updateSort: async saveData => {
    try {
      const indexData = saveData.map(({ _id, index }) => ({ _id, index }))
      const data = [{ key: 'SOSExperienceSubtopics', value: indexData }]
      const result = await fetchFromUrlPATCHAsync(`/${routeBase}`, data)

      if (result.code && result.message) {
        return { errorMessage: result.message }
      }

      return result
    } catch (e) {
      console.error(e.message)
      return { errorMessage: 'Sort error occurred.' }
    }
  },

  deleteById: async id => {
    try {
      const result = await fetchFromUrlDELETEAsync(`/${routeBase}/${id}`)

      if (result.code && result.message) {
        return { errorMessage: result.message }
      }

      return result
    } catch (e) {
      console.error(e.message)
      return { errorMessage: 'Delete error occurred.' }
    }
  }
}
