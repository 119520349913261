import React from 'react'
import HomePage from '../home'
import MyPage from '../../components/MyPage'
import MyTable from '../../components/MyTable'
import MyTableHeaderSort from '../../components/MyTableHeaderSort'
import { SearchIcon } from '../../Icons/svg'
import DeleteIcon from '@material-ui/icons/Delete'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button } from '@material-ui/core'

import { actions as pollsActions } from '../../redux/modules/polls'

class PollsList extends MyPage {
  constructor (props) {
    super(props)
    this.state = {
      fullList: [],
      isLoading: true,
      openDialog: false,
      deletePollId: null,
      deletePollTitle: null,
      searchKeyword: '',
      order: 'asc',
      orderBy: ''
    }
  }

  getList = async () => {
    const data = await pollsActions.getPollsList()
    return data
  }

  getTexts = () => {
    return {
      tableTitle: 'Registration Polls',
      searchTitle: 'Search Polls'
    }
  }

  edit = (e, obj) => {
    e.preventDefault()
    this.props.router.push({
      pathname: '/poll-edit',
      state: { obj: obj }
    })
  }

  handleReorder = async items => {
    items.forEach((element, index) => {
      element.index = index
    })
    this.setState({ fullList: items, order: 'asc', orderBy: 'index' })
    await pollsActions.savePollsList(this.state.fullList)
  }

  handleSearch = e => {
    e.preventDefault()
    let searchKeyword = e.target.value
    this.setState({ searchKeyword }, () => {
      const regexp = new RegExp(searchKeyword.replace(/[-+*.?|^$/()[\]{}\\]/g, '\\$&'), 'gi')
      let filteredList = this.state.fullList.filter(object => {
        return object.pollTitle.match(regexp)
      })
      this.setState({
        filteredList
      })
    })
  }

  handleDeletePoll = (e, item) => {
    this.setState({
      deletePollId: item._id,
      deletePollTitle: item.pollTitle
    })
    this.handleOpenDialog()
  }

  handleOpenDialog = () => {
    this.setState({
      openDialog: true,
    })
  }

  handleCloseDialog = value => {
    this.setState({
      isLoading: false,
      openDialog: false,
      deletePollId: null,
      deletePollTitle: null
    })
  }

  handleConfirmDialog = async () => {
    // Delete poll
    if (this.state.deletePollId) {
      this.setState({
        isLoading: true
      })

      const result = await pollsActions.deletePoll(this.state.deletePollId)

       if (result.success) {
        const list = await this.getList()
        this.setState({
          isLoading: false,
          fullList: list,
          filteredList: list
        })
      } else {
        console.error(result)
      }
    }

    this.handleCloseDialog()
  }

  async componentDidMount () {
    const list = await this.getList()
    this.setState({
      fullList: list,
      filteredList: list,
      isLoading: false
    })
  }

  makeToolBar = () => {
    return (
      <div className='device-tools'>
        <div className='search-icon'>
          <SearchIcon/>
          <input className='device-search' onChange={this.handleSearch} placeholder={this.getTexts().searchTitle}
            tabIndex="-1"/>
        </div>
        <div>
          <button className={'btn green mr-2'} onClick={this.edit}>+</button>
        </div>
      </div>
    )
  }

  makeHead = () => {
    return (
      <div className={'table-head'}>
        <div style={{ width: '80%', paddingLeft: '15px' }}>
          <MyTableHeaderSort
            label="Title"
            active={this.state.orderBy === 'title'}
            direction={this.state.order}
            onClick={this.createSortHandler('title')}
          />
        </div>

        <div style={{ width: '20%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Actions" />
        </div>
      </div>
    )
  }

  makeRow = item => {
    return (
      <div className={'table-row'}>
        <div style={{ width: '80%', paddingLeft: '15px' }}>{item.pollTitle}</div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <button
            type="button"
            className="btn"
            onClick={e => this.edit(e, item)}
          >
            Edit
          </button>
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <button
            type="button"
            className="btn"
            onClick={e => this.handleDeletePoll(e, item)}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
    )
  }

  render () {
    return (
      <HomePage router={this.props.router} addClassName="h-100">
        <div className="device-table-container">
          {!this.state.isLoading && (
            <MyTable
              tableName={this.getTexts().tableTitle}
              tableToolBar={this.makeToolBar}
              tableHead={this.makeHead}
              tableRow={this.makeRow}
              isDraggable={true}
              hasIndex={true}
              order={this.state.order}
              orderBy={this.state.orderBy}
              data={this.state.filteredList}
              onDragEnd={items => {
                this.handleReorder(items)
              }}
              hasPagination={true}
              rowsPerPage={10}
            />
          )}
        </div>
        <Dialog
          onClose={this.handleCloseDialog}
          open={this.state.openDialog}
          aria-labelledby="delete-dialog-title"
        >
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to delete poll "{this.state.deletePollTitle}"?
          </DialogTitle>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 24px 24px 24px'
          }}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleCloseDialog}>Cancel</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleConfirmDialog}>Ok</Button>
          </div>
        </Dialog>
      </HomePage>
    )
  }
}

export default PollsList
