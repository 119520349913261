import SessionEdit from '../../components/SessionEdit'

class SessionSoundEdit extends SessionEdit {
  constructor (props) {
    super(props)
    this.state.pageType = 'main'
  }

  getTexts () {
    return {
      pageTitle: 'Session'
    }
  }

  cancel = e => {
    e.preventDefault()
    this.props.router.push('/session-sound-list')
  }
}

export default SessionSoundEdit
