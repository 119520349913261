import React from 'react'
import uploadHelper from '../../utils/uploadHelper'
import { throttle, debounce } from 'lodash'

import { actions as adminActions } from '../../redux/modules/admin'
import { actions as policyActions } from '../../redux/modules/policies'

class PageWithPagination extends React.Component {
  constructor (props) {
    super(props)
    // updateHistory with debouncing
    this.debouncedUpdateHistory = debounce(this.updateHistory, 500)
  }
  /**
   * Sets current query params or default for fetching needs
   */
  setQueryParamsToState (params, cb = null) {
    const newState = {}
    const {
      page = 1,
      perPage = 100,
      orderBy = 'createdAt',
      order = 'DESC',
      searchField = false,
      searchKeyword = false
    } = params

    const pageInt = parseInt(page)

    if (pageInt && Number.isInteger(pageInt)) {
      newState.page = pageInt
    }

    const perPageInt = parseInt(perPage)
    if (perPageInt && Number.isInteger(perPageInt)) {
      newState.perPage = perPageInt
    }

    if (order && (order.toUpperCase() === 'DESC' || order.toUpperCase() === 'ASC')) {
      newState.order = order
    }

    if (orderBy) {
      newState.orderBy = orderBy
    }

    if (searchField && searchField) {
      newState.searchField = searchField
      newState.searchKeyword = searchKeyword
    }

    this.setState({ ...newState }, cb)
  }

  /**
   * Updates data when browser history changes
   */
  addHistoryListener = cb => {
    const historyListener = location => this.setQueryParamsToState(location.query, cb)
    const throttledHistoryListener = throttle(historyListener, 100)
    this.props.router.listen(throttledHistoryListener)
  }

  /**
   * Sets history
   */
  updateHistory = () => {
    const { page, perPage, order, orderBy, searchField, searchKeyword } = this.state
    const search = this.props.location.search || ''
    const searchParams = new URLSearchParams(search)
    searchParams.set('page', page)
    searchParams.set('perPage', perPage)
    searchParams.set('order', order)
    searchParams.set('orderBy', orderBy)
    if (searchField && searchKeyword) {
      searchParams.set('searchField', searchField)
      searchParams.set('searchKeyword', searchKeyword)
    } else {
      searchParams.delete('searchField')
      searchParams.delete('searchKeyword')
    }
    this.props.router.push(this.props.location.pathname + '?' + searchParams.toString())
  }

  /**
   * Changes history when page changed,
   * then data is fetched
   */
  handleChangePage = async page => {
    this.setState({ page }, async () => {
      this.updateHistory()
    })
  }

  /**
   * Changes history when page changed,
   * and data is fetched
   */
  handleChangeRowsPerPage = async perPage => {
    this.setState({ perPage, page: 1 }, async () => {
      this.updateHistory()
    })
  }

  /**
   * Changes history when sorting
   * and data is fetched
   */
  handleChangeSort = (orderBy, order) => {
    this.setState({
      orderBy,
      order: order === 'DESC' ? 'ASC' : 'DESC',
      page: 1
    }, async () => {
      this.updateHistory()
    })
  }

  /**
  * Changes history when searching
  * and data is fetched
  */
  handleСhangeSearch = e => {
    e.preventDefault()
    const { searchField } = this.state
    let searchKeyword = e.target.value
    searchKeyword = searchKeyword.replace(/[-+*.?|^$/()[\]{}\\]/g, '\\$&')

    this.setState({
      searchField,
      searchKeyword,
      page: 1
    }, async () => {
      this.debouncedUpdateHistory()
    })
  }

  getPath = () => {
    return this.props.location.pathname
  }

  loadPolicy = async () => {
    const policyId = adminActions.getAdminPolicyId()
    const policyData = await policyActions.getPolicyById(policyId)
    let pagePolicies = policyData[this.getPageTag(this.getPath())]
    if (pagePolicies) {
      return pagePolicies
    } else {
      return { canView: false, canChange: false, canDelete: false }
    }
  }

  shortenText = (str, length = 100) => {
    return str.length > length ? str.substring(0, 100) + '…' : str
  }

  handleChange = e => {
    e.preventDefault()
    this.setState({ [e.target.id]: e.target.value })
  }

  handleSelect = (stateId, value) => {
    this.setState({ [stateId]: value })
  }

  createSortHandler = property => event => {
    this.handleSort(event, property)
  }

  handleSort = (e, property) => {
    const orderBy = property
    let order = 'desc'
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }
    this.setState({ order, orderBy })
  }

  handleDropImage = (droppedFiles, stateId, errorStateId = null) => {
    droppedFiles.forEach(file => {
      this.handleFileImage(file, stateId, errorStateId)
    })
  }

  handleFileImage = (file, stateId, errorStateId = null) => {
    if (file !== null) {
      this.setState(
        { file: file },
        () => this.imageUploadHandler(stateId, errorStateId))
    }
  }

  imageUploadHandler = (stateId, errorStateId = null) => {
    const formData = new FormData()
    formData.append('myImage', this.state.file)
    uploadHelper
      .instance()
      .post('/upload/image', formData, { timeout: 1 * 60 * 1000 })
      .then(response => {
        if (response.data.success) {
          this.setState({ [stateId]: response.data.name })
          this.setState({ [`${stateId}64`]: response.data.name64 })
          if (response.data.url) {
            const stateFieldShortName = stateId.replace('FileName', '') + 'URL'
            this.setState({ [stateFieldShortName]: response.data.url })
          }
          if (errorStateId) {
            this.setState({ [errorStateId]: '' })
          }
        } else {
          if (errorStateId) {
            this.setState({ [errorStateId]: response.data.error })
          }
        }
      })
      .catch(error => { console.error(error) })
  }

  handleDropAudio = (droppedFiles, stateId, errorStateId = null) => {
    droppedFiles.forEach(file => {
      this.handleFileAudio(file, stateId, errorStateId)
    })
  }

  handleFileAudio = (file, stateId, errorStateId = null) => {
    if (file !== null) {
      this.setState({ file: file }, () => {
        this.audioUploadHandler(stateId, errorStateId)
      })
    }
  }

  audioUploadHandler = (stateId, errorStateId = null) => {
    const formData = new FormData()
    formData.append('myAudio', this.state.file)
    uploadHelper
      .instance()
      .post('/upload/audio', formData, { timeout: 2 * 60 * 1000 })
      .then(response => {
        if (response.data.success) {
          this.setState({ [stateId]: response.data.name })
          if (response.data.url) {
            const stateFieldShortName = stateId.replace('FileName', '') + 'URL'
            this.setState({ [stateFieldShortName]: response.data.url })
          }
          if (response.data.audioFileDuration) {
            this.setState({ audioFileDuration: response.data.audioFileDuration })
          }
          if (errorStateId) {
            this.setState({ [errorStateId]: '' })
          }
        } else {
          if (errorStateId) {
            this.setState({ [errorStateId]: response.data.error })
          }
        }
      })
      .catch(error => { console.error(error) })
  }

  handleDropVideo = (droppedFiles, stateId, errorStateId = null) => {
    droppedFiles.forEach(file => {
      this.handleFileVideo(file, stateId, errorStateId)
    })
  }

  handleFileVideo = (file, stateId, errorStateId = null) => {
    if (file !== null) {
      this.setState({ file: file }, () => {
        this.videoUploadHandler(stateId, errorStateId)
      })
    }
  }

  videoUploadHandler = (stateId, errorStateId = null) => {
    this.setState({ videoIsUploading: true })

    const formData = new FormData()
    formData.append('myVideo', this.state.file)
    uploadHelper
      .instance()
      .post('/upload/video', formData, { timeout: 5 * 60 * 1000 })
      .then(response => {
        if (response.data.success) {
          if (response.data.url) {
            this.setState({ [stateId]: response.data.url })
            // Legacy code fix: FileName fields with s3 Key are needed for upload confirmation
            const keyProp = stateId.replace('URL', '') + 'FileName'
            this.setState({ [keyProp]: response.data.name })
          }

          if (response.data.videoFileDuration) {
            this.setState({ videoFileDuration: response.data.videoFileDuration })
          }

          if (errorStateId) {
            this.setState({ [errorStateId]: '' })
          }
        } else {
          if (errorStateId) {
            this.setState({ [errorStateId]: response.data.error })
          }
        }
        this.setState({ videoIsUploading: false })
      })
      .catch(error => {
        console.error(error)
        this.setState({ videoIsUploading: false })
      })
  }

  /**
   * Handles remove file button
   */
  deleteUploadFile = async (stateId, errorStateId = null) => {
    if (window.confirm('Are you sure you want to delete this file?')) {
      // Check file url field in the state (legacy code workaround)
      const stateFieldShortName = stateId.replace('FileName', '') + 'URL'
      if (this.state[stateFieldShortName]) {
        // Take aws s3 url from the state
        const url = this.state[stateFieldShortName]
        // Make the delete
        const deleteResult = await uploadHelper.deleteFileByUrl(url)
        if (deleteResult) {
          this.setState({ [stateFieldShortName]: '' })
        }
      }
      // Legacy code
      this.setState({ [stateId]: '' })
      if (errorStateId) {
        this.setState({ [errorStateId]: '' })
      }
    }
  }
}

export default PageWithPagination
