import React from 'react';
import HomePage from '../home';
import Formsy from 'formsy-react';
import MyPage from '../../components/MyPage';
import MyInput from '../../components/MyInput';
import MyTable from '../../components/MyTable';
import MyTableHeaderSort from '../../components/MyTableHeaderSort';
import QuizModal from './modal';
import ErrorMessage from '../../components/errormessage';
import DeleteIcon from '@material-ui/icons/Delete'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button } from '@material-ui/core'

import { actions as quizActions } from '../../redux/modules/quiz';

class QuizEdit extends MyPage {

  constructor(props) {
    super(props);
    const obj = this.getObject();

    this.state = {
      object: obj,
      title: obj.title,
      questionsList: [],
      deleteQuestionId: null,
      deleteDialogIsOpened: false,
      errorMessage: "",
      canSubmit: false,
      showValidationErrors: false,
      isLoading: true,
    };
  }

  getObject = () => {
    if (this.props.location.state  && this.props.location.state.obj) {
      return this.props.location.state.obj;
    } else {
      return {
        title: "",
      };
    }
  }

  getTexts = () => {
    return {
      pageTitle: "Quiz",
    }
  }

  cancel = e => {
    e.preventDefault();
    this.props.router.push('/quiz-list');
  }

  showDeleteDialog = () => {
    this.setState({
      deleteDialogIsOpened: true,
    })
  }

  hideDeleteDialog = value => {
    this.setState({
      deleteDialogIsOpened: false
    })
  }

  handleDeleteQuestion = (e, item) => {
    this.setState({
      deleteQuestionId: item._id
    })
    this.showDeleteDialog()
  }

  deleteQuestion = async () => {
    const result = await quizActions
      .deleteQuestion(this.state.object._id, this.state.deleteQuestionId)
    if (result.success) {
      const list = await quizActions.getQuestionsList(this.state.object._id);
      this.setState({
        deleteQuestionId: null,
        deleteDialogIsOpened: false,
        questionsList: list
      })
    } else {
      console.error(result)
    }
  }

  saveQuestionToList = async (obj) => {
    const currentQuestionsList = this.state.questionsList;
    if (! obj._id) {
      currentQuestionsList.push(obj);
    }
    this.setState({ questionsList: currentQuestionsList });
    this.state.object.title = this.state.title;

    // Auto-save questions
    this.state.object.questionsList = this.state.questionsList;
    const result = await quizActions.saveQuiz(this.state.object);
    const list = await quizActions.getQuestionsList(this.state.object._id);
    this.setState({ questionsList: list });
    this.state.object.questionsList = this.state.questionsList;

    if (result.success) {
      alert("Questions saved to the database.");
    }
  }


  save = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to save this quiz?")) {
      this.state.object.title = this.state.title;
      this.state.object.questionsList = this.state.questionsList;

      const result = await quizActions.saveQuiz(this.state.object);
      if (result) {
        this.cancel(e);
      }
    }
  }

  async componentDidMount() {
    const questionsList = await quizActions.getQuestionsList(this.state.object._id);
    this.setState({ questionsList, isLoading: false });
  }

  makeToolBar = () => {
    return (
      <div className='device-tools' style={{display: "flex", "justifyContent": "flex-end"}}>
        <div>
          {/* <button className={"btn green mr-2"} onClick={this.editQuestion}>+</button> */}
          <QuizModal onExit={this.saveQuestionToList} />
        </div>
      </div>
    );
  }

  makeHead = () => {
    return (
      <div className={"table-head"}>

        <div style={{ width: '70%', paddingLeft: "15px" }}>
          <MyTableHeaderSort label="Title" />
        </div>

        <div style={{ width: '10%', paddingLeft: "15px" }}>
          <MyTableHeaderSort label="Answer" />
        </div>

        <div style={{ width: '10%', paddingLeft: "15px" }}>
          <MyTableHeaderSort label="Actions" />
        </div>

        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Delete" />
        </div>

      </div>
    );
  }

  makeRow = (item) => {
    return (
      <div className={"table-row"}>
        <div style={{ width: "70%", paddingLeft: "15px" }}>{item.title}</div>
        <div style={{ width: "10%", paddingLeft: "15px" }}>{item.correctAnswer === 1 ? "True" : "False" }</div>

        <div style={{ width: "10%", paddingLeft: "15px", display: "flex" }}>
          <QuizModal object={item} onExit={this.saveQuestionToList} />
          {/* <button type="button" className="btn red" onClick={(e) => this.removeQuestion(e, item)}>Remove</button> */}
        </div>

        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <button
            type="button"
            className="btn"
            onClick={e => this.handleDeleteQuestion(e, item)}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
    );
  }

  render() {
    const style = {color:'red'};
    return (
      <HomePage router={this.props.router}>
        {! this.state.isLoading &&
          <div className="devices-page-wrapper">
            <h3>{this.getTexts().pageTitle}</h3>
            <Formsy className='create-device-form'
              onValidSubmit={this.submit}
              onInvalidSubmit={() => this.setState({ showValidationErrors: true })}
              onValid={() => this.setState({ canSubmit: true })}
              onInvalid={() => this.setState({ canSubmit: false })}
            >
              <div className='create-device-row'>
                <label>Title <span style={style}>*</span></label>
                <MyInput
                  id="title"
                  name="title"
                  classNameDiv='create-branch-row'
                  className='create-device-input'
                  autocomplete="off"
                  value={this.state.title}
                  onChange={this.handleChange}
                  required
                  showError={this.state.showValidationErrors}
                />
              </div>
              <div className='create-device-row'>
                <label>Questions</label>
                <MyTable
                  tableToolBar={this.makeToolBar}
                  tableHead={this.makeHead}
                  tableRow={this.makeRow}
                  hasIndex={true}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  data={this.state.questionsList}
                />
              </div>
              <ErrorMessage text={this.state.errorMessage}/>
              <button className='btn green mr-2' type="submit" onClick={this.state.canSubmit ? this.save : undefined}>Save</button>
              <button className='btn blue mr-2' type="button" onClick={this.cancel}>Cancel</button>
              {/* <button className='btn red mr-2' type="button" onClick={this.delete}>Delete</button> */}
            </Formsy>
          </div>
        }
        <Dialog
          onClose={this.hideDeleteDialog}
          open={this.state.deleteDialogIsOpened}
          aria-labelledby="delete-dialog-title"
        >
          <DialogTitle id="simple-dialog-title">
            Confirm question delete
          </DialogTitle>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 24px 24px 24px'
          }}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.hideDeleteDialog}>Cancel</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.deleteQuestion}>Ok</Button>
          </div>
        </Dialog>
      </HomePage>
    );
  }
}

export default QuizEdit;
