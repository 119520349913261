import { fetchFromUrlGETAsync } from '../fetch/'

export const GENERAL = 0
export const RECAP = 1
export const QUIZ = 2
export const DIAGNOSIS = 3
export const PAIN_CHANGE = 4
export const ME_TIME = 5
export const ABOUT = 6
export const GENERAL_WITH_VIDEO = 7
export const ARTICLE = 8
export const SUCCESS_STORIES = 9

export const actions = {
  getSessionTypeList: async () => {
    try {
      const result = await fetchFromUrlGETAsync('/session/types')
      return result.success ? result.data : []
    } catch (e) {
      console.log(e.message)
      return []
    }
  },

  getSessionTypeName: (typeId, typesArr) => {
    if (!typesArr || !typesArr.length) {
      return ''
    }

    const type = typesArr.find(({ id }) => id === parseInt(typeId, 10))

    if (type && type.name) {
      return type.name
    }

    return ''
  }
}
