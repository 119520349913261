import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'

const ValidationDialog = ({ open, title, info, handleClose }) => {
  return (
    <Dialog onClose={handleClose} open={open} aria-labelledby="delete-dialog-title">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <DialogTitle id="simple-dialog-title">{title || ''}</DialogTitle>
        <button className="btn close-btn" type="button" onClick={handleClose}>
          <CloseIcon />
        </button>
      </div>
      <DialogContent dangerouslySetInnerHTML={{ __html: info }}></DialogContent>
    </Dialog>
  )
}

ValidationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default ValidationDialog
