import SessionList from '../../components/SessionList'
import { actions as sessionActions } from '../../redux/modules/sessions'

class PainSessionList extends SessionList {
  constructor (props) {
    super(props)
    this.state.pageType = 'pain'
  }

  getTexts = () => {
    return {
      tableTitle: 'Pain Change Sessions',
      searchTitle: 'Search Session'
    }
  }

  getList = async () => {
    const list = await sessionActions.getPainSessionsList()
    return list
  }

  getEditRoute = () => {
    return '/session-pain-edit'
  }
}

export default PainSessionList
