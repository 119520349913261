import React, { Component } from 'react';
import ErrorMessage from '../../components/errormessage';
import Formsy from 'formsy-react';
import MyInput from '../../components/MyInput';

import { fetchFromUrlPOSTAsync } from '../../redux/fetch';
import md5 from 'md5';

import config from '../../config'

class PasswordChange extends Component {

    constructor(props) {
        super(props);
        this.state = {
            code: this.props.location.query.code,
            password: "",
            passwordConfirm: "",
            inputError: "",
            canSubmit: false,
        };
    }

    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        if (this.state.password === this.state.passwordConfirm) {
            if (this.state.code === undefined) {
                this.props.router.push("/error");
            } else {
                let urlParams = [
                    { key: 'code', value: this.state.code },
                    { key: 'password', value: md5(this.state.password) },
                ];
                const result = await fetchFromUrlPOSTAsync('/user/forgot-password-change', urlParams);
                if (result.success) {
                    this.setState({ message: "Your password has been changed successfully." });
                } else {
                    this.props.router.push("/error");
                }
            }
        } else {
            this.setState({ inputError: "The same password must be typed twice." });
        }
    }

    render() {
        const style = {color:'red'}
        return (
            <div className="d-flex">
                <div className="login-page d-flex" >
                <div className='background-img p-0'>
                <div className='background-color'></div>
                </div>
                    <div className="login-wrapper">
                        <h3 className="login-welcome">{ config.title }</h3>
                        {! this.state.message ?
                            <Formsy className='login-form' onValidSubmit={this.handleSubmit} onValid={() => this.setState({canSubmit:true})} onInvalid={() => this.setState({canSubmit:false})}>
                                <div className="mt-3 d-flex flex-column align-items-start">
                                    <label>Password <span style={style}>*</span></label>
                                    <MyInput
                                        id="password"
                                        name="password"
                                        classNameDiv='mt-3 d-flex flex-column align-items-start'
                                        className='login-input'
                                        autocomplete="off"
                                        type="password"
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </div>
                                <div className="mt-3 d-flex flex-column align-items-start">
                                    <label>Confirm password <span style={style}>*</span></label>
                                    <MyInput
                                        id="passwordConfirm"
                                        name="passwordConfirm"
                                        classNameDiv='mt-3 d-flex flex-column align-items-start'
                                        className='login-input'
                                        autocomplete="off"
                                        type="password"
                                        value={this.state.passwordConfirm}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </div>
                                <ErrorMessage text={this.state.inputError} />
                                <button className="login-button" type="submit" onClick={this.handleSubmit} disabled={!this.state.canSubmit}>
                                    <p className="login-button-text">CHANGE</p>
                                </button>
                            </Formsy>
                            :
                            <p>{this.state.message}</p>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default PasswordChange;
