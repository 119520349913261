import React from 'react'
import PropTypes from 'prop-types'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'

const FactorTop = ({ factors, title, subtitle }) => {
  const top100FactorData = () => {
    return factors.map(element => {
      return { name: element.factor, Usage: element.count }
    })
  }

  const factorChunks = []
  let factorChunk = []
  for (const factor of factors) {
    if (factorChunk.length < 25) {
      factorChunk.push(factor)
    } else {
      factorChunks.push(factorChunk)
      factorChunk = []
    }
  }

  if (factorChunk.length) {
    factorChunks.push(factorChunk)
  }

  return (
    <div>
      <div className="d-flex">
        <h5 className="h-statistics">{title || ''}</h5>
      </div>
      <div className="d-flex">
        <h5 className="sub-h-statistics">{subtitle || ''}</h5>
      </div>
      <div className="mb16">
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              {factorChunks.map((factorChunk, index) => {
                return (<td key={index} style={{ verticalAlign: 'baseline' }}>
                  {factorChunk.map((element, index) => {
                    return (
                      <tr key={`top100_${index}`}>
                        <td>{element.factor}</td>
                        <td>{element.count}</td>
                      </tr>
                    )
                  })}
                </td>)
              })}
            </tr>
          </tbody>
        </table>
      </div>
      <BarChart
        width={1000}
        height={300}
        data={top100FactorData()}
        margin={{ top: 20, right: 10, left: 10, bottom: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="Usage" stackId="a" fill="#5588ee" />
      </BarChart>
    </div>
  )
}

FactorTop.propTypes = {
  top100Factor: PropTypes.array.isRequired
}

export default FactorTop
