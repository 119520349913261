import React from 'react';
import HomePage from '../home';
import MyPage from '../../components/MyPage';
import MyTable from '../../components/MyTable';
import MyTableHeaderSort from '../../components/MyTableHeaderSort';
import { SearchIcon } from '../../Icons/svg';

import formatDate from '../../utils/formatDate';
import { actions as requestActions } from '../../redux/modules/diagnosisRequests';

class DiagnosisRequestList extends MyPage {

  constructor(props) {
    super(props);
    this.state = {
      fullList: [],
      filteredList: [],
      isLoading: true,
      searchKeyword: '',
      order: 'asc',
      orderBy: '',
    };
  }

  getList = async () => {
    const data = await requestActions.getRequestsList();
    return data;
  }

  getTexts = () => {
    return {
      tableTitle: "Diagnosis Requests",
      searchTitle: "Search Request",
    }
  }

  approve = async (e, obj) => {
    e.preventDefault();
    this.props.router.push({
      pathname: '/diagnosis-edit',
      state: { obj: { title: obj._id, sessions: [] }, isRequest: true }
    });
  }

  handleSearch = (e) => {
    e.preventDefault();
    let searchKeyword = e.target.value;
    this.setState({searchKeyword}, () => {
      const regexp = new RegExp(searchKeyword.replace(/[-+*.?|^$/()[\]{}\\]/g, "\\$&"), "gi");
      let filteredList = this.state.fullList.filter(object => {
        return object._id.match(regexp)
      });
      this.setState({
        filteredList
      });
    });
  }

  async componentDidMount() {
    const list = await this.getList();
    this.setState({
      fullList: list,
      filteredList: list,
      isLoading: false,
    });
  }

  makeToolBar = () => {
    return (
      <div className='device-tools'>
        <div className='search-icon'>
          <SearchIcon/>
          <input className='device-search' onChange={this.handleSearch} placeholder={this.getTexts().searchTitle} tabIndex="-1"/>
        </div>
        {/* <div>
          <button className={"btn mr-2"}>Test</button>
          <button className={"btn mr-2"}>Test2</button>
        </div> */}
      </div>
    );
  }

  makeHead = () => {
    return (
      <div className={"table-head"}>

        <div style={{ width: '35%', paddingLeft: "15px" }}>
          <MyTableHeaderSort
            label="Title"
            active={this.state.orderBy === "_id"}
            direction={this.state.order}
            onClick={this.createSortHandler("_id")}
          />
        </div>

        <div style={{ width: '25%', paddingLeft: "15px" }}>
          <MyTableHeaderSort
            label="Entries"
            active={this.state.orderBy === "entries"}
            direction={this.state.order}
            onClick={this.createSortHandler("entries")}
          />
        </div>

        <div style={{ width: '25%', paddingLeft: "15px" }}>
          <MyTableHeaderSort
            label="Creation Date"
            active={this.state.orderBy === "createdDate"}
            direction={this.state.order}
            onClick={this.createSortHandler("createdDate")}
          />
        </div>

        <div style={{ width: '25%', paddingLeft: "15px" }}>
          <MyTableHeaderSort label="Actions" />
        </div>

      </div>
    );
  }

  makeRow = (item) => {
    return (
      <div className={"table-row"}>
        <div style={{ width: "35%", paddingLeft: "15px" }}>{item._id}</div>
        <div style={{ width: "25%", paddingLeft: "15px" }}>{item.entries}</div>
        <div style={{ width: "25%", paddingLeft: "15px" }}>{formatDate(item.createdDate)}</div>
        <div style={{ width: "25%", paddingLeft: "15px" }}>
          <button type="button" className="btn" onClick={(e) => this.approve(e, item)}>Approve</button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <HomePage router={this.props.router} addClassName="h-100">
        <div className='device-table-container'>
          {! this.state.isLoading &&
            <MyTable
              tableName={this.getTexts().tableTitle}
              tableToolBar={this.makeToolBar}
              tableHead={this.makeHead}
              tableRow={this.makeRow}
              // isDraggable={true}
              // isCheckable={true}
              hasIndex={true}
              order={this.state.order}
              orderBy={this.state.orderBy}
              data={this.state.filteredList}
              // onDragEnd={() => {console.log("END DRAG")}}
              hasPagination={true}
              rowsPerPage={10}
            />
          }
        </div>
      </HomePage>
    );
  }
}

export default DiagnosisRequestList;
