import React, { Component } from 'react';
import HomePage from '../home';
import Formsy from 'formsy-react';
import ErrorMessage from '../../components/errormessage';
import { actions as phraseActions} from '../../redux/modules/phrases';
import config from '../../config'

class DailyInspirationEdit extends Component {

  constructor(props) {
    super(props);
    const obj = this.getObject();
    this.state = {
      object: obj,
      text: obj.text,
      canSubmit: false,
      errorMessage: "",
      isLoading: true,
    };
  }

  getObject = () => {
    if (this.props.location.state && this.props.location.state.obj) {
      return this.props.location.state.obj;
    } else {
      return {
        text: "",
        index: 9999,
      };
    }
  }

  handleChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  pageTitle() {
    return "Daily Inspiration Phrase";
  }

  reorder = items => {
    if (!config.feature.diagnosis) {
      throw new Error('This feature is not allowed to use without enabled diagnosis feature')
    }

    this.setState(prevState => ({
      sessions: { ...prevState.diagnosis, sessions: items }
    }));

    // this.state.diagnosis.sessions = items;
    this.setState({ sessions: this.state.diagnosis.sessions });
  }

  cancel = (e) => {
    e.preventDefault();
    this.props.router.push('/daily-inspiration');
  }

  save = async (e) => {
    e.preventDefault();
    if (this.state.text === "") {
      this.setState({ errorMessage: "This field can not be empty" });
    } else {
      this.setState({ errorMessage: "" });
      if (window.confirm("Are you sure you want to save this phrase?")) {
        this.state.object.text = this.state.text;
        const result = await phraseActions.savePhrase(this.state.object);
        if (result.success) {
          this.setState({ errorMessage: "" });
          this.cancel(e);
        } else {
          this.setState({ errorMessage: result.message });
        }
      }
    }
  }

  delete = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this phrase?")) {
      phraseActions.deletePhrase(this.state.object._id)
      .then((result) => {
        if (result.success) {
          this.cancel(e);
        }
      })
    }
  }

  async componentDidMount() {
    this.setState({
      text: this.state.object.text,
      isLoading: false,
    });
  }

  render() {
    const style = {color:'red'}
    return (
      <HomePage router={this.props.router} addClassName="h-100">
        <div className="devices-page-wrapper w-100">
          <h3 className="pl-3">{this.pageTitle()}</h3>
          <Formsy className='create-device-form transparent'
            onValidSubmit={this.submit}
            onValid={() => this.setState({canSubmit:true})}
            onInvalid={() => this.setState({canSubmit:false})}
          >
            <div className='create-device-row'>
              <label>Text <span style={style}>*</span></label>
              <textarea
                id="text"
                name="text"
                className='create-device-input'
                rows={5}
                value={this.state.text}
                onChange={this.handleChange}
              />
            </div>
            <ErrorMessage text={this.state.errorMessage}/>
            <div className="w-100 d-flex justify-content-end">
              <button className='btn green mr-2' type="submit" onClick={this.save} >Save</button>
              {this.state.object._id &&
                <button className='btn red mr-2' type="button" onClick={this.delete}>Delete</button>
              }
              <button className='btn blue' type="button" onClick={this.cancel}>Cancel</button>
            </div>
          </Formsy>
        </div>
      </HomePage>
    );
  }
}

export default DailyInspirationEdit;
