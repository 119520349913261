import React from 'react'
import Dropzone from 'react-dropzone'
import Formsy from 'formsy-react'

import HomePage from '../../pages/home'
import MyPage from '../MyPage'
import MyInput from '../MyInput'
import MySelect from '../MySelect'
import ErrorMessage from '../errormessage'
import imageLib from '../../themes'
import { urlImg, urlAudio, urlVideo } from '../../redux/fetch/urlHelper'
import CloseIcon from '@material-ui/icons/Close'
import FakeAudioPlayer from '../FakeAudioPlayer'
import ReactPlayer from 'react-player'
import TimeEventModal from './modal'
import SourceModal from './sourcemodal'
import { CircularProgress } from '@material-ui/core'

import { actions as sessionActions } from '../../redux/modules/sessions'
import { actions as sessionTypeActions } from '../../redux/modules/sessionTypes'
import { GENERAL, RECAP, DIAGNOSIS, GENERAL_WITH_VIDEO, ARTICLE, SUCCESS_STORIES } from '../../redux/modules/sessionTypes'
import { actions as diagnosisActions } from '../../redux/modules/diagnosis'
import { actions as feelGoodTaskActions } from '../../redux/modules/feelGoodTasks'

import { actions as authorActions } from '../../redux/modules/authors'
import { actions as quizActions } from '../../redux/modules/quiz'

import { actions as collectionActions } from '../../redux/modules/collections'
import { actions as topicActions } from '../../redux/modules/topics'
import { actions as challengeActions } from '../../redux/modules/challenges'
import { actions as successStoriesActions } from '../../redux/modules/successStories'

import config from '../../config'

class DiagnosisSelect extends MySelect {
  changeValue = option => {
    this.setState({ selectedOption: option })
    if (Array.isArray(option)) {
      this.props.onChange(this.props.stateField, option.map(item => item.label))
    } else {
      this.props.onChange(this.props.stateField, option.value)
    }
  }
}

class SessionEdit extends MyPage {
  constructor (props) {
    super(props)
    const obj = this.getObject()

    this.state = {
      object: obj,
      pageType: '',
      session: obj,
      title: obj.title,
      type: obj.type,
      section: obj.section,
      topics: obj.topics || [],
      subtopics: obj.subtopics || [],
      difficulty: this.isBodySession() ? obj.difficulty : undefined,
      changePainStatusType: obj.changePainStatusType,
      quizId: obj.quizId,
      description: obj.description,
      thoughts: obj.thoughts,
      readTime: obj.readTime || 3,
      article: obj.article || '',
      isFree: obj.isFree,
      isLockedForUnregisteredUsers: obj.isLockedForUnregisteredUsers,
      // source_text:obj.source_text,
      // source_link:obj.source_link,
      unlockNext: obj.unlockNext ? obj.unlockNext : 1,
      imageFileName: obj.imageFileName,
      imageFileName64: obj.imageFileName64 ? obj.imageFileName64 : '',
      imageURL: obj.imageURL,
      backgroundFileName: obj.backgroundFileName,
      backgroundFileName64: obj.backgroundFileName64
        ? obj.backgroundFileName64
        : '',
      backgroundURL: obj.backgroundURL,
      audioFileName: obj.audioFileName,
      audioFileDuration: obj.audioFileDuration,
      videoFileDuration: obj.videoFileDuration,
      audioURL: obj.audioURL,
      videoFileName: obj.videoFileName,
      videoURL: obj.videoURL,
      diagnosisTags: config.feature.diagnosis ? obj.diagnosisTags.map(element => {
        return { value: element, label: element }
      }) : [],
      feelGoodTaskId: obj.feelGoodTaskId,
      authorId: obj.authorId,
      selectedSuccessStories: obj.successStories ? obj.successStories.map(story => {
        return { value: story }
      }) : [],
      timeEvents: obj.timeEvents ? obj.timeEvents : [],
      sources: obj.sources ? obj.sources : [],
      topicOptions: [],
      subtopicOptions: [],
      diagnosisOptions: [],
      feelGoodTasksOptions: [],
      quizOptions: [],
      authorOptions: [],
      successStoriesOptions: [],
      errorMessage: '',
      uploadErrors: {},
      canSubmit: false,
      showValidationErrors: false,
      isLoading: true,
      videoIsUploading: false,
      // Challenges fields
      allChallengeOptions: [],
      visibleChallenges: [],
      filteredVisibleChallenges: [],
      activatedChallenges: [],
      // Session types
      sessionTypes: []
    }

    if (this.isBodySession()) {
      this.loadSessionChallenges('body')
    } else if (this.isMindSession()) {
      this.loadSessionChallenges('mind')
    } else {
      this.loadSessionChallenges()
    }

    this.loadSessionTypes()
  }

  getObject = () => {
    if (this.props.location.state && this.props.location.state.obj) {
      return this.props.location.state.obj
    } else {
      return {
        title: '',
        type: this.getDefaultObjectType(),
        section: this.isPRPSoundSession() ? undefined : this.isBodySession() ? 'body' : 'mind',
        topics: [],
        subtopics: [],
        difficulty: this.isBodySession() ? null : undefined,
        index: 9999,
        changePainStatusType: null,
        quizId: null,
        description: '',
        thoughts: '',
        article: '',
        imageFileName: '',
        imageURL: '',
        backgroundFileName: '',
        backgroundURL: '',
        audioFileName: '',
        audioURL: '',
        videoFileName: '',
        videoURL: '',
        diagnosisTags: [],
        feelGoodTaskId: null,
        authorId: null,
        isFree: true,
        isLockedForUnregisteredUsers: false,
        // source_text:'',
        // source_link:'',
        unlockNext: 1,
        timeEvents: [],
        sources:[]
      }
    }
  }

  getDefaultObjectType () {
    return GENERAL
  }

  getTexts () {
    return {
      pageTitle: ''
    }
  }

  isBodySession () {
    return (this.state && this.state.section === 'body') ||
      this.props.location.pathname === '/session-body-edit'
  }

  isMindSession () {
    return this.props.location.pathname === '/session-metime-edit'
  }

  isPRPSoundSession () {
    return this.props.location.pathname === '/session-sound-edit'
  }

  hasVideoField () {
    if (this.isMindSession() || this.isBodySession()) {
      return true
    }

    if (this.isPRPSoundSession() && this.state.type === GENERAL_WITH_VIDEO) {
      return true
    }

    return false
  }

  hasAudioField () {
    return (this.state.type !== GENERAL_WITH_VIDEO && this.state.type !== ARTICLE  && this.state.type !== SUCCESS_STORIES)
  }

  hasReadTimeField () {
    return (this.state.type === ARTICLE || this.state.type === SUCCESS_STORIES)
  }

  hasArticleField () {
    return (this.state.type === ARTICLE)
  }

  hasSuccessStoryField () {
    return (this.state.type === SUCCESS_STORIES)
  }

  hasThoughtsFields () {
    return (this.state.type !== SUCCESS_STORIES)
  }

  handleSelect = (stateId, value) => {
    this.setState({ [stateId]: value })
    if (stateId === 'type') {
      if (value !== DIAGNOSIS) {
        this.setState({ quizId: null })
      }
      if (value === 4) {
        this.setState({ changePainStatusType: 1 })
      }
      if (value !== 4) {
        this.setState({ changePainStatusType: null })
      }
      if (value !== 5) {
        this.setState({ authorId: null })
      }
    }
  }

  addTimeEvent = (obj, index) => {
    const timeEventsList = this.state.timeEvents
    if (index !== undefined) {
      timeEventsList[index] = obj
    } else {
      timeEventsList.push(obj)
    }
    timeEventsList.sort((a, b) => {
      const aFrom = parseInt(a.timeFrom.replace(':', ''), 10)
      const bFrom = parseInt(b.timeFrom.replace(':', ''), 10)
      const aTo = parseInt(a.timeTo.replace(':', ''), 10)
      const bTo = parseInt(b.timeTo.replace(':', ''), 10)
      if (aFrom > bFrom || (aFrom === bFrom && aTo > bTo)) {
        return 1
      } else if (bFrom > aFrom || (aFrom === bFrom && bTo > aTo)) {
        return -1
      } else {
        return 0
      }
    })
    this.setState({ timeEvents: timeEventsList })
  }

  removeTimeEvent = (e, index) => {
    e.preventDefault()

    if (window.confirm('Are you sure you want to remove this event?')) {
      const timeEventsList = this.state.timeEvents
      timeEventsList.splice(index, 1)
      this.setState({ timeEvents: timeEventsList })
    }
  }

  addSources = (obj, index) => {
    const sourcesList = this.state.sources
    if (index !== undefined) {
      sourcesList[index] = obj
    } else {
      sourcesList.push(obj)
    }
    // sourcesList.sort((a, b) => {
    //   const aFrom = parseInt(a.timeFrom.replace(':', ''), 10)
    //   const bFrom = parseInt(b.timeFrom.replace(':', ''), 10)
    //   const aTo = parseInt(a.timeTo.replace(':', ''), 10)
    //   const bTo = parseInt(b.timeTo.replace(':', ''), 10)
    //   if (aFrom > bFrom || (aFrom === bFrom && aTo > bTo)) {
    //     return 1
    //   } else if (bFrom > aFrom || (aFrom === bFrom && bTo > aTo)) {
    //     return -1
    //   } else {
    //     return 0
    //   }
    // })
    this.setState({ sources: sourcesList })
  }

  removeSources = (e, index) => {
    e.preventDefault()

    if (window.confirm('Are you sure you want to remove this source?')) {
      const sourcesList = this.state.sources
      sourcesList.splice(index, 1)
      this.setState({ sources: sourcesList })
    }
  }

  cancel = e => {
    e.preventDefault()
    this.props.router.push('/exitRoute')
  }

  save = async e => {
    e.preventDefault()

    let saveCancel = false
    const audioConfirmFlag = this.state.audioFileName === ''
    const videoConfirmFlag = this.state.videoFileName === ''
    const articleConfirmFlag = this.state.article === ''

    if (audioConfirmFlag && this.state.type !== GENERAL_WITH_VIDEO && this.state.type !== ARTICLE) {
      if (
        window.confirm(
          'This session has no audio file. Do you want to continue?'
        )
      ) {
        saveCancel = false
      } else {
        saveCancel = true
      }
    }

    if (videoConfirmFlag && this.state.type === GENERAL_WITH_VIDEO) {
      if (
        window.confirm(
          'This session has no video file. Do you want to continue?'
        )
      ) {
        saveCancel = false
      } else {
        saveCancel = true
      }
    }

    if (articleConfirmFlag && this.state.type === ARTICLE) {
      if (
        window.confirm(
          'This session has no article. Do you want to continue?'
        )
      ) {
        saveCancel = false
      } else {
        saveCancel = true
      }
    }

    if (!saveCancel) {
      if (window.confirm('Are you sure you want to save this session?')) {
        this.state.session.title = this.state.title
        this.state.session.type = this.state.type
        this.state.session.section = this.state.section
        this.state.session.topics = this.state.selectedTopics ? this.state.selectedTopics.map(option => option.value) : []
        this.state.session.subtopics = this.state.selectedSubtopics ? this.state.selectedSubtopics.map(option => option.value) : []
        this.state.session.successStories = this.state.selectedSuccessStories ? this.state.selectedSuccessStories.map(option => option.value) : []
        this.state.session.difficulty = this.state.difficulty
        this.state.session.changePainStatusType = this.state.changePainStatusType
        this.state.session.description = this.state.description
        this.state.session.thoughts = this.state.thoughts
        this.state.session.article = this.state.article
        this.state.session.isFree = this.state.isFree
        this.state.session.isLockedForUnregisteredUsers = this.state.isLockedForUnregisteredUsers
        // this.state.session.source_text = this.state.source_text
        // this.state.session.source_link = this.state.source_link
        this.state.session.imageFileName = this.state.imageFileName
        this.state.session.imageFileName64 = this.state.imageFileName64
        this.state.session.imageURL = this.state.imageURL
        this.state.session.backgroundFileName = this.state.backgroundFileName
        this.state.session.backgroundFileName64 = this.state.backgroundFileName64
        this.state.session.backgroundURL = this.state.backgroundURL
        this.state.session.audioFileName = this.state.audioFileName
        this.state.session.audioFileDuration = this.state.audioFileDuration
        this.state.session.videoFileDuration = this.state.videoFileDuration
        this.state.session.audioURL = this.state.audioURL
        this.state.session.videoFileName = this.state.videoFileName
        this.state.session.videoURL = this.state.videoURL
        this.state.session.authorId = this.state.authorId
        this.state.session.unlockNext = this.state.unlockNext
        this.state.session.timeEvents = this.state.timeEvents
        this.state.session.sources = this.state.sources

        if (this.hasReadTimeField()) {
          this.state.session.readTime = this.state.readTime
        }

        // Challenges data
        const { visibleChallenges, activatedChallenges } = this.state
        this.state.session.visibleChallenges = visibleChallenges ? visibleChallenges
          .map(({ value }) => value) : []
        this.state.session.activatedChallenges = activatedChallenges ? activatedChallenges
          .map(({ value }) => value) : []

        if (config.feature.quiz) this.state.session.quizId = this.state.quizId
        if (config.feature.diagnosis) {
          this.state.session.diagnosisTags = this.state.diagnosisTags.map(
            element => {
              let value
              if (typeof element === 'string') {
                value = element
              } else if (typeof element === 'object') {
                value = element.value
              }
              return value
            }
          )
        }

        if (config.feature.feelGoodTask) this.state.session.feelGoodTaskId = this.state.feelGoodTaskId

        const result = await sessionActions.saveSession(this.state.session)

        if (result) {
          this.cancel(e)
        }
      }
    }
  }

  async componentDidMount () {
    let feelGoodTasksOptions = []
    let diagnosisOptions = []
    let quizOptions = []

    if (config.feature.diagnosis) {
      const diagList = await diagnosisActions.getDiagnosisList()
      diagnosisOptions = diagList.map(element => {
        return { value: element.title, label: element.title }
      })
    }

    if (config.feature.feelGoodTask) {
      const fgtList = await feelGoodTaskActions.getTasksList()
      feelGoodTasksOptions = [{ value: null, label: 'None' }].concat(
          fgtList.map(element => {
            return { value: element._id, label: element.title }
          })
      )
    }

    if (config.feature.quiz) {
      const quizList = await quizActions.getQuizList()
      quizOptions = [{ value: null, label: 'None' }].concat(
          quizList.map(element => {
            return { value: element._id, label: element.title }
          })
      )
    }

    const authList = await authorActions.getAuthorList()
    const authorOptions = [{ value: null, label: 'None' }].concat(
      authList.map(element => {
        return { value: element._id, label: element.name }
      })
    )

    if (config.feature.diagnosis) { this.setState({ diagnosisOptions }) }
    if (config.feature.feelGoodTask) { this.setState({ feelGoodTasksOptions }) }
    if (config.feature.quiz) { this.setState({ quizOptions }) }

    if (this.isBodySession()) {
      const topicList = await topicActions.getTopicList()
      const topicOptions = topicList.map(element => {
        return { value: element._id, label: element.title }
      })
      const selectedTopics = this.state.object.topics ? this.state.topics.map(topicId => {
        return topicOptions.find(option => option.value === topicId)
      }) : []

      const subtopicList = await topicActions.getSubtopicsList()
      const subtopicOptions = subtopicList.map(element => {
        return { value: element._id, label: element.title }
      })
      const selectedSubtopics = this.state.object.subtopics ? this.state.object.subtopics.map(subtopicId => {
        return subtopicOptions.find(option => option.value === subtopicId)
      }) : []

      this.setState({
        topicOptions,
        subtopicOptions,
        selectedTopics,
        selectedSubtopics
      })
    }

    if (this.isPRPSoundSession()) {
      const successStoriesList = await successStoriesActions.getListWithDiagnoses()
      const successStoriesOptions = successStoriesList.map(element => {
        let title = element.title
        if (element.diagnosesList && element.diagnosesList.length) {
          const diagnosesTitles = element.diagnosesList.map(diagnosis => diagnosis.title)
          title = title + ' [' + diagnosesTitles.join(', ') + ']'
        }
        return { value: element._id, label: title }
      })

      this.setState({
        successStoriesOptions
      })
    }

    this.setState({
      // diagnosisOptions,
      // feelGoodTasksOptions,
      // quizOptions,
      authorOptions,
      isLoading: false
    })

    if (this.rap) {
      const audio = this.rap.audioEl
      let _self = this
      audio.addEventListener('loadedmetadata', function () {
        _self.setState({
          audioFileDuration: audio.duration
        })
      }, false)
    }
  }

  /**
   * Sets session types to state
   */
  async loadSessionTypes () {
    const sessionTypes = await sessionTypeActions.getSessionTypeList()

    if (sessionTypes) {
      this.setState({ sessionTypes })
    }
  }

  /**
   * Sets Challenges options to state
   * @param {string} sessionType
   */
  async loadSessionChallenges (sessionType = null) {
    const challengeOptions = []
    const visibleChallengeOptions = []
    const activatedChallengeOptions = []
    const filteredVisibleChallenges = []

    const { object } = this.state
    const visibleChallengeIds = object.visibleChallenges || false
    const activatedChallengeIds = object.activatedChallenges || false

    const challenges = await challengeActions.getChallengesList(
      { type: sessionType ? sessionType.toLowerCase() : null }
    )

    const filteredChallengesByVisible = this.getFilteredChallengesByVisible(challenges)

    if (challenges && challenges.length) {
      challenges.forEach(({ _id, title }) => {
        challengeOptions.push({ value: _id, label: title })

        if (visibleChallengeIds && visibleChallengeIds.includes(_id)) {
          visibleChallengeOptions.push({ value: _id, label: title })
        }

        if (activatedChallengeIds && activatedChallengeIds.includes(_id)) {
          activatedChallengeOptions.push({ value: _id, label: title })
        }
      })

      filteredChallengesByVisible.forEach(({ _id, title }) => {
        filteredVisibleChallenges.push({ value: _id, label: title })
      })
    }

    this.setState({
      allChallengeOptions: challengeOptions,
      visibleChallenges: visibleChallengeOptions,
      activatedChallenges: activatedChallengeOptions,
      filteredVisibleChallenges,
    })
  }

  getFilteredChallengesByVisible = (challenges) => {
    return challenges.filter(({ isVisibleByDefault }) => !isVisibleByDefault)
  }

  render () {
    const style = { color: 'red' }
    let iimm =
      this.state.imageFileName === ''
        ? imageLib.noImage
        : urlImg(this.state.imageFileName)
    let iimm2 =
      this.state.backgroundFileName === ''
        ? imageLib.noImage
        : urlImg(this.state.backgroundFileName)

    return (
      <HomePage router={this.props.router}>
        {!this.state.isLoading && (
          <div className="devices-page-wrapper">
            <h3>{this.getTexts().pageTitle}</h3>
            <Formsy
              className="create-device-form"
              onValidSubmit={this.submit}
              onInvalidSubmit={() =>
                this.setState({ showValidationErrors: true })
              }
              onValid={() => this.setState({ canSubmit: true })}
              onInvalid={() => this.setState({ canSubmit: false })}
            >
              <div className="create-device-row">
                <label>
                  Title <span style={style}>*</span>
                </label>
                <MyInput
                  id="title"
                  name="title"
                  classNameDiv="create-branch-row"
                  className="create-device-input"
                  autocomplete="off"
                  value={this.state.title}
                  onChange={this.handleChange}
                  required
                  showError={this.state.showValidationErrors}
                />
              </div>

              {this.state.pageType === 'main' && (
                <div className="create-device-row">
                  <label>Type</label>
                  <MySelect
                    stateField="type"
                    className="create-branch-select"
                    value={this.state.type}
                    options={this.state.sessionTypes
                      .map(({ id, name }) => ({ value: parseInt(id), label: name }))}
                    onChange={this.handleSelect}
                  />
                </div>
              )}

              {this.state.type === GENERAL && <div style={{ height: '50px' }} />}
              {config.feature.diagnosis && this.state.type === RECAP && (
                <div className="create-device-row">
                  <label>Diagnosis Tags</label>
                  <DiagnosisSelect
                    stateField="diagnosisTags"
                    className="create-branch-select"
                    value={this.state.diagnosisTags}
                    options={this.state.diagnosisOptions}
                    onChange={this.handleSelect}
                    isMulti={true}
                    isSearchable={true}
                    isCreatable={true}
                  />
                </div>
              )}
              {config.feature.quiz && this.state.type === DIAGNOSIS && (
                <div className="create-device-row">
                  <label>Quiz</label>
                  <MySelect
                    stateField="quizId"
                    className="create-branch-select"
                    value={this.state.quizId}
                    options={this.state.quizOptions}
                    onChange={this.handleSelect}
                  />
                </div>
              )}
              {this.state.type === 4 && (
                <div className="create-device-row">
                  <label>Pain Change Type</label>
                  <MySelect
                    stateField="changePainStatusType"
                    className="create-branch-select"
                    value={this.state.changePainStatusType}
                    options={[
                      // { value: null, label: 'Select pain change type...' },
                      { value: 1, label: 'Increase' },
                      { value: 2, label: 'Decrease' }
                    ]}
                    onChange={this.handleSelect}
                  />
                </div>
              )}
              {this.state.type === 5 && (
                <div className="create-device-row">
                  <label>Author</label>
                  <MySelect
                    stateField="authorId"
                    className="create-branch-select"
                    value={this.state.authorId}
                    options={this.state.authorOptions}
                    onChange={this.handleSelect}
                  />
                </div>
              )}

              { this.hasSuccessStoryField() && (
              <div className="create-device-row">
                <label>
                  Success Stories
                </label>

                <MySelect
                  stateField="selectedSuccessStories"
                  className="create-branch-select"
                  value={this.state.selectedSuccessStories}
                  options={this.state.successStoriesOptions}
                  onChange={this.handleSelect}
                  isMulti={true}
                  isSearchable={true}
                />
                {this.state.showValidationErrors && this.state.selectedSuccessStories.length === 0 &&
                  <ErrorMessage text="This field is required" />
                }
              </div>
              )}

              { this.isBodySession() && (
                <div className='create-device-row'>
                  <label>Topics <span style={style}>*</span></label>
                  <MySelect
                    stateField="selectedTopics"
                    className="create-branch-select"
                    value={this.state.selectedTopics}
                    options={this.state.topicOptions}
                    onChange={this.handleSelect}
                    isMulti={true}
                    isSearchable={true}
                  />
                  {this.state.showValidationErrors && this.state.selectedTopics.length === 0 &&
                    <ErrorMessage text="This field is required" />
                  }
                </div>
                ) }

              { this.isBodySession() && (
                <div className='create-device-row'>
                  <label>Subtopics</label>
                  <MySelect
                    stateField="selectedSubtopics"
                    className="create-branch-select"
                    value={this.state.selectedSubtopics}
                    options={this.state.subtopicOptions}
                    onChange={this.handleSelect}
                    isMulti={true}
                    isSearchable={true}
                  />
                </div>
              ) }

              { this.isBodySession() && (
                <div className="create-device-row">
                  <label>Difficulty</label>
                  <MySelect
                    stateField="difficulty"
                    className="create-branch-select"
                    value={this.state.difficulty}
                    options={collectionActions
                      .getDifficultyTypes(true)
                      .map((element, index) => {
                        return { value: index, label: element }
                      })}
                    onChange={this.handleSelect}
                  />
                </div>
              ) }

              { (
                <div className="create-device-row">
                  <label>Make Challenges visible</label>
                  <MySelect
                    stateField="visibleChallenges"
                    className="create-branch-select"
                    value={this.state.visibleChallenges}
                    options={this.state.filteredVisibleChallenges}
                    onChange={this.handleSelect}
                    isMulti={true}
                    isSearchable={true}
                  />
                </div>
              ) }

              { (
                <div className="create-device-row">
                  <label>Activate Challenges</label>
                  <MySelect
                    stateField="activatedChallenges"
                    className="create-branch-select"
                    value={this.state.activatedChallenges}
                    options={this.state.allChallengeOptions}
                    onChange={this.handleSelect}
                    isMulti={true}
                    isSearchable={true}
                  />
                </div>
              ) }

              {this.state.pageType !== 'about' && (
                <div className="create-device-row">
                  <label>Description</label>
                  <textarea
                    id="description"
                    name="description"
                    className="create-device-input"
                    rows={5}
                    value={this.state.description}
                    onChange={this.handleChange}
                  />
                </div>
              )}
              {this.state.pageType !== 'about' && this.hasThoughtsFields() && (
                <div className="create-device-row">
                  <label>Thoughts</label>
                  <textarea
                    id="thoughts"
                    name="thoughts"
                    className="create-device-input"
                    rows={5}
                    value={this.state.thoughts}
                    onChange={this.handleChange}
                  />
                </div>
              )}

              {(this.state.pageType === 'main' ||
                this.state.pageType === 'meTime') && (
                <div className="create-device-row">
                  <label>Free</label>
                  <MySelect
                    stateField="isFree"
                    className="create-branch-select"
                    value={this.state.isFree}
                    options={[
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' }
                    ]}
                    onChange={this.handleSelect}
                  />
                </div>
              )}

              {(this.state.pageType === 'main' ||
                this.state.pageType === 'meTime') && (
                <div className="create-device-row">
                  <label>Locked For Unregistered Users</label>
                  <MySelect
                    stateField="isLockedForUnregisteredUsers"
                    className="create-branch-select"
                    value={this.state.isLockedForUnregisteredUsers}
                    options={[
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' }
                    ]}
                    onChange={this.handleSelect}
                  />
                </div>
              )}
              {this.state.pageType === 'main' && (
                <div className="create-device-row">
                  <label>Open Next Sessions</label>
                  <MySelect
                    stateField="unlockNext"
                    className="create-branch-select"
                    value={this.state.unlockNext}
                    options={[
                      { value: 1, label: '1' },
                      { value: 5, label: '5' }
                    ]}
                    onChange={this.handleSelect}
                  />
                </div>
              )}

              {this.hasReadTimeField() && (
                  <div className="create-device-row">
                    <label>Read time</label>
                    <MyInput
                      id="readTime"
                      name="readTime"
                      classNameDiv="create-branch-row"
                      className="create-device-input"
                      type="number"
                      value={this.state.readTime}
                      onChange={this.handleChange}
                      showError={this.state.showValidationErrors}
                    />
                  </div>
              )}

              {this.hasArticleField() && (
                  <div className="create-device-row">
                    <label>Article</label>
                    <textarea
                      id="article"
                      name="article"
                      className="create-device-input"
                      rows={20}
                      value={this.state.article}
                      onChange={this.handleChange}
                    />
                  </div>
              )}

              {this.state.pageType !== 'about' && (
                <div className="create-device-row">
                  <label>
                    Image <span style={style}>*</span>
                  </label>
                  <div className="div-row">
                    <Dropzone
                      className="upload-border"
                      onDrop={files => {
                        this.handleDropImage(
                          files,
                          'imageFileName',
                          'imageFileNameError'
                        )
                      }}
                      accept="image/jpeg,image/jpg,image/tiff,image/gif,image/png"
                      multiple={false}
                      type={'file'}
                    >
                      <span
                        className="w-100"
                        style={{ textAlign: 'center', marginBottom: '5px' }}
                      >
                        Drag and drop file or{' '}
                      </span>
                      <button className="btn blue" type="button">
                        BROWSE
                      </button>
                    </Dropzone>
                    <div style={{ textAlign: 'right', position: 'relative' }}>
                      {this.state.imageFileName !== '' && (
                        <button
                          className="btn delete-btn"
                          type="button"
                          onClick={() =>
                            this.deleteUploadFile(
                              'imageFileName',
                              'imageFileNameError'
                            )
                          }
                        >
                          <CloseIcon />
                        </button>
                      )}
                      <img
                        style={{
                          width: 180,
                          height: 180,
                          objectFit: 'contain',
                          border: '1px solid #e6e9ec',
                          marginLeft: '20px'
                        }}
                        src={iimm}
                        alt=""
                      />
                    </div>
                  </div>
                  <ErrorMessage text={this.state.imageFileNameError} />
                  <MyInput
                    id="imageFileName"
                    name="imageFileName"
                    classNameDiv="create-branch-row"
                    className="create-device-input"
                    value={this.state.imageFileName}
                    hidden
                    disabled
                    required
                    showError={this.state.showValidationErrors}
                  />
                </div>
              )}

              <div className="create-device-row">
                <label>
                  Background Image <span style={style}>*</span>
                </label>
                <div className="div-row">
                  <Dropzone
                    className="upload-border"
                    onDrop={files => {
                      this.handleDropImage(
                        files,
                        'backgroundFileName',
                        'backgroundFileNameError'
                      )
                    }}
                    accept="image/jpeg,image/jpg,image/tiff,image/gif,image/png"
                    multiple={false}
                    type={'file'}
                  >
                    <span
                      className="w-100"
                      style={{ textAlign: 'center', marginBottom: '5px' }}
                    >
                      Drag and drop file or{' '}
                    </span>
                    <button className="btn blue" type="button">
                      BROWSE
                    </button>
                  </Dropzone>
                  <div style={{ textAlign: 'right', position: 'relative' }}>
                    {this.state.backgroundFileName !== '' && (
                      <button
                        className="btn delete-btn"
                        type="button"
                        onClick={() =>
                          this.deleteUploadFile(
                            'backgroundFileName',
                            'backgroundFileNameError'
                          )
                        }
                      >
                        <CloseIcon />
                      </button>
                    )}
                    <img
                      style={{
                        width: 180,
                        height: 180,
                        objectFit: 'contain',
                        border: '1px solid #e6e9ec',
                        marginLeft: '20px'
                      }}
                      src={iimm2}
                      alt=""
                    />
                  </div>
                </div>
                <ErrorMessage text={this.state.backgroundFileNameError} />
                <MyInput
                  id="backgroundFileName"
                  name="backgroundFileName"
                  classNameDiv="create-branch-row"
                  className="create-device-input"
                  value={this.state.backgroundFileName}
                  hidden
                  disabled
                  required
                  showError={this.state.showValidationErrors}
                />
              </div>

              { this.hasVideoField() && (
                <div className="create-device-row">
                  <label>Video</label>
                  <div className="div-row">
                  <Dropzone
                    className="upload-border"
                    onDrop={files => {
                      this.handleDropVideo(
                        files,
                        'videoURL',
                        'videoURLError'
                      )
                    }}
                    onChange={this.onVideoChange}
                    accept="video/mp4"
                    multiple={false}
                    type={'file'}
                  >
                    <span className="w-50">Drag and drop file or </span>
                    <button className="btn blue" type="button">
                      BROWSE
                    </button>
                  </Dropzone>
                  <div
                    style={{
                      textAlign: 'right',
                      position: 'relative',
                      marginLeft: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      border: '1px solid #e6e9ec'
                    }}
                  >
                    { this.state.videoIsUploading ? (
                      <CircularProgress />
                      ) : this.state.videoURL !== '' && this.state.videoURL ? (
                      <div>
                        <button
                          className="btn delete-btn"
                          type="button"
                          onClick={() =>
                            this.deleteUploadFile(
                              'videoURL',
                              'videoURLError'
                            )
                          }
                        >
                          <CloseIcon />
                        </button>

                        <ReactPlayer
                          url={this.state.videoURL}
                          controls={true}
                          style={{ marginTop: '20px' }}
                          onDuration={ duration => {
                            this.setState({ videoFileDuration: Math.floor(duration) })
                          } }
                          />
                      </div>
                    ) : (
                      <FakeAudioPlayer text="No video file" />
                    )}
                  </div>
                </div>
                <ErrorMessage text={this.state.videoURLError} />
                <MyInput
                  id="videoURL"
                  name="videoURL"
                  classNameDiv="create-branch-row"
                  className="create-device-input"
                  value={this.state.videoURL}
                  hidden
                  disabled
                  showError={this.state.showValidationErrors}
                />
                </div>
              )}

              { this.hasAudioField() && (
              <div className="create-device-row">
                <label>Audio</label>
                <div className="div-row">
                  <Dropzone
                    className="upload-border"
                    onDrop={files => {
                      this.handleDropAudio(
                        files,
                        'audioFileName',
                        'audioFileNameError'
                      )
                    }}
                    onChange={this.onAudioChange}
                    accept="audio/mpeg,audio/mp3,audio/m4a,audio/x-m4a"
                    multiple={false}
                    type={'file'}
                  >
                    <span className="w-50">Drag and drop file or </span>
                    <button className="btn blue" type="button">
                      BROWSE
                    </button>
                  </Dropzone>
                  <div
                    style={{
                      textAlign: 'right',
                      position: 'relative',
                      marginLeft: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      border: '1px solid #e6e9ec'
                    }}
                  >
                    {this.state.audioFileName !== '' ? (
                      <div>
                        <button
                          className="btn delete-btn"
                          type="button"
                          onClick={() =>
                            this.deleteUploadFile(
                              'audioFileName',
                              'backgroundFileNameError'
                            )
                          }
                        >
                          <CloseIcon />
                        </button>
                        <ReactPlayer
                          url={this.state.audioURL || urlAudio(this.state.audioFileName)}
                          controls={true}
                          style={{ marginTop: '20px', height: '40px' }}
                          />
                      </div>
                    ) : (
                      <FakeAudioPlayer text="No audio file" />
                    )}
                  </div>
                </div>
                <ErrorMessage text={this.state.audioFileNameError} />
                <MyInput
                  id="audioFileName"
                  name="audioFileName"
                  classNameDiv="create-branch-row"
                  className="create-device-input"
                  value={this.state.audioFileName}
                  hidden
                  disabled
                  showError={this.state.showValidationErrors}
                />
              </div>
              )}
              {this.state.pageType === 'main' && (
                <div className="create-device-row">
                  <label>Sources</label>
                  <div>
                    <div className={'table-head bb'}>
                      <div style={{ width: '40%', paddingLeft: '15px' }}>
                        Source Text
                      </div>
                      <div style={{ width: '40%', paddingLeft: '15px' }}>
                        Source Link
                      </div>
                      <div style={{ width: '20%', paddingLeft: '15px' }}>
                        Actions
                      </div>
                    </div>
                    {this.state.sources.map((element, index) => {
                      return (
                        <div
                          key={`source_${index}`}
                          className={'table-row'}
                        >
                          <div style={{ width: '40%', paddingLeft: '15px' }}>
                            {element.source_text}
                          </div>
                          <div style={{ width: '40%', paddingLeft: '15px' }}>
                            {element.source_link}
                          </div>
                          <div
                            style={{
                              width: '20%',
                              paddingLeft: '15px',
                              display: 'flex'
                            }}
                          >
                            <SourceModal
                              index={index}
                              object={element}
                              onExit={(obj, isCreating) =>
                                this.addSources(obj, isCreating)
                              }
                            />
                            <button
                              className="btn red mr-2"
                              type="button"
                              onClick={e => this.removeSources(e, index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div style={{ width: '10%', paddingLeft: '15px' }}>
                    <SourceModal
                      onExit={(obj, isCreating) =>
                        this.addSources(obj, isCreating)
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.pageType !== 'about' && !this.hasArticleField() && (this.state.type !== SUCCESS_STORIES) && (
                <div className="create-device-row">
                  <label>Time Events</label>
                  <div>
                    <div className={'table-head bb'}>
                      <div style={{ width: '10%', paddingLeft: '15px' }}>
                        Type
                      </div>
                      <div style={{ width: '50%', paddingLeft: '15px' }}>
                        Content/File
                      </div>
                      <div style={{ width: '10%', paddingLeft: '15px' }}>
                        Start
                      </div>
                      <div style={{ width: '10%', paddingLeft: '15px' }}>
                        Finish
                      </div>
                      <div style={{ width: '20%', paddingLeft: '15px' }}>
                        Actions
                      </div>
                    </div>
                    {this.state.timeEvents.map((element, index) => {
                      return (
                        <div
                          key={`time_event_${index}`}
                          className={'table-row'}
                        >
                          <div style={{ width: '10%', paddingLeft: '15px' }}>
                            {element.type === 'text' ? 'Text' : 'Image'}
                          </div>
                          <div style={{ width: '50%', paddingLeft: '15px' }}>
                            {element.value}
                          </div>
                          <div style={{ width: '10%', paddingLeft: '15px' }}>
                            {element.timeFrom}
                          </div>
                          <div style={{ width: '10%', paddingLeft: '15px' }}>
                            {element.timeTo}
                          </div>
                          <div
                            style={{
                              width: '20%',
                              paddingLeft: '15px',
                              display: 'flex'
                            }}
                          >
                            <TimeEventModal
                              index={index}
                              object={element}
                              onExit={(obj, isCreating) =>
                                this.addTimeEvent(obj, isCreating)
                              }
                            />
                            <button
                              className="btn red mr-2"
                              type="button"
                              onClick={e => this.removeTimeEvent(e, index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div style={{ width: '10%', paddingLeft: '15px' }}>
                    <TimeEventModal
                      onExit={(obj, isCreating) =>
                        this.addTimeEvent(obj, isCreating)
                      }
                    />
                  </div>
                </div>
              )}

              <ErrorMessage text={this.state.errorMessage} />
              <button
                className="btn green mr-2"
                type="submit"
                onClick={this.state.canSubmit ? this.save : undefined}
              >
                Save
              </button>
              <button
                className="btn blue mr-2"
                type="button"
                onClick={this.cancel}
              >
                Cancel
              </button>
            </Formsy>
          </div>
        )}
      </HomePage>
    )
  }
}

export default SessionEdit
