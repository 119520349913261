import { fetchFromUrlPOSTAsync } from '../fetch/';

export const actions = {

  getPhrasesList: async () => {
    const result = await fetchFromUrlPOSTAsync('/daily/get_sorted_list', []);
    return result.success ? result.data : [];
  },

  savePhrase: async (saveData) => {
    let urlParams = [
      { key: 'saveData', value: saveData },
    ];
    const result = await fetchFromUrlPOSTAsync('/daily/save_one', urlParams);
    return result;
  },
  
  savePhrasesList: async (saveData) => {
    let urlParams = [
      { key: 'saveData', value: saveData },
    ];
    const result = await fetchFromUrlPOSTAsync('/daily/save_all', urlParams);
    return result;
  },

  deletePhrase: async (phraseId) => {
    let urlParams = [
      { key: 'phraseId', value: phraseId },
    ];
    const result = await fetchFromUrlPOSTAsync('/daily/delete_one', urlParams);
    return result;
  },
};
