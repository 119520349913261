import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';

class MySelect extends CreatableSelect {

    constructor(props) {
        super(props);

        // console.log("props val", this.props.value, this.getValue(this.props.value));
        
        this.state = {
            selectedOption: this.getValue(this.props.value),
        };


        if (this.props.isSearchable) {
            if (this.props.options.find(option => option.value === null)) {
                console.log("Error will occur if searchable Select contains an option with null value.");
            }
        }
    }

    changeValue = (option) => {
        this.setState({ selectedOption: option });
        if (Array.isArray(option)) {
            this.props.onChange(this.props.stateField, option.map(item => item));
        } else {
            this.props.onChange(this.props.stateField, option.value);
        }
    }

    getValue = (value) => {
        if (Array.isArray(value)) {
            const result = value.map((element) => {
                let findResult = this.props.options.find(option => option.value === element.value);
                if (this.props.isMulti && ! findResult) {
                    findResult = element;
                }
                return findResult;
            });
            return result;
        } else {
            return this.props.options.find(option => option.value === value);
        }
    }

    render() {

        return (
            this.props.isCreatable ?
                <CreatableSelect
                    className={this.props.className}
                    value={this.state.selectedOption}
                    onChange={this.changeValue}
                    options={this.props.options}
                    isMulti={true}
                    isSearchable={this.props.isSearchable}
                />
            :
                <Select
                    className={this.props.className}
                    value={this.state.selectedOption}
                    onChange={this.changeValue}
                    options={this.props.options}
                    isMulti={this.props.isMulti}
                    isSearchable={this.props.isSearchable}
                    isDisabled={this.props.disabled}
                />
        )
    }
}

export default MySelect;