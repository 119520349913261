import { fetchFromUrlPOSTAsync } from '../fetch/'

export const actions = {
  getSessionsList: async () => {
    const result = await fetchFromUrlPOSTAsync('/session/get_sorted_list', [])
    return result.success ? result.data : []
  },

  getDiagnosisTypeSessionsList: async () => {
    const result = await fetchFromUrlPOSTAsync(
      '/session/get_diagnosis_type_sessions',
      []
    )
    return result.success ? result.data : []
  },

  getPainSessionsList: async () => {
    const result = await fetchFromUrlPOSTAsync(
      '/session/get_pain_change_sessions',
      []
    )
    return result.success
      ? result.data.increaseType.concat(result.data.decreaseType)
      : []
  },

  getMeTimeSessionsList: async (section = null) => {
    const result = await fetchFromUrlPOSTAsync(
      '/session/get_metime_sessions',
      [ { key: 'section', value: section } ]
    )
    return result.success ? result.data : []
  },

  getAboutSession: async () => {
    const result = await fetchFromUrlPOSTAsync(
      '/session/get_about_pathways_session',
      []
    )
    if (result.success) {
      return result.data
    }
  },

  getByFeelGoodTaskId: async feelGoodTaskId => {
    let urlParams = [{ key: 'feelGoodTaskId', value: feelGoodTaskId }]
    const result = await fetchFromUrlPOSTAsync('/session/get_by_fgt', urlParams)
    return result.success ? result.data : null
  },

  saveSession: async data => {
    let urlParams = [{ key: 'saveData', value: data }]
    const result = await fetchFromUrlPOSTAsync('/session/save_one', urlParams)
    return result.success
  },

  saveSessionsList: async saveData => {
    let urlParams = [{ key: 'saveData', value: saveData }]
    const result = await fetchFromUrlPOSTAsync('/session/save_list', urlParams)
    return result
  },

  deleteSession: async saveData => {
    let urlParams = [{ key: 'saveData', value: saveData }]
    const result = await fetchFromUrlPOSTAsync('/session/delete', urlParams)
    return result
  }
}
