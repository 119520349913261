import { fetchFromUrlPOSTAsync, fetchFromUrlGETAsync, fetchFromUrlPATCHAsync, fetchFromUrlDELETEAsync } from '../fetch'

const routeBase = 'factor-subtopics'

export const actions = {
  getList: async ({ page, perPage, order, orderBy, searchField, searchKeyword }) => {
    try {
      const urlParams = new URLSearchParams('')
      urlParams.append('page', page)
      urlParams.append('perPage', perPage)
      urlParams.append('order', order)
      urlParams.append('orderBy', orderBy)
      if (searchField && searchKeyword) {
        urlParams.append('searchField', searchField)
        urlParams.append('searchKeyword', searchKeyword)
      }

      const result = await fetchFromUrlGETAsync(`/${routeBase}/list/?${urlParams}`)

      return result || {}
    } catch (e) {
      console.error(e.message)
      return {}
    }
  },

  getById: async id => {
    try {
      const result = await fetchFromUrlGETAsync(`/${routeBase}/${id}`)

      return result || {}
    } catch (e) {
      console.error(e.message)
      return {}
    }
  },

  save: async saveData => {
    try {
      let result = {}
      const { _id } = saveData
      if (_id) {
        result = await fetchFromUrlPATCHAsync(`/${routeBase}/${_id}`, [{ key: 'saveData', value: saveData }])
      } else {
        result = await fetchFromUrlPOSTAsync(`/${routeBase}`, [{ key: 'saveData', value: saveData }])
      }

      return result || {}
    } catch (e) {
      console.error(e.message)
      return {}
    }
  },

  updateSort: async saveData => {
    try {
      const indexData = saveData.map(({ _id, index }) => ({ _id, index }))
      const sortData = [{ key: 'sortData', value: indexData }]
      const result = await fetchFromUrlPATCHAsync(`/${routeBase}/update-sort/`, sortData)

      return result || {}
    } catch (e) {
      console.error(e.message)
      return {}
    }
  },

  deleteById: async id => {
    try {
      const result = await fetchFromUrlDELETEAsync(`/${routeBase}/${id}`)

      return result || {}
    } catch (e) {
      console.error(e.message)
      return {}
    }
  }
}
