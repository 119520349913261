const SET_ADMIN = 'SET_ADMIN';

export default function reducer(state, action) {
  if (!state) {
    state = {
      admin: {}
    }
  }

  switch (action.type) {
    case SET_ADMIN:
      return { ...state, admin: action.payload };
    // case SET_ADMIN_POLICY:
    //   return { ...state, admin.polic: action.payload };
    default:
      return state;
  }
}