import React from 'react'
import HomePage from '../home'
import MyPage from '../../components/MyPage'
import MyTable from '../../components/MyTable'
import MyTableHeaderSort from '../../components/MyTableHeaderSort'
import { Loader } from '../../components/loader'

import { actions as feedbackActions } from '../../redux/modules/feedbacks'

class FeedbackList extends MyPage {
  constructor (props) {
    super(props)
    this.state = {
      fullList: [],
      isLoading: true
    }
  }

  getList = async () => {
    const data = await feedbackActions.getData()
    return data
  }

  async componentDidMount () {
    const list = await this.getList()
    this.setState({
      fullList: list,
      isLoading: false
    })
  }

  makeHead = () => {
    return (
      <div className="table-head">
        <div style={{ width: '20%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="User Name" />
        </div>
        <div style={{ width: '20%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Email" />
        </div>
        <div style={{ width: '60%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Feedback" />
        </div>
      </div>
    )
  }

  makeRow = item => {
    return (
      <div className={'table-row'}>
        <div style={{ width: '20%', paddingLeft: '15px' }}>{item.userName}</div>
        <div style={{ width: '20%', paddingLeft: '15px' }}>{item.userEmail}</div>
        <div style={{ width: '60%', paddingLeft: '15px' }}>{item.feedback}</div>
      </div>
    )
  }

  render () {
    return (
      <HomePage router={this.props.router}>
        <div className="device-table-container">
          {this.state.isLoading ? <Loader /> : (
            <MyTable
              tableName="Users feedbacks"
              tableHead={this.makeHead}
              tableRow={this.makeRow}
              hasIndex={true}
              data={this.state.fullList}
              hasPagination={true}
              rowsPerPage={40}
            />
          )}
        </div>
      </HomePage>
    )
  }
}

export default FeedbackList
