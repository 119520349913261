import React from 'react'
import HomePage from '../home'
import Formsy from 'formsy-react'
import MyPage from '../../components/MyPage'
import MyInput from '../../components/MyInput'
import ErrorMessage from '../../components/errormessage'
import { actions } from '../../redux/modules/SOSExperienceSubtopics'

class SOSExperienceSubtopicEdit extends MyPage {
  constructor(props) {
    super(props)
    this.state = {
      errorMessage: '',
      canSubmit: false,
      showValidationErrors: false,
      isLoading: true,
      openDialog: false
    }
  }

  getObject = () => {
    if (this.props.location.state && this.props.location.state.obj) {
      const object = this.props.location.state.obj
      this.setState({
        object,
        isLoading: false
      })
    } else {
      this.setState({
        object: {
          title: ''
        },
        isLoading: false
      })
    }
  }

  getTexts = () => {
    return {
      pageTitle: 'SOS Experience Subtopic'
    }
  }

  handleChange = e => {
    e.preventDefault()
    const target = e.target
    this.setState(state => {
      return {
        object: {
          ...state.object,
          [target.id]: target.value
        }
      }
    })
  }

  cancel = e => {
    e.preventDefault()
    this.props.router.push('/sos-experience-subtopics-list')
  }

  save = async e => {
    e.preventDefault()
    if (window.confirm('Are you sure you want to save this SOS Experience Subtopic?')) {
      const { _id, errorMessage } = await actions.save(this.state.object)

      if (_id) {
        this.setState({ errorMessage: '' })
        this.cancel(e)
      }

      if (errorMessage) {
        this.setState({ errorMessage })
      }
    }
  }

  componentDidMount () {
    this.getObject()
  }

  render () {
    const style = { color: 'red' }
    const videoURL = this.state.videoURL || ''

    return (
      <HomePage router={this.props.router}>
        <div className="devices-page-wrapper">
          <ErrorMessage text={this.state.errorMessage} />
          <h3>{this.getTexts().pageTitle}</h3>
          {!this.state.isLoading && (
            <Formsy
              className="create-device-form"
              onValidSubmit={this.submit}
              onInvalidSubmit={() =>
                this.setState({ showValidationErrors: true })
              }
              onValid={() => this.setState({ canSubmit: true })}
              onInvalid={() => this.setState({ canSubmit: false })}
            >
              <div className="create-device-row">
                <label>
                  Title <span style={style}>*</span>
                </label>
                <MyInput
                  id="title"
                  name="title"
                  classNameDiv="create-branch-row"
                  className="create-device-input"
                  autocomplete="off"
                  value={this.state.object.title}
                  onChange={this.handleChange}
                  required
                  showError={this.state.showValidationErrors}
                />
              </div>
              <button
                className="btn green mr-2"
                type="submit"
                onClick={this.state.canSubmit ? this.save : undefined}
              >
                Save
              </button>
              <button
                className="btn blue mr-2"
                type="button"
                onClick={this.cancel}
              >
                Cancel
              </button>
            </Formsy>
          )}
        </div>
      </HomePage>
    )
  }
}

export default SOSExperienceSubtopicEdit
