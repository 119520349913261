import React from 'react'

const Loader = function () {
  return (
    <div style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'}}
    >
      <img src='assets/images/material-loader.gif' />
    </div>
  )
}

export { Loader }
