import { fetchFromUrlPOSTAsync, fetchFromUrlDELETEAsync } from '../fetch/';

export const actions = {

  getTopicList: async () => {
    const result = await fetchFromUrlPOSTAsync('/topic/get_sorted_list', []);
    return result.success ? result.data : [];
  },

  getSubtopicsList: async () => {
    const result = await fetchFromUrlPOSTAsync('/subtopic/get_sorted_list', [])
    return result.success ? result.data : []
  },

  saveTopic: async (saveData) => {
    let urlParams = [
      { key: 'saveData', value: saveData },
    ];
    const result = await fetchFromUrlPOSTAsync('/topic/save_one', urlParams);
    return result;
  },

  saveSubtopic: async data => {
    const saveData = data
    delete saveData.selectedDiagnoses

    let urlParams = [
      { key: 'saveData', value: saveData },
    ]
    const result = await fetchFromUrlPOSTAsync('/subtopic/save_one', urlParams)
    return result
  },

  saveTopicsList: async (saveData) => {
    let urlParams = [
      { key: 'saveData', value: saveData },
    ];
    const result = await fetchFromUrlPOSTAsync('/topic/save_all', urlParams);
    return result;
  },

  saveSubtopicsList: async saveData => {
    let urlParams = [
      { key: 'saveData', value: saveData },
    ]
    const result = await fetchFromUrlPOSTAsync('/subtopic/save_all', urlParams)
    return result
  },

  deleteTopicById: async id => {
    try {
      const result = await fetchFromUrlDELETEAsync(`/topic/delete/${id}`)
      if (result.status === 200) {
        return { success: true }
      }
      return false
    } catch (e) {
      return false
    }
  },

  deleteSubtopicById: async id => {
    try {
      const result = await fetchFromUrlDELETEAsync(`/subtopic/delete/${id}`)
      if (result.status === 200) {
        return { success: true }
      }
      return false
    } catch (e) {
      return false
    }
  }
}
