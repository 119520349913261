import React from 'react'
import { Modal } from 'reactstrap'

import Paper from '@material-ui/core/Paper'

import MyPage from '../../components/MyPage'
import MyTable from '../../components/MyTable'
import MyTableHeaderSort from '../../components/MyTableHeaderSort'
import Select from 'react-select';
import { range } from 'lodash'
import { actions as diagnosisActions } from '../../redux/modules/diagnosis'
import './style.css'
class SelectRecommendedDiagnoses extends MyPage {
  constructor (props) {
    super(props)
    const obj = this.props.object
    this.state = {
      modal: false,
      isCreating: !this.props.object,
      object: obj,
      allDiagnoses: [],
      selectedDiagnoses: {},
      selectedRelevance: undefined,
      prevRelevance: {}
    }
  }

  getTexts = () => {
    return {
      tableTitle: 'Select Recommended Diagnoses',
    }
  }

  toggle = async () => {
    if (!this.state.modal) {
      const list = await diagnosisActions.getDiagnosisForCollection()

      const obj = this.props.object
      const selectedDiagnoses = {}
      if (obj.selectedDiagnoses) {
        obj.selectedDiagnoses.forEach(diagnosis => {
          selectedDiagnoses[diagnosis._id] = diagnosis
        })
      }

      this.setState({
          object: obj,
          allDiagnoses: list,
          selectedDiagnoses
      })
    }

    this.setState({ modal: !this.state.modal })
    this.setState({ selectedRelevance: undefined, prevRelevance: {} })
  }

  handleChange = e => {
    e.preventDefault()
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleCheck = (e, selected) => {
    e.preventDefault()

    Object.keys(this.state.selectedDiagnoses).forEach(key => {
      if (!selected.find(item => item._id === key)) {
        delete this.state.selectedDiagnoses[key]
      }
    })

    let itemsToAdd = {}

    selected.forEach((item, index) => {
      const itemId = item._id

      if (!this.state.selectedDiagnoses[itemId]) {

        let relevance = 5

        if (this.state.prevRelevance[itemId]) {
          relevance = this.state.prevRelevance[itemId]
        }

        itemsToAdd = {
          ...itemsToAdd,
          [itemId]: {
            _id: itemId,
            relevance: relevance
          }
        }
      }
    })

    if (Object.keys(itemsToAdd).length) {
      this.setState({
        selectedDiagnoses: {
          ...this.state.selectedDiagnoses,
          ...itemsToAdd
        }
      })
    }
  }

  handleSubmit = async event => {
    event.preventDefault()

    if (window.confirm('Are you sure you want to save diagnoses?')) {
        this.state.object.selectedDiagnoses = Object.keys(this.state.selectedDiagnoses)
          .map(key => {
            return this.state.selectedDiagnoses[key]
          })

        this.toggle()

        this.props.onExit(this.state.object)
    }
  }

  onChangeDiagnosisRelevance = async event => {
    const itemId = event.currentTarget.id.split('_')[1]
    const relevance = parseInt(event.currentTarget.value, 10)

    if (this.state.selectedDiagnoses[itemId]) {
      const selectedDiagnoses = this.state.selectedDiagnoses
      selectedDiagnoses[itemId].relevance = relevance
      this.setState((state) => {
        return {
          selectedDiagnoses,
          prevRelevance: {...state.prevRelevance, [itemId]: relevance}
        }
      })
    }
  }

  makeHead = () => {
    return (
      <div className="table-head">
        <div style={{ width: '70%', paddingLeft: '15px' }}>
          <MyTableHeaderSort
            label="Title"
            active={this.state.orderBy === 'title'}
            direction={this.state.order}
            onClick={this.createSortHandler('title')}/>
        </div>
        <div style={{ width: '30%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label='Relevance' />
        </div>
      </div>
    )
  }

  setRelevanceForSeleted = () => {
    const { selectedDiagnoses = {}, selectedRelevance } = this.state
    const selectedIds = Object.keys(selectedDiagnoses)

    if (!selectedIds || !selectedIds.length || selectedRelevance === undefined ) {
      return
    }

    const prevRelevance = {}

    for (const id of selectedIds) {
      selectedDiagnoses[id].relevance = selectedRelevance
      prevRelevance[id] = selectedRelevance
    }

    this.setState((state) => {
      return { 
        selectedDiagnoses,
        prevRelevance: { ...state.prevRelevance, ...prevRelevance }
      }
    })
  }

  beforeTableHead = () => {
    const { selectedDiagnoses = {} } = this.state
    const selectedIds = Object.keys(selectedDiagnoses)

    if (!selectedIds || !selectedIds.length) {
      return null
    }

    const { selectedRelevance } = this.state
    let value = selectedRelevance
    
    if (selectedRelevance !== undefined) {
      value = { label: selectedRelevance, value: selectedRelevance }
    }

    return (
      <div className='relevance-box'>
        <Select
          className='relevance-select'
          placeholder='Relevance for selected diagnoses'
          value={value}
          onChange={({ value }) => this.setState({ selectedRelevance: value})}
          options={range(0, 6).map(n => ({ label: n, value: n }))}
        />
         <button 
          onClick={this.setRelevanceForSeleted}
          className="btn mr-2"
          type="button"
        >
          Apply
        </button>
      </div>
    )
  }

  makeRow = item => {
    return (
      <div className={'table-row'}>
        <div style={{ width: '70%', paddingLeft: '15px' }}>
          {item.title}
        </div>

        <div style={{ width: '30%', paddingLeft: '15px' }}>
          <input
            style={{ width: '40px' }}
            maxLength='1'
            id={ 'relevance_' + item._id }
            onChange={this.onChangeDiagnosisRelevance}
            type="text"
            disabled={ !this.state.selectedDiagnoses[item._id] }
            value={ (this.state.selectedDiagnoses[item._id] && this.state.selectedDiagnoses[item._id].relevance) ||
              (this.state.selectedDiagnoses[item._id] && this.state.selectedDiagnoses[item._id].relevance === 0
                ? '0' : '')} />
        </div>
      </div>
    )
  }

  render () {
    return (
      <div>
        {this.props.toggleButton}
        <button className="btn green mr-2" type="button" onClick={this.toggle}>
          { this.state.isCreating ? '+' : 'Edit' }
        </button>

        <Modal
          toggle={this.toggle}
          isOpen={this.state.modal}
        >

        <Paper style={{ width: '100%', padding: '2%' }}>

          <MyTable
            tableName={this.getTexts().tableTitle}
            tableToolBar={this.makeToolBar}
            beforeTableHead={this.beforeTableHead}
            tableHead={this.makeHead}
            tableRow={this.makeRow}
            isCheckable={true}
            handleCheck={(e, selected) => { this.handleCheck(e, selected) }}
            selected={this.state.object.selectedDiagnoses}
            hasIndex={true}
            order={this.state.order}
            orderBy={this.state.orderBy}
            data={this.state.allDiagnoses}
            rowsPerPage={10}
          />

          <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px' }}>
            <button type="button" className="btn green mr-2" onClick={ this.handleSubmit }>Confirm</button>
            <button type="button" className="btn mr-2" onClick={ this.toggle }>Cancel</button>
          </div>

        </Paper>
        </Modal>
      </div>
    )
  }
}

export default SelectRecommendedDiagnoses
