import React from 'react'
import HomePage from '../home'
import Formsy from 'formsy-react'
import MyPage from '../../components/MyPage'
import MyInput from '../../components/MyInput'
import MyTable from '../../components/MyTable'
import MyTableHeaderSort from '../../components/MyTableHeaderSort'
import QuizModal from './modal'
import ErrorMessage from '../../components/errormessage'
import DeleteIcon from '@material-ui/icons/Delete'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { actions as pollActions } from '../../redux/modules/polls'

class PollEdit extends MyPage {
  constructor(props) {
    super(props)
    const obj = this.getObject()

    this.state = {
      object: obj,
      pollTitle: obj.pollTitle,
      pollDescription: obj.pollDescription,
      pollStatisticsTitle: obj.pollStatisticsTitle,
      pollShowStatistics: obj.pollShowStatistics,
      optionsList: obj.pollOptions,
      removedOptions: [],
      errorMessage: '',
      canSubmit: false,
      showValidationErrors: false,
      isLoading: false
    }
  }

  getObject = () => {
    if (this.props.location.state && this.props.location.state.obj) {
      return this.props.location.state.obj
    } else {
      return {
        pollTitle: '',
        pollDescription: '',
        pollStatisticsTitle: '',
        pollShowStatistics: false,
        pollOptions: []
      }
    }
  }

  getTexts = () => {
    return {
      pageTitle: 'Poll'
    }
  }

  handleDeletePollOption = (e, item) => {
    if (item._id) {
      // Prepare to delete item from DB
      this.setState({
        deletePollOptionId: item._id,
        deletePollOptionText: item.pollOptionText
      })
      this.handleOpenDialog()
    } else {
      // Simply remove newly added item from array
      const newList = []
      let itemRemoved = false
      this.state.optionsList.forEach(checkItem => {
        if (checkItem.pollOptionText !== item.pollOptionText || itemRemoved) {
          newList.push(checkItem)
        } else {
          itemRemoved = true
        }
      })

      this.setState({ optionsList: newList })
    }
  }

  handleOpenDialog = () => {
    this.setState({
      openDialog: true
    })
  }

  handleCloseDialog = value => {
    this.setState({
      isLoading: false,
      openDialog: false,
      deletePollOptionId: null,
      deletePollOptionText: null
    })
  }

  handleConfirmDialog = async () => {
    // Delete poll option

    if (this.state.deletePollOptionId) {
      this.setState({
        isLoading: true
      })

      const result = await pollActions.deletePollOption(
        this.state.deletePollOptionId
      )

      if (result.success) {
        const newList = []
        this.state.optionsList.forEach(checkItem => {
          if (checkItem._id !== this.state.deletePollOptionId) {
            newList.push(checkItem)
          }
        })
        this.setState({ optionsList: newList })
      } else {
        console.error(result)
      }
    }

    this.handleCloseDialog()
  }

  handleChange = e => {
    e.preventDefault()
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleCheckboxChange = e => {
    this.setState((state, e) => {
      return {
        ...state,
        pollShowStatistics: !state.pollShowStatistics
      }
    })
  }

  cancel = e => {
    e.preventDefault()
    this.props.router.push('/polls-list')
  }

  addOptionToList = async obj => {
    const currentOptionsList = this.state.optionsList
    if (!obj._id) {
      currentOptionsList.push(obj)
    }
    this.setState({ optionsList: currentOptionsList })
  }

  save = async e => {
    e.preventDefault()
    if (window.confirm('Are you sure you want to save this Poll?')) {
      this.state.object.pollTitle = this.state.pollTitle
      this.state.object.pollDescription = this.state.pollDescription
      this.state.object.pollStatisticsTitle = this.state.pollStatisticsTitle
      this.state.object.pollShowStatistics = this.state.pollShowStatistics
      this.state.object.pollOptions = this.state.optionsList
      const result = await pollActions.savePoll(this.state.object)
      if (result) {
        this.cancel(e)
      }
    }
  }

  async componentDidMount() {
    // console.log('did moount')
  }

  makeToolBar = () => {
    return (
      <div
        className="device-tools"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <div>
          <QuizModal onExit={this.addOptionToList} />
        </div>
      </div>
    )
  }

  makeHead = () => {
    return (
      <div className={'table-head'}>
        <div style={{ width: '80%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Option" />
        </div>

        <div style={{ width: '20%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Actions" />
        </div>
      </div>
    )
  }

  makeRow = item => {
    return (
      <div className={'table-row'}>
        <div style={{ width: '80%', paddingLeft: '15px' }}>
          {item.pollOptionText}
        </div>

        <div style={{ width: '10%', paddingLeft: '15px', display: 'flex' }}>
          <QuizModal object={item} onExit={this.addOptionToList} />
        </div>

        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <button
            type="button"
            className="btn"
            onClick={e => this.handleDeletePollOption(e, item)}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
    )
  }

  render() {
    const style = { color: 'red' }
    return (
      <HomePage router={this.props.router}>
        <div className="devices-page-wrapper">
          <h3>{this.getTexts().pageTitle}</h3>
          {!this.state.isLoading && (
            <Formsy
              className="create-device-form"
              onValidSubmit={this.submit}
              onInvalidSubmit={() =>
                this.setState({ showValidationErrors: true })
              }
              onValid={() => this.setState({ canSubmit: true })}
              onInvalid={() => this.setState({ canSubmit: false })}
            >
              <div className="create-device-row">
                <label>
                  Title <span style={style}>*</span>
                </label>
                <MyInput
                  id="pollTitle"
                  name="title"
                  classNameDiv="create-branch-row"
                  className="create-device-input"
                  autocomplete="off"
                  value={this.state.pollTitle}
                  onChange={this.handleChange}
                  required
                  showError={this.state.showValidationErrors}
                />
              </div>
              <div className="create-device-row">
                <label>
                  Description <span style={style}>*</span>
                </label>
                <MyInput
                  id="pollDescription"
                  name="description"
                  classNameDiv="create-branch-row"
                  className="create-device-input"
                  autocomplete="off"
                  value={this.state.pollDescription}
                  onChange={this.handleChange}
                  required
                  showError={this.state.showValidationErrors}
                />
              </div>
              <div className="create-device-row">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="pollShowStatistics"
                      name="pollShowStatistics"
                      checked={this.state.pollShowStatistics}
                      onChange={this.handleCheckboxChange}
                      value="pollShowStatistics"
                      color="primary"
                    />
                  }
                  label={
                    <span className="create-checkbox-label">
                      Show poll statistics in the dashboard
                    </span>
                  }
                />
              </div>
              <div
                className={
                  this.state.pollShowStatistics
                    ? 'create-device-row'
                    : 'create-device-row hidden'
                }
              >
                <label>Title of the statistic report</label>
                <MyInput
                  id="pollStatisticsTitle"
                  name="pollStatisticsTitle"
                  classNameDiv="create-branch-row"
                  className="create-device-input"
                  autocomplete="off"
                  value={this.state.pollStatisticsTitle}
                  onChange={this.handleChange}
                  showError={this.state.showValidationErrors}
                />
              </div>
              <div className="create-device-row">
                <label>Options</label>
                <MyTable
                  tableToolBar={this.makeToolBar}
                  tableHead={this.makeHead}
                  tableRow={this.makeRow}
                  hasIndex={true}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  data={this.state.optionsList}
                />
              </div>
              <ErrorMessage text={this.state.errorMessage} />
              <button
                className="btn green mr-2"
                type="submit"
                onClick={this.state.canSubmit ? this.save : undefined}
              >
                Save
              </button>
              <button
                className="btn blue mr-2"
                type="button"
                onClick={this.cancel}
              >
                Cancel
              </button>
            </Formsy>
          )}
        </div>
        <Dialog
          onClose={this.handleCloseDialog}
          open={this.state.openDialog}
          aria-labelledby="delete-dialog-title"
        >
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to delete option "
            {this.state.deletePollOptionText}"?
          </DialogTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 24px 24px 24px'
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleCloseDialog}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleConfirmDialog}
            >
              Ok
            </Button>
          </div>
        </Dialog>
      </HomePage>
    )
  }
}

export default PollEdit
