import React from 'react'

function getBlockLabel (key) {
  switch (key) {
    case 'today':
      return 'Today'
    case 'yesterday':
      return 'Yesterday'
    case 'lastWeek':
      return 'Last week'
    case 'lastMonth':
      return 'Last month'
    case 'lastThreeMonth':
      return 'Last tree month'
    default:
      return 'Default Label'
  }
}

function SubscriptionsStatistics (props) {
  const statistics = []

  for (let key in props.subscriptionsStatistic) {
    const block =
      <table key={getBlockLabel(key)} style={{ width: '33%', marginBottom: '16px' }}>
        <tbody>
          <tr>
            <th>{getBlockLabel(key)}</th>
            <td></td>
          </tr>
          <tr>
            <td>total</td>
            <td>{props.subscriptionsStatistic[key].total}</td>
          </tr>
          <tr>
            <td>one month subscriptions</td>
            <td>{props.subscriptionsStatistic[key].monthly}</td>
          </tr>
          <tr>
            <td>one year subscriptions</td>
            <td>{props.subscriptionsStatistic[key].yearly}</td>
          </tr>
          <tr>
            <td>lifetime subscriptions</td>
            <td>{props.subscriptionsStatistic[key].lifetime}</td>
          </tr>
          <tr>
            <td>one-off lifetime subscriptions</td>
            <td>{props.subscriptionsStatistic[key].oneOffLifetime}</td>
          </tr>
        </tbody>
      </table>
    statistics.push(block)
  }

  return (
    <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
      {statistics}
    </div>
  )
}

export { SubscriptionsStatistics }
