import React from 'react';
import HomePage from '../home';
import MyPage from '../../components/MyPage';
import MyTable from '../../components/MyTable';
import MyTableHeaderSort from '../../components/MyTableHeaderSort';
import { SearchIcon } from '../../Icons/svg';

import formatDate from '../../utils/formatDate';
import { actions as adminActions } from '../../redux/modules/admin';


class AdminList extends MyPage {

  constructor(props) {
    super(props);
    this.state = {
      fullList: [],
      filteredList: [],
      isLoading: true,
      searchKeyword: '',
      order: 'asc',
      orderBy: '',
    };
  }

  getList = async () => {
    const data = await adminActions.getAdminList();
    return data;
  }

  getTexts = () => {
    return {
      tableTitle: "Admins",
      searchTitle: "Search Admin",
    }
  }

  edit = (e, obj) => {
    e.preventDefault();
    this.props.router.push({
      pathname: '/admin-edit',
      state: { obj: obj, selfEdit: false }
    });
  }

  handleSearch = (e) => {
    e.preventDefault();
    let searchKeyword = e.target.value;
    this.setState({searchKeyword}, () => {
      const regexp = new RegExp(searchKeyword.replace(/[-+*.?|^$/()[\]{}\\]/g, "\\$&"), "gi");
      let filteredList = this.state.fullList.filter(object => {
        return (object.name.match(regexp) || object.lastName.match(regexp) || object.email.match(regexp))
      });
      this.setState({ filteredList });
    });
  }

  async componentDidMount() {
    const list = await this.getList();
    this.setState({
      fullList: list,
      filteredList: list,
      isLoading: false,
    });
  }

  makeToolBar = () => {
    return (
      <div className='device-tools'>
        <div className='search-icon'>
          <SearchIcon/>
          <input className='device-search' onChange={this.handleSearch} placeholder={this.getTexts().searchTitle} tabIndex="-1"/>
        </div>
        <div>
          <button className={"btn green mr-2"} onClick={this.edit}>+</button>
        </div>
      </div>
    );
  }

  makeHead = () => {
    return (
      <div className={"table-head"}>

        <div style={{ width: '20%', paddingLeft: "15px" }}>
          <MyTableHeaderSort
            label="First Name"
            active={this.state.orderBy === "firstName"}
            direction={this.state.order}
            onClick={this.createSortHandler("firstName")}
          />
        </div>

        <div style={{ width: '20%', paddingLeft: "15px" }}>
          <MyTableHeaderSort
            label="Last Name"
            active={this.state.orderBy === "lastName"}
            direction={this.state.order}
            onClick={this.createSortHandler("lastName")}
          />
        </div>

        <div style={{ width: '40%', paddingLeft: "15px" }}>
          <MyTableHeaderSort
            label="Email"
            active={this.state.orderBy === "email"}
            direction={this.state.order}
            onClick={this.createSortHandler("email")}
          />
        </div>


        {/* <div style={{ width: '20%', paddingLeft: "15px" }}>
          <MyTableHeaderSort
            label="Creation Date"
            active={this.state.orderBy === "createdDate"}
            direction={this.state.order}
            onClick={this.createSortHandler("createdDate")}
          />
        </div> */}

        <div style={{ width: '20%', paddingLeft: "15px" }}>
          <MyTableHeaderSort label="Actions" />
        </div>

      </div>
    );
  }

  makeRow = (item) => {
    return (
      <div className={"table-row"}>
        <div style={{ width: "20%", paddingLeft: "15px" }}>{item.firstName}</div>
        <div style={{ width: "20%", paddingLeft: "15px" }}>{item.lastName}</div>
        <div style={{ width: "40%", paddingLeft: "15px" }}>{item.email}</div>
        {/* <div style={{ width: "20%", paddingLeft: "15px" }}>{formatDate(item.createdDate)}</div> */}
        <div style={{ width: "20%", paddingLeft: "15px" }}>
          <button type="button" className="btn" onClick={(e) => this.edit(e, item)}>Edit</button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <HomePage router={this.props.router}>
        <div className='device-table-container'>
          {! this.state.isLoading &&
            <MyTable
              tableName={this.getTexts().tableTitle}
              tableToolBar={this.makeToolBar}
              tableHead={this.makeHead}
              tableRow={this.makeRow}
              // isDraggable={true}
              // isCheckable={true}
              hasIndex={true}
              order={this.state.order}
              orderBy={this.state.orderBy}
              data={this.state.filteredList}
              hasPagination={true}
              rowsPerPage={10}
            />
          }
        </div>
      </HomePage>
    );
  }
}

export default AdminList;
