import SessionList from '../../components/SessionList'
import { actions as sessionActions } from '../../redux/modules/sessions'
// import config from '../../config'

class BodySessionsList extends SessionList {
  constructor (props) {
    super(props)
    this.state.pageType = 'meTime'
  }

  getTexts = () => {
    return {
      tableTitle: 'Body Sessions',
      searchTitle: 'Search Session'
    }
  }

  getList = async () => {
    const list = await sessionActions.getMeTimeSessionsList('body')
    return list
  }

  getEditRoute = () => {
    return '/session-body-edit'
  }
}

export default BodySessionsList
