import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button } from '@material-ui/core'

const DeleteDialog = ({ open, title, handleConfirm, handleCancel }) => {
  return (
    <Dialog onClose={handleCancel} open={open} aria-labelledby="delete-dialog-title">
      <DialogTitle id="simple-dialog-title">{title || ''}</DialogTitle>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 24px 24px 24px'
        }}
      >
        <Button variant="contained" color="primary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={handleConfirm}>
          Ok
        </Button>
      </div>
    </Dialog>
  )
}

DeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired
}

export default DeleteDialog
