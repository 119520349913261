import React from 'react'
import HomePage from '../home'
import Dropzone from 'react-dropzone'
import Formsy from 'formsy-react'
import MyPage from '../../components/MyPage'
import MyInput from '../../components/MyInput'
import ErrorMessage from '../../components/errormessage'
import imageLib from '../..//themes'
import { urlImg } from '../../redux/fetch/urlHelper'
import CloseIcon from '@material-ui/icons/Close'

import { actions as authorActions } from '../../redux/modules/authors'

class AuthorEdit extends MyPage {
  constructor (props) {
    super(props)
    const obj = this.getObject()
    this.state = {
      object: obj,
      name: obj.name,
      description: obj.description ? obj.description : '',
      imageFileName: obj.imageFileName,
      imageFileName64: obj.imageFileName64 ? obj.imageFileName64 : '',
      imageURL: obj.imageURL,
      canSubmit: false,
      showValidationErrors: false,
      errorMessage: '',
    }
  }

  getObject = () => {
    if (this.props.location.state && this.props.location.state.obj) {
      return this.props.location.state.obj
    } else {
      return {
        name: '',
        description: '',
        imageFileName: '',
        imageURL: ''
      }
    }
  }

  getTexts = () => {
    return {
      pageTitle: 'Author',
    }
  }

  // onChangeImage = (e, index) => {
  //   this.handleFileImage(e.target.files[0], index);
  // }

  cancel = e => {
    e.preventDefault()
    this.props.router.push('/author-list')
  }

  save = async e => {
    e.preventDefault()
    if (window.confirm('Are you sure you want to save this author?')) {
      this.state.object.name = this.state.name
      this.state.object.description = this.state.description
      this.state.object.imageFileName = this.state.imageFileName
      this.state.object.imageFileName64 = this.state.imageFileName64
      this.state.object.imageURL = this.state.imageURL
      const result = await authorActions.saveAuthor(this.state.object)
      if (result.success) {
        this.setState({ errorMessage: '' })
        this.cancel(e)
      } else {
        this.setState({ errorMessage: result.message })
      }
    }
  }

  render () {
    const style = { color: 'red' }
    let iimm = this.state.imageFileName === '' ? imageLib.noImage : urlImg(this.state.imageFileName)
    return (
      <HomePage router={this.props.router} addClassName="h-100">
        <div className="devices-page-wrapper w-100 pl-3 pr-3">
          <h3>{this.getTexts().pageTitle}</h3>
          <Formsy className='create-device-form'
            onValidSubmit={this.submit}
            onInvalidSubmit={() => this.setState({ showValidationErrors: true })}
            onValid={() => this.setState({ canSubmit: true })}
            onInvalid={() => this.setState({ canSubmit: false })}
          >
            <div className='create-device-row'>
              <label>Name <span style={style}>*</span></label>
              <MyInput
                id="name"
                name="name"
                classNameDiv='create-branch-row'
                className='create-device-input'
                autocomplete="off"
                value={this.state.name}
                onChange={this.handleChange}
                required
                showError={this.state.showValidationErrors}
              />
            </div>

            <div className='create-device-row'>
              <label>Description</label>
              <textarea
                id="description"
                name="description"
                className='create-device-input'
                rows={5}
                value={this.state.description}
                onChange={this.handleChange}
              />
            </div>

            <div className='create-device-row'>
              <label>Image <span style={style}>*</span></label>
              <div className='div-row'>
                <Dropzone
                  className="upload-border"
                  onDrop={files => { this.handleDropImage(files, 'imageFileName', 'imageFileNameError') }}
                  // onChange={(e) => this.onChangeImage(e, 0)}
                  accept="image/jpeg,image/jpg,image/tiff,image/gif,image/png"
                  multiple={false}
                  type={'file'}>
                    <span className="w-100" style={{ textAlign: 'center', marginBottom: '5px' }}>
                      Drag and drop file or
                    </span>
                    <button className="btn blue" type="button">BROWSE</button>
                </Dropzone>
                <div style={{ textAlign: 'right', position: 'relative' }}>
                  {this.state.imageFileName !== '' &&
                    <button className="btn delete-btn" type="button" onClick={() =>
                      this.deleteUploadFile('imageFileName', 'imageFileNameError')}>
                      <CloseIcon />
                    </button>
                  }
                  <img style={{
                    width: 180,
                    height: 180,
                    objectFit: 'contain',
                    border: '1px solid #e6e9ec',
                    marginLeft: '20px' }}
                    src={iimm} alt="" />
                </div>
              </div>
              <ErrorMessage text={this.state.imageFileNameError} />
              <MyInput
                id="imageFileName"
                name="imageFileName"
                classNameDiv='create-branch-row'
                className='create-device-input'
                value={this.state.imageFileName}
                hidden
                disabled
                required
                showError={this.state.showValidationErrors}
              />
            </div>

            <ErrorMessage text={this.state.errorMessage}/>
            <div className="w-100 d-flex justify-content-end">
              <button className='btn green mr-2' type="submit" onClick={this.state.canSubmit ? this.save : undefined}>
                Save
              </button>
              <button className='btn blue mr-2' type="button" onClick={this.cancel}>Cancel</button>
            </div>
          </Formsy>
        </div>
      </HomePage>
    )
  }
}

export default AuthorEdit
