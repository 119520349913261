import React from 'react'
import HomePage from '../home'
import Dropzone from 'react-dropzone'
import Formsy from 'formsy-react'
import MyPage from '../../components/MyPage'
import MyInput from '../../components/MyInput'
import MySelect from '../../components/MySelect'
import imageLib from '../..//themes'
import { urlImg } from '../../redux/fetch/urlHelper'
import CloseIcon from '@material-ui/icons/Close'
import ErrorMessage from '../../components/errormessage'

import { actions as topicActions } from '../../redux/modules/topics'
import config from '../../config'

class TopicEdit extends MyPage {
  constructor (props) {
    super(props)
    const obj = this.getObject()
    this.state = {
      object: obj,
      title: obj.title,
      description: obj.description,
      section: obj.section,
      isFeatured: obj.isFeatured ? obj.isFeatured : false,
      imageFileName: obj.imageFileName,
      imageFileName64: obj.imageFileName64 ? obj.imageFileName64 : '',
      imageURL: obj.imageURL,
      canSubmit: false,
      showValidationErrors: false
    }
  }

  getObject = () => {
    if (this.props.location.state && this.props.location.state.obj) {
      return this.props.location.state.obj
    } else {
      return {
        title: '',
        description: '',
        index: null,
        section: null,
        isFeatured: false,
        imageFileName: '',
        imageURL: ''
      }
    }
  }

  getTexts = () => {
    return {
      pageTitle: 'Topic'
    }
  }

  cancel = e => {
    e.preventDefault()
    this.props.router.push('/topic-list')
  }

  save = async e => {
    e.preventDefault()
    this.state.object.title = this.state.title
    this.state.object.description = this.state.description
    if (this.state.section) {
      this.state.object.section = this.state.section
    }
    this.state.object.isFeatured = this.state.isFeatured
    this.state.object.imageFileName = this.state.imageFileName
    this.state.object.imageFileName64 = this.state.imageFileName64
    this.state.object.imageURL = this.state.imageURL
    const result = await topicActions.saveTopic(this.state.object)
    if (result) {
      this.cancel(e)
    }
  }

  render () {
    const style = { color: 'red' }
    let iimm =
      this.state.imageFileName === ''
        ? imageLib.noImage
        : urlImg(this.state.imageFileName)
    return (
      <HomePage router={this.props.router}>
        <div className="devices-page-wrapper">
          <h3>{this.getTexts().pageTitle}</h3>
          <Formsy
            className="create-device-form"
            onValidSubmit={this.submit}
            onInvalidSubmit={() =>
              this.setState({ showValidationErrors: true })
            }
            onValid={() => this.setState({ canSubmit: true })}
            onInvalid={() => this.setState({ canSubmit: false })}
          >
            <div className="create-device-row">
              <label>
                Title <span style={style}>*</span>
              </label>
              <MyInput
                id="title"
                name="title"
                classNameDiv="create-branch-row"
                className="create-device-input"
                autocomplete="off"
                value={this.state.title}
                onChange={this.handleChange}
                required
                showError={this.state.showValidationErrors}
              />
            </div>

            <div className="create-device-row">
              <label>
                Description
              </label>

              <textarea
                id="description"
                name="description"
                className='create-device-input'
                rows={5}
                value={this.state.description}
                onChange={this.handleChange}
              />
            </div>

            { config.feature.mindAndBody
              ? <div className="create-device-row">
                <label>Display in section</label>
                <MySelect
                  stateField="section"
                  className="create-branch-select"
                  value={this.state.section}
                  options={[
                    { value: 'mind', label: 'Mind' },
                    { value: 'body', label: 'Body' }
                  ]}
                  onChange={this.handleSelect}
                />
              </div>
            : null }

            <div className="create-device-row">
              <label>Displayed in Featured Topics</label>
              <MySelect
                stateField="isFeatured"
                className="create-branch-select"
                value={this.state.isFeatured}
                options={[
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No' }
                ]}
                onChange={this.handleSelect}
              />
            </div>

            <div className="create-device-row">
              <label>
                Image <span style={style}>*</span>
              </label>
              <div className="div-row">
                <Dropzone
                  className="upload-border"
                  onDrop={files => {
                    this.handleDropImage(
                      files,
                      'imageFileName',
                      'imageFileNameError'
                    )
                  }}
                  // onChange={(e) => this.onChangeImage(e, 0)}
                  accept="image/jpeg,image/jpg,image/tiff,image/gif,image/png"
                  multiple={false}
                  type={'file'}
                >
                  <span
                    className="w-100"
                    style={{ textAlign: 'center', marginBottom: '5px' }}
                  >
                    Drag and drop file or{' '}
                  </span>
                  <button className="btn blue" type="button">
                    BROWSE
                  </button>
                </Dropzone>
                <div style={{ textAlign: 'right', position: 'relative' }}>
                  {this.state.imageFileName !== '' && (
                    <button
                      className="btn delete-btn"
                      type="button"
                      onClick={() =>
                        this.deleteUploadFile(
                          'imageFileName',
                          'imageFileNameError'
                        )
                      }
                    >
                      <CloseIcon />
                    </button>
                  )}
                  <img
                    style={{
                      width: 180,
                      height: 180,
                      objectFit: 'contain',
                      border: '1px solid #e6e9ec',
                      marginLeft: '20px'
                    }}
                    src={iimm}
                    alt=""
                  />
                </div>
              </div>
              <ErrorMessage text={this.state.imageFileNameError} />
              <MyInput
                id="imageFileName"
                name="imageFileName"
                classNameDiv="create-branch-row"
                className="create-device-input"
                value={this.state.imageFileName}
                hidden
                disabled
                required
                showError={this.state.showValidationErrors}
              />
            </div>

            <button
              className="btn green mr-2"
              type="submit"
              onClick={this.state.canSubmit ? this.save : undefined}
            >
              Save
            </button>
            <button
              className="btn blue mr-2"
              type="button"
              onClick={this.cancel}
            >
              Cancel
            </button>
          </Formsy>
        </div>
      </HomePage>
    )
  }
}

export default TopicEdit
