import { fetchFromUrlPOSTAsync, fetchFromUrlGETAsync, fetchFromUrlPATCHAsync, fetchFromUrlDELETEAsync } from '../fetch'

const routeBase = 'success-stories'

export const actions = {
  getList: async () => {
    const urlParams = new URLSearchParams('')
    let result = {}

    // if (type) {
    //   urlParams.append('type', type)
    // }


    // if (urlParams) {
    //   result = await fetchFromUrlGETAsync(`/${routeBase}/get-list?${urlParams}`)
    // } else {
      result = await fetchFromUrlGETAsync(`/${routeBase}/get-list`, [])
    // }

    return result.success ? result.data : []
  },

  getListWithDiagnoses: async () => {
    const urlParams = new URLSearchParams('')
    let result = {}

    result = await fetchFromUrlGETAsync(`/${routeBase}/get-list-with-diagnoses`, [])

    return result.success ? result.data : []
  },

  save: async saveData => {
    let urlParams = [{ key: 'saveData', value: saveData }]

    if (saveData._id) {
      const result = await fetchFromUrlPATCHAsync(`/${routeBase}/` + saveData._id, urlParams)
      return result
    } else {
      const result = await fetchFromUrlPOSTAsync(`/${routeBase}`, urlParams)
      return result
    }
  },

  deleteStory: async storyId => {
    const result = await fetchFromUrlDELETEAsync(`/${routeBase}/` + storyId)
    return result
  }
}
