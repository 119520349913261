import React from 'react'
import HomePage from '../home'
import Dropzone from 'react-dropzone'
import Formsy from 'formsy-react'
import MyPage from '../../components/MyPage'
import MyInput from '../../components/MyInput'
import MySelect from '../../components/MySelect'
import imageLib from '../../themes'
import { urlImg } from '../../redux/fetch/urlHelper'
import CloseIcon from '@material-ui/icons/Close'
import ErrorMessage from '../../components/errormessage'

import SelectRecommendedDiagnosesModal from '../SelectRecommendedDiagnoses'

import { actions as topicActions } from '../../redux/modules/topics'

import config from '../../config'

class SubtopicEdit extends MyPage {
  constructor (props) {
    super(props)
    const obj = this.getObject()
    this.state = {
      object: obj,
      title: obj.title,
      diagnoses: obj.diagnoses,
      imageFileName: obj.imageFileName,
      imageFileName64: obj.imageFileName64 ? obj.imageFileName64 : '',
      canSubmit: false,
      showValidationErrors: false,
      isLoading: true
    }
  }

  getObject = () => {
    if (this.props.location.state && this.props.location.state.obj) {
      return this.props.location.state.obj
    } else {
      return {
        title: '',
        diagnoses: [],
        index: null,
        imageFileName: ''
      }
    }
  }

  getTexts = () => {
    return {
      pageTitle: 'Subtopic'
    }
  }

  handleSelect = (stateId, value) => {
    this.setState({ [stateId]: value })
  }

  onCloseSelectDiagnoses = () => {
    this.setState({ selectedDiagnoses: this.state.object.selectedDiagnoses })
  }

  cancel = e => {
    e.preventDefault()
    this.props.router.push('/subtopic-list')
  }

  save = async e => {
    e.preventDefault()
    this.state.object.title = this.state.title
    if (config.feature.recommendations) {
      this.state.object.diagnoses = this.state.selectedDiagnoses.map(diagnosis => {
        return {
          diagnosisId: diagnosis._id,
          relevance: diagnosis.relevance
        }
      })
    }
    this.state.object.imageFileName = this.state.imageFileName
    this.state.object.imageFileName64 = this.state.imageFileName64

    const result = await topicActions.saveSubtopic(this.state.object)
    if (result) {
      this.cancel(e)
    }
  }

  async componentDidMount () {
    const selectedDiagnoses = this.state.object.diagnoses || []
    this.state.object.selectedDiagnoses = selectedDiagnoses.map(diagnosis => {
      return {
        _id: diagnosis.diagnosisId,
        relevance: diagnosis.relevance
      }
    })

    this.setState({
      selectedDiagnoses,
      isLoading: false
    })
  }

  render () {
    const style = { color: 'red' }

    return (
      <HomePage router={this.props.router}>
        { !this.state.isLoading &&
        <div className="devices-page-wrapper">
          <h3>{this.getTexts().pageTitle}</h3>
          <Formsy
            className="create-device-form"
            onValidSubmit={this.submit}
            onInvalidSubmit={() =>
              this.setState({ showValidationErrors: true })
            }
            onValid={() => this.setState({ canSubmit: true })}
            onInvalid={() => this.setState({ canSubmit: false })}
          >
            <div className="create-device-row">
              <label>
                Title <span style={style}>*</span>
              </label>
              <MyInput
                id="title"
                name="title"
                classNameDiv="create-branch-row"
                className="create-device-input"
                autocomplete="off"
                value={this.state.title}
                onChange={this.handleChange}
                required
                showError={this.state.showValidationErrors}
              />
            </div>

            { config.feature.recommendations
                ? <div className='create-device-row'>
                  <label>Recommended Diagnoses</label>

                  <SelectRecommendedDiagnosesModal
                    object={this.state.object}
                    authorId={this.state.authorId}
                    onExit={this.onCloseSelectDiagnoses} />

                </div>
                : null }

            <button
              className="btn green mr-2"
              type="submit"
              onClick={this.state.canSubmit ? this.save : undefined}
            >
              Save
            </button>
            <button
              className="btn blue mr-2"
              type="button"
              onClick={this.cancel}
            >
              Cancel
            </button>
          </Formsy>
        </div>
        }
      </HomePage>
    )
  }
}

export default SubtopicEdit
