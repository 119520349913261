import { withFormsy } from 'formsy-react';
import React from 'react';
import ErrorMessage from '../errormessage';

class MyInput extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      // hasChanged: false,
      validationErrors: {},
    }
  }

  changeValue = (e) => {
    this.props.setValue(e.currentTarget.value);
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  render() {
    let errorMessage = this.props.getErrorMessage();

    // if (this.props.getValue() === undefined) {
    if (this.props.getValue === undefined) {
      console.error(`Couldn't get value for ${this.props.id} input`);
    }


    // console.log("this.props.getValue()", this.props.getValue());
    
    

    if (this.props.required && (this.props.getValue() === undefined || this.props.getValue() === null ||
      this.props.getValue().length === 0)) {
      errorMessage = "This field is required";
    }
    return (
      <div className={this.props.classNameDiv}>
        <input
          id={this.props.id}
          name={this.props.name}
          type={this.props.type}
          value={this.props.value}
          // onChange={this.props.onChange ? this.props.onChange : () => {}}
          onChange={this.changeValue}
          className={`${this.props.className} ${this.props.disabled ? 'disabled-input' : ''} `}
          hidden={this.props.hidden}
          disabled={this.props.disabled !== undefined ? this.props.disabled : false}
        />
        {this.props.showError &&
          <ErrorMessage text={errorMessage}/>
        }
      </div>
    );
  }
}

export default withFormsy(MyInput);


// class App extends React.Component {
//   constructor(props) {
//     super(props);
//     this.submit = this.submit.bind(this);
//   }

//   submit(data) {
//     alert(JSON.stringify(data, null, 4));
//   }

//   render() {
//     return (
//       <Formsy onSubmit={this.submit} className="custom-validation">
//         <MyInput name="year" title="Year of Birth" type="number" validations="test" validationError="Please type your year of birth" />
//         <FormsyDynamicInput name="dynamic" title="..." />
//         <button type="submit">Submit</button>
//       </Formsy>
//     );
//   }
// }

// class DynamicInput extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { validationType: 'time' };
//     this.changeValidation = this.changeValidation.bind(this);
//     this.changeValue = this.changeValue.bind(this);
//   }

//   changeValidation(validationType) {
//     this.setState({ validationType: validationType });
//     this.props.setValue(this.props.getValue());
//   }

//   changeValue(event) {
//     this.props.setValue(event.currentTarget.value);
//   }

//   render() {
//     const errorMessage = {
//       time: 'Not a valid time format',
//       decimal: "Not a valid decimal value",
//       binary: "Not a valid binary number"
//     }

//     return (
//       <div>
//         <label htmlFor={this.props.name}>{this.props.title}</label>
//         <MyInput validations={this.state.validationType} validationError={errorMessage[this.state.validationType]} type='text' name={this.props.name} onChange={this.changeValue} value={this.props.getValue() || ''} />
//         <Validations validationType={this.state.validationType} changeValidation={this.changeValidation} />
//       </div>
//     );
//   }
// }

// DynamicInput.displayName = "dynamic input";

// DynamicInput.propTypes = {
//   ...propTypes,
// };

// const FormsyDynamicInput = withFormsy(DynamicInput);

// class Validations extends React.Component {
//   constructor(props) {
//     super(props);
//     this.changeValidation = this.changeValidation.bind(this);
//   }

//   changeValidation(e) {
//     this.props.changeValidation(e.target.value);
//   }

//   render() {
//     const { validationType } = this.props;
//     return (
//       <fieldset>
//         <legend>Validation Type</legend>
//         <div>
//           <input onChange={this.changeValidation} name='validationType' type='radio' value='time' checked={validationType === 'time'} />Time
//         </div>
//         <div>
//           <input onChange={this.changeValidation} name='validationType' type='radio' value='decimal' checked={validationType === 'decimal'} />Decimal
//         </div>
//         <div>
//           <input onChange={this.changeValidation} name='validationType' type='radio' value='binary' checked={validationType === 'binary'} />Binary
//         </div>
//       </fieldset>
//     );
//   }
// }

