import React from 'react'
import HomePage from '../home'
import MyPage from '../../components/MyPage'
import MyTable from '../../components/MyTable'
import MyTableHeaderSort from '../../components/MyTableHeaderSort'
// import { SearchIcon } from '../../Icons/svg'
import DeleteIcon from '@material-ui/icons/Delete'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button } from '@material-ui/core'
import ErrorMessage from '../../components/errormessage'

import { actions as storiesActions } from '../../redux/modules/successStories'

class SuccessStoriesList extends MyPage {
  constructor (props) {
    super(props)
    this.state = {
      errorMessage: '',
      fullList: [],
      filteredList: [],
      isLoading: true,
      openDialog: false,
      deleteStoryId: null,
      deleteStoryTitle: null,
      order: 'asc',
      orderBy: ''
    }
  }

  getList = async () => {
    const data = await storiesActions.getList()
    return data
  }

  getTexts = () => {
    return {
      tableTitle: 'Success Stories'
    }
  }

  edit = (e, obj) => {
    e.preventDefault()
    this.props.router.push({
      pathname: '/success-stories-edit',
      state: { obj: obj }
    })
  }
  
  handleDeleteStory = (e, item) => {
    this.setState({
      deleteStoryId: item._id,
      deleteStoryTitle: item.title
    })
    this.handleOpenDialog()
  }
  
  
  handleOpenDialog = () => {
    this.setState({
      openDialog: true,
    })
  }

  handleCloseDialog = value => {
    this.setState({
      isLoading: false,
      openDialog: false,
      deleteStoryId: null,
      deleteStoryTitle: null
    })
  }

  handleConfirmDialog = async () => {
    // Delete story
    if (this.state.deleteStoryId) {
      this.setState({
        isLoading: true
      })

      const result = await storiesActions.deleteStory(this.state.deleteStoryId)

       if (result.success) {
        const list = await this.getList()
        this.setState({
          errorMessage: '',
          isLoading: false,
          fullList: list,
          filteredList: list
        })
      } else {
        this.setState({ errorMessage: result.error })
      }
    }

    this.handleCloseDialog()
  }
  
  async componentDidMount () {
    const list = await this.getList()
    this.setState({
      fullList: list,
      filteredList: list,
      isLoading: false
    })
  }

  makeToolBar = () => {
    return (
      <div className='device-tools'>
        <div>
          <button className={'btn green mr-2'} onClick={this.edit}>+</button>
        </div>
      </div>
    )
  }

  makeHead = () => {
    return (
      <div className={'table-head'}>
        <div style={{ width: '80%', paddingLeft: '15px' }}>
          <MyTableHeaderSort
            label="Title"
            active={this.state.orderBy === 'title'}
            direction={this.state.order}
            onClick={this.createSortHandler('title')}
          />
        </div>

        <div style={{ width: '20%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Actions" />
        </div>
      </div>
    )
  }

  makeRow = item => {
    return (
      <div className={'table-row'}>
        <div style={{ width: '80%', paddingLeft: '15px' }}>
          {item.title}
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <button
            type="button"
            className="btn"
            onClick={e => this.edit(e, item)}
          >
            Edit
          </button>
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <button
            type="button"
            className="btn"
            onClick={e => this.handleDeleteStory(e, item)}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
    )
  }

  render () {
    return (
      <HomePage router={this.props.router} addClassName="h-100">
        <div className="device-table-container">
          <ErrorMessage text={this.state.errorMessage} />
          {!this.state.isLoading && (
            <MyTable
              tableName={this.getTexts().tableTitle}
              tableToolBar={this.makeToolBar}
              tableHead={this.makeHead}
              tableRow={this.makeRow}
              isDraggable={false}
              hasIndex={true}
              order={this.state.order}
              orderBy={this.state.orderBy}
              data={this.state.filteredList}
              hasPagination={true}
              rowsPerPage={25}
            />
          )}
        </div>
        <Dialog
          onClose={this.handleCloseDialog}
          open={this.state.openDialog}
          aria-labelledby="delete-dialog-title"
        >
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to delete story "{this.state.deleteStoryTitle}"?
          </DialogTitle>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 24px 24px 24px'
          }}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleCloseDialog}>Cancel</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleConfirmDialog}>Ok</Button>
          </div>
        </Dialog>
      </HomePage>
    )
  }
}

export default SuccessStoriesList
