import { fetchFromUrlPOSTAsync } from '../fetch'

export const actions = {
  getPollsList: async () => {
    const result = await fetchFromUrlPOSTAsync('/polls/registration-polls', [])
    return result.success ? result.result : []
  },

  getOptionsList: async pollId => {
    let urlParams = [
      { key: 'pollId', value: pollId },
    ]
    const result = await fetchFromUrlPOSTAsync('/polls/get-options', urlParams)
    return result.success ? result.data : []
  },

  deletePoll: async pollId => {
    let urlParams = [{ key: 'pollId', value: pollId }]

    const result = await fetchFromUrlPOSTAsync('/polls/delete-poll', urlParams)
    return result
  },

  deletePollOption: async pollOptionId => {
    let urlParams = [{ key: 'pollOptionId', value: pollOptionId }]

    const result = await fetchFromUrlPOSTAsync('/polls/delete-poll-option', urlParams)
    return result
  },

  savePoll: async saveData => {
    let urlParams = [{ key: 'saveData', value: saveData }]

    if (saveData._id) {
      const result = await fetchFromUrlPOSTAsync('/polls/update-poll', urlParams)
      return result
    } else {
      const result = await fetchFromUrlPOSTAsync('/polls/add-poll', urlParams)
      return result
    }
  },

  savePollsList: async data => {
    let saveData = data.map(item => {
      return {
        _id: item._id,
        index: item.index
      }
    })

    let urlParams = [{ key: 'saveData', value: saveData }]
    const result = await fetchFromUrlPOSTAsync('/polls/save-polls-list', urlParams)
    return result
  }
}
