import React from 'react';
import { Modal } from 'reactstrap';
import Dropzone from 'react-dropzone';
import Formsy from 'formsy-react';

import Paper from '@material-ui/core/Paper';

import MyPage from '../../components/MyPage';
import MyInput from '../MyInput';
import ErrorMessage from '../../components/errormessage';
import CloseIcon from '@material-ui/icons/Close';

import { urlImg } from "../../redux/fetch/urlHelper";
import imageLib from '../../themes';

class SourceModal extends MyPage {
    constructor(props) {
        super(props);
        const obj = this.getObject();
        this.state = {
            modal: false,
            isCreating: ! this.props.object ? true : false,
            index: this.props.index,
            object: obj,
            source_text: obj.source_text,
            source_link: obj.source_link,
            sourceError: "",
            canSubmit: true,
            showValidationErrors: false,
        };
    }

    getObject = () => {
        if (this.props.object) {
            const obj = {
                source_text: this.props.object.source_text,
                source_link: this.props.object.source_link,
            };
            return obj;
        } else {
            return {
                source_text: "",
                source_link: "",
            };
        }
    }

    getTexts = () => {
        return {
            tableTitle: "Sessions",
        }
    }

    toggle = async () => {
        if (!this.state.modal) {
            const obj = this.getObject();
            console.log("LOAD", obj);
            this.setState({
                index: this.props.index,
                object: obj,
                source_text: obj.source_text,
                source_link: obj.source_link,
                sourceError: "",
                canSubmit: true,
                showValidationErrors: false,
            });
        }
        this.setState({ modal: !this.state.modal });
    }

    handleChange = (e) => {
        e.preventDefault();

        let allowChange = true;
        if (allowChange) {
            this.setState({
                [e.target.id]: e.target.value,
            });
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        
        this.state.object.source_text = this.state.source_text;
        this.state.object.source_link = this.state.source_link;

            this.setState({ sourceError: "" });
            this.toggle();

            this.props.onExit(this.state.object, this.state.index);
        
    }

    render() {
        return (
            <div>
                {this.props.toggleButton}
                <button className="btn green mr-2" type="button" onClick={this.toggle}>
                    {this.state.isCreating ? "+" : "Edit"}
                </button>

                <Modal
                    toggle={this.toggle}
                    isOpen={this.state.modal}
                >
                    <Paper style={{ width: '100%', padding: '2%' }}>

                        <Formsy className='create-device-form'
                            onValidSubmit={this.submit}
                            onInvalidSubmit={() => this.setState({ showValidationErrors: true })}
                            onValid={() => this.setState({ canSubmit: true })}
                            onInvalid={() => this.setState({ canSubmit: false })}
                        >
                            
                            
                            <div className='create-device-row'>
                                <label>Source Text</label>
                                <MyInput
                                    id="source_text"
                                    name="source_text"
                                    className='create-device-input'
                                    type="text"
                                    value={this.state.source_text}
                                    required
                                    onChange={this.handleChange}
                                    showError={this.state.showValidationErrors}
                                />
                            </div>
                            
                            <div className='create-device-row'>
                                <label>Source Link</label>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <MyInput
                                        id="source_link"
                                        name="source_link"
                                        type="text"
                                        className='create-device-input'
                                        value={this.state.source_link}
                                        required
                                        onChange={this.handleChange}
                                        showError={this.state.showValidationErrors}
                                    />
                                </div>
                            </div>
                            <ErrorMessage text={this.state.sourceError} />
                            <div style={{display: "flex", justifyContent: "flex-end", paddingTop: "10px"}}>
                                <button type="submit" className="btn green mr-2" onClick={this.state.canSubmit ? this.handleSubmit : undefined}>Confirm</button>
                                <button type="button" className="btn mr-2" onClick={ this.toggle }>Cancel</button>
                            </div>
                        </Formsy>
                    </Paper>
                </Modal>
            </div>
        );
    }
}

export default SourceModal;