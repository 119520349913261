import SessionEdit from '../../components/SessionEdit';

class MeTimeSessionEdit extends SessionEdit {

  constructor(props) {
    super(props);
    this.state.pageType = "pain";
    this.state.session.changePainStatusType = 1;
    this.state.changePainStatusType = 1;
  }

  getDefaultObjectType() {
    return 4;
  }

  getTexts() {
    return {
      pageTitle: "Pain Change Session",
    };
  }

  cancel = (e) => {
    e.preventDefault();
    this.props.router.push('/session-pain-list');
  }
}

export default MeTimeSessionEdit;
