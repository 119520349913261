import React from 'react';
import HomePage from '../home';
import MyPage from '../../components/MyPage';
import MyTable from '../../components/MyTable';
import MyTableHeaderSort from '../../components/MyTableHeaderSort';
import { SearchIcon } from '../../Icons/svg';

import { actions as FAQActions} from '../../redux/modules/faq';


class FAQList extends MyPage {

  constructor(props) {
    super(props);
    this.state = {
      fullList: [],
      filteredList: [],
      isLoading: true,
      searchKeyword: '',
      order: 'asc',
      orderBy: '',
    };
  }

  getList = async () => {
    const data = await FAQActions.getFAQList();
    return data;
  }

  getTexts = () => {
    return {
      tableTitle: "FAQ",
      searchTitle: "Search",
    }
  }

  edit = (e, obj = null) => {
    e.preventDefault();
    this.props.router.push({
      pathname: '/faq-edit',
      state: { obj }
    });
  }

  handleSearch = (e) => {
    e.preventDefault();
    let searchKeyword = e.target.value;
    this.setState({searchKeyword}, () => {
      const regexp = new RegExp(searchKeyword.replace(/[-+*.?|^$/()[\]{}\\]/g, "\\$&"), "gi");
      let filteredList = this.state.fullList.filter(object => {
        return ((object.title).match(regexp) || (object.content).match(regexp))
      });
      this.setState({
        filteredList
      });
    });
  }

  async componentDidMount() {
    const list = await this.getList();
    this.setState({
      fullList: list,
      filteredList: list,
      isLoading: false,
    });
  }


  handleReorder = async (items) => {
    items.forEach((element, index) => {
      element.index = index;
    });
    this.setState({ fullList: items, order: 'asc', orderBy: 'index' });
    await FAQActions.saveFAQList(this.state.fullList);
  }

  makeToolBar = () => {
    return (
      <div className='device-tools'>
        <div className='search-icon'>
          <SearchIcon/>
          <input className='device-search' onChange={this.handleSearch} placeholder={this.getTexts().searchTitle} tabIndex="-1"/>
        </div>
        <div>
          <button className={"btn green mr-2"} onClick={this.edit}>+</button>
        </div>
      </div>
    );
  }

  makeHead = () => {
    return (
      <div className={"table-head"}>

        {/* <div style={{ width: '10%', paddingLeft: "15px" }}>
          <MyTableHeaderSort label="#" />
        </div> */}

        <div style={{ width: '30%', paddingLeft: "15px" }}>
          <MyTableHeaderSort label="Title" />
        </div>

        <div style={{ width: '80%', paddingLeft: "15px" }}>
          <MyTableHeaderSort label="Content" />
        </div>

        <div style={{ width: '20%', paddingLeft: "15px" }}>
          <MyTableHeaderSort label="Actions" />
        </div>

      </div>
    );
  }

  makeRow = (item) => {
    return (
      <div className={"table-row"}>
        {/* <div style={{ width: "10%", paddingLeft: "15px" }}>{item.index}</div> */}
        <div style={{ width: "30%", paddingLeft: "15px" }}>{item.title}</div>
        <div style={{ width: "80%", paddingLeft: "15px" }}>{this.shortenText(item.content, 200)}</div>
        <div style={{ width: "20%", paddingLeft: "15px" }}>
          <button type="button" className="btn" onClick={(e) => this.edit(e, item)}>Edit</button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <HomePage router={this.props.router}>
        <div className='device-table-container'>
          {! this.state.isLoading &&
            <MyTable
              tableName={this.getTexts().tableTitle}
              tableToolBar={this.makeToolBar}
              tableHead={this.makeHead}
              tableRow={this.makeRow}
              isDraggable={true}
              // isCheckable={true}
              hasIndex={true}
              order={this.state.order}
              orderBy={this.state.orderBy}
              data={this.state.filteredList}
              onDragEnd={(items) => {this.handleReorder(items)}}
              hasPagination={true}
              rowsPerPage={10}
            />
          }
        </div>
      </HomePage>
    );
  }
}

export default FAQList;
