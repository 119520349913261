import React, { Component } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import TableSortLabel from '@material-ui/core/TableSortLabel'

class MyTableHeaderSort extends Component {
  render () {
    const { active, label, direction, onClick } = this.props
    if (onClick) {
      return (
        <Tooltip title="Sort">
          <TableSortLabel
            active={active}
            direction={direction}
            onClick={onClick}
          >
            {label}
          </TableSortLabel>
        </Tooltip>
      )
    } else {
      return label
    }
  }
}

export default MyTableHeaderSort
