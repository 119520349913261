import { store } from '../store';
import { fetchFromUrlPOSTAsync } from '../fetch/';

const SET_ADMIN = 'SET_ADMIN';

export const actions = {

  getAdmin: async (login, password) => {
    let urlParams = [
      { key: 'login', value: login },
      { key: 'password', value: password },
    ];
    const result = await fetchFromUrlPOSTAsync('/admin/login', urlParams);
    if (result.success) {
      store.dispatch({ type: SET_ADMIN, payload: result.data });
    }
    return result;
  },

  clearAdmin: () => {
    store.dispatch({ type: SET_ADMIN, payload: {} });
  },

  getAdminPolicyId: () => {
    return store.getState().adminReducer.admin.policyId;
  },

  // updatePolicy: (data) => {
  //   store.dispatch({ type: SET_ADMIN_POLICY, payload: data });
  // },

  saveAdmin: async (adminData, selfEdit) => {
    let urlParams = [
      { key: 'saveData', value: adminData },
      { key: 'device', value: "Web" },
    ];
    const result = await fetchFromUrlPOSTAsync('/admin/save_one', urlParams);
    if (result.success && selfEdit) {
      store.dispatch({ type: SET_ADMIN, payload: result.data });
    }
    return result.success;
  },


  getAdminList: async () => {
    const result = await fetchFromUrlPOSTAsync('/admin/get_all', []);
    return result.success ? result.data : [];
  },


  // getAdminPolicy: async (policyId) => {
  //   let urlParams = [
  //     { key: 'policyId', value: policyId },
  //     { key: 'device', value: "Web" },
  //   ];
  //   const result = await fetchFromUrlPOSTAsync('/admin/get_policy', urlParams);
  //   return result.success ? result.data : [];
  // },
};