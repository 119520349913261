import React from 'react'
import HomePage from '../home'
import Formsy from 'formsy-react'
import MyPage from '../../components/MyPage'
import MyInput from '../../components/MyInput'
import MyTable from '../../components/MyTable'
import MyTableHeaderSort from '../../components/MyTableHeaderSort'
import SelectSessionsModal from './SelectSessionsModal'
import ErrorMessage from '../../components/errormessage'
import DeleteIcon from '@material-ui/icons/Delete'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button } from '@material-ui/core'

import { actions as journeyActions } from '../../redux/modules/journey'

class MilestoneEdit extends MyPage {
  constructor (props) {
    super(props)
    const obj = this.getObject()

    console.log('obj: ', obj)

    this.state = {
      object: obj,
      title: obj.title,
      description: obj.description,
      sessionsList: obj.sessions,
      removedSessions: [],
      errorMessage: '',
      canSubmit: false,
      showValidationErrors: false,
      isLoading: false,
      openDialog: false
    }
  }

  getObject = () => {
    if (this.props.location.state && this.props.location.state.obj) {
      console.log(this.props.location.state.obj)
      return this.props.location.state.obj
    } else {
      return {
        title: '',
        description: '',
        sessions: []
      }
    }
  }

  getTexts = () => {
    return {
      pageTitle: 'Milestone'
    }
  }

  handleDeleteSession = (e, item) => {
    for (let i = 0; i < this.state.sessionsList.length; i++) {
      if (this.state.sessionsList[i] === item) {
        this.setState({
          deleteSessionIndex: i,
        })

        break
      }
    }

    this.handleOpenDialog()
  }

  handleOpenDialog = () => {
    this.setState({
      openDialog: true,
    })
  }

  handleCloseDialog = value => {
    this.setState({
      isLoading: false,
      openDialog: false,
      deleteSessionId: null,
      deleteTitle: null
    })
  }

  handleConfirmDialog = async => {
    if (this.state.deleteSessionIndex >= 0) {
      const list = this.state.sessionsList
      list.splice(this.state.deleteSessionIndex, 1)
      this.setState({ sessionsList: list })
    }

    this.handleCloseDialog()
  }

  handleChange = e => {
    e.preventDefault()
    console.log('handleChange e: ', e)
    this.setState({
        [e.target.id]: e.target.value
    })
  }

  cancel = e => {
    e.preventDefault()
    this.props.router.push('/journey-edit')
  }

  addSessionToList = async obj => {
    this.setState({ sessionsList: obj.sessions || [] })
  }

  save = async e => {
    e.preventDefault()
    if (window.confirm('Are you sure you want to save this Milestone?')) {
      this.state.object.title = this.state.title
      this.state.object.description = this.state.description
      this.state.object.sessions = this.state.sessionsList

      const result = await journeyActions.saveMilestone(this.state.object)
      if (result) {
        if (!result.success) {
          this.setState({ errorMessage: result.error })
        } else {
          this.cancel(e)
          this.setState({ errorMessage: '' })
        }
      }
    }
  }

  async componentDidMount () {
    console.log('did moount')
  }

  makeToolBar = () => {
    return (
      <div
        className="device-tools"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <div>
          <SelectSessionsModal
            sessions={this.state.object.allSessions}
            selectedSessions={this.state.object.sessions}
            object={this.state.object}
            onExit={this.addSessionToList}
          />
        </div>
      </div>
    )
  }

  makeHead = () => {
    return (
      <div className={'table-head'}>
        <div style={{ width: '80%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Session" />
        </div>

        <div style={{ width: '20%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Actions" />
        </div>
      </div>
    )
  }

  makeRow = item => {
    return (
      <div className={'table-row'}>
        <div style={{ width: '80%', paddingLeft: '15px' }}>{item.title}</div>

        <div style={{ width: '20%', paddingLeft: '15px' }}>
          <button
            type="button"
            className="btn"
            onClick={e => this.handleDeleteSession(e, item)}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
    )
  }

  render () {
    const style = { color: 'red' }
    return (
      <HomePage router={this.props.router}>
        <div className="devices-page-wrapper">
          <ErrorMessage text={this.state.errorMessage} />
          <h3>{this.getTexts().pageTitle}</h3>
        {!this.state.isLoading && (
            <Formsy
              className="create-device-form"
              onValidSubmit={this.submit}
              onInvalidSubmit={() =>
                this.setState({ showValidationErrors: true })
              }
              onValid={() => this.setState({ canSubmit: true })}
              onInvalid={() => this.setState({ canSubmit: false })}
            >
              <div className="create-device-row">
                <label>
                  Title <span style={style}>*</span>
                </label>
                <MyInput
                  id="title"
                  name="title"
                  classNameDiv="create-branch-row"
                  className="create-device-input"
                  autocomplete="off"
                  value={this.state.title}
                  onChange={this.handleChange}
                  required
                  showError={this.state.showValidationErrors}
                />
              </div>
              <div className="create-device-row">
                <label>
                  Description <span style={style}>*</span>
                </label>
                <MyInput
                  id="description"
                  name="description"
                  classNameDiv="create-branch-row"
                  className="create-device-input"
                  autocomplete="off"
                  value={this.state.description}
                  onChange={this.handleChange}
                  required
                  showError={this.state.showValidationErrors}
                />
              </div>
              <div className="create-device-row">
                <label>Sessions</label>
                <MyTable
                  tableToolBar={this.makeToolBar}
                  tableHead={this.makeHead}
                  tableRow={this.makeRow}
                  hasIndex={true}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  data={this.state.sessionsList}
                />
              </div>
              {/* <ErrorMessage text={this.state.errorMessage} /> */}
              <button
                className="btn green mr-2"
                type="submit"
                onClick={this.state.canSubmit ? this.save : undefined}
              >
                Save
              </button>
              <button
                className="btn blue mr-2"
                type="button"
                onClick={this.cancel}
              >
                Cancel
              </button>
            </Formsy>
        )}
        </div>
        <Dialog
          onClose={this.handleCloseDialog}
          open={this.state.openDialog}
          aria-labelledby="delete-dialog-title"
        >
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to delete session "{this.state.deleteTitle}"?
          </DialogTitle>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 24px 24px 24px'
          }}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleCloseDialog}>Cancel</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleConfirmDialog}>Ok</Button>
          </div>
        </Dialog>
      </HomePage>
    )
  }
}

export default MilestoneEdit
