import React from 'react'

import MyPage from '../../components/MyPage'
import HomePage from '../home'
import Button from '@material-ui/core/Button'

import { actions as sessionActions } from '../../redux/modules/sessions'

class SessionTranscriptionEdit extends MyPage {
  constructor (props) {
    super(props)
    const obj = this.getObject()

    this.state = {
      sessionId: obj._id,
      title: obj.title,
      transcription: obj.transcription ? obj.transcription : '',
      isLoading: true,
      isDataChanged: false,
      isDataSaveError: false,
      statusMessage: 'All data saved',
      error: false
    }
  }

  getObject = () => {
    if (this.props.location.state && this.props.location.state.obj) {
      return this.props.location.state.obj
    } else {
      return {
        title: '',
        transcription: ''
      }
    }
  }

  handleChange = e => {
    const { value } = e.target

    this.setState({
      transcription: value,
      isDataChanged: true
    })
  }

  saveChanges = async e => {
    if (this.state.isDataChanged) {
      const session = {
        _id: this.state.sessionId,
        transcription: this.state.transcription
      }

      const result = await sessionActions.saveSession(session)
      if (result) {
        this.setState({
          isDataChanged: false
        })
      } else {
        this.setState({
          iisDataSaveError: true,
          statusMessage: result.error,
        })
      }
    }
  }

  render () {
    return (
      <HomePage router={this.props.oruter} addClassName="h-100">
        <div className="device-page-wrapper" style={{ width: '98%' }}>
          <h3>{this.state.title}</h3>
          <div className="create-device-row">
            <textarea
              className="create-device-input"
              name="transcription"
              rows={20}
              defaultValue={this.state.transcription}
              onChange={this.handleChange}
            />
          </div>

          <div className="create-device-row" style={{ flexDirection: 'row' }}>
            <Button
              variant="contained"
              color={this.state.dataChanged ? 'secondary' : 'primary'}
              onClick={this.saveChanges}
              style={{ width: '200px' }}
              disabled={!this.state.isDataChanged}
            >
              Save changes
            </Button>
            {(!this.state.isDataChanged || this.state.error) && (
              <p style={
              {
                color: this.state.error ? 'red' : '#18ab18',
                fontWeight: 'bold',
                width: '200px',
                textAlign: 'center',
                marginTop: '0.4em',
                marginBottom: '0'
              }}>{this.state.statusMessage}</p>
            )}
          </div>
        </div>

      </HomePage>
    )
  }
}

export default SessionTranscriptionEdit
