import { fetchFromUrlGETAsync, fetchFromUrlPOSTAsync } from '../fetch/'

export const actions = {
  getData: async () => {
    const result = await fetchFromUrlGETAsync('/screens-text', [])
    return result.success ? result.data : []
  },

  saveData: async screensText => {
    let urlParams = [{ key: 'saveData', value: screensText }]
    const result = await fetchFromUrlPOSTAsync('/screens-text/save', urlParams)
    return result
  }
}
