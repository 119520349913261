
import React from 'react';
import './FakeAudioPlayer.css';

class FakeAudioPlayer extends React.Component {
  render() {
    return (
      <div className="fakeplayer">
        {this.props.text}
      </div>
    );
  }
}

export default FakeAudioPlayer;
