import React from 'react'
import PropTypes from 'prop-types'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'

const DiagnosisTop = ({ top10Diagnosis }) => {
  const top10DiagnosisData = () => {
    return top10Diagnosis.map(element => {
      return { name: element.name, Requests: element.count }
    })
  }

  return (
    <div>
      <div className="d-flex">
        <h5 className="h-statistics">Top 10 Requested Diagnosis</h5>
      </div>
      <div className="d-flex mb16">
        <table style={{ width: '50%' }}>
          <tbody>
            {/* <tr>
            <th><h5 className="h-statistics">Top 10 Requested Diagnosis</h5></th>
          </tr> */}
            {top10Diagnosis.map((element, index) => {
              return (
                <tr key={`top10_${index}`}>
                  <td>{element.name}</td>
                  <td>{element.count}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <BarChart
          width={600}
          height={250}
          data={top10DiagnosisData()}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          className="dashboard-barchart"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="Requests" stackId="a" fill="#5588ee" />
        </BarChart>
      </div>
    </div>
  )
}

DiagnosisTop.propTypes = {
  top10Diagnosis: PropTypes.array.isRequired
}

export default DiagnosisTop
