import React from 'react';
import HomePage from '../home';
import Dropzone from 'react-dropzone';
import Formsy from 'formsy-react';
import MyPage from '../../components/MyPage';
import MyTable from '../../components/MyTable';
import MyTableHeaderSort from '../../components/MyTableHeaderSort';
import MyInput from '../../components/MyInput';
import MySelect from '../../components/MySelect';
import imageLib from '../..//themes';
import { urlImg } from "../../redux/fetch/urlHelper";
import CloseIcon from '@material-ui/icons/Close';
import ErrorMessage from '../../components/errormessage';
import Checkbox from '@material-ui/core/Checkbox';

import { actions as policyActions } from '../../redux/modules/policies';

class PermissionEdit extends MyPage {

  constructor(props) {
    super(props);
    const obj = this.getObject();

    this.getPagesList().forEach((element) => {
      if (!obj[element.field]) {
        // console.log(element.field, obj[element.field]);
        obj[element.field] = this.getPagePermissions(element.specifics);
        // console.log(element.field, obj[element.field]);
      }
    });

    console.log("OBJ CNS", obj);


    this.state = {
      object: obj,
      name: obj.name,
      canSubmit: false,
      showValidationErrors: false,
      order: 'asc',
      orderBy: '',
    };

    this.getPagesList().forEach((element) => {
      this.state[element.field] = obj[element.field];
    });

  }

  getObject = () => {
    if (this.props.location.state && this.props.location.state.obj) {
      const obj = this.props.location.state.obj;
      return obj;
    } else {
      return {
        name: "",
        index: null,
      };
    }
  }

  getTexts = () => {
    return {
      pageTitle: "Permission",
      tableTitle: "",
    }
  }

  getPagesList = () => {
    return policyActions.getPagesList();
  };

  getPagePermissions = (specifics) => {
    const hasView = specifics.includes("V");
    const hasChange = specifics.includes("C");
    const hasDelete = specifics.includes("D");
    const obj = {}
    if (hasView || hasChange || hasDelete) {
      if (hasView) { obj.canView = false };
      if (hasChange) { obj.canChange = false };
      if (hasDelete) { obj.canDelete = false };
    }
    return obj;
  }

  cancel = (e) => {
    e.preventDefault();
    this.props.router.push('/permission-list');
  }

  save = async (e) => {
    e.preventDefault();
    this.state.object.name = this.state.name;
    // this.state.object.isFeatured = this.state.isFeatured;
    // this.state.object.imageFileName = this.state.imageFileName;
    const result = await policyActions.savePolicy(this.state.object);
    if (result) {
      this.cancel(e);
    }
  }




  handleCheckBox = (e, checked, pageField, property) => {

    this.state.object[pageField][property] = checked;
    if (property === "canView" && checked === false) {
        if (this.state.object[pageField]["canChange"] === true) {
          this.state.object[pageField]["canChange"] = false;
        }
      if (this.state.object[pageField]["canDelete"] === true) {
        this.state.object[pageField]["canDelete"] = false;
      }
    }
    console.log("POST", pageField, property, this.state.object[pageField][property]);
    this.setState({ object: this.state.object });
  };





  makeHead = () => {
    return (
      <div className={"table-head"}>

        <div style={{ width: '30%', paddingLeft: "15px" }}>
          <MyTableHeaderSort label="Name" />
        </div>
        <div style={{ width: '20%', paddingLeft: "15px" }}>
          <MyTableHeaderSort label="View" />
        </div>
        <div style={{ width: '20%', paddingLeft: "15px" }}>
          <MyTableHeaderSort label="Change" />
        </div>
        <div style={{ width: '20%', paddingLeft: "15px" }}>
          <MyTableHeaderSort label="Delete" />
        </div>

      </div>
    );
  }

  makeRow = (item) => {
    return (
      <div className={"table-row"}>
        <div style={{ width: "30%", paddingLeft: "15px" }}>{item.label}</div>
        <div style={{ width: "20%", paddingLeft: "15px" }}>
          <Checkbox
            checked={this.state[item.field].canView}
            onChange={(e, checked) => this.handleCheckBox(e, checked, item.field, "canView")}
          />
        </div>
        <div style={{ width: "20%", paddingLeft: "15px" }}>
          {this.state[item.field].hasOwnProperty("canChange") &&
            <Checkbox
              checked={this.state[item.field].canChange}
              onChange={(e, checked) => this.handleCheckBox(e, checked, item.field, "canChange")}
              disabled={!this.state[item.field].canView}
            />
          }
        </div>
        <div style={{ width: "20%", paddingLeft: "15px" }}>
          {this.state[item.field].hasOwnProperty("canDelete") &&
            <Checkbox
              checked={this.state[item.field].canDelete}
              onChange={(e, checked) => this.handleCheckBox(e, checked, item.field, "canDelete")}
              disabled={!this.state[item.field].canView}
            />
          }
        </div>
      </div>
    );
  }

  render() {
    const style = {color:'red'}
    return (
      <HomePage router={this.props.router}>
        <div className="devices-page-wrapper">
          <h3>{this.getTexts().pageTitle}</h3>
          <Formsy className="create-device-form"
            onValidSubmit={this.submit}
            onInvalidSubmit={() => this.setState({showValidationErrors: true})}
            onValid={() => this.setState({canSubmit: true})}
            onInvalid={() => this.setState({canSubmit: false})}
          >
            <div className='create-device-row'>
              <label>Name <span style={style}>*</span></label>
              <MyInput
                id="name"
                name="name"
                classNameDiv='create-branch-row'
                className='create-device-input'
                autocomplete="off"
                value={this.state.name}
                onChange={this.handleChange}
                required
                showError={this.state.showValidationErrors}
              />
            </div>

            <MyTable
              tableName={this.getTexts().tableTitle}
              tableToolBar={this.makeToolBar}
              tableHead={this.makeHead}
              tableRow={this.makeRow}
              // isDraggable={true}
              // isCheckable={true}
              // hasIndex={true}
              order={this.state.order}
              orderBy={this.state.orderBy}
              data={this.getPagesList()}
              // hasPagination={true}
              // rowsPerPage={10}
            />

            <button className='btn green mr-2' type="submit" onClick={this.state.canSubmit ? this.save : undefined}>Save</button>
            <button className='btn blue mr-2' type="button" onClick={this.cancel}>Cancel</button>
          </Formsy>
        </div>
      </HomePage>
    );
  }
}

export default PermissionEdit;
