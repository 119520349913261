import React from 'react'
import HomePage from '../home'
import Formsy from 'formsy-react'
import MyPage from '../../components/MyPage'
import MyInput from '../../components/MyInput'
import ErrorMessage from '../../components/errormessage'
import imageLib from '../../themes'
import { actions } from '../../redux/modules/SOSExperiences'
import { actions as SOSExperienceSubtopicActions } from '../../redux/modules/SOSExperienceSubtopics'
import Dropzone from 'react-dropzone'
import { CircularProgress } from '@material-ui/core'
import ReactPlayer from 'react-player'
import CloseIcon from '@material-ui/icons/Close'
import FakeAudioPlayer from '../../components/FakeAudioPlayer'
import MySelect from '../../components/MySelect'
import { Player, Controls } from '@lottiefiles/react-lottie-player';

class SOSExperienceEdit extends MyPage {
  constructor(props) {
    super(props)
    this.state = {
      SOSExperienceSubtopics: [],
      SOSExperienceSubtopicOptions: [],
      errorMessage: '',
      canSubmit: false,
      showValidationErrors: false,
      isLoading: true,
      openDialog: false
    }
  }

  getObject = () => {
    if (this.props.location.state && this.props.location.state.obj) {
      const object = this.props.location.state.obj
      this.setState({
        object,
        isLoading: false,
        audioFileName: object.audioFileName,
        audioURL: object.audioURL,
        animationFileName: object.animationFileName,
        animationURL: object.animationURL,
        imageFileName: object.imageFileName,
        imageURL: object.imageURL,
      })
    } else {
      this.setState({
        object: {
          title: '',
          audioFileName: '',
          audioURL: '',
          animationFileName: '',
          animationURL: '',
          imageFileName: '',
          imageURL: '',
          isFree: true,
        },
        isLoading: false
      })
    }
  }

  getTexts = () => {
    return {
      pageTitle: 'SOS Experience'
    }
  }

  handleChange = e => {
    e.preventDefault()
    const target = e.target
    this.setState(state => {
      return {
        object: {
          ...state.object,
          [target.id]: target.value
        }
      }
    })
  }

  handleSelect = (stateId, value) => {
    this.setState(state => {
      return {
        object: {
          ...state.object,
          [stateId]: value
        }
      }
    })
  }

  cancel = e => {
    e.preventDefault()
    this.props.router.push('/sos-experiences-list')
  }

  save = async e => {
    if (!this.state.audioFileName &&
      !this.state.audioURL) {
      return this.setState({ errorMessage: 'Audio is required.' })
    }

    if (!this.state.animationFileName &&
      !this.state.animationURL) {
      return this.setState({ errorMessage: 'Animation is required.' })
    }

    if (!this.state.imageFileName &&
      !this.state.imageURL) {
      return this.setState({ errorMessage: 'Image is required.' })
    }

    if (!this.state.object.SOSExperienceSubtopicId) {
      return this.setState({ errorMessage: 'SOS Experience Subtopic is required.' })
    }

    e.preventDefault()
    if (window.confirm('Are you sure you want to save this SOS Experience?')) {
      this.state.object.audioFileName = this.state.audioFileName
      this.state.object.animationFileName = this.state.animationFileName
      this.state.object.imageFileName = this.state.imageFileName
      const { _id, errorMessage } = await actions.save(this.state.object)

      if (_id) {
        this.setState({ errorMessage: '' })
        this.cancel(e)
      }

      if (errorMessage) {
        this.setState({ errorMessage })
      }
    }
  }

  componentDidMount () {
    (async () => {
      await this.fetchSOSExperienceSubtopics()
      this.getObject()
    })()
  }

   /**
   * Loads SOS Experience Subtopics
   * @param {number} page
   */
  async fetchSOSExperienceSubtopics (page = 1) {
    let { SOSExperienceSubtopics } = this.state
    const { data, errorMessage, hasMore } = await SOSExperienceSubtopicActions.getList({
      page,
      perPage: 100
    })

    if (errorMessage) {
      return this.setState({ errorMessage })
    }

    SOSExperienceSubtopics = SOSExperienceSubtopics.concat(data)

    if (SOSExperienceSubtopics.length) {
      this.setState({
        SOSExperienceSubtopics,
        SOSExperienceSubtopicOptions: SOSExperienceSubtopics.map(({ _id, title }) => ({ value: _id, label: title }))
      },
        () => {
          if (hasMore) {
            this.loadChallengeSubtopics(page + 1)
          }
        })
    }
  }

  resizeFile = (file) => new Promise(resolve => {
    var canvas = document.createElement("canvas")
    var ctx = canvas.getContext("2d");

    const image = new Image();
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      image.src = reader.result;
      image.onload = function () {
        scaleToFill(this);
      }

      function scaleToFill(img) {

        const neededImageWidth = 300
        const neededImageHeight = 300

        canvas.width = neededImageWidth
        canvas.height = neededImageHeight

        const scale = Math.max(canvas.width / img.width, canvas.height / img.height);

        const x = (canvas.width / 2) - (img.width / 2) * scale;
        const y = (canvas.height / 2) - (img.height / 2) * scale;

        ctx.drawImage(img, x, y, img.width * scale, img.height * scale);

        canvas.toBlob(blob => {
          const resizedFile = new File([blob], file.name, {
            type: blob.type
          })

          resolve(resizedFile)
        })
      }
    }
  });

  render() {
    const style = { color: 'red' }
    const audioURL = this.state.audioURL || ''
    const animationURL = this.state.animationURL || ''

    let iimm =
      this.state.imageURL === ''
        ? imageLib.noImage
        : this.state.imageURL

    return (
      <HomePage router={this.props.router}>
        <div className="devices-page-wrapper">
          <ErrorMessage text={this.state.errorMessage} />
          <h3>{this.getTexts().pageTitle}</h3>
          {!this.state.isLoading && (
            <Formsy
              className="create-device-form"
              onValidSubmit={this.submit}
              onInvalidSubmit={() =>
                this.setState({ showValidationErrors: true })
              }
              onValid={() => this.setState({ canSubmit: true })}
              onInvalid={() => this.setState({ canSubmit: false })}
            >
              <div className="create-device-row">
                <label>
                  Title <span style={style}>*</span>
                </label>
                <MyInput
                  id="title"
                  name="title"
                  classNameDiv="create-branch-row"
                  className="create-device-input"
                  autocomplete="off"
                  value={this.state.object.title}
                  onChange={this.handleChange}
                  required
                  showError={this.state.showValidationErrors}
                />
              </div>
              <div className="create-device-row">
                <label>
                  SOS Experience Subtopic <span style={style}>*</span>
                </label>
                <MySelect
                  className="create-branch-select"
                  stateField="SOSExperienceSubtopicId"
                  value={this.state.object.SOSExperienceSubtopicId || null}
                  options={this.state.SOSExperienceSubtopicOptions}
                  onChange={this.handleSelect}
                  isMulti={false}
                  isSearchable={true}
                  required
                />
              </div>
              <div className="create-device-row">
                <label>Free</label>
                <MySelect
                  stateField="isFree"
                  className="create-branch-select"
                  value={this.state.object.isFree !== undefined ? this.state.object.isFree : true}
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' }
                  ]}
                  onChange={this.handleSelect}
                />
              </div>

              <div className="create-device-row">
                <label>
                  Image <span style={style}>*</span>
                </label>
                <div className="div-row">
                  <Dropzone
                    className="upload-border"
                    onDrop={async files => {
                      const resizedImage = await this.resizeFile(files[0])

                      this.handleDropImage(
                        [resizedImage],
                        'imageFileName',
                        'imageFileNameError'
                      )
                    }
                    }
                    accept="image/jpeg,image/jpg,image/tiff,image/gif,image/png"
                    multiple={false}
                    type={'file'}
                  >
                    <span
                      className="w-100"
                      style={{ textAlign: 'center', marginBottom: '5px' }}
                    >
                      Drag and drop file or{' '}
                    </span>
                    <button className="btn blue" type="button">
                      BROWSE
                      </button>
                  </Dropzone>
                  <div style={{ textAlign: 'right', position: 'relative' }}>
                    {this.state.imageFileName !== '' && (
                      <button
                        className="btn delete-btn"
                        type="button"
                        onClick={() =>
                          this.deleteUploadFile(
                            'imageFileName',
                            'imageFileNameError'
                          )
                        }
                      >
                        <CloseIcon />
                      </button>
                    )}
                    <img
                      style={{
                        width: 180,
                        height: 180,
                        objectFit: 'contain',
                        border: '1px solid #e6e9ec',
                        marginLeft: '20px'
                      }}
                      src={this.state.imageURL}
                      alt=""
                    />
                  </div>
                </div>
                <ErrorMessage text={this.state.imageFileNameError} />
                <MyInput
                  id="imageFileName"
                  name="imageFileName"
                  classNameDiv="create-branch-row"
                  className="create-device-input"
                  value={iimm}
                  hidden
                  disabled
                  required
                  showError={this.state.showValidationErrors}
                />
              </div>

              <div className="create-device-row">
                <label>Audio <span style={style}>*</span></label>
                <div className="div-row">
                  <Dropzone
                    className="upload-border"
                    onDrop={files => {
                      console.log({ files })
                      this.handleDropAudio(
                        files,
                        'audioFileName',
                        'audioFileNameError'
                      )
                    }}
                    onChange={this.onAudioChange}
                    accept="audio/mpeg,audio/mp3,audio/m4a,audio/x-m4a"
                    multiple={false}
                    type={'file'}
                  >
                    <span
                      className="w-100"
                      style={{ textAlign: 'center', marginBottom: '5px' }}
                    >
                      Drag and drop file or{' '}
                    </span>
                    <button className="btn blue" type="button">
                      BROWSE
                    </button>
                  </Dropzone>
                  <div
                    style={{
                      textAlign: 'right',
                      position: 'relative',
                      marginLeft: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      border: '1px solid #e6e9ec'
                    }}
                  >
                    {this.state.audioFileName !== '' ? (
                      <div>
                        <button
                          className="btn delete-btn"
                          type="button"
                          onClick={() =>
                            this.deleteUploadFile(
                              'audioFileName',
                              'backgroundFileNameError'
                            )
                          }
                        >
                          <CloseIcon />
                        </button>

                        <ReactPlayer
                          url={audioURL}
                          controls={true}
                          style={{ marginTop: '20px', height: '40px' }}
                        />
                      </div>
                    ) : (
                        <FakeAudioPlayer text="No audio file" />
                      )}
                  </div>
                </div>
              </div>

              <div className="create-device-row">
                <label>Animation <span style={style}>*</span></label>
                <div className="div-row">
                  <Dropzone
                    className="upload-border"
                    onDrop={async files => {
                      this.handleDropAnimation(
                        files,
                        'animationFileName',
                        'animationFileNameError'
                      )
                    }}
                    accept="application/json"
                    multiple={false}
                    type={'file'}
                  >
                    <span
                      className="w-100"
                      style={{ textAlign: 'center', marginBottom: '5px' }}
                    >
                      Drag and drop file or{' '}
                    </span>
                    <button className="btn blue" type="button">
                      BROWSE
                      </button>
                  </Dropzone>
                  <div
                    style={{
                      textAlign: 'right',
                      position: 'relative',
                      marginLeft: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      border: '1px solid #e6e9ec'
                    }}
                  >
                    {this.state.animationFileName !== '' ? (
                      <div>
                        <button
                          className="btn delete-btn"
                          type="button"
                          onClick={() =>
                            this.deleteUploadFile(
                              'animationFileName',
                              'backgroundFileNameError'
                            )
                          }
                        >
                          <CloseIcon />
                        </button>
                        <Player
                          autoplay
                          loop
                          src={animationURL}
                          style={{ height: '300px', width: '300px' }}
                        >
                          <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} />
                        </Player>
                      </div>
                    ) : (
                        <FakeAudioPlayer text="No animation file" />
                      )}
                  </div>
                </div>
              </div>
              <button
                className="btn green mr-2"
                type="submit"
                onClick={this.state.canSubmit ? this.save : undefined}
              >
                Save
              </button>
              <button
                className="btn blue mr-2"
                type="button"
                onClick={this.cancel}
              >
                Cancel
              </button>
            </Formsy>
          )}
        </div>
      </HomePage>
    )
  }
}

export default SOSExperienceEdit
