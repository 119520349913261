import React, { Component } from 'react'
import '../header/iPad/Style.css'
import { Link, browserHistory } from 'react-router'
import './menu.css'
import config from '../../config'

import DashboardIcon from '@material-ui/icons/Dashboard'
import UsersIcon from '@material-ui/icons/AccountBox'
import SessionIcon from '@material-ui/icons/VideoLibrary'
import PainSessionIcon from '@material-ui/icons/LibraryAdd'
import MeTimeSessionIcon from '@material-ui/icons/LibraryBooks'
import MindSessionIcon from '@material-ui/icons/Mood'
import BodySessionIcon from '@material-ui/icons/Accessibility'
import HelpIcon from '@material-ui/icons/Help'
import DiagnosisIcon from '@material-ui/icons/LocalHospital'
import DailyIcon from '@material-ui/icons/Today'
import TaskIcon from '@material-ui/icons/ListAlt'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import QuizIcon from '@material-ui/icons/LiveHelp'
import RequestIcon from '@material-ui/icons/Assignment'
// import StatisticsIcon from '@material-ui/icons/Assessment';
import TopicIcon from '@material-ui/icons/Layers'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
// import AuthorIcon from '@material-ui/icons/LocalLibrary';
import AuthorIcon from '@material-ui/icons/RecentActors'
import CollectionIcon from '@material-ui/icons/LibraryMusic'
import ChallengesIcon from '@material-ui/icons/Grade'
import AdminIcon from '@material-ui/icons/AssignmentInd'
import PolicyIcon from '@material-ui/icons/Group'
import { JourneyIcon, FactorIcon } from '../../Icons/svg'

import { SideNav, Nav } from 'react-sidenav'

class NavCat extends Component {
  render () {
    let { label, level } = this.props
    level = level ? level : 0
    // let baseClassName = "dashboard menu-link-item d-flex";
    let baseClassName = 'dashboard d-flex'
    return (
      <Link key={`cat_${label}`} className={baseClassName}>
        <div className="collapsed d-flex align-items-center">
          <div style={{ width: level * 16 }} />
          {/* {tab.icon} */}
          <p className="ml-3 nav-text">{label}</p>
        </div>
      </Link>
    )
  }
}

class TestNav extends Component {
  render () {
    let { label, level, icon, route, subRoutes } = this.props
    level = level ? level : 0
    let baseClassName = 'dashboard menu-link-item d-flex'
    // let baseClassName = "dashboard d-flex";
    const activeClassName = 'menu-link-wrapper-active'

    if (subRoutes &&
        subRoutes.includes(browserHistory.getCurrentLocation().pathname)) {
      baseClassName += ' ' + activeClassName
    }
    return (
      <Link key={`tab_${label}`}
        activeClassName={activeClassName} className={baseClassName}
        to={route} style={{ width: '100%' }}
      >
        <div className="collapsed d-flex align-items-center">
          <div style={{ width: level * 16 }} />
          {icon}
          <p className="ml-3 nav-text">{label}</p>
        </div>
      </Link>
    )
  }
}
class SideMenu extends Component {
  wrapNav = (id, content) => {
    return (
      <Nav id={id} style={{ padding: 0, borderBottom: '1px solid #2196F3' }}>
        {content}
      </Nav>
    )
  }

  render () {
    return (
      <div className="left-navbar-wraper">
        <div className="left-navbar" id="sidebar">

          <div className="list-group">
            <SideNav
              defaultSelectedPath="1"
              // expandOnHover={true}
              onItemSelection={this.onItemSelection}
              className=""
            >
              <Nav id="1">
                <NavCat label="Main" />

                {this.wrapNav('nav-dashboard',
                  <TestNav level={1}
                    label="Dashboard"
                    icon={<DashboardIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/dashboard"
                  />
                )}

                {/* !!! UNLOCK */}
                {/* {this.wrapNav("nav-admins",
                  <TestNav level={1}
                    label="Admins"
                    icon={<AdminIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/admin-list" subRoutes={["/admin-edit"]}
                  />
                )} */}

                {/* !!! UNLOCK */}
                {/* {this.wrapNav("nav-policies",
                  <TestNav level={1}
                    label="Permissions"
                    icon={<PolicyIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/permission-list" subRoutes={["/permission_edit"]}
                  />
                )} */}

                {this.wrapNav('nav-users',
                  <TestNav level={1}
                    label="Users"
                    icon={<UsersIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/user-list" subRoutes={['/user-edit']}
                  />
                )}

                { config.feature.userFeedback ? this.wrapNav('nav-feedbacks',
                  <TestNav level={1}
                    label="Feedbacks"
                    icon={<MeTimeSessionIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/user-feedback"
                  />
                ) : null }

              </Nav>

              { config.feature.programSection
                ? <Nav id="1">
                <NavCat label={config.title + ' Program' }/>

                { config.feature.diagnosis ? this.wrapNav('nav-diagnosis',
                  <TestNav level={1}
                    label="Diagnosis List"
                    icon={<DiagnosisIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/diagnosis-list" subRoutes={['/diagnosis-edit']}
                  />
                ) : null }

                {/* eslint-disable-next-line max-len */}
                { config.feature.diagnosis ? this.wrapNav('nav-diagnosis-request',
                  <TestNav level={1}
                    label="Diagnosis Requests"
                    icon={<RequestIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/diagnosis-request" subRoutes={['/diagnosis-edit']}
                  />
                ) : null }

                { config.feature.journey ? this.wrapNav('nav-journey',
                  <TestNav level={1}
                    label="Journey"
                    icon={<JourneyIcon className="DashboardIcon" />}
                    route="/journey-edit"
                  />
                ) : null }

                {this.wrapNav('nav-sessions',
                  <TestNav level={1}
                    label="Sessions List"
                    icon={<SessionIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/session-sound-list" subRoutes={['/session_edit']}
                  />
                )}

                { config.feature.sessionPlain ? this.wrapNav('nav-pain-sessions',
                  <TestNav level={1}
                    label="Pain Change Sessions"
                    icon={<PainSessionIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/session-pain-list"
                    subRoutes={['/session-pain-edit']}
                  />
                ) : null }

                {this.wrapNav('nav-daily',
                  <TestNav level={1}
                    label="Daily Inspiration"
                    icon={<DailyIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/daily-inspiration"
                    subRoutes={['/daily-inspiration-edit']}
                  />
                )}

                { config.feature.feelGoodTask ? this.wrapNav('nav-tasks',
                  <TestNav level={1}
                    label="Feel Good Tasks"
                    icon={<TaskIcon
                            className="DashboardIcon"
                            style={{ color: '#fff' }} />}
                    route="/task-list" subRoutes={['/task-edit']}
                  />
                ) : null }

                {this.wrapNav('nav-daily',
                  <TestNav level={1}
                    label="Success Stories"
                    icon={<ThumbUpIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/success-stories-list"
                    subRoutes={['/success-stories-edit']}
                  />
                )}

                {this.wrapNav('nav-quizzes',
                  <TestNav level={1}
                    label="Quizzes"
                    icon={<QuizIcon
                      className="DashboardIcon"
                      style={{ color: '#fff' }} />}
                    route="/quiz-list" subRoutes={['/quiz-edit']}
                  />
                )}

                { config.feature.registrationPolls ? this.wrapNav('nav-pollsList',
                  <TestNav level={1}
                    label="Registration Polls"
                    icon={<QuizIcon
                      className="DashboardIcon"
                      style={{ color: '#fff' }} />}
                    route="/polls-list" subRoutes={['/poll-edit']}
                  />
                ) : null }

                { config.feature.screensText ? this.wrapNav('nav-screensText',
                  <TestNav level={1}
                    label="Edit Screens Text"
                    icon={<MeTimeSessionIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/screens-text-edit"
                  />
                ) : null}
              </Nav>
              : null }

              <Nav id="1">
                <NavCat label={config.customTitles && config.customTitles['meTimeSection']
                  ? config.customTitles['meTimeSection'] : 'MeTime'}/>

                {this.wrapNav('nav-authors',
                  <TestNav level={1}
                    label="Authors"
                    icon={<AuthorIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/author-list" subRoutes={['/author-edit']}
                  />
                )}

                { !config.feature.mindAndBody ? this.wrapNav('nav-mesessions',
                  <TestNav level={1}
                    label={config.customTitles && config.customTitles['meTimeSessions']
                      ? config.customTitles['meTimeSessions'] : 'MeTime Sessions'}
                    icon={<MeTimeSessionIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/session-metime-list"
                    subRoutes={['/session-metime-edit']}
                  />
                ) : null }

                { config.feature.mindAndBody ? this.wrapNav('nav-subtopics',
                  <TestNav level={1}
                    label='Mind Sessions'
                    icon={<MindSessionIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/mind-sessions-list"
                    subRoutes={['/session-metime-edit']}
                  />
                ) : null }

                { config.feature.mindAndBody ? this.wrapNav('nav-subtopics',
                  <TestNav level={1}
                    label='Body Sessions'
                    icon={<BodySessionIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/body-sessions-list"
                    subRoutes={['/session-bidy-edit']}
                  />
                ) : null }

                {this.wrapNav('nav-topics',
                  <TestNav level={1}
                    label="Topics"
                    icon={<TopicIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/topic-list" subRoutes={['/topic-edit']}
                  />
                )}

                { config.feature.mindAndBody ? this.wrapNav('nav-subtopics',
                <TestNav level={1}
                  label="Subtopics"
                  icon={<TopicIcon className="DashboardIcon"
                  style={{ color: '#fff' }} />}
                  route="/subtopic-list" subRoutes={['/subtopic-edit']}
                />
                ) : null }

                {this.wrapNav('nav-collections',
                  <TestNav level={1}
                    label="Collections"
                    icon={<CollectionIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/collection-list" subRoutes={['/collection-edit']}
                  />
                )}

                { config.feature.challenges ? this.wrapNav('nav-subtopics',
                  <TestNav level={1}
                    label="Challenges"
                    icon={<ChallengesIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/challenges-list" subRoutes={['/challenges-edit']}
                  />
                ) : null }

                { config.feature.challenges ? this.wrapNav('nav-subtopics',
                  <TestNav level={1}
                    label="Challenge Subtopics"
                    icon={<TopicIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/challenge-subtopics-list" subRoutes={['/challenge-subtopic-edit']}
                  />
                ) : null }

                { config.feature.factors && this.wrapNav('nav-subtopics',
                  <TestNav level={1}
                    label="Factors"
                    icon={<FactorIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/factors-list" subRoutes={['/factor-edit']}
                  />
                ) }

                { config.feature.factors && this.wrapNav('nav-subtopics',
                  <TestNav level={1}
                    label="Factor Subtopics"
                    icon={<TopicIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/factor-subtopics-list" subRoutes={['/factor-subtopic-edit']}
                  />
                ) }

                { config.feature.referralCodes && this.wrapNav('nav-subtopics',
                  <TestNav level={1}
                    label="Referral Codes"
                    icon={<MoneyOffIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/referral-codes-list" subRoutes={['/referral-code-edit']}
                  />
                ) }
              </Nav>

             <Nav id="1">
                <NavCat label="SOS"/>

                {this.wrapNav('nav-subtopics',
                  <TestNav level={1}
                    label="SOS Experiences"
                    icon={<SessionIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/sos-experiences-list" subRoutes={['/sos-experience-edit']}
                  />
                )}
                {this.wrapNav('nav-subtopics',
                  <TestNav level={1}
                    label="SOS Experience Subtopics"
                    icon={<TopicIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/sos-experience-subtopics-list" subRoutes={['/sos-experience-subtopic-edit']}
                  />
                )}
              </Nav>

              { config.feature.otherSection
                ? <Nav id="1">
                <NavCat label="Other"/>

                {this.wrapNav('nav-faq',
                  <TestNav level={1}
                    label="FAQ"
                    icon={<HelpIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/faq" subRoutes={['/faq-edit']}
                  />
                )}

                {this.wrapNav('nav-about',
                  <TestNav level={1}
                    label="About Pathways"
                    icon={<HelpIcon className="DashboardIcon"
                    style={{ color: '#fff' }} />}
                    route="/session-about-edit"
                  />
                )}
              </Nav>
              : null }
            </SideNav>

            <div style={{ height: '64px' }}></div>
          </div>
        </div>
      </div>
    )
  }
}

export default SideMenu
