import React from 'react'
import HomePage from '../home'
import Formsy from 'formsy-react'
import MyPage from '../../components/MyPage'
import MyInput from '../../components/MyInput'
import ErrorMessage from '../../components/errormessage'
import MySelect from '../../components/MySelect'
import { actions } from '../../redux/modules/factors'
import { actions as factorSubtopicActions } from '../../redux/modules/factorSubtopics'

class FactorsEdit extends MyPage {
  constructor (props) {
    super(props)
    this.state = {
      factorSubtopicOptions: [],
      errorMessage: '',
      canSubmit: false,
      showValidationErrors: false,
      isLoading: true,
      openDialog: false
    }
  }

  getObject = () => {
    if (this.props.location.state && this.props.location.state.obj) {
      const object = this.props.location.state.obj

      this.setState({
        object,
        selectedFactorSubtopicOptions: this.getSelectedFactorSubtopicOptions(object.factorSubtopics),
        isLoading: false
      })
    } else {
      this.setState({
        object: {
          title: '',
          selectedFactorSubtopicOptions: [],
        },
        isLoading: false
      })
    }
  }

  /**
   * Loads Factor Subtopics and sets to state
   * @param {number} page
   */
  async loadFactorSubtopicOptions (page = 1) {
    let { factorSubtopicOptions } = this.state

    const { data, error } = await factorSubtopicActions.getList({
      page,
      perPage: 50,
      order: 'ASC',
      orderBy: 'title'
    })

    if (error) {
      this.setState({ errorMessage: error })
    }

    const { items, total } = data

    if (!items || !items.length || !total) {
      return
    }

    factorSubtopicOptions = factorSubtopicOptions.concat(items.map(({ _id, title }) => ({ value: _id, label: title })))

    if (factorSubtopicOptions.length) {
      this.setState({
        factorSubtopicOptions
      },
        () => {
          const currentLength = this.state.factorSubtopicOptions.length

          if (currentLength && currentLength < parseInt(total)) {
            this.loadFactorSubtopicOptions(page + 1)
          }
        })
    }
  }

  getSelectedFactorSubtopicOptions (factorSubtopics) {
    if (factorSubtopics && factorSubtopics.length) {
      return factorSubtopics.map(({ _id, title }) => ({ value: _id, label: title }))
    }

    return []
  }

  getTexts = () => {
    return {
      pageTitle: 'Factor Subtopic'
    }
  }

  handleChange = e => {
    e.preventDefault()
    const target = e.target
    this.setState(state => {
      return {
        object: {
          ...state.object,
          [target.id]: target.value
        }
      }
    })
  }

  cancel = e => {
    e.preventDefault()
    this.props.router.push('/factors-list')
  }

  save = async e => {
    e.preventDefault()
    if (window.confirm('Are you sure you want to save this Factor?')) {
      if (this.state.selectedFactorSubtopicOptions) {
        this.state.object.factorSubtopicIds = this.state.selectedFactorSubtopicOptions.map(option => option.value)
      }

      const result = await actions.save(this.state.object)
      if (result) {
        if (!result.success) {
          this.setState({ errorMessage: result.error })
        } else {
          this.cancel(e)
          this.setState({ errorMessage: '' })
        }
      }
    }
  }

  componentDidMount () {
    this.getObject()
    this.loadFactorSubtopicOptions()
  }

  render () {
    const style = { color: 'red' }

    return (
      <HomePage router={this.props.router}>
        <div className="devices-page-wrapper">
          <ErrorMessage text={this.state.errorMessage} />
          <h3>{this.getTexts().pageTitle}</h3>
          {!this.state.isLoading && (
            <Formsy
              className="create-device-form"
              onValidSubmit={this.submit}
              onInvalidSubmit={() =>
                this.setState({ showValidationErrors: true })
              }
              onValid={() => this.setState({ canSubmit: true })}
              onInvalid={() => this.setState({ canSubmit: false })}
            >
              <div className="create-device-row">
                <label>
                  Title <span style={style}>*</span>
                </label>
                <MyInput
                  id="title"
                  name="title"
                  classNameDiv="create-branch-row"
                  className="create-device-input"
                  autocomplete="off"
                  value={this.state.object.title}
                  onChange={this.handleChange}
                  required
                  showError={this.state.showValidationErrors}
                />
              </div>
              <div className="create-device-row">
                <label>
                  Factor Subtopics
                </label>
                <MySelect
                  className="create-branch-select"
                  stateField="selectedFactorSubtopicOptions"
                  value={this.state.selectedFactorSubtopicOptions}
                  options={this.state.factorSubtopicOptions}
                  onChange={this.handleSelect}
                  isMulti={true}
                  isSearchable={true}
                />
              </div>
              <button
                className="btn green mr-2"
                type="submit"
                onClick={this.state.canSubmit ? this.save : undefined}
              >
                Save
              </button>
              <button
                className="btn blue mr-2"
                type="button"
                onClick={this.cancel}
              >
                Cancel
              </button>
            </Formsy>
          )}
        </div>
      </HomePage>
    )
  }
}

export default FactorsEdit
