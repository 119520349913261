import React from 'react'
import HomePage from '../home'
import MyPage from '../../components/MyPage'
import MyTable from '../../components/MyTable'
import MyTableHeaderSort from '../../components/MyTableHeaderSort'
// import { SearchIcon } from '../../Icons/svg'
import DeleteIcon from '@material-ui/icons/Delete'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button } from '@material-ui/core'
import ErrorMessage from '../../components/errormessage'

import { actions as challengesActions } from '../../redux/modules/challenges'

class ChallengesList extends MyPage {
  constructor (props) {
    super(props)
    this.state = {
      errorMessage: '',
      fullList: [],
      filteredList: [],
      isLoading: true,
      openDialog: false,
      deleteChallengeId: null,
      deleteChallengeTitle: null,
      order: 'asc',
      orderBy: ''
    }
  }

  getList = async () => {
    const data = await challengesActions.getChallengesList()
    return data
  }

  getTexts = () => {
    return {
      tableTitle: 'Challenges'
    }
  }

  edit = (e, obj) => {
    e.preventDefault()
    this.props.router.push({
      pathname: '/challenge-edit',
      state: { obj: obj }
    })
  }

  handleReorder = async items => {
    items.forEach((element, index) => {
      element.index = index
    })

    this.setState({ fullList: items, order: 'asc', orderBy: 'index' })

    const result = await challengesActions.saveChallengesList(this.state.fullList)

    if (!result.success) {
      this.setState({ errorMessage: result.error })
    } else {
      this.setState({ errorMessage: '' })
    }
  }

  handleDeleteChallenge = (e, item) => {
    this.setState({
      deleteChallengeId: item._id,
      deleteChallengeTitle: item.title
    })
    this.handleOpenDialog()
  }

  handleOpenDialog = () => {
    this.setState({
      openDialog: true,
    })
  }

  handleCloseDialog = value => {
    this.setState({
      isLoading: false,
      openDialog: false,
      deleteChallengeId: null,
      deleteChallengeTitle: null
    })
  }

  handleConfirmDialog = async () => {
    // Delete challenge
    if (this.state.deleteChallengeId) {
      this.setState({
        isLoading: true
      })

      const result = await challengesActions.deleteChallenge(this.state.deleteChallengeId)

       if (result.success) {
        const list = await this.getList()
        this.setState({
          errorMessage: '',
          isLoading: false,
          fullList: list,
          filteredList: list
        })
      } else {
        this.setState({ errorMessage: result.error })
      }
    }

    this.handleCloseDialog()
  }

  async componentDidMount () {
    const list = await this.getList()
    this.setState({
      fullList: list,
      filteredList: list,
      isLoading: false
    })
  }

  makeToolBar = () => {
    return (
      <div className='device-tools'>
        <div>
          <button className={'btn green mr-2'} onClick={this.edit}>+</button>
        </div>
      </div>
    )
  }

  makeHead = () => {
    return (
      <div className={'table-head'}>
        <div style={{ width: '40%', paddingLeft: '15px' }}>
          <MyTableHeaderSort
            label="Title"
            active={this.state.orderBy === 'title'}
            direction={this.state.order}
            onClick={this.createSortHandler('title')}
          />
        </div>

        <div style={{ width: '10%', paddingLeft: '15px' }}>
          Visible by default
        </div>

        <div style={{ width: '30%', paddingLeft: '15px' }}>
          Visible in sessions
        </div>

        <div style={{ width: '10%', paddingLeft: '15px' }}>
        </div>

        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Actions" />
        </div>
      </div>
    )
  }

  makeRow = item => {
    return (
      <div className={'table-row'}>
        <div style={{ width: '40%', paddingLeft: '15px' }}>
          {item.title}
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          { item.isVisibleByDefault ? 'Yes' : 'No' }
        </div>
        <div style={{ width: '30%', paddingLeft: '15px' }}>
          {
            item.visibleInSessions
              ? item.visibleInSessions.map((session, index) => (
                <React.Fragment key={`${session.title}-${index}`}>
                  {session.title}
                  <br />
                </React.Fragment>
              ))
              : null
          }
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <button
            type="button"
            className="btn"
            onClick={e => this.edit(e, item)}
          >
            Edit
          </button>
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <button
            type="button"
            className="btn"
            onClick={e => this.handleDeleteChallenge(e, item)}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
    )
  }

  render () {
    return (
      <HomePage router={this.props.router} addClassName="h-100">
        <div className="device-table-container">
          <ErrorMessage text={this.state.errorMessage} />
          {!this.state.isLoading && (
            <MyTable
              tableName={this.getTexts().tableTitle}
              tableToolBar={this.makeToolBar}
              tableHead={this.makeHead}
              tableRow={this.makeRow}
              isDraggable={true}
              hasIndex={true}
              order={this.state.order}
              orderBy={this.state.orderBy}
              data={this.state.filteredList}
              onDragEnd={items => {
                this.handleReorder(items)
              }}
              hasPagination={true}
              rowsPerPage={10}
            />
          )}
        </div>
        <Dialog
          onClose={this.handleCloseDialog}
          open={this.state.openDialog}
          aria-labelledby="delete-dialog-title"
        >
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to delete Challenge "{this.state.deleteChallengeTitle}"?
          </DialogTitle>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 24px 24px 24px'
          }}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleCloseDialog}>Cancel</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleConfirmDialog}>Ok</Button>
          </div>
        </Dialog>
      </HomePage>
    )
  }
}

export default ChallengesList
