import React from 'react'
import './Style.css'
import classNames from 'classnames'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import ListIcon from '@material-ui/icons/List'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    padding: '2%'
  },
  table: {
    minWidth: 200
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  selectedBar: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '48px',
    paddingLeft: '24px',
    paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark
        },
  selectedBarText: {
    fontSize: '0.825rem',
    fontFamily: 'Roboto Helvetica Arial, sans-serif',
    lineHeight: '1.5em'
  }
})

const dragReorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

class TableWithPagination extends React.Component {
  constructor (props) {
    super(props)
    const { data, selected } = this.props
    this.state = {
      dragItems: data || [],
      selected: selected || [],
    }
  }

  handleChangePage = (e, page) => {
    // M-UI pagination that starts from 0
    this.props.handleChangePage(page + 1)
  }

  onDragEnd = result => {
    if (!result.destination) {
      return
    }
    const dragItems = this.props.data
    const startIndex = this.props.rowsPerPage * this.props.page
    let items = dragReorder(
      dragItems,
      result.source.index,
      result.destination.index
    )
    items = items.map((item, index) => {
      item.index = index + startIndex
      return item
    })

    if (this.props.onDragEnd) {
      this.props.onDragEnd(items)
    }
  }

  handleCheckAll = e => {
    const { selected } = this.state
    let newSelected
    if (selected.length === this.props.data.length) {
      newSelected = []
    } else {
      newSelected = this.props.data
    }

    this.setState({ selected: newSelected })
    if (this.props.handleCheck) {
      this.props.handleCheck(e, newSelected)
    }
  }

  handleCheckBox = (e, item) => {
    const { selected } = this.state
    const selectedIdList = selected.map(element => element._id)
    const selectedIndex = selectedIdList.indexOf(item._id)

    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    this.setState({ selected: newSelected })
    if (this.props.handleCheck) {
      this.props.handleCheck(e, newSelected)
    }
  }

  handleChangeRowsPerPage = e => {
    this.props.handleChangeRowsPerPage(e.target.value)
  }

  isSelected = item => {
    return (
      this.state.selected.find(element => element._id === item._id) !==
      undefined
    )
  }

  render () {
    const { totalCount, data, page, rowsPerPage, rowsPerPageOptions = [25, 50, 100, 250]  } = this.props
    const { selected } = this.state
    
    return (
      <div>
        <h3 style={{ paddingLeft: '15px' }}>{this.props.tableName}</h3>
        <Paper className={this.props.classes.root}>
          {this.props.tableToolBar && <div>{this.props.tableToolBar()}</div>}
          {this.props.isCheckable && (
            <div
              className={classNames(this.props.classes.selectedBar, {
                [this.props.classes.highlight]: selected.length > 0
              })}
            >
              {selected.length > 0 && (
                <span className={this.props.classes.selectedBarText}>
                  {selected.length} selected
                </span>
              )}
            </div>
          )}
          <div className={this.props.classes.tableWrapper}>
            <div
              className={this.props.classes.table}
              aria-labelledby="tableTitle"
            >
              <DragDropContext onDragEnd={this.onDragEnd}>
                <div>
                  <div className={'table-head bb'}>
                    {this.props.isDraggable && (
                      <div style={{ width: '32px' }} />
                    )}
                    {this.props.isCheckable && (
                      <div padding="checkbox">
                        <Checkbox
                          indeterminate={
                            this.state.selected.length > 0 &&
                            this.state.selected.length < this.props.data.length
                          }
                          checked={
                            this.state.selected.length ===
                            this.props.data.length
                          }
                          onClick={this.handleCheckAll}
                        />
                      </div>
                    )}
                    {this.props.hasIndex && (
                      <div style={{ textAlign: 'left', flexDirection: 'row' }}>
                        #
                      </div>
                    )}
                    {this.props.tableHead && this.props.tableHead()}
                  </div>
                </div>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      className="table-body"
                      ref={provided.innerRef}
                      style={{
                        fontSize: '0.8125rem',
                        fontWeight: 400,
                        width: '100%',
                        flex: 1
                      }}
                    >
                      {data && data.map((item, index) => {
                          const isSelected = this.isSelected(item)
                          return (
                            <Draggable
                              key={item._id}
                              draggableId={item._id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  role="checkbox"
                                  aria-checked={isSelected}
                                  tabIndex={-1}
                                  key={item._id}
                                  selected={isSelected}
                                  className={'table-row bb'}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  {this.props.isDraggable ? (
                                    <div
                                      {...provided.dragHandleProps}
                                      style={{ width: '32px' }}
                                    >
                                      <ListIcon />
                                    </div>
                                  ) : (
                                    <div {...provided.dragHandleProps} />
                                  )}
                                  {this.props.isCheckable && (
                                    <div padding="checkbox">
                                      <Checkbox
                                        checked={isSelected}
                                        onClick={event =>
                                          this.handleCheckBox(event, item)
                                        }
                                      />
                                    </div>
                                  )}
                                  {this.props.hasIndex &&
                                    this.props.notIndexFirst && (
                                      <div padding="none">
                                        {index === 0
                                          ? '-'
                                          : page * rowsPerPage + index}
                                      </div>
                                    )}
                                  {this.props.hasIndex &&
                                    !this.props.notIndexFirst && (
                                      <div padding="none">
                                        {page * rowsPerPage + index + 1}
                                      </div>
                                    )}
                                  {this.props.tableRow(item)}
                                </div>
                              )}
                            </Draggable>
                          )
                        })}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
          {this.props.hasPagination && (
            <TablePagination
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              page={page}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </div>
    )
  }
}

TableWithPagination.propTypes = {
  tableName: PropTypes.string,
  tableToolBar: PropTypes.func,
  tableHead: PropTypes.func,
  tableRow: PropTypes.func,
  isDraggable: PropTypes.bool,
  isCheckable: PropTypes.bool,
  hasIndex: PropTypes.bool,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDragEnd: PropTypes.func,
  selected: PropTypes.array,
  handleCheck: PropTypes.func,
  hasPagination: PropTypes.bool,
  notIndexFirst: PropTypes.bool,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired
}

export default withStyles(styles)(TableWithPagination)
