import React, { Component } from 'react';

import './index.css';
// import Desktop from './desktop';
import Ipad from './iPad'

class Header extends Component {

  render() {
    return (
        <Ipad router={this.props.router}/>
    );
  }
}

export default Header;
