import { fetchFromUrlPOSTAsync, fetchFromUrlGETAsync, fetchFromUrlPATCHAsync, fetchFromUrlDELETEAsync } from '../fetch'

export const actions = {
  getChallengesList: async ({ type = null } = {}) => {
    const urlParams = new URLSearchParams('')
    let result = {}

    if (type) {
      urlParams.append('type', type)
    }

    if (urlParams) {
      result = await fetchFromUrlGETAsync(`/challenges/get-list?${urlParams}`)
    } else {
      result = await fetchFromUrlGETAsync('/challenges/get-list', [])
    }

    return result.success ? result.data : []
  },

  saveChallenge: async saveData => {
    let urlParams = [{ key: 'saveData', value: saveData }]

    if (saveData._id) {
      const result = await fetchFromUrlPATCHAsync('/challenges/' + saveData._id, urlParams)
      return result
    } else {
      const result = await fetchFromUrlPOSTAsync('/challenges', urlParams)
      return result
    }
  },

  saveChallengesList: async data => {
    let saveData = data.map(item => {
      return {
        _id: item._id,
        index: item.index
      }
    })

    let urlParams = [{ key: 'saveData', value: saveData }]
    const result = await fetchFromUrlPATCHAsync('/challenges', urlParams)
    return result
  },

  deleteChallenge: async challengeId => {
    const result = await fetchFromUrlDELETEAsync('/challenges/' + challengeId)
    return result
  }
}
