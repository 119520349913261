import React, { Component } from 'react';
import { connect } from "react-redux";
import './Style.css';
import ErrorMessage from '../../components/errormessage';

import { actions as adminActions } from '../../redux/modules/admin';
import { actions as userActions } from '../../redux/modules/users';

import md5 from 'md5';

import config from '../../config'

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            inputError: ""
        };
    }

    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const result = await adminActions.getAdmin(this.state.username, md5(this.state.password));
        if (result.success) {
            userActions.saveToken(result.accessToken);
            this.props.router.push('/dashboard');
        } else {
            this.setState({ inputError: result.errMsg });
            console.log(this.state.inputError, result);
            
        }
    }

    render() {
        return (
            <div className="d-flex">
                <div className="login-page d-flex" >
                <div className='background-img p-0'>
                <div className='background-color'></div>
                </div>
                    <div className="login-wrapper">
                        <h3 className="login-welcome">{ config.title }</h3>
                        <form className="login-form" onSubmit={this.handleSubmit}>
                            <div className="d-flex flex-column align-items-start">
                                <input className="login-input" placeholder="Username" id="username" autoFocus value={this.state.username} onChange={this.handleChange} />
                            </div>
                            <div className="mt-3 d-flex flex-column align-items-start">
                                <input className="login-input" type="Password" id="password" placeholder="Password" value={this.state.password} onChange={this.handleChange} />
                            </div>
                            <ErrorMessage text={this.state.inputError} />
                            <button className="login-button" type="submit" onClick={this.handleClick}>
                                <p className="login-button-text">LOGIN</p>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        admin: state.adminReducer.admin
    };
}
export default connect(mapStateToProps, null)(Login);
