import { fetchFromUrlPOSTAsync, fetchFromUrlDELETEAsync } from '../fetch/';

export const actions = {

  getQuizList: async () => {
    const result = await fetchFromUrlPOSTAsync('/quiz/get_all', []);
    return result.success ? result.data : [];
  },

  getQuestionsList: async (quizId) => {
    let urlParams = [
      { key: 'quizId', value: quizId },
    ];
    const result = await fetchFromUrlPOSTAsync('/quiz/get_questions', urlParams);
    return result.success ? result.data : [];
  },

  saveQuiz: async (saveData) => {
    let urlParams = [
      { key: 'saveData', value: saveData },
    ];
    const result = await fetchFromUrlPOSTAsync('/quiz/save_one', urlParams);
    return result;
  },

  deleteQuiz: async (quizId) => {
    const result = await fetchFromUrlDELETEAsync('/quiz/' + quizId);
    return result;
  },

  deleteQuestion: async (quizId, questionId) => {
    const result = await fetchFromUrlDELETEAsync('/quiz/' + quizId + '/questions/' + questionId);
    return result;
  }
};
