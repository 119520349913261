import React, { Component } from 'react';
import HomePage from '../home';
import Formsy from 'formsy-react';
import MyInput from '../../components/MyInput';
import MySelect from '../../components/MySelect';
// import Select from 'react-select';
import ErrorMessage from '../../components/errormessage';

import { actions as taskActions } from '../../redux/modules/feelGoodTasks';
import { actions as sessionActions } from '../../redux/modules/sessions';

class TaskEdit extends Component {

  constructor(props) {
    super(props);
    const obj = this.getObject();

    // determine sessionId: .find

    this.state = {
      object: obj,
      title: obj.title,
      question: obj.question,
      description: obj.description,
      maxLevel: obj.maxLevel,
      // sessionId: obj.sessionId,
      sessionList: [],
      errorMessage: "",
      canSubmit: false,
      showValidationErrors: false,
      isLoading: true,
    };
  }

  getObject = () => {
    if (this.props.location.state  && this.props.location.state.obj) {
      return this.props.location.state.obj;
    } else {
      return {
        title: "",
        type: 1,
        question: "",
        description: "",
        maxLevel: 5,
        // sessionId: null,
      };
    }
  }

  getTexts = () => {
    return {
      pageTitle: "Feel Good Task",
    }
  }

  handleChange = e => {
    e.preventDefault();
    this.setState({
        [e.target.id]: e.target.value
    });
  }

  handleSelect = (stateId, value) => {
    this.setState({ [stateId]: value });
  }

  cancel = e => {
    e.preventDefault();
    this.props.router.push('/task-list');
  }

  save = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to save this task?")) {
      this.state.object.title = this.state.title;
      this.state.object.question = this.state.question;
      this.state.object.description = this.state.description;
      this.state.object.maxLevel = this.state.maxLevel;
      this.state.object.sessionId = this.state.sessionId;

      const result = await taskActions.saveTask(this.state.object);
      if (result) {
        this.cancel(e);
      }
    }
  }

  async componentDidMount() {
    const sessionList = await sessionActions.getSessionsList();
    let sessionId = null;
    if (this.state.object._id) {
      const selectedSession = await sessionActions.getByFeelGoodTaskId(this.state.object._id);
      if (selectedSession) {
        sessionId = selectedSession._id;
      }
    }

    this.setState({ sessionList, sessionId, isLoading: false });
  }

  render() {
    const style = {color:'red'}
    return (
      <HomePage router={this.props.router}>
        {! this.state.isLoading &&
          <div className="devices-page-wrapper">
            <h3>{this.getTexts().pageTitle}</h3>
            <Formsy className='create-device-form'
              onValidSubmit={this.submit}
              onInvalidSubmit={() => this.setState({ showValidationErrors: true })}
              onValid={() => this.setState({ canSubmit: true })}
              onInvalid={() => this.setState({ canSubmit: false })}
            >
              <div className='create-device-row'>
                <label>Title <span style={style}>*</span></label>
                <MyInput
                  id="title"
                  name="title"
                  classNameDiv='create-branch-row'
                  className='create-device-input'
                  autocomplete="off"
                  value={this.state.title}
                  onChange={this.handleChange}
                  required
                  showError={this.state.showValidationErrors}
                />
              </div>
              <div className='create-device-row'>
                <label>Question</label>
                <MyInput
                  id="question"
                  name="question"
                  classNameDiv='create-branch-row'
                  className='create-device-input'
                  autocomplete="off"
                  value={this.state.question}
                  onChange={this.handleChange}
                />
              </div>
              <div className='create-device-row'>
                <label>Description</label>
                <textarea
                  id="description"
                  name="description"
                  className='create-device-input'
                  rows={5}
                  value={this.state.description}
                  onChange={this.handleChange}
                />
              </div>
              <div className='create-device-row'>
                <label>Max Level</label>
                <MySelect
                  stateField="maxLevel"
                  className='create-branch-select'
                  value={this.state.maxLevel}
                  options={[
                    { value: 5, label: '5' },
                    { value: 10, label: '10' },
                  ]}
                  onChange={this.handleSelect}
                />
              </div>

              {this.state.object.type === 1 &&
                <div className='create-device-row'>
                  <label>Session</label>
                  <MySelect
                    stateField="sessionId"
                    className="create-branch-select"
                    value={this.state.sessionId}
                    options={[{ value: null, label: "None" }].concat(this.state.sessionList.map((session) => {
                      return { value: session._id, label: session.index + 1 + " - " + session.title };
                    }))}
                    onChange={this.handleSelect}
                  />
                </div>
              }

              <ErrorMessage text={this.state.errorMessage}/>
              <button className='btn green mr-2' type="submit" onClick={this.state.canSubmit ? this.save : undefined}>Save</button>
              <button className='btn blue mr-2' type="button" onClick={this.cancel}>Cancel</button>
              {/* <button className='btn red mr-2' type="button" onClick={this.delete}>Delete</button> */}
            </Formsy>
          </div>
        }
      </HomePage>
    );
  }
}

export default TaskEdit;
