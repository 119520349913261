import { fetchFromUrlPOSTAsync, fetchFromUrlDELETEAsync } from '../fetch/'

export const actions = {
  getAuthorList: async () => {
    const result = await fetchFromUrlPOSTAsync('/author/get_all', [])
    return result.success ? result.data : []
  },

  saveAuthor: async saveData => {
    let urlParams = [{ key: 'saveData', value: saveData }]
    const result = await fetchFromUrlPOSTAsync('/author/save_one', urlParams)
    return result
  },

  getSessions: async authorId => {
    let urlParams = [{ key: 'authorId', value: authorId }]
    const result = await fetchFromUrlPOSTAsync('/session/get_by_author_id', urlParams)
    return result.success ? result.data : []
  },

  deleteById: async authorId => {
    try {
      const result = await fetchFromUrlDELETEAsync(`/author/delete/${authorId}`)
      if (result.status === 200) {
        return { success: true }
      }
      if (result.status === 422 && result.validationErrors) {
        return { success: false, validationErrors: result.validationErrors }
      }
      return false
    } catch (e) {
      return false
    }
  }
}
