import { url } from './urlHelper'
import { store } from '../store'

export async function fetchFromUrlGETAsync (urlxx, parameters) {
  const urlFinal = url(urlxx)
  const authData = 'Web:' + store.getState().usersReducer.accessToken

  try {
    const response = await fetch(urlFinal, {
      method: 'GET',
      timeout: 3000,
      headers: {
        authorization: authData
      }
    })
    if (!response.ok) {
      throw Error(response.responseText)
    }
    const json = await response.json()
    if (json.forceLogout) {
      store.dispatch({ type: 'SET_TOKEN', payload: '' })
    }
    return json
  } catch (error) {
    throw error
  }
}

export async function fetchFromUrlPOSTAsync (urlxx, parameters, toSend = {}) {
  let urlFinal = url(urlxx)
  parameters.forEach(par => {
    toSend[par.key] = par.value
  })
  toSend.accessToken = store.getState().usersReducer.accessToken
  toSend.device = 'Web'
  try {
    const response = await fetch(urlFinal, {
      method: 'POST',
      timeout: 3000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(toSend)
    })
    if (!response.ok && response.status !== 422) {
      throw Error(response.responseText)
    }
    const json = await response.json()
    if (json.forceLogout) {
      store.dispatch({ type: 'SET_TOKEN', payload: '' })
    }
    return json
  } catch (error) {
    throw error
  }
}

export async function fetchFromUrlPATCHAsync (urlxx, parameters, toSend = {}) {
  let urlFinal = url(urlxx)
  parameters.forEach(par => {
    toSend[par.key] = par.value
  })
  toSend.accessToken = store.getState().usersReducer.accessToken
  toSend.device = 'Web'
  try {
    const response = await fetch(urlFinal, {
      method: 'PATCH',
      timeout: 3000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(toSend)
    })
    if (!response.ok && response.status !== 422) {
      throw Error(response.responseText)
    }
    const json = await response.json()
    if (json.forceLogout) {
      store.dispatch({ type: 'SET_TOKEN', payload: '' })
    }
    return json
  } catch (error) {
    throw error
  }
}

export async function fetchFromUrlDELETEAsync (urlxx, parameters) {
  const urlFinal = url(urlxx)
  const authData = 'Web:' + store.getState().usersReducer.accessToken

  try {
    const response = await fetch(urlFinal, {
      method: 'DELETE',
      timeout: 3000,
      headers: {
        authorization: authData
      }
    })

    if (response.status === 204) {
      return { success: true }
    }

    const json = await response.json()
    json.status = response.status
    if (!response.ok && !json.error) {
      throw Error(response.responseText)
    }

    if (json.forceLogout) {
      store.dispatch({ type: 'SET_TOKEN', payload: '' })
    }
    return json
  } catch (error) {
    throw error
  }
}
