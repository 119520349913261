import React from 'react'
import HomePage from '../home'
import MyPage from '../../components/MyPage'
import MyTable from '../../components/MyTable'
import MyTableHeaderSort from '../../components/MyTableHeaderSort'
import { SearchIcon } from '../../Icons/svg'
import DeleteIcon from '@material-ui/icons/Delete'
import DeleteDialog from '../../components/DeleteDialog'
import { actions as topicActions } from '../../redux/modules/topics'
import { urlImg } from '../../redux/fetch/urlHelper'
import imageLib from '../../themes'
import config from '../../config'

class TopicList extends MyPage {
  constructor (props) {
    super(props)

    this.setIsSubtopicsList()

    this.state = {
      fullList: [],
      filteredList: [],
      isLoading: true,
      searchKeyword: '',
      order: 'asc',
      orderBy: ''
    }

    this.showSections = (config.feature.mindAndBody && !this.isSubtopicsList)
    this.showSubtopicsFieldOnly = this.isSubtopicsList
  }

  setIsSubtopicsList () {
    this.isSubtopicsList = false
  }

  getList = async () => {
    const data = await topicActions.getTopicList()
    return data
  }

  getTexts = () => {
    return {
      tableTitle: 'Topics',
      searchTitle: 'Search Topic'
    }
  }

  edit = (e, obj) => {
    e.preventDefault()
    this.props.router.push({
      pathname: '/topic-edit',
      state: { obj: obj }
    })
  }

  handleSearch = e => {
    e.preventDefault()
    let searchKeyword = e.target.value
    this.setState({ searchKeyword }, () => {
      const regexp = new RegExp(
        searchKeyword.replace(/[-+*.?|^$/()[\]{}\\]/g, '\\$&'),
        'gi'
      )
      let filteredList = this.state.fullList.filter(object => {
        return object.title.match(regexp)
      })
      this.setState({ filteredList })
    })
  }

  /**
   * Listens delete btn
   */
  handleItemDelete = (e, item) => {
    if (!item || !item._id) return
    const deleteDialogParams = {
      open: true,
      title: `Are you sure you want to delete "${item.title || ''}" ?`,
      handleConfirm: async () => {
        try {
          const delRes = await topicActions.deleteTopicById(item._id)
          if (!delRes) return
          if (delRes.success) {
            this.updateList()
          }
        } catch (e) {
          console.log(e)
        }
        deleteDialogParams.handleCancel()
      },
      handleCancel: () => {
        this.setState(state => {
          return {
            ...state,
            deleteDialogParams: false
          }
        })
      }
    }

    this.setState(state => {
      return {
        ...state,
        deleteDialogParams
      }
    })
  }

  handleReorder = async items => {
    items.forEach((element, index) => {
      element.index = index
    })
    this.setState({ fullList: items, order: 'asc', orderBy: 'index' })
    await topicActions.saveTopicsList(this.state.fullList)
  }

  updateList = async () => {
    const list = await this.getList()
    this.setState(state => {
      return {
        ...state,
        fullList: list,
        filteredList: list,
        isLoading: false
      }
    })
  }

  async componentDidMount () {
    await this.updateList()
  }

  makeToolBar = () => {
    return (
      <div className="device-tools">
        <div className="search-icon">
          <SearchIcon />
          <input
            className="device-search"
            onChange={this.handleSearch}
            placeholder={this.getTexts().searchTitle}
            tabIndex="-1"
          />
        </div>
        <div>
          <button className={'btn green mr-2'} onClick={this.edit}>
            +
          </button>
        </div>
      </div>
    )
  }

  makeHead = () => {
    return (
      <div className={'table-head'}>
        { !this.showSubtopicsFieldOnly
          ? <div style={{ width: '10%', paddingLeft: '15px' }}>
            <MyTableHeaderSort label="Image" />
          </div>
          : null }

        <div style={{ width: '60%', paddingLeft: '15px' }}>
          <MyTableHeaderSort
            label="Title"
            active={this.state.orderBy === 'title'}
            direction={this.state.order}
            onClick={this.createSortHandler('title')}
          />
        </div>

        { this.showSections
          ? <div style={{ width: '20%', paddingLeft: '15px' }}>
            <MyTableHeaderSort
              label="Section"
              active={this.state.orderBy === 'section'}
              direction={this.state.order}
              onClick={this.createSortHandler('section')}
            />
          </div>
          : null
        }

        { !this.showSubtopicsFieldOnly
          ? <div style={{ width: '20%', paddingLeft: '15px' }}>
            <MyTableHeaderSort
              label="Featured"
              active={this.state.orderBy === 'isFeatured'}
              direction={this.state.order}
              onClick={this.createSortHandler('isFeatured')}
            />
          </div>
          : null }

        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Actions" />
        </div>

        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Delete" />
        </div>
      </div>
    )
  }

  makeRow = item => {
    return (
      <div className={'table-row'}>
        { !this.showSubtopicsFieldOnly
          ? <div style={{ width: '10%', paddingLeft: '15px' }}>
            {item.imageFileName !== '' ? (
              <img
                style={{ width: 50, height: 50, objectFit: 'contain' }}
                src={urlImg(item.imageFileName)}
                alt=""
              />
            ) : (
              <img
                style={{ width: 50, height: 50, objectFit: 'contain' }}
                src={imageLib.noImage}
                alt=""
              />
            )}
          </div>
          : null }

        <div style={{ width: '60%', paddingLeft: '15px' }}>{item.title}</div>
        { this.showSections
          ? <div style={{ width: '20%', paddingLeft: '15px' }}>
              { item.section === 'mind' ? 'Mind' : item.section === 'body' ? 'Body' : '' }
            </div>
          : null
        }

        { !this.showSubtopicsFieldOnly
          ? <div style={{ width: '20%', paddingLeft: '15px' }}>
            {item.isFeatured ? 'Yes' : 'No'}
          </div>
          : null }

        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <button
            type="button"
            className="btn"
            onClick={e => this.edit(e, item)}
          >
            Edit
          </button>
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <button type="button" className="btn" onClick={e => this.handleItemDelete(e, item)}>
            <DeleteIcon />
          </button>
        </div>
      </div>
    )
  }

  render () {
    const { deleteDialogParams } = this.state
    return (
      <HomePage router={this.props.router} addClassName="h-100">
        <div className="device-table-container">
          {!this.state.isLoading && (
            <MyTable
              tableName={this.getTexts().tableTitle}
              tableToolBar={this.makeToolBar}
              tableHead={this.makeHead}
              tableRow={this.makeRow}
              isDraggable={true}
              // isCheckable={true}
              hasIndex={true}
              order={this.state.order}
              orderBy={this.state.orderBy}
              data={this.state.filteredList}
              onDragEnd={items => {
                this.handleReorder(items)
              }}
              hasPagination={true}
              rowsPerPage={10}
            />
          )}
        </div>
        {deleteDialogParams && <DeleteDialog {...deleteDialogParams} />}
      </HomePage>
    )
  }
}

export default TopicList
