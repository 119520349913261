import { fetchFromUrlGETAsync } from '../fetch'

export const actions = {
  getFactorsUsageStatistics: async () => {
    try {
      const result = await fetchFromUrlGETAsync('/pain-statistics/factors-usage', [])
      return result.success ? result.data : null
    } catch (e) {
      console.log(e.message)
    }
  },

  getCustomFactorsUsageStatistics: async () => {
    try {
      const result = await fetchFromUrlGETAsync('/pain-statistics/custom-factors-usage', [])
      return result.success ? result.data : null
    } catch (e) {
      console.log(e.message)
    }
  }
}
