import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Router, browserHistory } from 'react-router'
import routes from './routes'

class AuthRouter extends Component {
  render () {
    const unprotectedRoutes = ['/forgot-password', '/forgot-password-change']
    const noToken = this.props.accessToken === ''
    !unprotectedRoutes.includes(browserHistory.getCurrentLocation().pathname) &&
      noToken &&
      browserHistory.push('/login')

    return <Router history={browserHistory} routes={routes} />
  }
}

function mapStateToProps (state) {
  return {
    accessToken: state.usersReducer.accessToken
  }
}
export default connect(
  mapStateToProps,
  null
)(AuthRouter)
