import React from 'react'

export default function TextAreaWithLabel (props) {
  let styles = {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2em'
  }
  return (
    <div style={styles}>
      <label>{props.label}</label>
      <textarea
        id={props.id}
        name={props.name}
        group={props.group}
        className="create-device-input"
        rows={4}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
      />
    </div>
  )
}
