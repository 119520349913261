import SessionEdit from '../../components/SessionEdit';
import { actions as sessionActions } from '../../redux/modules/sessions';

class AboutSessionEdit extends SessionEdit {

  constructor(props) {
    super(props);
    this.state.pageType = "about";
  }

  getDefaultObjectType() {
    return 6;
  }
  
  getObject = () => {
    return this.state.session;
  }

  getTexts() {
    return {
      pageTitle: "About Pathways Session",
    };
  }

  cancel = (e) => {
    e.preventDefault();
    this.props.router.push('/dashboard');
  }

  async componentDidMount() {
    const obj = await sessionActions.getAboutSession();
    this.setState({
      session: obj,
      title: obj.title,
      type: obj.type,
      description: obj.description,
      thoughts: obj.thoughts,
      imageFileName: obj.imageFileName,
      backgroundFileName: obj.backgroundFileName,
      audioFileName: obj.audioFileName,
      isLoading: false
    });
  }
}

export default AboutSessionEdit;
