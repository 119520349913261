export default item => {
  let diagnosis = false
  let diagnosisTitles = false

  if (item && item.diagnosis && item.diagnosis.length) {
    diagnosisTitles = item.diagnosis.map(({ title }) => title.trim() || '')
  }

  if (diagnosisTitles) {
    diagnosis = diagnosisTitles.join(', ')
  }

  if (item && item.diagnosisRequestTitle && item.diagnosisRequestTitle.length) {
    const diagnosisRequestTitles = item.diagnosisRequestTitle.join(', ')
    if (diagnosis) {
      diagnosis += diagnosisRequestTitles
    } else {
      diagnosis = diagnosisRequestTitles
    }
  }

  if (diagnosis) {
    return diagnosis
  }

  return 'None'
}
