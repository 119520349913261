import SessionEdit from '../../components/SessionEdit'
import config from '../../config'

class MeTimeSessionEdit extends SessionEdit {
  constructor (props) {
    super(props)
    this.state.pageType = 'meTime'
  }

  getDefaultObjectType () {
    return 5
  }

  getTexts () {
    if (config.feature && config.feature.mindAndBody) {
      return {
        pageTitle: this.isBodySession() ? 'Body Session' : 'Mind Session'
      }
    }

    return {
      pageTitle: config.customTitles && config.customTitles['meTimeSession']
      ? config.customTitles['meTimeSession'] : 'MeTime Session',
    }
  }

  cancel = event => {
    event.preventDefault()

    if (config.feature && config.feature.mindAndBody) {
      if (this.isBodySession()) {
        this.props.router.push('/body-sessions-list')
      } else {
        this.props.router.push('/mind-sessions-list')
      }
    } else {
      this.props.router.push('/session-metime-list')
    }
  }
}

export default MeTimeSessionEdit
