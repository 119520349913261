import { fetchFromUrlGETAsync } from '../fetch/'

export const actions = {
  getStatistics: async () => {
    try {
      const result = await fetchFromUrlGETAsync('/user/registration-polls/statistics', [])
      return result.success ? result.data : null
    } catch (e) {
      console.log(e.message)
    }
  }
}
