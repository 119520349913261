import React from 'react'
import { Modal } from 'reactstrap'
// import { Link } from 'react-router';

import Paper from '@material-ui/core/Paper'
// import Images from '../../themes';

import Formsy from 'formsy-react'
import MyInput from '../../components/MyInput'
import ErrorMessage from '../../components/errormessage'

export default class EditRegistrationPollOptionModal extends React.Component {
  constructor (props) {
    super(props)
    const obj = this.getObject()
    this.state = {
      modal: false,
      isCreating: !this.props.object,
      object: obj,
      pollOptionText: obj.pollOptionText,

      canSubmit: false,
      showValidationErrors: false,
    }
    this.toggle = this.toggle.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  getObject = () => {
    if (this.props.object) {
      return this.props.object
    } else {
      return {
        pollOptionText: ''
      }
    }
  }

  toggle () {
    // if (!this.state.modal && !this.props.object) {
    //     console.log("CREATE");
    // }
    if (!this.state.modal) {
      const obj = this.getObject()
      console.log('LOAD', obj)
      this.setState({
        object: obj,
        pollOptionText: obj.pollOptionText
      })
    }

    this.setState({
      modal: !this.state.modal
    })
  }

  handleChange = e => {
    e.preventDefault()
    console.log('e.target: ', e.target)
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSelect = (stateId, value) => {
    this.setState({ [stateId]: value })
  }

  handleSubmit (event) {
    event.preventDefault()
  }

  save = async e => {
    e.preventDefault()

    this.state.object.pollOptionText = this.state.pollOptionText

    this.toggle()

    this.props.onExit(this.state.object)
  }

  render () {
    const style = { color: 'red' }
    return (
      <div>
        {this.props.toggleButton}
        <button className="btn green mr-2" type="button" onClick={this.toggle}>
          {this.state.isCreating ? '+' : 'Edit'}
        </button>

        <Modal
          toggle={this.toggle}
          isOpen={this.state.modal}
        >
          <Paper style={{ width: '100%', padding: '2%' }}>
            <Formsy className='create-device-form80'
              onValidSubmit={this.submit}
              onInvalidSubmit={() => this.setState({ showValidationErrors: true })}
              onValid={() => this.setState({ canSubmit: true })}
              onInvalid={() => this.setState({ canSubmit: false })}
            >
              <div className='create-device-row'>
                <label>Title <span style={style}>*</span></label>
                <MyInput
                  id="pollOptionText"
                  name="pollOptionText"
                  classNameDiv='create-branch-row'
                  className='create-device-input'
                  autocomplete="off"
                  value={this.state.pollOptionText}
                  onChange={this.handleChange}
                  required
                  showError={this.state.showValidationErrors}
                />
              </div>

              <ErrorMessage text={this.state.errorMessage} />
              <button className='btn green mr-2' type="submit"
                onClick={this.state.canSubmit ? this.save : undefined}>Save</button>
              <button className='btn blue mr-2' type="button" onClick={this.toggle}>Cancel</button>
            </Formsy>
          </Paper>
        </Modal>
      </div>
    )
  }
}
