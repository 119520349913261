import config from '../config'

const Images = {
    // logo :              '/assets/svgs/swoop-logo.svg',
    logo : `/assets/svg/${config.title}_Logo_Vertical.svg`,
    // logo :              '/assets/svg/Pathways_Logo_icon.svg',

    backgroundLogo :    '/assets/images/backgroundLogo.png',

    loginBackground : '/assets/images/loginBackground.jpg',
    noImage : '/assets/images/noImage.png',

    personal: '/assets/svg/account.svg',


    fb_svg : '/assets/svg/fb.svg',
    gmail_svg : '/assets/svg/gmail.svg',

    test: '/assets/svg/fb.svg',
    crown: '/assets/svg/crown.svg',

    arrowDown: '/assets/svg/arrow-down.svg',
    arrowRight: '/assets/svg/arrow-right.svg',

}

export default Images
