import React from 'react';
import { connect } from "react-redux";
import HomePage from '../home';
import Formsy from 'formsy-react';
import MyPage from '../../components/MyPage';
import MyInput from '../../components/MyInput';
import MySelect from '../../components/MySelect';

import { actions as adminActions } from '../../redux/modules/admin';
import { actions as policyActions } from '../../redux/modules/policies';
import md5 from 'md5';


class Profile extends MyPage {
    constructor(props) {
        super(props);

        // let obj;
        let selfEdit = false;
        if (this.props.location.state) {
            // obj = this.props.location.state.obj;
            selfEdit = this.props.location.state.selfEdit;
        }
        // console.log("obj", obj);
        console.log("selfEdit", selfEdit);

        const obj = this.getObject();

        this.state = {
            object: obj,
            login: obj.login ? obj.login : "",
            firstName: obj.firstName ? obj.firstName : "",
            lastName: obj.lastName ? obj.lastName : "",
            email: obj.email ? obj.email : "",
            mobile: obj.mobile ? obj.mobile : "",
            memberImage: obj.memberImage ? obj.memberImage : "",
            policyId: obj.policyId ? obj.policyId : null,
            isLoading: true,
            canSubmit: false,
            showValidationErrors: false,
            selfEdit: selfEdit,
            isCreating: obj.hasOwnProperty('password'),
        };
        if (this.state.isCreating) {
            this.state.password = this.state.object.password;
        }
    }

    getObject = () => {
        if (this.props.location.state  && this.props.location.state.obj) {
          return this.props.location.state.obj;
        } else {
          return {
            login: "",
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
            memberImage: "",
            password: "",
            policyId: null,
          };
        }
      }

    getTexts = () => {
        return {
            pageTitle: "Edit Profile",
        }
    }

    cancel = (e) => {
        e.preventDefault();
        this.props.router.push(this.state.selfEdit ? '/dashboard' : '/admin-list');
    }

    save = async (e) => {
        e.preventDefault();


        this.state.object.login = this.state.login;
        if (this.state.isCreating) {
            this.state.object.password = md5(this.state.password);
        }
        this.state.object.firstName = this.state.firstName;
        this.state.object.lastName = this.state.lastName;
        this.state.object.email = this.state.email;
        this.state.object.mobile = this.state.mobile;
        this.state.object.policyId = this.state.policyId;
        const result = await adminActions.saveAdmin(this.state.object, this.state.selfEdit);
        if (result) {
            this.cancel(e);
        }
    }





    async componentDidMount() {


        // appeler PageChecker
        //pgchecker appelle le store et v2rifie l'admin



        const permissionList = await policyActions.getPolicyList();

        this.setState({
            permissionOptions: permissionList.map((element) => { return { value: element._id, label: element.name } }),
            isLoading: false,
        });
    }


    render() {
        const style = { color: 'red' };
        return (
            <HomePage router={this.props.router}>
            {! this.state.isLoading &&
                <div className="devices-page-wrapper">
                        <h3>{this.getTexts().pageTitle}</h3>
                        <Formsy className='create-branch-form'
                            onValidSubmit={this.submit}
                            onInvalidSubmit={() => this.setState({ showValidationErrors: true })}
                            onValid={() => this.setState({ canSubmit: true })}
                            onInvalid={() => this.setState({ canSubmit: false })}
                        >
                            <div className='create-branch-row'>
                                <label>Login <span style={style}>*</span></label>
                                <MyInput
                                id="login"
                                name="login"
                                classNameDiv='create-branch-row'
                                className='create-branch-input'
                                value={this.state.login}
                                onChange={this.handleChange}
                                validationError="This field is required"
                                required
                                showError={this.state.showValidationErrors}
                                />
                            </div>
                            {this.state.isCreating &&
                                <div className='create-branch-row'>
                                    <label>Password <span style={style}>*</span></label>
                                    <MyInput
                                        id="password"
                                        name="password"
                                        classNameDiv='create-branch-row'
                                        className='create-branch-input'
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        validationError="This field is required"
                                        required
                                        showError={this.state.showValidationErrors}
                                    />
                                </div>
                            }
                            <div className='create-branch-row'>
                                <label>First Name <span style={style}>*</span></label>
                                <MyInput
                                id="firstName"
                                name="firstName"
                                classNameDiv='create-branch-row'
                                className='create-branch-input'
                                value={this.state.firstName}
                                onChange={this.handleChange}
                                validationError="This field is required"
                                required
                                showError={this.state.showValidationErrors}
                                />
                            </div>
                            <div className='create-branch-row'>
                                <label>Last Name <span style={style}>*</span></label>
                                <MyInput
                                    id="lastName"
                                    name="lastName"
                                    classNameDiv='create-branch-row'
                                    className='create-branch-input'
                                    value={this.state.lastName}
                                    onChange={this.handleChange}
                                    validationError="This field is required"
                                    required
                                    showError={this.state.showValidationErrors}
                                />
                            </div>
                            <div className='create-branch-row'>
                                <label>Email <span style={style}>*</span></label>
                                <MyInput
                                    id="email"
                                    name="email"
                                    classNameDiv='create-branch-row'
                                    className='create-branch-input'
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    validations="isEmail"
                                    validationError="This is not a valid email"
                                    required
                                    showError={this.state.showValidationErrors}
                                />
                            </div>
                            <div className='create-branch-row'>
                                <label>Phone Number <span style={style}>*</span></label>
                                <MyInput
                                    id="mobile"
                                    name="mobile"
                                    classNameDiv='create-branch-row'
                                    className='create-branch-input'
                                    value={this.state.mobile}
                                    onChange={this.handleChange}
                                    validationError="This field is required"
                                    required
                                    showError={this.state.showValidationErrors}
                                />
                            </div>

                            <div className='create-branch-row'>
                                <label>Permissions <span style={style}>*</span></label>
                                <MySelect
                                    stateField="policyId"
                                    className="create-branch-select"
                                    value={this.state.policyId}
                                    options={this.state.permissionOptions}
                                    onChange={this.handleSelect}
                                    disabled={this.state.selfEdit}
                                />
                                <MyInput
                                    id="policyId"
                                    name="policyId"
                                    classNameDiv='create-branch-row'
                                    className='create-branch-input'
                                    value={this.state.policyId ? this.state.policyId : ""}
                                    onChange={this.handleChange}
                                    validationError="This field is required"
                                    required
                                    hidden
                                    showError={this.state.showValidationErrors}
                                />
                            </div>

                            <div className="w-100 d-flex justify-content-end">
                            <button className='btn green mr-2' type="submit" onClick={(this.state.canSubmit && this.state.policyId) ? this.save : undefined}>Save</button>
                            <button className='btn blue' type="button" onClick={this.cancel}>Cancel</button>
                            </div>
                        </Formsy>
                    </div>
                }
            </HomePage>
        );
    }
}

function mapStateToProps(state) {
    return {
        admin: state.adminReducer.admin,
        accessToken: state.usersReducer.accessToken
    };
}
export default connect(mapStateToProps, null)(Profile);
