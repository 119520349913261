import React from 'react'
import HomePage from '../home'
import MyPage from '../../components/MyPage'
import MyTable from '../../components/MyTable'
import MyTableHeaderSort from '../../components/MyTableHeaderSort'
import { SearchIcon } from '../../Icons/svg'

import { actions as taskActions } from '../../redux/modules/feelGoodTasks'

class TaskList extends MyPage {
  constructor (props) {
    super(props)
    this.state = {
      fullList: [],
      filteredList: [],
      isLoading: true,
      searchKeyword: '',
      order: 'asc',
      orderBy: ''
    }
  }

  getList = async () => {
    const data = await taskActions.getTasksList()
    return data
  }

  getTexts = () => {
    return {
      tableTitle: 'Feel Good Tasks',
      searchTitle: 'Search Task'
    }
  }

  edit = (e, obj = null) => {
    e.preventDefault()
    this.props.router.push({
      pathname: '/task-edit',
      state: { obj }
    })
  }

  handleSearch = e => {
    e.preventDefault()
    let searchKeyword = e.target.value
    this.setState({ searchKeyword }, () => {
      const regexp = new RegExp(
        searchKeyword.replace(/[-+*.?|^$/()[\]{}\\]/g, '\\$&'),
        'gi'
      )
      let filteredList = this.state.fullList.filter(object => {
        return object.title.match(regexp)
      })
      this.setState({
        filteredList
      })
    })
  }

  async componentDidMount () {
    let list = await this.getList()
    list = list
      .filter(task => task.type === 0)
      .concat(list.filter(task => task.type === 1))
    this.setState({
      fullList: list,
      filteredList: list,
      isLoading: false
    })
  }

  makeToolBar = () => {
    return (
      <div className="device-tools">
        <div className="search-icon">
          <SearchIcon />
          <input
            className="device-search"
            onChange={this.handleSearch}
            placeholder={this.getTexts().searchTitle}
            tabIndex="-1"
          />
        </div>
        <div>
          <button className={'btn green mr-2'} onClick={this.edit}>
            +
          </button>
        </div>
      </div>
    )
  }

  makeHead = () => {
    return (
      <div className={'table-head'}>
        <div style={{ width: '20%', paddingLeft: '15px' }}>
          <MyTableHeaderSort
            label="Title"
            active={this.state.orderBy === 'title'}
            direction={this.state.order}
            onClick={this.createSortHandler('title')}
          />
        </div>

        <div style={{ width: '20%', paddingLeft: '15px' }}>
          <MyTableHeaderSort
            label="Question"
            active={this.state.orderBy === 'Question'}
            direction={this.state.order}
            onClick={this.createSortHandler('Question')}
          />
        </div>

        <div style={{ width: '50%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Description" />
        </div>

        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <MyTableHeaderSort
            label="Max Level"
            active={this.state.orderBy === 'maxLevel'}
            direction={this.state.order}
            onClick={this.createSortHandler('maxLevel')}
          />
        </div>

        <div style={{ width: '20%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Actions" />
        </div>
      </div>
    )
  }

  makeRow = item => {
    return (
      <div className={'table-row'}>
        <div style={{ width: '20%', paddingLeft: '15px' }}>{item.title}</div>
        <div style={{ width: '20%', paddingLeft: '15px' }}>{item.question}</div>
        <div style={{ width: '50%', paddingLeft: '15px' }}>
          {this.shortenText(item.description, 200)}
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>{item.maxLevel}</div>

        <div style={{ width: '20%', paddingLeft: '15px' }}>
          <button
            type="button"
            className="btn"
            onClick={e => this.edit(e, item)}
          >
            Edit
          </button>
        </div>
      </div>
    )
  }

  render () {
    return (
      <HomePage router={this.props.router}>
        <div className="device-table-container">
          {!this.state.isLoading && (
            <MyTable
              tableName={this.getTexts().tableTitle}
              tableToolBar={this.makeToolBar}
              tableHead={this.makeHead}
              tableRow={this.makeRow}
              hasIndex={true}
              order={this.state.order}
              orderBy={this.state.orderBy}
              data={this.state.filteredList}
              hasPagination={true}
              rowsPerPage={10}
              notIndexFirst={true}
            />
          )}
        </div>
      </HomePage>
    )
  }
}

export default TaskList
