import React, { Component } from 'react';
import './Style.css';

class ErrorMessage extends Component {
  render() {
    if (this.props.text) {
      return (
        <div className="error-message">
          <h6>{this.props.text}</h6>
        </div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }
}

export default ErrorMessage;
