import { fetchFromUrlPOSTAsync, fetchFromUrlGETAsync, fetchFromUrlPATCHAsync, fetchFromUrlDELETEAsync } from '../fetch'

export const actions = {
  getMilestonesList: async () => {
    const result = await fetchFromUrlGETAsync('/journey/milestones', [])
    return result.success ? result.result : []
  },

  getJourneySessionsList: async () => {
    // TODO
    const result = await fetchFromUrlPOSTAsync('/session/get_sorted_list', [])
    return result.success ? result.data : []
  },

  saveMilestone: async saveData => {
    let urlParams = [{ key: 'saveData', value: saveData }]

    if (saveData._id) {
      const result = await fetchFromUrlPATCHAsync('/journey/milestone/' + saveData._id, urlParams)
      return result
    } else {
      const result = await fetchFromUrlPOSTAsync('/journey/milestone', urlParams)
      return result
    }
  },

  saveMilestonesList: async data => {
    let saveData = data.map(item => {
      return {
        _id: item._id,
        index: item.index
      }
    })

    let urlParams = [{ key: 'saveData', value: saveData }]
    const result = await fetchFromUrlPATCHAsync('/journey/milestones', urlParams)
    return result
  },

  deleteMilestone: async milestoneId => {
    const result = await fetchFromUrlDELETEAsync('/journey/milestone/' + milestoneId)
    return result
  }
}
