import React from 'react'
import HomePage from '../../pages/home'
import MyPage from '../../components/MyPage'
import MyTable from '../../components/MyTable'
import MyTableHeaderSort from '../../components/MyTableHeaderSort'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { SearchIcon } from '../../Icons/svg'
import imageLib from '../../themes'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'

import { actions as sessionActions } from '../../redux/modules/sessions'
import { actions as sessionTypeActions } from '../../redux/modules/sessionTypes'
import { urlImg, urlAudio } from '../../redux/fetch/urlHelper'
import { Button } from '@material-ui/core'
import config from '../../config'

class SessionList extends MyPage {
  constructor (props) {
    super(props)
    this.state = {
      pageType: '',
      fullList: [],
      filteredList: [],
      taskList: [],
      isLoading: true,
      searchKeyword: '',
      order: 'asc',
      orderBy: '',
      open: false,
      deleteSessionId: null,
      sessionTypes: []
    }
  }

  getList = async () => {
    return []
  }

  getTaskList = async () => {
    return []
  }

  getTexts = () => {
    return {
      tableTitle: 'tableTitle',
      searchTitle: 'searchTitle'
    }
  }

  getEditRoute = () => {
    return '/editRoute'
  }

  edit = (e, obj = null) => {
    e.preventDefault()
    this.props.router.push({
      pathname: this.getEditRoute(),
      state: { obj }
    })
  }

  editTranscription = (e, obj = null) => {
    e.preventDefault()
    this.props.router.push({
      pathname: '/session-transcription-edit',
      state: { obj }
    })
  }

  handleSearch = e => {
    e.preventDefault()
    let searchKeyword = e.target.value
    this.setState({ searchKeyword }, () => {
      const regexp = new RegExp(
        searchKeyword.replace(/[-+*.?|^$/()[\]{}\\]/g, '\\$&'),
        'gi'
      )
      let filteredList = this.state.fullList.filter(object => {
        return object.title.match(regexp)
      })
      this.setState({
        filteredList
      })
    })
  }

  handleReorder = async items => {
    if (this.state.pageType === 'main') {
      items.forEach((element, index) => {
        element.index = index
      })
      this.setState({ fullList: items, order: 'asc', orderBy: 'index' })
      await sessionActions.saveSessionsList(
        this.state.fullList.map(obj => {
          return { _id: obj._id, index: obj.index }
        })
      )
    }
  }

  getTaskById = taskId => {
    const task = this.state.taskList.filter(task => task._id === taskId)
    return (
      <div className={'table-row'} >
        <div style={{ width: '15%', paddingLeft: '10px' }}>Feel Good task</div>
        <div style={{ width: '30%', paddingLeft: '10px' }}>
          {task[0].title}
        </div>
        <div style={{ width: '35%', paddingLeft: '10px' }} >
        {task[0].question}
        </div>
        <div style={{ width: '60%', paddingLeft: '15px' }}>
          {task[0].description}
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}></div>
        <div style={{ width: '20%', paddingLeft: '15px' }}></div>
      </div>
      )
  }

  handleDeleteSession = (e, item) => {
    this.setState({
      deleteSessionId: item._id
    })
    this.handleClickOpen()
  }

  deleteSession = async () => {
    const result = await sessionActions
      .deleteSession({ sessionId: this.state.deleteSessionId })
    if (result.success) {
      const list = await this.getList()
      this.setState({
        deleteSessionId: null,
        open: false,
        fullList: list,
        filteredList: list
      })
    } else {
      console.error(result)
    }
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    })
  }

  handleClose = value => {
    this.setState({
      open: false
    })
  }

  async componentDidMount () {
    const list = await this.getList()
    if (config.feature.feelGoodTask) {
      const taskList = await this.getTaskList()
      this.setState({ taskList: taskList })
    }

    const sessionTypes = await sessionTypeActions.getSessionTypeList()

    this.setState({
      fullList: list,
      filteredList: list,
      isLoading: false,
      sessionTypes
    })
  }

  makeToolBar = () => {
    return (
      <div className="device-tools">
        <div className="search-icon">
          <SearchIcon />
          <input
            className="device-search"
            onChange={this.handleSearch}
            placeholder={this.getTexts().searchTitle}
            tabIndex="-1"
          />
        </div>
        <div>
          <button className={'btn green mr-2'} onClick={this.edit}>
            +
          </button>
        </div>
      </div>
    )
  }

  makeHead = () => {
    return (
      <div className={'table-head'}>
        <div style={{ width: '15%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Image" />
        </div>

        <div style={{ width: '30%', paddingLeft: '15px' }}>
          <MyTableHeaderSort
            label="Title"
            active={this.state.orderBy === 'title'}
            direction={this.state.order}
            onClick={this.createSortHandler('title')}
          />
        </div>

        {this.state.pageType === 'main' && (
          <div style={{ width: '20%', paddingLeft: '15px' }}>
            <MyTableHeaderSort
              label="Type"
              active={this.state.orderBy === 'type'}
              direction={this.state.order}
              onClick={this.createSortHandler('type')}
            />
          </div>
        )}

        <div style={{ width: '15%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Audio File" />
        </div>

        <div style={{ width: '60%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Description" />
        </div>

        {(this.state.pageType === 'main' ||
          this.state.pageType === 'meTime') && (
          <div style={{ width: '10%', paddingLeft: '15px' }}>
            <MyTableHeaderSort
              label="Free"
              active={this.state.orderBy === 'isFree'}
              direction={this.state.order}
              onClick={this.createSortHandler('isFree')}
            />
          </div>
        )}

        {(this.state.pageType === 'main' ||
          this.state.pageType === 'meTime') && (
          <div style={{ width: '10%', paddingLeft: '15px' }}>
            <MyTableHeaderSort
              label="Locked for Unregistered"
              active={this.state.orderBy === 'isLockedForUnregisteredUsers'}
              direction={this.state.order}
              onClick={this.createSortHandler('isLockedForUnregisteredUsers')}
            />
          </div>
        )}

        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Actions" />
        </div>

        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Transcription" />
        </div>

        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Delete" />
        </div>
      </div>
    )
  }

  makeRow = item => {
    return (
      <div className={'table-row'} style={{ flexWrap: 'wrap' }}>
      <div className={'table-row'}>
        <div style={{ width: '15%', paddingLeft: '15px' }}>
          {item.imageFileName !== '' ? (
            <img
              style={{ width: 50, height: 50, objectFit: 'contain' }}
              src={urlImg(item.imageFileName)}
              alt=""
            />
          ) : (
            <img
              style={{ width: 50, height: 50, objectFit: 'contain' }}
              src={imageLib.noImage}
              alt=""
            />
          )}
        </div>

        <div style={{ width: '30%', paddingLeft: '15px' }}>{item.title}</div>
        {this.state.pageType === 'main' && (
          <div style={{ width: '20%', paddingLeft: '15px' }}>
            {sessionTypeActions.getSessionTypeName(item.type, this.state.sessionTypes)}
          </div>
        )}
        <div style={{ width: '15%', paddingLeft: '15px' }}>
          <a href={urlAudio(item.audioFileName)}>Open</a>
        </div>
        <div style={{ width: '60%', paddingLeft: '15px' }}>
          {this.shortenText(item.description, 200)}
        </div>

        {(this.state.pageType === 'main' ||
          this.state.pageType === 'meTime') && (
          <div style={{ width: '10%', paddingLeft: '15px' }}>
            {item.isFree === true ? (
              <RemoveIcon />
            ) : (
              <img src={imageLib.crown} alt="" />
            )}
          </div>
        )}

        {(this.state.pageType === 'main' ||
          this.state.pageType === 'meTime') && (
          <div style={{ width: '10%', paddingLeft: '15px' }}>
            {item.isLockedForUnregisteredUsers === true &&
              <AddIcon />
            }

            {item.isLockedForUnregisteredUsers === false &&
              <RemoveIcon />
            }
          </div>
        )}

        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <button
            type="button"
            className="btn"
            onClick={e => this.edit(e, item)}
          >
            Edit
          </button>
        </div>

        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <button
            type="button"
            className="btn"
            onClick={e => this.editTranscription(e, item)}
          >
            Edit
          </button>
        </div>

        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <button
            type="button"
            className="btn"
            onClick={e => this.handleDeleteSession(e, item)}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>

      {(item.feelGoodTaskId && item.feelGoodTaskId !== null)
        ? <div className={'table-row'} style={{
          minWidth: '100%',
          backgroundColor: 'rgb(241, 241, 241)',
          padding: '4px 0',
          margin: '0 0 4px 5px'
        }}>
          {this.getTaskById(item.feelGoodTaskId)}
        </div> : ''
      }
      </div>
    )
  }

  render () {
    return (
      <HomePage router={this.props.router}>
        <div className="device-table-container">
          {!this.state.isLoading && (
            <MyTable
              tableName={this.getTexts().tableTitle}
              tableToolBar={this.makeToolBar}
              tableHead={this.makeHead}
              tableRow={this.makeRow}
              isDraggable={this.state.pageType === 'main'}
              // isCheckable={true}
              hasIndex={true}
              order={this.state.order}
              orderBy={this.state.orderBy}
              data={this.state.filteredList}
              onDragEnd={items => {
                this.handleReorder(items)
              }}
              hasPagination={true}
              rowsPerPage={100}
            />
          )}
        </div>
        <Dialog
          onClose={this.handleClose}
          open={this.state.open}
          aria-labelledby="delete-dialog-title"
        >
          <DialogTitle id="simple-dialog-title">
            Confirm session delete
          </DialogTitle>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 24px 24px 24px'
          }}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleClose}>Cancel</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.deleteSession}>Ok</Button>
          </div>
        </Dialog>
      </HomePage>
    )
  }
}

export default SessionList
