import React, { Fragment, Component } from 'react'
// import './Style.css';
import config from '../../config'
import HomePage from '../home'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList } from 'recharts'

import MyPage from '../../components/MyPage'

import { actions as userActions } from '../../redux/modules/users'
import { actions as firstQuizActions } from '../../redux/modules/firstQuiz'
import { actions as regPollsActions } from '../../redux/modules/registrationPolls'
import { actions as painStatisticsActions } from '../../redux/modules/painStatistics'

import { SubscriptionsStatistics } from '../../components/SubscribtionsStatistics'
import DiagnosisTop from '../../components/DiagnosisTop'
import FactorsTop from '../../components/FactorsTop'
import RegistrationPollsStatistics from '../../components/RegistrationPollsStatistics'

// import PageChecker from '../../utils/PageChecker';

class Dashboard extends MyPage {
  constructor (props) {
    super(props)
    this.state = {
      userNumber: 0,
      subscribedNumber: 0,
      subscriptionsStatistic: {},
      top10Diagnosis: [],
      factorsUsageStatistics: [],
      customFactorsUsageStatistics: [],
      isLoading: true
    }
  }

  async componentDidMount () {
    const result = await userActions.getAllUsersStatistics()
    const firstQuizData = await firstQuizActions.getFirstQuizResults()
    const regPollsData = await regPollsActions.getStatistics()
    const factorsUsageStatistics = await painStatisticsActions.getFactorsUsageStatistics()
    const customFactorsUsageStatistics = await painStatisticsActions.getCustomFactorsUsageStatistics()

    this.setState({
      userNumber: result.data.userNumber,
      subscribedNumber: result.data.subscribedNumber,
      firstQuizData: firstQuizData,
      regPollsData: regPollsData,
      factorsUsageStatistics,
      customFactorsUsageStatistics,
      isLoading: false
    })

    if (config.feature.diagnosis) {
      this.setState({
        top10Diagnosis: result.data.top10Diagnosis,
        subscriptionsStatistic: result.data.subscriptionsStatistic
      })
    }
  }

  top10DiagnosisData = () => {
    return this.state.top10Diagnosis.map(element => {
      return { name: element.name, Requests: element.count }
    })
  }

  tableLineBreak = () => {
    return (
      <tr>
        <td style={{ height: '16px' }} />
      </tr>
    )
  }

  step1Labels = value => {
    const questions = [
      '',
      'I expect and worry about pain in certain situations.',
      'I will stop any activity as soon as I sense pain coming on.',
      'I try to avoid activities that cause pain.',
      'Pain seems to cause my heart to pound or race.',
      'As soon as pain comes on, I take medication to reduce it.',
      'When I feel pain, I think that I may be seriously ill or injured.',
      'When I hurt I think about the pain constantly.',
      'When pain comes on strong I think I might become paralyzed or more disabled.',
      'I find it hard to concentrate when I hurt.'
    ]
    return questions[value]
  }

  renderStep1Tooltip = props => {
    const { active, label, payload, separator } = props
    if (active) {
      return (
        <div
          style={{
            backgroundColor: 'white',
            padding: '10px',
            border: 'solid 1px lightgrey'
          }}
        >
          <div style={{ width: '200px' }}>
            <p>{this.step1Labels(label)}</p>
          </div>
          {payload.map((element, index) => {
            return (
              <p key={`toolip_${index}`}>
                <span style={{ color: element.fill }}>{`${element.name} ${separator} ${element.value}`}</span>
              </p>
            )
          })}
        </div>
      )
    }
  }

  step2Label = value => {
    const types = [
      'People-pleaser',
      'Perfectionist',
      'High expectations of yourself',
      'Self critical',
      'Overly responsible',
      'Guilt-prone',
      'None of the above'
    ]
    return types[value]
  }

  step2Tooltip = (value, name, props) => {
    const total =
      this.state.firstQuizData[`E2Q${props.payload.id}`][0] + this.state.firstQuizData[`E2Q${props.payload.id}`][1]
    return value + ' out of ' + total
  }

  step3Labels = value => {
    const step3Questions = {
      E3Q1:
        'Have you suffered from physical, sexual or emotional abuse, abandonment and loss, parental arguing, bullying or similar traumatic events?',
      E3Q2: 'Do you have a history of psychophysiological disorders e.g. PTSD, depression, bipolar illness?',
      E3Q3: 'Were there significant life events occurring at the onset of the pain symptom(s)?'
    }
    return step3Questions['E3Q' + value]
  }

  renderStep3Tooltip = props => {
    const { active, label, payload, separator } = props
    if (active) {
      return (
        <div
          style={{
            backgroundColor: 'white',
            padding: '10px',
            border: 'solid 1px lightgrey'
          }}
        >
          <div style={{ width: '200px' }}>
            <p>{this.step3Labels(label)}</p>
          </div>
          {payload.map((element, index) => {
            return (
              <p key={`toolip_${index}`}>
                <span style={{ color: element.fill }}>{`${element.name} ${separator} ${element.value}`}</span>
              </p>
            )
          })}
        </div>
      )
    }
  }

  // if (! this.state.isLoading) {

  render () {
    let E1ScoreData = []
    let E2ScoreData = []
    let E3ScoreData = []

    if (config.feature.diagnosis && !this.state.isLoading) {
      for (let i = 1; i <= 9; i++) {
        E1ScoreData.push({
          name: i,
          Never: this.state.firstQuizData[`E1Q${i}`][0],
          Rarely: this.state.firstQuizData[`E1Q${i}`][1],
          Sometimes: this.state.firstQuizData[`E1Q${i}`][2],
          Often: this.state.firstQuizData[`E1Q${i}`][3],
          'Almost Always': this.state.firstQuizData[`E1Q${i}`][4],
          Always: this.state.firstQuizData[`E1Q${i}`][5]
        })
      }
      const types = [
        '',
        'People-pleaser',
        'Perfectionist',
        'High expectations of yourself',
        'Self critical',
        'Overly responsible',
        'Guilt-prone',
        'None of the above'
      ]
      for (let i = 1; i <= 7; i++) {
        E2ScoreData.push({
          id: i,
          type: types[i],
          Selected: this.state.firstQuizData[`E2Q${i}`][0]
        })
      }
      for (let i = 1; i <= 3; i++) {
        E3ScoreData.push({
          name: i,
          Unanswered: this.state.firstQuizData[`E3Q${i}`][0],
          Yes: this.state.firstQuizData[`E3Q${i}`][1],
          No: this.state.firstQuizData[`E3Q${i}`][2]
        })
      }
    }

    return (
      <HomePage router={this.props.router} addClassName="h-100">
        {!this.state.isLoading && (
          <div className="devices-page-wrapper">
            <h3 style={{ paddingLeft: '15px' }}>Dashboard</h3>
            <div className="dashboard-page">
              <table style={{ width: '50%' }}>
                <tbody>
                  <tr>
                    <th>Users</th>
                    <td>{this.state.userNumber}</td>
                  </tr>
                  <tr>
                    <th>Subscribed</th>
                    <td>{this.state.subscribedNumber}</td>
                  </tr>
                  {this.tableLineBreak()}
                  <tr>
                    <th><h5 className="h-statistics">Subscriptions Statistics</h5></th>
                  </tr>
                </tbody>
              </table>

              <SubscriptionsStatistics subscriptionsStatistic={this.state.subscriptionsStatistic} />
              {config.feature.diagnosis ? (
                <Fragment>
                  <DiagnosisTop top10Diagnosis={this.state.top10Diagnosis} />
                  <RegistrationPollsStatistics regPollsData={this.state.regPollsData} />
                  <FactorsTop 
                    title='Top 100 Factors'
                    subtitle='Usage Statistics for the last month'
                    factors={this.state.factorsUsageStatistics}
                   />
                  <FactorsTop 
                    title='Top 100 Custom Factors'
                    subtitle='Usage Statistics for the last month'
                    factors={this.state.customFactorsUsageStatistics}
                  />
                </Fragment>
              ) : null}
            </div>
            {config.feature.diagnosis ? (
              <div className="device-table-container">
                <h3>First Quiz Reports</h3>
                <div className="dashboard-page">
                  <h5>Step 1</h5>
                  <BarChart
                    width={900}
                    height={300}
                    data={E1ScoreData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    barCategoryGap={'10%'}
                    reverseStackOrder={true}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip content={this.renderStep1Tooltip} />
                    <Legend />
                    <Bar dataKey="Never" stackId="a" fill="#bdb2bd" />
                    <Bar dataKey="Rarely" stackId="a" fill="#5588ee" />
                    <Bar dataKey="Sometimes" stackId="a" fill="#82ca9d" />
                    <Bar dataKey="Often" stackId="a" fill="#eecc55" />
                    <Bar dataKey="Almost Always" stackId="a" fill="#ee9955" />
                    <Bar dataKey="Always" stackId="a" fill="#ee5533" />
                  </BarChart>
                </div>
                <div className="dashboard-page mt-3">
                  <h5>Step 2</h5>
                  <BarChart
                    width={900}
                    height={300}
                    data={E2ScoreData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis hide={true} />
                    <YAxis />
                    <Tooltip labelFormatter={this.step2Label} formatter={this.step2Tooltip} />
                    <Bar dataKey="Selected" stackId="a" fill="#5588ee">
                      <LabelList dataKey="type" position="top" />
                    </Bar>
                  </BarChart>
                </div>
                <div className="dashboard-page mt-3">
                  <h5>Step 3</h5>
                  <BarChart
                    width={600}
                    height={200}
                    data={E3ScoreData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    barGap={'0%'}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip content={this.renderStep3Tooltip} />
                    <Legend />
                    <Bar dataKey="Unanswered" stackId="a" fill="#bdb2bd" />
                    <Bar dataKey="Yes" stackId="b" fill="#82ca9d" />
                    <Bar dataKey="No" stackId="c" fill="#ee9955" />
                  </BarChart>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </HomePage>
    )
  }
}

export default Dashboard
