import React from 'react'
import { browserHistory } from 'react-router'
import HomePage from '../home'
import Formsy from 'formsy-react'
import MyPage from '../../components/MyPage'
import MyTable from '../../components/MyTable'
import MyTableHeaderSort from '../../components/MyTableHeaderSort'
import MyInput from '../../components/MyInput'
import Button from '@material-ui/core/Button'
import { Tooltip } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import CheckIcon from '@material-ui/icons/Check'
import RemoveIcon from '@material-ui/icons/Remove'
import Favorite from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import DiagnosisIcon from '@material-ui/icons/LocalHospital'
import AssignmentIcon from '@material-ui/icons/Assignment'
import ArchiveIcon from '@material-ui/icons/Delete'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Fab from '@material-ui/core/Fab'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { actions as userActions } from '../../redux/modules/users'
import { actions as factorActions } from '../../redux/modules/factors'
import { urlImg } from '../../redux/fetch/urlHelper'
import formatDate from '../../utils/formatDate'
import imageLib from '../../themes'
import config from '../../config'
import moment from 'moment'
import { MONTHLY_SUBSCRIPTION, ANNUAL_SUBSCRIPTION, LIFETIME_SUBSCRIPTION, ONE_OFF_LIFETIME_SUBSCRIPTION, ONE_OFF_LIFETIME_SUBSCRIPTION_79_99 } from '../../config'

class UserEdit extends MyPage {
  constructor(props) {
    super(props)

    let obj
    if (this.props.location.state) {
      obj = this.props.location.state.obj
    } else {
      browserHistory.push('/user-list')
    }

    this.state = {
      object: obj,
      userId: obj ? obj._id : '',
      name: obj ? obj.name : '',
      email: obj ? obj.email : '',
      password: '',
      editPassword: false,
      objectSet: !!obj,
      diagnosisList: [],
      factors: [],
      factorList: [],
      factorTitleList: {},
      sessions: [],
      subscriptions: [],
      isLoading: true,
      canSubmit: false,
      showValidationErrors: false,
      tabValue: 0,
      oldPRPSessions: [],
      newPRPSessions: [],
      mindSessions: [],
      bodySessions: []
    }
  }

  getTexts = () => {
    return {
      pageTitle: 'User'
    }
  }

  handleChange = e => {
    e.preventDefault()
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  editPasswordMode = () => {
    if (this.state.editPassword && this.state.password !== '') {
      this.handleResetPassword()
    }
    this.setState({
      editPassword: !this.state.editPassword
    })
  }

  handleResetPassword = async () => {
    const data = {
      userId: this.state.object._id,
      password: this.state.password
    }
    userActions.setNewPassword(data)
  }

  createSortHandlerDiagnosis = property => event => {
    this.handleSortDiagnosis(event, property)
  }

  handleSortDiagnosis = (e, property) => {
    const orderByDiagnosis = property
    let orderDiagnosis = 'desc'
    if (
      this.state.orderByDiagnosis === property &&
      this.state.orderDiagnosis === 'desc'
    ) {
      orderDiagnosis = 'asc'
    }
    this.setState({ orderDiagnosis, orderByDiagnosis })
  }

  cancel = e => {
    e.preventDefault()
    this.props.router.push('/user-list')
  }

  save = async e => {
    e.preventDefault()
    this.state.object.name = this.state.name
    this.state.object.email = this.state.email
    const result = await userActions.saveUser(this.state.object)
    if (result) {
      this.cancel(e)
    }
  }

  handleChangeUserSubscription = async event => {
    const data = {
      userId: this.state.object._id,
      saveData: {
        productId: event.target.value,
        transactionDate: Date.now(),
        setByAdminAt: Date.now(),
        transactionId: 'ADM' + Date.now()
      }
    }
    userActions
      .setSubscriptions(data)
      .then(() => {
        return userActions.getSubscriptions(this.state.object._id)
      })
      .then(newSubscriptions => {
        this.setState({
          subscriptions: newSubscriptions
        })
      })
      .catch(error => console.error(error))
  }

  removeSubscribtion = async event => {
    userActions
      .deleteSubscription({ subscriptionId: event.currentTarget.id })
      .then(() => {
        return userActions.getSubscriptions(this.state.object._id)
      })
      .then(newSubscriptions => {
        this.setState({
          subscriptions: newSubscriptions
        })
      })
      .catch(error => console.error(error))
  }

  makeHeadDiagnosis = () => {
    return (
      <div className={'table-head'}>
        <div style={{ width: '10%', paddingLeft: '15px' }} />
        <div style={{ width: '100%', paddingLeft: '15px' }}>
          <MyTableHeaderSort
            label="Name"
            active={this.state.orderByDiagnosis === 'title'}
            direction={this.state.orderDiagnosis}
            onClick={this.createSortHandlerDiagnosis('title')}
          />
        </div>
        <div style={{ width: '30%', paddingLeft: '15px' }}>Created</div>
      </div>
    )
  }

  makeHeadFactors = () => {
    return (
      <div className={'table-head'}>
        <div style={{ width: '30%', paddingLeft: '15px' }}>
          <MyTableHeaderSort
            label="Date"
            active='Date'
          />
        </div>
        <div style={{ width: '70%', paddingLeft: '15px' }}>Marked Factors</div>
      </div>
    )
  }

  makeRowFactors = item => {
    return (
      <div className={'table-row'}>
        <div style={{ width: '30%', paddingLeft: '15px' }}>
          {item.date}
        </div>
        <div style={{ width: '70%', paddingLeft: '15px' }}>
          {item.factors.map(({ title }) => title).join(', ')}
        </div>
      </div>
    )
  }

  makeRowDiagnosis = item => {
    return (
      <div className={'table-row'}>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          {item.isArchived ? (
            <Tooltip placement="top" title="Archived">
              <ArchiveIcon />
            </Tooltip>
          ) : item.diagnosisRequestId ? (
            <Tooltip placement="top" title="Diagnosis">
              <DiagnosisIcon />
            </Tooltip>
          ) : (
                <Tooltip placement="top" title="Request">
                  <AssignmentIcon />
                </Tooltip>
              )}
        </div>
        <div style={{ width: '100%', paddingLeft: '15px' }}>{item.title}</div>
        <div style={{ width: '30%', paddingLeft: '15px' }}>
          {formatDate(item.createdDate)}
        </div>
      </div>
    )
  }

  makeHeadRegistrationPolls = () => {
    return (
      <div className={'table-head'}>
        <div style={{ width: '60%', paddingLeft: '15px' }}>
          Poll Title
        </div>
        <div style={{ width: '40%', paddingLeft: '15px' }}>
          Votes
        </div>
      </div>
    )
  }

  makeRowRegistrationPolls = item => {
    let answers = ''
    if (item.selectedOptions && item.selectedOptions.length && item.poll && item.poll.pollOptions) {
      item.selectedOptions.forEach(selectedOptionId => {
        item.poll.pollOptions.forEach(possibleOption => {
          if (selectedOptionId === possibleOption._id) {
            if (answers.length === 0) {
              answers = possibleOption.pollOptionText
            } else {
              answers += ', ' + possibleOption.pollOptionText
            }
          }
        })
      })
    }

    return (
      <div className={'table-row'}>
        <div style={{ width: '60%', paddingLeft: '15px' }}>{item.poll ? item.poll.pollTitle : null}</div>
        <div style={{ width: '40%', paddingLeft: '15px' }}>{answers}</div>
      </div>
    )
  }

  makeHeadSessions = () => {
    return (
      <div className={'table-head'}>
        <div style={{ width: '5%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="#" />
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Image" />
        </div>
        <div style={{ width: '50%', paddingLeft: '15px' }}>
          <MyTableHeaderSort
            label="Title"
            active={this.state.orderBy === 'title'}
            direction={this.state.order}
            onClick={this.createSortHandler('title')}
          />
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <MyTableHeaderSort
            label="Free"
            active={this.state.orderBy === 'isFree'}
            direction={this.state.order}
            onClick={this.createSortHandler('isFree')}
          />
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Played" />
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          <MyTableHeaderSort label="Favorite" />
        </div>
      </div>
    )
  }

  makeRowSessions = item => {
    return (
      <div className={'table-row'}>
        <div style={{ width: '5%', paddingLeft: '15px' }}>{item.index + 1}</div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          {item.imageFileName !== '' ? (
            <img
              style={{ width: 50, height: 50, objectFit: 'contain' }}
              src={urlImg(item.imageFileName)}
              alt=""
            />
          ) : (
              <img
                style={{ width: 50, height: 50, objectFit: 'contain' }}
                src={imageLib.noImage}
                alt=""
              />
            )}
        </div>
        <div style={{ width: '50%', paddingLeft: '15px' }}>
          <span
            style={
              item.isArchived ? { color: 'lightgrey' } : { color: 'black' }
            }
          >
            {item.title}
          </span>
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          {item.isFree === true ? (
            <RemoveIcon />
          ) : (
              <img src={imageLib.crown} alt="" />
            )}
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          {item.isPlayed === true ? <CheckIcon /> : <RemoveIcon />}
        </div>
        <div style={{ width: '10%', paddingLeft: '15px' }}>
          {item.isFavorite === true ? <Favorite /> : <FavoriteBorderIcon />}
        </div>
      </div>
    )
  }

  async componentDidMount () {
    if (this.state.objectSet && this.state.object._id) {
      const user = await userActions.getById(this.state.object._id)
      const diagnosisList = await userActions.getDiagnosis(
        this.state.object._id
      )

      const sessionList = await userActions.getSessions(this.state.object._id)
      const firstQuizResults = await userActions.getFirstQuizResults(
        this.state.object._id
      )
      const subscriptions = await userActions.getSubscriptions(
        this.state.object._id
      )
      const userInfo = await userActions.getUserInfo(this.state.object._id)

      const oldPRPSessions = sessionList.filter(({ type }) => type === 0 || type === 1 || type === 2 || type === 3)
      const newPRPSessions = sessionList.filter(({ type }) => type === 7 || type === 8 || type === 9)
      const mindSessions = sessionList.filter(({ section, type }) => section === 'mind' || type === 5)
      const bodySessions = sessionList.filter(({ section }) => section === 'body')

      this.setState({
        user: user,
        diagnosis: diagnosisList,
        sessions: sessionList,
        firstQuizResults: firstQuizResults.data,
        subscriptions: subscriptions,
        userInfo: userInfo,
        isLoading: false,
        oldPRPSessions,
        newPRPSessions,
        mindSessions,
        bodySessions
      })
    }

    await this.loadFactors()
    await this.loadUserFactors(this.state.object._id)
  }

  loadFactors = async (page = null, perPage = null) => {
    const _page = page ? page : 1
    const _perPage = perPage ? perPage : 200

    const { success, data } = await factorActions.getList({
      page: _page,
      perPage: _perPage
    })

    if (success && data) {
      this.setState(state => {
        return {
          ...state,
          factorList: [
            ...state.factorList,
            ...data.items
          ]
        }
      }, () => {
        const factorTitleList = {}
        this.state.factorList.forEach(factor => {
        factorTitleList[factor._id] = factor.title
        this.setState({ factorTitleList }) 
      })
    })

      if (this.state.factorList.length < data.total) {
        await this.loadFactors(_page + 1)
      }
    }
  }

  loadUserFactors = async (userId, startDate = null, endDate = null) => {
    const toDate = startDate ? new Date(startDate) : new Date()
    let fromDate

    if (endDate) {
      fromDate = new Date(endDate)
    } else {
      fromDate = new Date(toDate)
      fromDate.setDate(fromDate.getDate() - 31)
    }

    const { painStatistics, hasMore } = await userActions.getPainStatistics(
      userId,
      fromDate,
      toDate
    )

    if (hasMore) {
      painStatistics.pop()
    }

    const factors = []

    painStatistics.forEach(item => {
      let completedFactors = item.factors.filter(factor => factor.completed === true)
      
      if (completedFactors && completedFactors.length) {
        completedFactors.map(factor => {
          factor.title = this.state.factorTitleList[factor.factorId]
          return factor
        })

        factors.push(
          {
            date: moment(item.date).format('YYYY-MM-DD'),
            factors: completedFactors
          }
        )
      }
    })

    this.setState(state => {
      return {
        ...state,
        factors: [
          ...state.factors,
          ...factors
        ]
      }
    })

    if (hasMore) {
      const _startDate = new Date(fromDate)
      _startDate.setDate(_startDate.getDate())
      await this.loadUserFactors(userId, _startDate)
    }
  }

  renderSuscriptionType = param => {
    switch (param) {
      case MONTHLY_SUBSCRIPTION:
        return `Monthly`
      case ANNUAL_SUBSCRIPTION:
        return `Annual`
      case LIFETIME_SUBSCRIPTION:
        return `Lifetime`
      case ONE_OFF_LIFETIME_SUBSCRIPTION:
        return `One-off Lifetime`
      case ONE_OFF_LIFETIME_SUBSCRIPTION_79_99:
        return `One-off Lifetime`
      default:
        console.log('Wrong data in subsriptions response')
        break
    }
  }

  step1Result = field => {
    const step1Questions = {
      E1Q1: 'I expect and worry about pain in certain situations.',
      E1Q2: 'I will stop any activity as soon as I sense pain coming on.',
      E1Q3: 'I try to avoid activities that cause pain.',
      E1Q4: 'Pain seems to cause my heart to pound or race.',
      E1Q5: 'As soon as pain comes on, I take medication to reduce it.',
      E1Q6: 'When I feel pain, I think that I may be seriously ill or injured.',
      E1Q7: 'When I hurt I think about the pain constantly.',
      E1Q8:
        'When pain comes on strong I think I might become paralyzed or more disabled.',
      E1Q9: 'I find it hard to concentrate when I hurt.'
    }
    const step1Answers = {
      0: 'Never',
      1: 'Rarely',
      2: 'Sometimes',
      3: 'Often',
      4: 'Almost Always',
      5: 'Always'
    }
    return (
      <div key={`question_${field}`} className={'table-row'}>
        <div style={{ width: '80%', paddingLeft: '15px' }}>
          {step1Questions[field]}
        </div>
        <div style={{ width: '20%', paddingLeft: '15px' }}>
          {step1Answers[this.state.firstQuizResults[field]]} (
          {this.state.firstQuizResults[field]})
        </div>
      </div>
    )
  }

  step2Result = field => {
    const step2Traits = {
      E2Q1: 'People-pleaser',
      E2Q2: 'Perfectionist',
      E2Q3: 'High expectations of yourself',
      E2Q4: 'Self critical',
      E2Q5: 'Overly responsible',
      E2Q6: 'Guilt-prone',
      E2Q7: 'None of the above'
    }
    return (
      this.state.firstQuizResults[field] && (
        <div key={`question_${field}`} className={'table-row'}>
          <div style={{ width: '100%', paddingLeft: '15px' }}>
            {step2Traits[field]}
          </div>
        </div>
      )
    )
  }

  step3Result = field => {
    const step3Questions = {
      E3Q1:
        'Have you suffered from physical, sexual or emotional abuse, abandonment and loss, parental arguing, bullying or similar traumatic events?',
      E3Q2:
        'Do you have a history of psychophysiological disorders e.g. PTSD, depression, bipolar illness?',
      E3Q3:
        'Were there significant life events occurring at the onset of the pain symptom(s)?'
    }
    const step3Answers = {
      0: 'Unanswered',
      1: 'Yes',
      2: 'No'
    }
    return (
      <div key={`question_${field}`} className={'table-row'}>
        <div style={{ width: '80%', paddingLeft: '15px' }}>
          {step3Questions[field]}
        </div>
        <div style={{ width: '20%', paddingLeft: '15px' }}>
          {step3Answers[this.state.firstQuizResults[field]]}
        </div>
      </div>
    )
  }

  getProfileImageUrl () {
    const { user } = this.state
    let imageUrl = '/assets/images/avatar-placeholder.jpg'

    if (user && user.profileImageUrl) {
      imageUrl = user.profileImageUrl
    }

    return imageUrl
  }

  handleChangeTabSessions = (e, tabValue) => {
    this.setState({
      tabValue,
    })
  };

  tabSessionsA11yProps = index => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  makeTabPanel = (value, index, tabPanelType) => {

    let dataSessions = []

    switch (tabPanelType) {
      case "PRP(old)":
        dataSessions = this.state.oldPRPSessions
        break;
      case "PRP(new)":
        dataSessions = this.state.newPRPSessions
        break;
      case "Mind":
        dataSessions = this.state.mindSessions
        break;
      case "Body":
        dataSessions = this.state.bodySessions
        break;
      case "All":
        dataSessions = this.state.sessions
        break;
      default:
        break;
    }

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
      >
        {value === index && (
          <Typography component={'span'}>
            {dataSessions.length > 0 ? (
              <MyTable
                tableHead={this.makeHeadSessions}
                tableRow={this.makeRowSessions}
                order={this.state.order}
                orderBy={this.state.orderBy}
                data={dataSessions}
                rowsPerPage={tabPanelType === "All" ? this.state.sessions.length : 5}
                hasPagination={true}
              />
            ) : (
                <Paper style={{ width: '100%', padding: '2%', fontSize: '14px' }}>
                  This user has no sessions.
                </Paper>
              )}
          </Typography>
        )}
      </div>
    );
  }

  render () {
    if (!this.state.objectSet) {
      return ''
    }
    const style = { color: 'red' }
    const profileImageUrl = this.getProfileImageUrl()
    return (
      <HomePage router={this.props.router}>
        {!this.state.isLoading && (
          <div className="devices-page-wrapper">
            <h1>{this.getTexts().pageTitle}</h1>
            <Formsy
              className="create-device-form"
              onValidSubmit={this.submit}
              onInvalidSubmit={() =>
                this.setState({ showValidationErrors: true })
              }
              onValid={() => this.setState({ canSubmit: true })}
              onInvalid={() => this.setState({ canSubmit: false })}
            >
              <div className="create-device-row">
                <label>
                  Name <span style={style}>*</span>
                </label>
                <MyInput
                  id="name"
                  name="name"
                  classNameDiv="create-branch-row"
                  className="create-device-input"
                  autocomplete="off"
                  value={this.state.name}
                  onChange={this.handleChange}
                  validationError="This field is required"
                  required
                  showError={this.state.showValidationErrors}
                />
              </div>

              <div className="create-device-row">
                <label>
                  Email <span style={style}>*</span>
                </label>
                <MyInput
                  id="email"
                  name="email"
                  classNameDiv="create-branch-row"
                  className="create-device-input"
                  autocomplete="off"
                  value={this.state.email}
                  onChange={this.handleChange}
                  validations="isEmail"
                  validationError="This is not a valid email"
                  required
                  showError={this.state.showValidationErrors}
                />
              </div>

              <div className="create-device-row">
                <label>
                  Profile Image
                </label>
                <img
                  className='edit-image'
                  src={profileImageUrl}
                  alt="profile image"
                />
              </div>

              <div className="create-device-row">
                <label>Password</label>
                {this.state.editPassword ? (
                  <MyInput
                    id="password"
                    name="password"
                    classNameDiv="create-branch-row"
                    className="create-device-input"
                    autocomplete="off"
                    value={this.state.password}
                    onChange={this.handleChange}
                  />
                ) : (
                    <span />
                  )}
                <Button
                  variant="contained"
                  color={this.state.editPassword ? 'secondary' : 'primary'}
                  onClick={this.editPasswordMode}
                  style={{ width: '200px' }}
                >
                  {this.state.editPassword
                    ? 'Save new Password'
                    : 'Set New Password'}
                </Button>
              </div>

              <div className="create-device-row">
                <label>Subscriptions</label>
                <Paper
                  style={{ width: '100%', padding: '2%', fontSize: '14px' }}
                >
                  <div>
                    <div className={'table-head bb'}>
                      <div style={{ width: '40%', paddingLeft: '15px' }}>
                        Type
                      </div>
                      <div style={{ width: '25%', paddingLeft: '15px' }}>
                        Created
                      </div>
                      <div style={{ width: '25%', paddingLeft: '15px' }}>
                        End
                      </div>
                      <div style={{ width: '10%', paddingLeft: '15px' }}>
                        Remove
                      </div>
                    </div>

                    {this.state.subscriptions.map((element, index) => {
                      return (
                        <div key={`diagnosis_${index}`} className={'table-row'}>
                          <div
                            style={{
                              width: '40%',
                              paddingLeft: '15px',
                              paddingTop: '10px',
                              paddingBottom: '10px'
                            }}
                          >
                            {this.renderSuscriptionType(element.productId)}
                          </div>
                          <div
                            style={{
                              width: '25%',
                              paddingLeft: '15px',
                              paddingTop: '10px',
                              paddingBottom: '10px'
                            }}
                          >
                            {formatDate(element.transactionDate)}
                          </div>
                          <div
                            style={{
                              width: '25%',
                              paddingLeft: '15px',
                              paddingTop: '10px',
                              paddingBottom: '10px'
                            }}
                          >
                            {formatDate(element.endDate)}
                          </div>
                          <div
                            style={{
                              width: '10%',
                              paddingLeft: '15px',
                              paddingTop: '10px',
                              paddingBottom: '10px'
                            }}
                            id={element._id}
                            onClick={this.removeSubscribtion}
                          >
                            <Fab aria-label="Delete">
                              <ArchiveIcon />
                            </Fab>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  <div className={'table-row'}>
                    <div style={{ width: '50%', paddingLeft: '15px' }}>
                      {this.state.subscriptions.length === 0
                        ? 'This user has no subscriptions.'
                        : ''}
                    </div>
                    <div style={{ width: '50%', paddingLeft: '15px' }}>
                      <FormControl className="" style={{ width: '200px' }}>
                        <InputLabel htmlFor="subscription">
                          Select subscription
                        </InputLabel>
                        <Select
                          value=""
                          onChange={this.handleChangeUserSubscription}
                          inputProps={{
                            name: 'subscription',
                            id: 'subscription'
                          }}
                        >
                          <MenuItem value={MONTHLY_SUBSCRIPTION}>
                            Monthly
                          </MenuItem>
                          <MenuItem value={ANNUAL_SUBSCRIPTION}>
                            Annual
                          </MenuItem>
                          <MenuItem value={LIFETIME_SUBSCRIPTION}>
                            Lifetime
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </Paper>
              </div>
              {config.feature.diagnosis
                ? <div className="create-device-row">
                  <label>Diagnosis</label>

                  {this.state.diagnosis.length > 0 ? (
                    <MyTable
                      tableHead={this.makeHeadDiagnosis}
                      tableRow={this.makeRowDiagnosis}
                      order={this.state.orderDiagnosis}
                      orderBy={this.state.orderByDiagnosis}
                      data={this.state.diagnosis}
                      rowsPerPage={5}
                      hasPagination={true}
                    />
                  ) : (
                      <Paper
                        style={{ width: '100%', padding: '2%', fontSize: '14px' }}
                      >
                        This user has no diagnosis.
                      </Paper>
                    )}
                </div>
                : null}
              <div className="create-device-row">
                <label>Sessions</label>
                <AppBar position="static" color="default">
                  <Tabs
                    value={this.state.tabValue}
                    onChange={this.handleChangeTabSessions}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="simple tabs example"
                  >
                    <Tab label={`PRP (old) ${this.state.oldPRPSessions.length}`} {...this.tabSessionsA11yProps(0)} />
                    <Tab label={`PRP (new) ${this.state.newPRPSessions.length}`} {...this.tabSessionsA11yProps(1)} />
                    <Tab label={`Mind ${this.state.mindSessions.length}`} {...this.tabSessionsA11yProps(2)} />
                    <Tab label={`Body ${this.state.bodySessions.length}`}{...this.tabSessionsA11yProps(3)} />
                    <Tab label={`All ${this.state.sessions.length}`}{...this.tabSessionsA11yProps(4)} />
                  </Tabs>
                </AppBar>
                {this.makeTabPanel(this.state.tabValue, 0, "PRP(old)")}
                {this.makeTabPanel(this.state.tabValue, 1, "PRP(new)")}
                {this.makeTabPanel(this.state.tabValue, 2, "Mind")}
                {this.makeTabPanel(this.state.tabValue, 3, "Body")}
                {this.makeTabPanel(this.state.tabValue, 4, "All")}
              </div>
              <div className="create-device-row">
                <label>Factors</label>

                {this.state.factors.length > 0 ? (
                  <MyTable
                    tableHead={this.makeHeadFactors}
                    tableRow={this.makeRowFactors}
                    data={this.state.factors}
                    rowsPerPage={20}
                    hasPagination={true}
                  />
                ) : (
                    <Paper
                      style={{ width: '100%', padding: '2%', fontSize: '14px' }}
                    >
                      This user has no factors.
                    </Paper>
                  )}
              </div>
              <div className="create-device-row">
                <label>Registration Polls</label>
                {this.state.userInfo && Object.keys(this.state.userInfo.registrationPolls).length > 0 ? (
                  <MyTable
                    tableHead={this.makeHeadRegistrationPolls}
                    tableRow={this.makeRowRegistrationPolls}
                    data={this.state.userInfo.registrationPolls}
                    rowsPerPage={10}
                    hasPagination={false}
                  />
                ) : (
                    <Paper
                      style={{ width: '100%', padding: '2%', fontSize: '14px' }}
                    >
                      This user has no answers on Registration Polls.
                    </Paper>
                  )}
              </div>
              <div className="create-device-row">
                <label>First Quiz Results</label>
                {Object.keys(this.state.firstQuizResults).length > 0 ? (
                  <div>
                    <Paper
                      style={{
                        width: '100%',
                        padding: '2%',
                        fontSize: '14px',
                        marginBottom: '10px'
                      }}
                    >
                      <div className={'table-head bb'}>
                        <div style={{ width: '80%', paddingLeft: '15px' }}>
                          Step 1
                        </div>
                        <div style={{ width: '20%', paddingLeft: '15px' }}>
                          Answers
                        </div>
                      </div>
                      {this.step1Result('E1Q1')}
                      {this.step1Result('E1Q2')}
                      {this.step1Result('E1Q3')}
                      {this.step1Result('E1Q4')}
                      {this.step1Result('E1Q5')}
                      {this.step1Result('E1Q6')}
                      {this.step1Result('E1Q7')}
                      {this.step1Result('E1Q8')}
                      {this.step1Result('E1Q9')}
                      <div key={'step1Total'} className={'table-row'}>
                        <div style={{ width: '80%', paddingLeft: '15px' }}>
                          Total Score
                        </div>
                        <div style={{ width: '20%', paddingLeft: '15px' }}>
                          {this.state.firstQuizResults.E1Score}
                        </div>
                      </div>
                    </Paper>
                    <Paper
                      style={{
                        width: '100%',
                        padding: '2%',
                        fontSize: '14px',
                        marginBottom: '10px'
                      }}
                    >
                      <div className={'table-head bb'}>
                        <div style={{ width: '80%', paddingLeft: '15px' }}>
                          Step 2
                        </div>
                      </div>
                      {this.step2Result('E2Q1')}
                      {this.step2Result('E2Q2')}
                      {this.step2Result('E2Q3')}
                      {this.step2Result('E2Q4')}
                      {this.step2Result('E2Q5')}
                      {this.step2Result('E2Q6')}
                      {this.step2Result('E2Q7')}
                    </Paper>
                    <Paper
                      style={{
                        width: '100%',
                        padding: '2%',
                        fontSize: '14px',
                        marginBottom: '10px'
                      }}
                    >
                      <div className={'table-head bb'}>
                        <div style={{ width: '80%', paddingLeft: '15px' }}>
                          Step 3
                        </div>
                        <div style={{ width: '20%', paddingLeft: '15px' }}>
                          Answers
                        </div>
                      </div>
                      {this.step3Result('E3Q1')}
                      {this.step3Result('E3Q2')}
                      {this.step3Result('E3Q3')}
                    </Paper>
                  </div>
                ) : (
                    <Paper
                      style={{ width: '100%', padding: '2%', fontSize: '14px' }}
                    >
                      This user has no data.
                    </Paper>
                  )}
              </div>

              <button
                className="btn green mr-2"
                type="submit"
                onClick={this.state.canSubmit ? this.save : undefined}
              >
                Save
              </button>
              <button
                className="btn blue mr-2"
                type="button"
                onClick={this.cancel}
              >
                Cancel
              </button>
            </Formsy>
          </div>
        )}
      </HomePage>
    )
  }
}

export default UserEdit
