const toolbarOptions = [
  ['bold', 'italic', 'underline'],
  ['blockquote'],
  [{ list: 'ordered' }, { list: 'bullet' }]
]

const modules = {
  toolbar: toolbarOptions
}

const theme = 'snow'

export default { modules, theme }
